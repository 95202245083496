import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import { ClipLoader } from 'react-spinners'
import { Button, Modal } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import {
  loadAllUsers, saveUser, deleteUser, usersUpdated,
  setUserSaved,
  setUsersLoaded,
  setUserDeleted,
  setError,
  setUserSaving,
  setUserDeleting
} from '../actions/userActions'
import { loadLandlords } from '../actions/landlordActions'
import { loadCompanys } from '../actions/companyActions'
import TextInput from './common/TextInput'
import SecuredTextInput from './common/SecuredTextInput'
import { logoutUser } from '../actions/sessionActions'
import { showInfoToast } from '../utils/Toast'

const rolesSuperuser = [
  { value: 'superuser', label: 'Superuser' },
  { value: 'admin', label: 'Administrator' },
  { value: 'companyAdmin', label: 'Company Administrator' }
]

const rolesAdmin = [
  { value: 'admin', label: 'Administrator' },
  { value: 'companyAdmin', label: 'Company Administrator' }
]

const rolesCompanyAdmin = [
  { value: 'companyAdmin', label: 'Company Administrator' }
]

let roles = []

const AdminUserDetails = (props) => {
  const { userId } = useParams()
  const navigate = useNavigate()
  const setContext: any = useOutletContext()

  const [originalRole, setOriginalRole] = useState('')

  const [state, setState] = useState({
    id: '',
    modalIsOpen: false,
    modalError: '',
    email: '',
    password: '',
    landlord: '',
    landlordId: '',
    role: '',
    roleIndex: '',
    deletingId: '-1',
    companies: [],
    firstName: '',
    lastName: ''
  })

  useEffect(() => {
    if (props.users === undefined || props.users == null) {
      props.loadAllUsers()
      props.loadCompanys()
      props.loadLandlords()
    } else {
      setUserState(props.users)
      props.loadCompanys()
      props.loadLandlords()
    }
    props.setUserSaving(false)
    props.setUserDeleting(false)
  }, [])

  useEffect(() => {
    if (setContext && state && (state.firstName || state.lastName)) {
      setContext({ name: `${state.firstName} ${state.lastName}` })
    }
    
  }, [state?.firstName, state?.lastName])

  useEffect(() => {
    if (props.userSaved) {
      console.log('props.id', props.id)
      console.log('userId', userId)

      if (originalRole !== '' && originalRole !== state.role && userId === props.id) {
        showInfoToast('Role for current user has been modified. You will be logged out shortly.')
        setTimeout(() => {
          props.logoutUser()
        }, 4000)
      }

      props.setUserSaved(false)
      
      changeState('id', props.id)
      changeState('companies', props.userCompanies)

      if (props.claims.role !== 'superuser') {
        changeState('landlordId', props.claims.landlordId)
      }

      props.setUserSaving(false)
      if (userId === 'new') {
        navigate(`/admin-users/${props.id}`)
      }
    }
    if (props.usersLoaded) {
      props.setUsersLoaded(false)
      setUserState(props.users)
    }
    if (props.userDeleted) {
      props.setUserDeleted(false)
      if (state.id === props.claims.userId) {
        props.logoutUser()
        navigate('/Login')
      } else {
        navigate('/admin-users')
      }
      onNew()
    }

    if (props.error !== '') {
      props.setError('')
      setState({ ...state, modalIsOpen: false })
    }
  }, [props])

  const onChange = (event) => {
    changeState(event.target.name, event.target.value)
  }

  const onDelete = (event) => {
    event.preventDefault()
    openModal()
  }

  const onDeleteCompanyField = (event, index) => {
    event.preventDefault()
    const comps = [...state.companies]

    comps.splice(index, 1)

    addEmptyCompanyAtEnd(comps)
  }

  const onLandlordChanged = (val) => {
    if (val) {
      if (val.value !== state.landlordId) {
        changeState('companies', [])
      }
      changeState('landlord', val.label)
      changeState('landlordId', val.value)
    }
  }

  const onCompanySelectChanged = (index, val) => {
    if (val) {
      const comps = []
      state.companies.forEach((y, id) => {
        if (id === index) {
          comps.push(val.value)
        } else {
          comps.push(y)
        }
      })
      addEmptyCompanyAtEnd(comps)
    }
  }

  const onNew = () => {
    setState({
      id: '',
      email: '',
      password: '',
      landlord: '',
      landlordId: '',
      firstName: '',
      lastName: '',
      role: '',
      roleIndex: '',
      modalIsOpen: false,
      modalError: '',
      deletingId: '-1',
      companies: []
    })
  }

  const onRoleChanged = (val) => {
    if (val) {
      changeState('role', val.label)
      changeState('roleIndex', val.value)
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    props.saveUser(props.claims, state)
  }

  const setUserState = (users) => {
    if (userId && userId !== 'new' && userId !== '') {
      console.log('userId', userId)
      console.log('users', users)
      const user = users.filter((x) => {
        return x.id === userId
      })
      if (user !== null && user.length === 1) {
        return populateExisting(user[0])
      }
    }
    return onNew()
  }

  const addEmptyCompanyAtEnd = (comps) => {
    const countEmpty = comps.filter(x => x === '').length
    if (countEmpty < 1) {
      let newArray = comps.slice()
      if (newArray === null) {
        newArray = []
      }
      newArray.push('')
      setState({ ...state, companies: newArray })
    } else {
      setState({ ...state, companies: comps })
    }
  }

  const deleteAndCloseModal = (event) => {
    event.preventDefault()
    props.deleteUser(state, props.claims.userId)
  }

  const openModal = () => {
    props.setUserDeleting(false)
    setState({ ...state, modalIsOpen: true, modalError: '' })
  }

  const closeModal = () => {
    setState({ ...state, modalIsOpen: false, modalError: '' })
  }

  const populateExisting = (row) => {
    const companyIds = row.companies.map(x => x.id)
    companyIds.push('')
    setOriginalRole(row.role)
    setState({
      id: row.id,
      email: row.email,
      password: '',
      landlord: row.landlord,
      landlordId: row.landlordId,
      role: row.role,
      roleIndex: row.role,
      firstName: row.firstName,
      lastName: row.lastName,
      modalIsOpen: false,
      modalError: '',
      deletingId: '-1',
      companies: companyIds
    })
  }

  const changeState = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const renderModal = () => {
    let message = null
    if (state.id === props.claims.userId) {
      message = 'WARNING - Please confirm - You are about to delete yourself as a user. You will be logged out after deleting.'
    } else {
      message = 'Please confirm you wish to delete this user'
    }
    return (
      <Modal show={state.modalIsOpen} onHide={closeModal}>
        <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <Modal.Title style={{ marginRight: 'auto'}}>Delete</Modal.Title>
          {/* Custom Close Button */}
          <Button 
            type='button'
            className='close'
            aria-label='Close'
            onClick={closeModal}
            style={{ border: 'none', boxShadow: 'none' }}
            disabled={props.userDeleting}
          >
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-danger'
            onClick={deleteAndCloseModal}
            disabled={props.userDeleting}
          >
            Delete {props.userDeleting ? buttonSpinner : ''}
          </button>
          <button
            className='btn btn-default pull-left'
            onClick={closeModal}
            disabled={props.userDeleting}
          >
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  const spinner = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
      <ClipLoader size={40} />
    </div>
  )

  const buttonSpinner = <ClipLoader size={15} />

  const submit = (
    <button
      style={{ marginRight: 10 }}
      className='btn btn-primary'
      onClick={onSubmit}
      disabled={props.userSaving}
    >
      Save {props.userSaving ? buttonSpinner : ''}
    </button>
  )

  let deleteBtn = null
  if (state.id) {
    deleteBtn = (
      <button
        className='btn btn-danger'
        onClick={onDelete}
        disabled={props.userSaving}
      >
        Delete
      </button>
    )
  }

  let roleSelect = null
  let landlordSelect = null
  if (props.claims.role === 'superuser') {
    roles = rolesSuperuser
    roleSelect = (
      <div className='form-group col-sm-12 col-lg-5 '>
        <label htmlFor='role'>Role</label>
        <Select
          name='role'
          value={roles.find(x => x.value === state.roleIndex)}
          options={roles}
          onChange={onRoleChanged}
          isClearable={false}
        />
      </div>
    )

    landlordSelect = (
      <div className='form-group col-sm-12 col-lg-5 col-lg-pull-5' >
        <label htmlFor='landlord'>Landlord</label>
        <Select
          name='landlord'
          value={props.landlords.find(x => x.value === state.landlordId)}
          options={props.landlords}
          onChange={onLandlordChanged}
          isClearable={false}
        />
      </div>
    )
  }

  const validCompanies = props.companys ?
    props.companys.filter(x => x.landlordId ===
      (props.claims.role !== 'superuser' ? props.claims.landlordId : state.landlordId)).map((y) => {
      return ({ value: y.id, label: y.name })
    }) : []

  let companySelect = null
  if (state.role === 'companyAdmin' || state.roleIndex === 'companyAdmin') {
    if (state.companies.length === 0) {
      const array = ['']
      setState({ ...state, companies: array })
    }

    if (props.companys) {
      companySelect = state.companies.map((numbers, index) => {

        // This check is added hide those companies that are not assigned to the company admin who is logged in but assigned to user
        // whose detail are opened so instead of empty drop down we are hiding it.
        let hiddenClass = (validCompanies.find(x => x.value === numbers) === null ||
          validCompanies.find(x => x.value === numbers) === undefined) && numbers !== '' ? 'hidden' : ''
        // To filter company options that need to be select as we dont want to enter company that are already assigned
        let companyOption = validCompanies.filter(function (e) { return this.indexOf(e.value) < 0 }, 
          state.companies.filter(x => x !== numbers))

        return (
          <div className={'form-group col-sm-12 col-lg-5 ' + hiddenClass} key={index}>
            <label htmlFor={numbers}>Company</label>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: '1' }}>
                <Select
                  name={numbers}
                  options={companyOption}
                  value={companyOption.find(x => x.value === state.companies[index]) || { value: '', label: '' }}
                  onChange={value => onCompanySelectChanged(index, value)}
                  isClearable={false}
                />
              </div>
              <button
                className='btn btn-link'
                onClick={event => onDeleteCompanyField(event, index)}
                disabled={props.userSaving}
              >
                <span className='text-danger glyphicon glyphicon-remove' />
              </button>
            </div>
          </div>
        )
      })
    }
  }

  if (props.claims.role === 'admin') {
    roles = rolesAdmin
    roleSelect = (
      <div className='form-group col-sm-12 col-lg-5'>
        <label htmlFor='role'>Role</label>
        <Select
          name='role'
          value={roles.find(x => x.value === state.roleIndex)}
          options={roles}
          onChange={onRoleChanged}
        />
      </div>
    )
  }

  if (props.claims.role === 'companyAdmin') {
    roles = rolesCompanyAdmin
    roleSelect = (
      <div className='form-group col-sm-12 col-lg-5'>
        <label htmlFor='role'>Role</label>
        <Select
          name='role'
          value={roles.find(x => x.value === state.roleIndex)}
          options={roles}
          onChange={onRoleChanged}
        />
      </div>
    )
  }
  const disabled = (state.id !== '')
  const formData = () => {
    return (
      <div>
        <form className=''>
          <div className='row'>
            <div className='col-sm-12 col-lg-5'>
              <TextInput
                maxLength={100}
                name='firstName'
                label='First Name'
                value={state.firstName}
                onChange={onChange}
              />
            </div>
            <div className='col-sm-12 col-lg-5'>
              <TextInput
                maxLength={100}
                name='lastName'
                label='Last Name'
                onChange={onChange}
                value={state.lastName}
              />
            </div>
            <div className='col-sm-12 col-lg-5'>
              <SecuredTextInput
                maxLength={100}
                name='email'
                label='Email'
                value={state.email}
                onChange={onChange}
                disabled={disabled}
              />
            </div>
            {roleSelect}
            {landlordSelect}
          </div>
          <div className='row'>
            {companySelect}
          </div>
          <div className=''>
            {submit}
            {deleteBtn}
          </div>
        </form>
        {renderModal()}
      </div>
    )
  }

  const name = `${state.firstName} ${state.lastName}`
  return (
    <div style={props.usersLoading || props.companysLoading ? {} : {}} className='container-fluid'>
      <h3>{`User Details ${name || (props.usersLoading || props.companysLoading ? '' : 'New')}`}</h3>
      {props.usersLoading || props.companysLoading ? spinner : formData()}
    </div>
  )
}

function mapStateToProps (state) {
  const userCompanyIds = state.user.companies.map(x => x.id)
  userCompanyIds.push('')
  return {
    users: state.user.users,
    claims: state.session,
    error: state.user.error,
    userSaved: state.user.userSaved,
    userSaving: state.user.userSaving,
    userDeleted: state.user.userDeleted,
    userDeleting: state.user.userDeleting,
    id: state.user.id,
    landlords: state.landlord.landlords === undefined ? []
      : state.landlord.landlords.map((x) => { return { value: x.id, label: x.name } }),
    companys: state.company.companys,
    userCompanies: userCompanyIds,
    usersLoading: state.user.usersLoading,
    usersLoaded: state.user.usersLoaded,
    companysLoading: state.company.companysLoading
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    loadAllUsers,
    loadLandlords,
    saveUser,
    deleteUser,
    loadCompanys,
    usersUpdated,
    logoutUser,
    setUserSaved,
    setUsersLoaded,
    setUserDeleted,
    setError,
    setUserSaving,
    setUserDeleting
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUserDetails)