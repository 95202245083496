import React from 'react'

interface PasswordBarProps {
  password: string
  onChangeStrength: (strength) => void
}

class PasswordStrength extends React.Component<PasswordBarProps> {
  state = {
    strength: '',
    level: 0
  }

  constructor (props) {
    super(props)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.password !== this.props.password) {
      this.computePasswordScore()
    }
  }

  computePasswordScore () {
    const { password } = this.props

    let pwdCheck = 0
    let validateRegex = ['[A-Z]', '[a-z]', '\\d', '\\W', '.{6,}']
    validateRegex.forEach((regex) => {
      if (new RegExp(regex).test(password)) {
        pwdCheck += 1
      }
    })

    const pwdLength = password.length
    if (pwdLength >= 6 && pwdLength <= 20 && pwdCheck === 5) {
      switch (true) {
        case pwdLength > 6 && pwdLength <= 8:
          pwdCheck += 2
          break
        case pwdLength > 8 && pwdLength <= 10:
          pwdCheck += 3
          break
        case pwdLength > 10 && pwdLength <= 12:
          pwdCheck += 4
          break
        case pwdLength > 12 && pwdLength <= 16:
          pwdCheck += 5
          break
        case pwdLength > 16 && pwdLength <= 20:
          pwdCheck += 6
          break
        default:
          break
      }

      switch (true) {
        case pwdCheck <= 2:
          this.setState({ strength: 'weak', level: 1 })
          this.props.onChangeStrength('weak')
          break
        case pwdCheck <= 5:
          this.setState({ strength: 'fair', level: 2 })
          this.props.onChangeStrength('fair')
          break
        case pwdCheck <= 7:
          this.setState({ strength: 'good', level: 3 })
          this.props.onChangeStrength('good')
          break
        case pwdCheck <= 10:
          this.setState({ strength: 'strong', level: 4 })
          this.props.onChangeStrength('strong')
          break
        default:
          this.setState({ strength: '', level: 0 })
          this.props.onChangeStrength('')
          break
      }
    } else if (pwdLength > 20) {
      this.setState({ strength: 'weak', level: 4 })
      this.props.onChangeStrength('weak')
    } else if (pwdLength > 0) {
      this.setState({ strength: 'weak', level: 1 })
      this.props.onChangeStrength('weak')
    } else {
      this.setState({ strength: '', level: 0 })
      this.props.onChangeStrength('')
    }
  }

  render () {
    let barDisplay = (null)
    if (this.props.password) {
      barDisplay = (
        <div>
          <progress
            className={`pwd-checker-bar strength-${this.state.strength}`}
            value={this.state.level}
            max='4'
          />
          <label className={`pw-strength level-${this.state.strength}`}>{this.state.strength}</label>
        </div>
      )
    }
    return (
      <div>{ barDisplay }</div>
    )
  }
}

export default PasswordStrength
