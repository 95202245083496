import * as $ from 'jquery'

$.fn.textWidth = function (_text, _font) {
  let fakeEl = $('<span>').hide().appendTo(document.body).text(_text || this.val() || this.text()).css('font', _font || this.css('font'))
  let width = fakeEl.width()
  fakeEl.remove()
  console.log(width)
  return width
}

$.fn.autoresize = function (options) {
  options = $.extend({padding: 10, minWidth: 0, maxWidth: 10000}, options || {})
  $(this).on('input', function () {
    $(this).css('width', Math.min(options.maxWidth, Math.max(options.minWidth, $(this).textWidth() + options.padding)))
  }).trigger('input')
  return this
}