import errorApi from '../api/ErrorApi'
import { showErrorToast } from '../utils/Toast'

export const ERROR_LOADED = 'ERROR_LOADED'

export function errorLoaded (error: any) {
  return { type: ERROR_LOADED, error }
}

export function loadLastError () {
  return function (dispatch: any) { // eslint-disable-line
    return errorApi.loadLastError().then((response) => {
      if (response) {
        dispatch(errorLoaded(response))
      }
    }).catch(err => {
      showErrorToast(err)
    })
  }
}

export function runSql (sql: any) {
  return function () { // eslint-disable-line
    return errorApi.runSql(sql).catch(err => {
      showErrorToast(err)
    })
  }
}