import authenticator from '../auth/authenticator'
import { showErrorToast } from '../utils/Toast'

export type SucceededCallback = (result: any) => void
export type FailedCallback = (result: any) => void

type Method = 'PUT' | 'POST' | 'GET' | 'DELETE'

export interface CancellablePromise<T> {
  promise: Promise<T>
  cancel: () => void
}

export function cancellable<T> (promise: Promise<T>): CancellablePromise<T> {
  let hasCancelled = false
  return {
    promise: promise.then(v => {
      if (hasCancelled) {
        throw { isCancelled: true }
        // showErrorToast('Authentication Failure')
      }
      return v
    }),
    cancel: () => hasCancelled = true
  }
}

const extractErrorFromResponse = (response: Response): Promise<string> => {
  return response
    .clone() // Clone it, because if it is an error, it is possible that it won't have json to parse
    .json() // try and convert it to json
    .then((result) => {
      return result.description // Return the error message in the json, per our standard error format
    })
    .catch((jsonParseError) => {
      return response.text() // Return the error message as string, because we couldn't parse it into json
        .catch((textReadError) => {
          console.error('Failed to determine error cause', response, textReadError)
          return new Error('Unable to determine cause of error; check console for more information.')
        })
    })
}

export const parseJsonResponse = <T>(response: Response): Promise<T> => {
  if (response.status === 204) {
    return Promise.resolve(undefined) // No object in this case
  }
  return response
    .json() // Try and parse, note we don't clone as it should always be valid json
    .catch((error: Error) => {
      throw new Error('Failed to parse data from server; response was not valid JSON.')
      // showErrorToast('Failed to parse data from server; response was not valid JSON.')
    })
}

export const executeRequest = (request: RequestInit, url: string): Promise<Response> => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL
  const newUrl = `${apiUrl}${url}`

  return fetch(newUrl, request)
    .catch((error) => {
      throw new Error(`Failed to make request: ${error}`) // Throw the formatted error up to whatever catch is listening
      // showErrorToast(`Failed to make request: ${error}`)
    })
    .then((response: Response): Response => {
      if (response.status === 401) {
        console.log('auth failure')
        // browserHistory.push('/Login')
        window.location.href = '/Login'
        throw Error('Authentication Failure')
        // showErrorToast('Authentication Failure')
      }
      return response
    })
    .then((response: Response) => {
      if (!response.ok) {
        // If the server rejects us, get the reason and throw it up
        return extractErrorFromResponse(response)
          .then((errorString: string) => {
            return Promise.reject(errorString) // Throw the extracted error message up to the next catch
          })
      }
      return Promise.resolve(response)
    })
}

export const createRequest = (method: Method, body?: any): RequestInit => {
  return {
    method,
    headers: {
      Accept: '*/*',
      Authorization: `Bearer ${authenticator.getLoggedInToken()}`,
      'Cache-Control': 'no-cache'
    },
    body
  }
}

export const executeJsonRequest = <T>(method: Method, url: string, body?: any): Promise<T> => {
  const request = createRequest(method, body ? JSON.stringify(body) : undefined)
  const headers = request.headers as Record<string, string>
  headers['Accept'] = 'application/json'
  headers['Content-Type'] = 'application/json'
  return executeRequest(request, url)
    .then((response) => {
      return parseJsonResponse<T>(response)
    })
}

export const getJson = (url: string): Promise<any> => {
  return executeJsonRequest('GET', url)
}

export const postJson = (url: string, body: any): Promise<any> => {
  return executeJsonRequest('POST', url, body)
}

export const putJson = (url: string, body: any): Promise<any> => {
  return executeJsonRequest('PUT', url, body)
}

export const deleteJson = (url: string): Promise<any> => {
  return executeJsonRequest('DELETE', url)
}