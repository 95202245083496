export class POSSettings {
  autoLockDuration: number
  requireLoginForEachTransaction: boolean
  shippitUrl: string
  automaticOrderManifesting: boolean
  retailTagGroupQuestionId: string
  constructor (posSettings?: POSSettings) {
    if (posSettings !== undefined && posSettings !== null) {
      this.requireLoginForEachTransaction = posSettings.requireLoginForEachTransaction
      this.autoLockDuration = posSettings.autoLockDuration
      this.shippitUrl = posSettings.shippitUrl
      this.automaticOrderManifesting = posSettings.automaticOrderManifesting
      this.retailTagGroupQuestionId = posSettings.retailTagGroupQuestionId
    } else {
      this.requireLoginForEachTransaction = false
      this.autoLockDuration = 0
      this.shippitUrl = ''
      this.automaticOrderManifesting = true
      this.retailTagGroupQuestionId = ''
    }
  }
}