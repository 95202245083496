import { ClipLoader } from 'react-spinners'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import { Button, Modal } from 'react-bootstrap'
import { loadActivityLogs, activityNoteAdd, saveActivityNote, updateNote, activityNoteCacel, deleteActivityNote } from '../actions/activityLogActions'

class ActivityLogList extends React.Component<any, any> {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      name: '',
      deleteActivityNote: null
    }
  }
  componentDidMount () {
    
    this.props.loadActivityLogs(this.props.tableName, this.props.parentRecordId)
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.parentRecordId !== this.props.parentRecordId) {
      this.props.loadActivityLogs(nextProps.tableName, nextProps.parentRecordId)
    }
  }

  onNotesAddClick (event, id) {
    this.props.activityNoteAdd(id)
  }

  onNotesSaveClick (event, activityNote) {
    this.props.saveActivityNote(activityNote, this.props.parentRecordId)
  }
  onNotesCancelClick (event, activityNote) {
    this.props.activityNoteCacel(activityNote)
  }

  onNoteChange (event, activityNote) {
    activityNote.note = event.target.value
    this.props.updateNote(activityNote)
  }

  onNotesDeleteClick = (event, activityNote) => {
    event.preventDefault()
    this.openModal(activityNote)
  }

  openModal = (activityNote) => {
    this.setState({ ...this.state, modalIsOpen: true, deleteActivityNote: activityNote })
  }
  closeModal = () => {
    this.setState({ ...this.state, modalIsOpen: false, deleteActivityNote: null })
  }

  deleteAndCloseModal = (event) => {
    event.preventDefault()
    this.props.deleteActivityNote(this.state.deleteActivityNote, this.props.parentRecordId)
    this.setState({ ...this.state, modalIsOpen: false, deleteActivityNote: null })
  }

  renderModal = () => {
    const by = this.state.deleteActivityNote === null || this.state.deleteActivityNote === undefined ? '' : this.state.deleteActivityNote.userName
    const deleteMessage = `Are you sure you want to delete the note created by "${by}" `
    return (
      <Modal show={this.state.modalIsOpen} onHide={this.closeModal}>
        <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <Modal.Title style={{ marginRight: 'auto'}}>Delete</Modal.Title>
          {/* Custom Close Button */}
          <Button type='button' className='close' aria-label='Close' onClick={this.closeModal} style={{ border: 'none', boxShadow: 'none' }}>
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{deleteMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{ margin: 5, textAlign: 'center' }}
            className='btn btn-danger'
            onClick={this.deleteAndCloseModal}
          >
            Delete
          </button>
          <button
            style={{ margin: 5, textAlign: 'center' }}
            className='btn btn-default pull-left'
            onClick={this.closeModal}
          >
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  render () {
    const spinner = (<ClipLoader size={40} />)
    const inlineSpinner = (<div style={{ marginLeft: '20px', height: '15px', display: 'inline-block' }}>
      <ClipLoader size={15} /></div>)

    const formData = () => {
      const styleOpacity = { opacity: '0.5' }
      return (<div className='well'>
        {(this.props.activityLogs === undefined || this.props.activityLogs.length === 0) ? 'No logs found' : this.props.activityLogs.map((x) => {
          const createdDate = new Date(x.createdDate)
          return (
            <div key={x.id} className='row' style={{ paddingBottom: 20 }}>
              <div className='col-sm-12'>
                <strong>{x.userName} </strong> &nbsp;&nbsp;
                {x.action}
                {this.props.claims.role !== 'admin' ? '' : <button
                  className='btn-link btn btn-xs pull-right' disabled={x.addDisable}
                  onClick={(event) => { this.onNotesAddClick(event, x.id) }}
                >
                  <i className='material-icons'>note_add</i>
                </button>}
                <p className='text-muted'>{moment(createdDate).format('DD/MM/YY, h:mm A')}</p>
              </div>
              {
                this.props.claims.role !== 'admin' ? '' :
                  (
                    <div className='col-sm-12'>
                      {x.activityNotes.map((y) => {
                        const modifiedDate = new Date(y.modifiedDate)

                        return y.id !== '0' ?
                          <div key={y.id} className='panel panel-default' style={y.activityNoteDeleting ? styleOpacity : {}}>
                            <div className='panel-header'>
                              {y.activityNoteDeleting ?
                                <button
                                  className='pull-right btn-link btn btn-xs'
                                  disabled={true}
                                >{inlineSpinner} </button>
                                :
                                <button
                                  className='pull-right btn-link btn btn-xs'
                                  onClick={event => this.onNotesDeleteClick(event, y)}
                                >
                                  <i className='material-icons'>clear</i>
                                </button>}
                            </div>
                            <div className='panel-body'>
                              <p style={{ wordWrap: 'break-word' }}>{y.note}</p>
                              <small className='text-muted'>Created by {y.userName} {moment(modifiedDate).format('DD/MM/YY, h:mm A')}</small>
                            </div>
                          </div>
                          :
                          <div key={y.id} style={{ paddingBottom: 20 }}>
                            <div style={{ paddingBottom: 5 }}>
                              <textarea
                                maxLength={500}
                                className='form-control'
                                onChange={(event) => { this.onNoteChange(event, y) }}
                                value={y.note}
                                style={{ height: '70px', resize: 'none' }}
                              />
                            </div>
                            <div>
                              {y.activityNoteSaving ?
                                <button
                                  className='btn btn-success btn-sm pull-right'
                                  disabled={true}
                                >Save {inlineSpinner} </button>
                                :
                                <button
                                  className='btn btn-success btn-sm pull-right'
                                  onClick={(event) => { this.onNotesSaveClick(event, y) }}
                                >
                                  Save
                                </button>}
                              <button
                                className='btn btn-default btn-sm'
                                disabled={y.activityNoteSaving}
                                onClick={(event) => { this.onNotesCancelClick(event, y) }}
                              >Cancel</button>
                            </div>

                          </div>
                      })}
                    </div>
                  )
              }
            </div>

          )
        })
        }
        {this.renderModal()}
      </div>)
    }
    return (
      <div>
        <h3>Activity Log</h3>
        <div className='text-info' style={{ overflowY: 'auto' }}>
          {this.props.activityLogsLoading ? spinner : formData()}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    activityLogs: state.activityLog.activityLogsLoading ?
      undefined : state.activityLog.activityLogs,
    activityLogsLoading: state.activityLog.activityLogsLoading,
    claims: state.session
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    loadActivityLogs,
    activityNoteAdd,
    saveActivityNote,
    activityNoteCacel,
    deleteActivityNote,
    updateNote
  }, dispatch)
}

export default connect<any, any, { parentRecordId: string, tableName: string }>(
  mapStateToProps,
  mapDispatchToProps
)(ActivityLogList)
