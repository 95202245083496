import authenticator from '../auth/authenticator'
import { showErrorToast } from '../utils/Toast'

export type SucceededCallback = (result: any) => void
export type FailedCallback = (result: any) => void

export interface RequestParams {
  url: string
  succeededCallback?: SucceededCallback
  failedCallback?: FailedCallback
}

type Method = 'PUT' | 'POST' | 'GET' | 'DELETE'

export const executeRequest = (request: RequestInit, params: RequestParams) => {
  let { url, succeededCallback, failedCallback } = params
  let ok = false

  const apiUrl = process.env.REACT_APP_API_BASE_URL
  const newUrl = `${apiUrl}${url}`

  return fetch(newUrl, request)
    .then((response) => {
      if (response.ok) {
        ok = true
      }
      if (response.status === 401) {
        // console.log('auth failure')
        // browserHistory.push('/Login')
        window.location.href = '/Login'
        throw Error('auth failure')
        // showErrorToast('Authentication Failure')
      }
      let contentType = response.headers.get('content-type')
      if (contentType && contentType.indexOf('application/json') !== -1)
        return response.json()
      else
        return undefined
    })
    .then((result: any) => {
      if (ok) {
        if (succeededCallback) {
          succeededCallback(result)
        }
      } else if (failedCallback) {
        failedCallback(result.description)
      }
    })
    .catch((error) => {
      if (failedCallback) {
        failedCallback(error.message)
      }
    })
}

export const createRequest = (method: Method, body?: any): RequestInit => {
  return {
    method,
    headers: {
      Accept: '*/*',
      Authorization: `Bearer ${authenticator.getLoggedInToken()}`,
      'Cache-Control': 'no-cache'
    },
    body
  }
}

export const executeJsonRequest = (method: Method, params: RequestParams, body?: any) => {
  const request = createRequest(method, body ? JSON.stringify(body) : undefined)
  const headers = request.headers as Record<string, string>
  headers['Accept'] = 'application/json'
  headers['Content-Type'] = 'application/json'
  executeRequest(request, params)
}

export const postAsync = (body: any, params: RequestParams) => {
  return executeJsonRequest('POST', params, body)
}

export const getAsync = (params: RequestParams) => {
  return executeJsonRequest('GET', params)
}

export const putAsync = (body: any, params: RequestParams) => {
  return executeJsonRequest('PUT', params, body)
}

export const deleteAsync = (params: RequestParams) => {
  return executeJsonRequest('DELETE', params)
}