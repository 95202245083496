import {
  COMPANYS_LOADED, COMPANYS_UPDATED, COMPANYS_LOADING,
  SAVE_COMPANY_ERROR, SAVE_COMPANY_SUCCESS, DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR, COMPANY_API_STARTED, COMPANY_LOADED, CLEAR_COMPANYS
} from '../actions/companyActions'
import { TENANTS_LOADED, REMOVE_TENANT } from '../actions/tenantActions'
import initialState from './initialState'
import Company from '../types/Company'

export default function companyReducer (state = initialState.company, action: any) {
  switch (action.type) {
    case COMPANYS_LOADED:
    {
      const newCompanys: any = []
      let i = 0
      if (Array.isArray(action.companys)) {
        Array.prototype.forEach.call(action.companys, (y) => {
          const newCompany = new Company(y)
          newCompany.sequence = i
          i += 1
          newCompanys.push(newCompany)
        })
      }
      return {
        ...state,
        companys: newCompanys,
        companySaved: false,
        companyDeleted: false,
        companysLoading: false,
        companysLoaded: true
      }
    }
    case COMPANYS_UPDATED:
      return { ...state, companys: action.companys }
    case COMPANYS_LOADING:
      return { ...state, companysLoading: true, companys: undefined }
    case CLEAR_COMPANYS:
    {
      const newCompanys: any = []
      return { ...state, companys: newCompanys, companysLoading: false, companysLoaded: false }
    }
    case COMPANY_LOADED:
      return { ...state, error: '', companySaved: false, companyDeleted: false }
    case SAVE_COMPANY_ERROR:
      return { ...state, error: action.error, companysLoading: false }
    case SAVE_COMPANY_SUCCESS:
    {
      const newCompanys = []
      let added = false

      Array.prototype.forEach.call(state.companys, (y) => {
        if (y.id === action.companyDetails.id) {
          newCompanys.push(new Company(action.companyDetails))
          added = true
        } else {
          newCompanys.push(new Company(y))
        }
      })

      if (!added) {
        const newCompany = new Company(action.companyDetails)
        newCompany.sequence = newCompanys.length + 1
        newCompanys.push(newCompany)
      }

      return { ...state, companys: newCompanys, error: '', companySaved: true,
        id: action.companyDetails.id, companysLoading: false }
    }
    case TENANTS_LOADED:
    {
      const newCompanys: any = []
      const added = false

      Array.prototype.forEach.call(state.companys, (y) => {
        const company = new Company(y)
        if (y.id === action.companyId) {
          company.environmentsCount = action.tenants.length
        }
        newCompanys.push(company)
      })

      return { ...state, companys: newCompanys }
    }
    case REMOVE_TENANT:
    {
      const newCompanys: any = []
      const added = false

      if (action.companyId === undefined)
        return { ...state }
      Array.prototype.forEach.call(state.companys, (y) => {
        const company = new Company(y)
        if (y.id === action.companyId) {
          company.environmentsCount = company.environmentsCount - 1
        }
        newCompanys.push(company)
      })

      return { ...state, companys: newCompanys }
    }
    case DELETE_COMPANY_SUCCESS:
    {
      const newCompanys: any = []
      state.companys.filter((x: any) => x.id !== action.id).forEach((y: any) => {
        newCompanys.push(new Company(y))
      })
      return { ...state, error: '', companys: newCompanys, companyDeleted: true, companysLoading: false }
    }
    case DELETE_COMPANY_ERROR:
      return { ...state, error: action.error, companySaved: false, companyDeleted: false, companysLoading: false }
    case COMPANY_API_STARTED:
      return { ...state, error: '', companySaved: false, companyDeleted: false, companysLoading: true }
    default:
      return state
  }
}
