import { ERROR_LOADED } from '../actions/errorActions'
import initialState from './initialState'

export default function errorReducer (state = initialState.error, action: any) {
  switch (action.type) {
    case ERROR_LOADED:
      return { ...state, errorDetails: action.error }
    default:
      return state
  }
}
