import React from 'react'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loadCompanys } from '../actions/companyActions'
import { Outlet } from 'react-router-dom'

class CompanyPage extends React.Component<any, any> {

  componentDidMount () {
    this.props.loadCompanys()
  }

  render () {
    const display = (<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ClipLoader size={40} /></div>)
    return (
      <div>
        {this.props.logged_in ?
          this.props.children
          : display }
      </div>
    )
  }
}

function mapStateToProps (state: any) {
  return {
    claims: state.session,
    logged_in: state.session.logged_in
  }
}

function mapDispatchToProps (dispatch: any) {
  return bindActionCreators({
    loadCompanys
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyPage)