
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import Select from 'react-select'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import { templateBulkCheckedUnchecked } from '../actions/templateActions'
import { Template, ShopUpdateType } from '../types/Template'

interface IStateProps {
  templatesLoading: boolean,
  navigations: any,
  templates: Template[],
  bulkNavigationType: any[]
}

interface IOwnProps {
  modalIsOpen: any,
  modalError: any,
  closeModal: any,
  applyAndCloseModal: any,
  onBulkNavigationChanged: any,
  onBulkNavigationTypeChanged: any,
  bulkNavigationSelected: string,
  bulkNavigationTypeSelected: ShopUpdateType
}

interface IDispatchProps {
  templateBulkCheckedUnchecked: any
}

const mapStateToProps = (state, ownprops: IOwnProps) => {
  return {
    templatesLoading: state.template.templatesLoading,
    templates: state.template.templatesLoading ? undefined : state.template.bulkTemplates,
    navigations: state.template.templatesLoading ? undefined : state.template.bulkNavigationOptions,
    bulkNavigationType: state.tempaltesLoading ? undefined : state.template.bulkNavigationType
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      templateBulkCheckedUnchecked
    },
    dispatch
  )
}

class BulkApplyTemplate extends React.Component<
  IStateProps & IDispatchProps & IOwnProps,
  {}> {

  toggleCheckbox = (e: any, label: number) => {
    this.props.templateBulkCheckedUnchecked(e.target.checked, label)
  }

  toggleSelectUnselectAll = (selectAll: boolean) => {
    this.props.templateBulkCheckedUnchecked(selectAll)
  }

  createCheckbox = (label: Template) => (
    <div key={label.id} className='checkbox'>
      <label key={label.id}>
        <input type='checkbox'
          value={label.id}
          key={label.id}
          checked={label.checked}
          onChange={(e) => { this.toggleCheckbox(e, label.id) }}
        />

        {label.name}
      </label>
    </div>
  )

  createCheckboxes = () => {
    if (this.props.templates) {
      return this.props.templates.map(this.createCheckbox)
    }
  }

  modalError = () => {
    return this.props.modalError ?
      <p style={{ textAlign: 'center' }} className='alert alert-danger'>{this.props.modalError}</p> : ''
  }

  selectAll = () => {
    const onClick = (e) => {
      this.toggleSelectUnselectAll(this.props.templates.length !== this.props.templates.filter((x) => x.checked === true).length)
    }
    return (this.props.templates) ? (
      <button
        className='btn btn-success'
        onClick={onClick}
      >
        {(this.props.templates.length === this.props.templates.filter((x) => x.checked === true).length) ?
          'Unselect all stores'
          : 'Select all stores'}
      </button>
    ) : ''
  }

  render () {

    return (
      <Modal show={this.props.modalIsOpen} onHide={this.props.closeModal} bsSize='large'>
        <Modal.Header style={{ display: 'flex' }}>
          <Modal.Title style={{ flex: 1, order: -4, alignSelf: 'center'}}>Bulk apply template</Modal.Title>
          <div style={{ maxWidth: 200, flex: 0, flexBasis: '200px', order: -3 }}>
            <label htmlFor='bulkTemplateType'>Template type</label>
            <Select
              name='bulkTemplateType'
              value={this.props.bulkNavigationType?.find(x => x.value === this.props.bulkNavigationTypeSelected)}
              options={this.props.bulkNavigationType}
              styles={
                {
                  control: (provided: any) => ({
                    ...provided,
                    maxWidth: 200
                  })
                }
              }
              isClearable={false}
              onChange={this.props.onBulkNavigationTypeChanged}
            />
          </div>
          <div style={{ maxWidth: 200, flex: 0, flexBasis: '200px', order: -2 }} className='container'>
            <label htmlFor='bulkTemplateOptions'>Template</label>
            <Select
              name='bulkTemplateOptions'
              value={this.props.navigations?.find(x => x.value === this.props.bulkNavigationSelected)}
              options={this.props.navigations}
              styles={
                {
                  control: (provided: any) => ({
                    ...provided,
                    maxWidth: 200
                  })
                }
              }
              isDisabled={!Object.values(ShopUpdateType).includes(this.props.bulkNavigationTypeSelected)}
              isClearable={false}
              onChange={this.props.onBulkNavigationChanged}
            />
          </div>
          <div style={{ maxWidth: 200, flex: 0, flexBasis: '200px', order: -1}} className='container'>
            <label className='invisible'>Template</label>
            <button
              className='btn btn-primary btn-block'
              onClick={this.props.applyAndCloseModal}
            >
              Apply
            </button>
          </div>
          <Button 
            type='button' 
            className='close modal-close-custom' 
            aria-label='Close' 
            onClick={this.props.closeModal} 
            style={{ border: 'none', boxShadow: 'none' }}
          >
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {this.modalError()}
          {this.selectAll()}
          <div style={{ columnWidth: 240, columnCount: 3 }}>
            {this.createCheckboxes()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-default'
            onClick={this.props.closeModal}
          >
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(BulkApplyTemplate)
