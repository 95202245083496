import React from 'react'
import TemplateList from './../TemplateList'
import {ShopUpdateType} from '../../types/Template'

export default class CustomerTemplateList extends React.Component {

  render () {
    return (
      <TemplateList title='Customer Templates' description='Create customer filtering and registration form'
        templateType={ShopUpdateType.CustomerTemplateId} url='/customer'
      >
        Set rules for customer filtering and configure registration form.
      </TemplateList>
    )
  }
}