import { PRODUCT_FILTER, Rule, RuleChild, SubFilter, TagGroup } from './Menu'
export class ProductFilter {
  id: number
  name: string
  subFilterId: string
  userName: string | null
  modifiedById: number
  modifiedDate: Date | null
  tenantId: string
  companyId: string
  subFilter: SubFilter | null
  rules: Rule[]
  ruleChilds: RuleChild[]
  tagGroups: TagGroup[]
  constructor (menu?: any) {
    if (menu !== undefined) {
      this.id = menu.id
      this.name = menu.name
      this.userName = menu.userName
      this.modifiedById = menu.modifiedById
      this.modifiedDate = menu.modifiedDate
      this.subFilter = menu.subFilter === null ? null : new SubFilter(menu.subFilter)
      if (this.subFilter) {
        this.subFilter.expanded = true
      }
      this.subFilterId = menu.subFilterId
      this.ruleChilds = menu.ruleChilds.map((p: any) => new RuleChild(p))
      this.rules = menu.rules.map((p: any) => new Rule(p))
      this.tenantId = menu.tenantId
      this.companyId = menu.companyId
      this.tagGroups = menu.tagGroups
    } else {
      this.id = 0
      this.name = ''
      this.userName = null
      this.modifiedById = 0
      this.modifiedDate = null
      this.subFilter = new SubFilter ({id: 0, isMatchingAll: true, parentSubFilterId: 0,
        type: PRODUCT_FILTER, title: 'Products filter', expanded: true})
      this.ruleChilds = []
      this.rules = []
      this.subFilterId = ''
    }
  }
}
