import React from 'react'

import TextInput from '../common/TextInput'
import { ZipPaymentSettings } from '../../types/ZipPaymentSettings'

interface Props {
    onChange: (settings: ZipPaymentSettings) => void
    settings: ZipPaymentSettings
}

interface State {
    settings: ZipPaymentSettings
}

export default class ZipPaymentProvider extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      settings: this.props.settings
    }
  }

  changeApiKey = (apiKey: string) => {
    let settings = {...this.state.settings, apiKey: apiKey }
    this.setState({ ...this.state, settings: settings },
      () => this.props.onChange(this.state.settings))
  }

  changeMerchantId = (merchantId: string) => {
    let settings = { ...this.state.settings, merchantId: merchantId }
    this.setState({ ...this.state, settings: settings },
      () => this.props.onChange(this.state.settings))
  }

  render () {
    return (
      <div className='panel panel-default'>
        <div className='panel-heading clearfix'>
          <h3 className='panel-title pull-left'>Zip</h3>
        </div>
        <div className='panel-body'>
          <div className='form-group row'>
            <div className='col-sm-10'>
              <TextInput
                maxLength={100}
                name='APIKey'
                label='API Key'
                value={this.state.settings.apiKey}
                onChange={(event) => this.changeApiKey(event.target.value)}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm-10'>
              <TextInput
                maxLength={100}
                name='merchantId'
                label='Merchant Id'
                value={this.state.settings.merchantId}
                onChange={(event) => this.changeMerchantId(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>)
  }
}