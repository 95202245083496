import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import {
  applyBulkTemplate,
  bulkNavigationTypeSelected,
  loadTemplates,
  templateOpenModal
} from '../actions/templateActions'
import { ShopUpdateType, Template } from '../types/Template'
import BulkApplyTemplate from './BulkApplyTemplate'
import Table from './common/Table'
import TemplateControl from './TemplateControl'
import EmptyState from './common/EmptyState'
import $ from 'jquery'

interface IDispatchProps {
    loadTemplates,
    applyBulkTemplate,
    bulkNavigationTypeSelected, templateOpenModal
}

interface IStateProps {
    bulkTemplates: any[],
    templateCount: number, templates: Template[],
    templateSaving: boolean, templatesLoading: boolean,
    claims: any, tenantId: string, companyId: string, logged_in: boolean
}

interface IOwnProps {
    search: string, height: number, modalIsOpen: boolean,
    bulkNavigationSelected: string,
    modalError: string, bulkNavigationTypeSelected: ShopUpdateType
}
interface IState {
    search: string,
    height: number,
    modalIsOpen: boolean,
    bulkNavigationSelected: string,
    bulkNavigationTypeSelected: number,
    modalError: string
}
class ApplyTemplateList extends React.Component<IDispatchProps & IStateProps & IOwnProps & any, IState> {

  defaultProps = {
    templates: null,
    bulkTemplates: null,
    claims: {}
  }

  columns = [
    { key: 'name', name: 'Name', sortable: true, resizable: true },
    { key: 'store', name: 'Store', sortable: true, resizable: true },
    { key: 'navigation', name: 'Navigation', sortable: true, resizable: true },
    { key: 'products', name: 'Products', sortable: true, resizable: true },
    { key: 'printers', name: 'Docket Format', sortable: true, resizable: true },
    { key: 'customers', name: 'Customers', sortable: true, resizable: true }
  ]

  constructor (props) {
    super(props)
    this.state = {
      search: '',
      height: -1,
      modalIsOpen: false,
      bulkNavigationSelected: '',
      bulkNavigationTypeSelected: -1,
      modalError: ''
    }
  }

  componentDidMount () {
    
    if (this.props.tenantId !== '' && this.props.tenantId !== undefined) {
      this.props.loadTemplates(this.props.tenantId)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.tenantId === undefined &&
            (nextProps.companyId !== undefined && nextProps.companyId !== '' && this.props.companyId !== nextProps.companyId)) {
      this.props.loadTemplates('-1')
    } else if (nextProps.tenantId !== undefined
            && nextProps.tenantId !== '' && this.props.tenantId !== nextProps.tenantId) {
      this.props.loadTemplates(nextProps.tenantId)
    }
  }

  applyAndCloseModal = (event) => {
    event.preventDefault()
    if (this.state.bulkNavigationTypeSelected === -1) {
      return this.setState({ modalError: 'Please select template type' })
    }
    if (this.state.bulkNavigationSelected === '') {
      return this.setState({ modalError: 'Please select a template' })
    }
    if (this.props.bulkTemplates.filter((x) => x.checked === true).length === 0) {
      return this.setState({ modalError: 'Please select store(s)' })
    }

    this.setState({ modalIsOpen: false, modalError: '' })
    this.props.applyBulkTemplate(
      this.props.bulkTemplates
        .filter((x) => x.checked === true)
        .map((y) => y.id)
      , this.state.bulkNavigationTypeSelected
      , this.state.bulkNavigationSelected
      , this.props.tenantId)
  }

  openModal = (event) => {
    event.preventDefault()
    this.props.templateOpenModal()
    this.setState({
      modalIsOpen: true,
      modalError: '',
      bulkNavigationSelected: '',
      bulkNavigationTypeSelected: -1
    })
  }

  closeModal = (event) => {
    event.preventDefault()
    this.setState({ modalIsOpen: false, modalError: '' })
  }

  onChange (event) {
    const field = event.target.name
    const state = this.state
    state[field] = event.target.value
    return this.setState({ ...state })
  }

  getCount = () => {
    if (this.state.search === '') {
      return this.props.templates === undefined ? 0 : this.props.templates.length
    }
    return this.props.templates.filter(p => p.name.search(new RegExp(this.state.search, 'i')) !== -1
            || p.generalTemplate.search(new RegExp(this.state.search, 'i')) !== -1
            || p.navigation.search(new RegExp(this.state.search, 'i')) !== -1
            || p.products.toString().search(new RegExp(this.state.search, 'i')) !== -1
            || p.printerTemplate.toString().search(new RegExp(this.state.search, 'i')) !== -1
            || p.customers.toString().search(new RegExp(this.state.search, 'i')) !== -1
            || p.showEditor).length
  }

  changeState = (name, value) => {
    const state = this.state
    state[name] = value
    return this.setState(state)
  }

  onBulkNavigationTypeChanged = (val) => {
    if (val) {
      let field = 'bulkNavigationSelected'
      const state = this.state
      state[field] = ''
      field = 'bulkNavigationTypeSelected'
      state[field] = val.value
      this.setState({ ...state })
      this.props.bulkNavigationTypeSelected(val)
    }
  }

  onBulkNavigationChanged = (val) => {
    if (val) {
      let field = 'bulkNavigationSelected'
      const state = this.state
      state[field] = val.value
      return this.setState({ ...state })
    }
  }

  allowTable = () => {
    $('#ApplyTemplate').DataTable() 
  }

  renderTemplate = () => {

    const spinner = (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
        <ClipLoader size={40} />
      </div>
    )
    const colHeads = this.columns.map((p) => <th key={p.key}>{p.name}</th>)
    const colBody = []

    for (let i = 0; i < this.props.templateCount; i++) {
      const itempush = (<TemplateControl key={i} index={i} search={this.state.search} />)
      if (itempush) {
        colBody.push(itempush)
      }
    }

    const dataGrid = () => {
      const count = this.getCount()
      if (count > 0) {        
        return (          
          <Table data={count} height={this.state.height}>            
            <thead>
              <tr>
                {colHeads}
                <th />
              </tr>
            </thead>
            <tbody>
              {colBody}
            </tbody>
          </Table>
        )
      } else {
        return (
          <EmptyState message={this.props.templateCount
            ? 'No store or template found'
            : 'No store or template available'}
          />
        )
      }
    }

    return (
      <div style={{ padding: 5, position: 'relative' }} ref='parent'>
        <h3>Apply Templates</h3>
        <div style={{ width: '100%' }} >
          <div className='row' >
            <div className='col-sm-4 col-xs-6'>
              <div className='right-inner-addon '>
                <i className='text-muted glyphicon glyphicon-search' />
                <input
                  maxLength={100}
                  name='search'
                  value={this.state.search}
                  onChange={(e) => this.onChange(e)}
                  className='form-control'
                  placeholder='Search by store or template name...'
                />
              </div>
            </div>
            <div className='col-sm-8 col-xs-6 text-right'>
              <Button
                // bsStyle='primary'
                variant='primary'
                style={{ margin: 5, textAlign: 'center' }}
                disabled={this.props.templatesLoading || this.props.templateSaving}
                onClick={(e) => { this.openModal(e) }}
              >
                                Bulk apply templates
              </Button>
            </div>
          </div>
          <hr />
          <div ref='table' className='table-responsive noOverflowx'>
            {this.props.templatesLoading ? spinner : dataGrid()}
          </div>
        </div>
      </div>
    )
  }

  renderModal = () => {
    return (
      <BulkApplyTemplate applyAndCloseModal={this.applyAndCloseModal}
        closeModal={this.closeModal}
        modalIsOpen={this.state.modalIsOpen}
        bulkNavigationSelected={this.state.bulkNavigationSelected}
        bulkNavigationTypeSelected={this.state.bulkNavigationTypeSelected}
        onBulkNavigationChanged={this.onBulkNavigationChanged}
        onBulkNavigationTypeChanged={this.onBulkNavigationTypeChanged}
        modalError={this.state.modalError}
      />
    )
  }

  render () {
    const display = (<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ClipLoader size={40} /></div>)
    return (
      <div>
        { this.props.logged_in ?
          (this.props.companyId && this.props.tenantId) ?
            (
              <div className='container-fluid' >
                {this.renderTemplate()}
                {this.renderModal()}
              </div>
            ) :
            (
              <div style={{ maxWidth: 500, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                <p className='alert alert-danger'>Select Company and Enviroment</p></div>
            )
          : display
        }
      </div>
    )
  }
}

function mapStateToProps (state): IStateProps {
  return {
    templatesLoading: state.template.templatesLoading,
    templateCount: !state.template.templates ? undefined : state.template.templates.length,
    templates: state.template.templatesLoading ? undefined : state.template.templates,
    bulkTemplates: state.template.templatesLoading ? undefined : state.template.bulkTemplates,
    templateSaving: state.template.templateSaving,
    claims: state.session,
    tenantId: state.session.tenantId,
    companyId: state.session.companyId,
    logged_in: state.session.logged_in
  }
}

function mapDispatchToProps (dispatch): IDispatchProps {
  return bindActionCreators({
    applyBulkTemplate,
    bulkNavigationTypeSelected,
    loadTemplates,
    templateOpenModal
  }, dispatch)
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyTemplateList)
