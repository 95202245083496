import { Customer } from '../types/Customer'
import { STORE_FILTER, SubFilter } from '../types/Menu'
export default {
  session: {
    logged_in: false,
    login_error: '',
    login_verify: '',
    email: '',
    role: '',
    landlord: '',
    landlordId: '',
    userId: '',
    response_loading: false,
    company: '',
    companyId: '',
    tenant: '',
    tenantId: '',
    companys: [],
    companysLoading: true,
    tenants: [] as any[],
    tenantsLoading: false,
    licencesUpdating: false,
    isIOS: /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
  },
  error: {
    errorDetails: {}
  },
  user: {
    users: [],
    landlords: [],
    error: '',
    userSaved: false,
    userDeleted: false,
    usersLoading: true,
    usersLoaded: false,
    id: '',
    companies: [],
    userSaving: false,
    userDeleting: false
  },
  landlord: {
    landlords: [],
    error: '',
    landlordSaved: false,
    landlordDeleted: false,
    landlordsLoading: true,
    landlordsLoaded: false,
    id: ''
  },
  company: {
    companys: undefined,
    error: '',
    companySaved: false,
    companyDeleted: false,
    id: '',
    sourceId: '',
    companysLoading: true,
    companysLoaded: false
  } as any,
  menu: {
    menus: [],
    menu: {
      tags: [],
      rules: [],
      subFilters: [],
      ruleChilds: [],
      name: '',
      companyId: '',
      tenantId: ''      
    },
    error: '',
    menuDeletedSuccess: false,
    menuSaved: false,
    menuSavedId: '',
    menuDeleted: false,
    menusLoading: false,
    menusLoaded: false,
    menuSaving: false
  },
  toast: {
    success: '',
    error: ''
  },
  tenant: {
    tenants: [],
    error: '',
    saved: false,
    loading: false,
    selectedCompanyId: '',
    selectedTenantId: ''
  },
  device: {
    regPins: [],
    devices: [],
    regPinsLoading: false,
    devicesLoading: false
  },
  activityLog: {
    activityLogs: undefined,
    error: '',
    id: '',
    activityLogsLoading: false,
    activityLogsLoaded: false
  } as any,
  template: {
    templates: [] as any[],
    navigations: [] as any[],
    products: [] as any[],
    printerTemplates: [] as any[],
    generalTemplates: [] as any[],
    customerTemplates: [] as any[],
    error: '',
    templateSaved: false,
    templateDeleted: false,
    templatesLoading: false,
    templatesLoaded: false,
    templateSaving: false,
    companyId: '',
    tenantId: '',
    bulkNavigationType: undefined,
    bulkNavigationOptions: undefined,
    bulkTemplates: [] as any[],
    currentTemplateName: ''
  },
  product: {
    products: undefined,
    product: {
      tags: [],
      rules: [],
      ruleChilds: [],
      name: '',
      companyId: '',
      tenantId: '',
      subFilter: undefined
    } as any,
    error: '',
    productDeletedSuccess: false,
    productSaved: false,
    productDeleted: false,
    productsLoading: false,
    productsLoaded: false,
    productSaving: false,
    productSavedId: ''
  },
  customer: {
    customers: undefined,
    customer: {
      id: 0,
      tags: [],
      rules: [],
      ruleChilds: [],
      name: '',
      companyId: '',
      tenantId: '',
      subFilterId: -1,
      subFilter: undefined,
      groups: undefined,
      userName: '',
      validationEnabled: false,
      checkForAttachedCustomerEnabled: false,
      googleApiKey: '',
      googleApiKeyEnabled: false,
      kleberApiKey: '',
      kleberApiKeyEnabled: true,
      modifiedById: 0,
      modifiedDate: null,
      tagGroups: undefined
    } as Customer,
    error: '',
    customerDeletedSuccess: false,
    customSavedId: '',
    customerSaved: false,
    customerDeleted: false,
    customersLoading: false,
    customersLoaded: false,
    customerSaving: false
  },
  store: {
    stockEnquiryFilter: {
      subFilter: new SubFilter({
        id: 0, isMatchingAll: true, parentSubFilterId: 0,
        type: STORE_FILTER, title: 'Stock Enquiry - Store Filter', expanded: true
      }),
      ruleChilds: [],
      rules: [],
      subFilterId: '',
      tagGroups: [],
      expanded: true
    } as any,
    discountTypes: [],
    carriers: [],
    packTypes: [],
    paymentTypes: [],
    ap21RewardsPrograms: [],
    retailTagTypes: [],
    transferOutWarehouseFilter: {
      subFilter: new SubFilter({
        id: 0, isMatchingAll: true, parentSubFilterId: 0,
        type: STORE_FILTER, title: 'New Transfer Destination-Warehouse/Store Filter', expanded: true
      }),
      ruleChilds: [],
      rules: [],
      subFilterId: '',
      tagGroups: [],
      expanded: true
    },
    floorToDoorSettings: {
      floorToDoorEnabled: false
    }
  },
  dataExplorer: {
    dataExplorerType: '',
    id: ''
  },
  appInfo: {
    currentVersion: '',
    activityLog: [],
    isLoading: false
  }
}
