import Feature from './Feature'

export default class Tenant {

  id: string
  name: string
  createdByName: string
  createdDate: string
  apiKey: string
  displayApiKey: string
  licencesCount: number
  licencesCountSaved: number
  sequence: number
  isTestEnvironment: boolean
  tenantDeleting: boolean
  features: Feature[]
  shippitWebhookUrl: string
  constructor (tenant?: any) {
    if (tenant) {
      this.id = tenant.id
      this.name = tenant.name
      this.createdByName = tenant.createdByName
      this.createdDate = tenant.createdDate
      this.displayApiKey = tenant.displayApiKey
      this.licencesCount = tenant.licencesCount
      this.licencesCountSaved = tenant.licencesCount
      this.apiKey = tenant.apiKey
      this.isTestEnvironment = tenant.isTestEnvironment
      this.features = tenant.features.map((x: any) => new Feature(x))
      this.tenantDeleting = tenant.tenantDeleting
      this.sequence = tenant.sequence
      this.shippitWebhookUrl = tenant.shippitWebhookUrl
    } else {
      this.id = ''
      this.name = ''
      this.createdByName = ''
      this.createdDate = ''
      this.apiKey = ''
      this.licencesCount = 0
      this.isTestEnvironment = true
      this.features = []
      this.tenantDeleting = false
    }
  }
}
