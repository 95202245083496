import React from 'react'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import {
  addRule, changeRuleOperator, changeRuleTagGroup, changeRuleTags, deleteRule
} from '../actions/menuActions'

import {
  Button, OverlayTrigger, Tooltip
} from 'react-bootstrap'

import * as Menu from './../types/Menu'
import { TagGroup } from './../types/Menu'

export interface StateProps { rules: Menu.Rule[], ruleChilds: Menu.RuleChild[], tagGroups: Menu.TagGroup[] }
export interface DispatchProps { addRule, changeRuleOperator, changeRuleTagGroup, changeRuleTags, deleteRule }
export interface OwnProps { subFilterId }

type IProps = StateProps & DispatchProps & OwnProps
export class Rules extends React.Component<IProps, {}> {
  private operatorTypes = [
    { value: 0, label: 'Is' },
    { value: 1, label: 'Is not' },
    { value: 2, label: 'Is any of' },
    { value: 3, label: 'Is none of' }
  ]
  shouldComponentUpdate (nextProps: Object, nextState) {
    for (let prop in nextProps) {
      if (nextProps[prop] !== this.props[prop])
        return true
    }

    return false
  }

  componentWillReceiveProps (nextProps: IProps) {
    this.addNewRuleIfNoneEmpty(nextProps)
  }

  componentWillMount () {
    this.addNewRuleIfNoneEmpty(this.props)
  }

  addNewRuleIfNoneEmpty (nextProps: IProps) {
    if (!(
      nextProps.rules.some((p) => p.tagGroupId === '' || p.operatorType === undefined)
            || nextProps.rules.some((p) => !nextProps.ruleChilds.some((q) => q.ruleId === p.id)))
            && nextProps.tagGroups) {
      nextProps.addRule(nextProps.subFilterId)
    }
  }

  render () {
    // console.log('this.props.tagGroups', this.props.tagGroups)
    let inlineSpinner = <div style={{ marginLeft: '20px', height: '15px', display: 'inline-block' }}>
      <ClipLoader size={15} /></div>
    let listItems = this.props.rules.filter(p => p.subFilterId === this.props.subFilterId)
      .map(p => {

        let tags = (this.props.tagGroups.find(q => q.id === p.tagGroupId) || {
          tags: [] as Menu.Tag[]
        }).tags.map(q => { return { value: q.id, label: q.title } })

        // console.log('tags', tags)
        // console.log('this.props.ruleChilds', this.props.ruleChilds)
        const val = this.props.ruleChilds.filter(q => q.ruleId === p.id)
        return (
          <div className='mg-bottom-lg grid-row' key={p.id} >
            <div className='grid-item' style={{
              paddingRight: '20px', overflow: 'visible', minWidth: '350px'
            }}
            >
              <Select
                name={'tagGroup' + p.id}
                value={this.props.tagGroups.find((group) => group.id === p.tagGroupId)}
                options={this.props.tagGroups}
                getOptionValue={(option: TagGroup) => option.id.toString()} // Convert id to string if needed
                getOptionLabel={(option: TagGroup) => option.title}
                onChange={(e) => this.props.changeRuleTagGroup(p.id, e.id)}
                isClearable={false}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    minWidth: '350px'
                  })
                }}
              />
            </div>
            <div className='grid-item' style={{ width: '200px', paddingRight: '20px' }}>
              <Select
                name={'operator' + p.id}
                value={this.operatorTypes?.find((operator) => operator.value === p.operatorType)}
                options={this.operatorTypes}
                onChange={(e) => this.props.changeRuleOperator(p.id, e.value)}
                isClearable={false}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    maxWidth: '200px'
                  })
                }}
              />
            </div>
            <div className='grid-item' style={{ overflow: 'visible', maxHeight: 'none', alignSelf: 'start' }} >
              <Select
                name={'tags' + p.id}
                options={tags}
                isMulti={p.operatorType > 1}
                isClearable={false}
                onChange={(e: any) => {
                  return this.props.changeRuleTags(p.id, p.operatorType > 1 ? e?.map(x => x.value?.toString()) : [e.value?.toString()])
                }}
                value={tags.filter(tag => val.some(x => x.tagId === tag.value))}
              />
            </div>
            <div className={`grid-item ${!p.tagGroupId ? 'invisible' : ''}`} style={{ maxWidth: 50, padding: 0 }}>
              <OverlayTrigger placement='bottom' overlay={<Tooltip id='remove'>Remove</Tooltip>}>
                <Button onClick={() => this.props.deleteRule(p.id)} className='pull-right' variant='link' size='lg' >
                  <i className='bi bi-x text-danger' />
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        )
      })
    return (
      <div className='grid-container'>
        {listItems}
      </div>
    )
  }
}

function mapStateToProps (state, ownProps: OwnProps): StateProps {
  return {
    rules: state.menu.menu.rules.filter(p => p.subFilterId === ownProps.subFilterId),
    ruleChilds: state.menu.menu.ruleChilds,
    tagGroups: state.menu.menu.tagGroups
  }
}

function mapDispatchToProps (dispatch): DispatchProps {
  return bindActionCreators({
    addRule, changeRuleOperator, changeRuleTagGroup, changeRuleTags, deleteRule
  }, dispatch)
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(Rules)
