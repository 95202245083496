import React from 'react'
import * as ReactBootstrap from 'react-bootstrap'
import * as LogoApi from '../../api/LogosApi'
import { ClipLoader } from 'react-spinners'
import * as Toast from '../../utils/Toast'
import DeleteModal from './../common/DeleteModal'
import { cancellable, CancellablePromise } from '../../api/promiseClient'
import { LoadState } from '../../types/LoadState'

const loadingFailed = <div>failed to load</div>

interface LogoProps {
    tenantId: string
    id: string
    name: string
    onClick: (id: string) => void
    selected: boolean
    onDelete: (id: string) => void
  }

  interface LogoState {
    loadState: LoadState
    imageData?: string
    showDeleteModal: boolean
  }

export default class Logo extends React.Component<LogoProps, LogoState> {
  fetchWebLogoPromise: CancellablePromise<LogoApi.WebLogo>

  constructor (props) {
    super(props)
    this.state = {
      loadState: 'Loading',
      showDeleteModal: false
    }
  }

  componentDidMount () {
    console.log('componentDidMount Logo.tsx ')
    const fetchWebLogoPromise = this.fetchWebLogoPromise = cancellable(LogoApi.fetchWebLogo(this.props.tenantId, this.props.id))
    fetchWebLogoPromise.promise
      .then((result) => this.setState({ imageData: result.base64Bitmap, loadState: 'Success' }))
      .catch((error) => {
        if (error.isCancelled)
          return undefined
        this.setState({ loadState: 'Error' })
        Toast.showErrorToast(error)
      })
  }
  componentWillUnmount () {
    this.fetchWebLogoPromise.cancel()
  }

  showDeleteModal () {
    this.setState({ showDeleteModal: true })
  }

  hideDeleteModal () {
    this.setState({ showDeleteModal: false })
  }

  render () {
    let body
    switch (this.state.loadState) {
      case 'Loading':
        body = <ClipLoader className='logo-chooser__logo__image' />
        break
      case 'Success':
        body = (
          <img
            alt={this.props.name}
            src={`data:image/bmp;base64,${this.state.imageData}`}
            className='logo-chooser__logo__image'
          />
        )
        break
      case 'Error':
      default:
        body = loadingFailed
    }
    let selected = ''
    if (this.props.selected) {
      selected = 'logo-chooser__logo--selected'
    }
    return (
      <div
        className={`logo-chooser__logo ${selected}`}
        onClick={() => this.props.onClick(this.props.id)}
      >
        {body}
        {/* <ReactBootstrap.Glyphicon
          glyph='remove-sign'
          className='logo-chooser__logo__delete'
          onClick={(event) => {
            event.stopPropagation()
            this.showDeleteModal()
          }}
        /> */}
        <span
          className='logo-chooser__logo__delete bi bi-x-circle'
          onClick={(event) => {
            event.stopPropagation()
            this.showDeleteModal()
          }}
        />
        <DeleteModal
          show={this.state.showDeleteModal}
          onHide={() => this.hideDeleteModal()}
          onCancel={() => this.hideDeleteModal()}
          onConfirm={() => {
            this.hideDeleteModal()
            this.props.onDelete(this.props.id)
          }}
          message={'Are you sure you want to delete this logo?'}
        />
      </div>
    )
  }
}