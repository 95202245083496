import ActivityNote from './ActivityNote'

export default class ActivityLog {
  id: string
  action: string
  createdbyId: string
  createdDate: Date | null
  tableName: string
  userName: string
  parentRecordId: string
  activityNotes: ActivityNote[]
  constructor (activityLog: any) {
    if (activityLog) {
      this.id = activityLog.id
      this.action = activityLog.action
      this.createdbyId = activityLog.createdbyId
      this.createdDate = activityLog.createdDate
      this.tableName = activityLog.tableName
      this.activityNotes = activityLog.activityNotes
      this.userName = activityLog.userName
      this.parentRecordId = activityLog.parentRecordId
    } else {
      this.id = ''
      this.action = ''
      this.createdbyId = ''
      this.tableName = ''
      this.activityNotes = []
      this.userName = ''
      this.createdDate = null
      this.parentRecordId = ''
    }
  }
}