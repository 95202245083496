import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Company from '../types/Company'
import Tenant from '../types/Tenant'
import Select from 'react-select'

import {
  sessionCompanySelection,
  sessionTenantSelection,
  loadCompanys,
  loadTenants
} from '../actions/sessionActions'
import { ClipLoader } from 'react-spinners'

const sidebarSubHeader = {
  padding: '0 20px',
  display: 'block'
}


interface StateProps {
  company: string
  companyId: number
  companys: any[]
  companysLoading: boolean
  tenant: string
  tenantId: number
  tenants: any[]
  tenantsLoading: boolean
}
interface DispatchProps {
  loadCompanys: any
  loadTenants: any
  sessionCompanySelection: any
  sessionTenantSelection: any
}

interface OwnProps { }

const mapStateToProps = (state: any, ownprops: OwnProps) => {
  return {
    company: state.session.company,
    companyId: state.session.companyId,
    tenant: state.session.tenant,
    tenantId: state.session.tenantId,
    companys:
      state.session.companys === undefined
        ? []
        : state.session.companys.map((x: any) => {
          return { value: x.id, label: x.name }
        }),
    tenants: state.session.tenants.map((x: any) => {
      return {
        ...x,
        value: x.id,
        label: x.name
      }
    }),
    tenantsLoading: state.session.tenantsLoading,
    companysLoading: state.session.companysLoading
  }
}

function mapDispatchToProps (dispatch: any) {
  return bindActionCreators(
    {
      loadCompanys,
      loadTenants,
      sessionCompanySelection,
      sessionTenantSelection
    },
    dispatch
  )
}

class EnvironmentSelect extends React.Component<
  StateProps & DispatchProps & OwnProps,
  {}> {

  constructor (props: any) {
    super(props)
    this.state = {
      open: true
    }
  }

  // shouldComponentUpdate(nextProps: Object, nextState: any) {
  //   for (let prop in nextProps) {
  //     if (nextProps[prop] !== this.props[prop]) return true
  //   }
  //   return false
  // }
  onCompanyChanged = (val: any) => {
    if (val) {
      this.props.sessionCompanySelection(val)

      this.props.loadTenants(val.value)
    }
  }
  onTenantChanged = (val: any) => {
    if (val) {
      this.props.sessionTenantSelection(val)
    }
  }
  componentWillMount () {
    this.props.loadCompanys()
  }
  render () {
    let companySelect = (
      <div className='form-group' style={{ flexBasis: 300, paddingRight: 20, display: 'flex' }}>
        <label htmlFor='company' className='control-label col-sm-4 text-right'>Company</label>
        <div className='col-sm-8'>
          <Select
            name='company'
            value={this.props.companys.find(x => x.value?.toString() === this.props.companyId?.toString())}
            options={this.props.companys}
            onChange={this.onCompanyChanged}
            isLoading={this.props.companysLoading}
            isClearable={false}
            components={{ LoadingIndicator: ((e) => <ClipLoader size={20} />) }}
          />
        </div>
      </div>
    )
    let tenantSelect = (
      <div className='form-group' style={{ flexBasis: 330, paddingRight: 20 }}>
        <label htmlFor='tenant' className='control-label col-sm-5 text-right'>Environment</label>
        <div className='col-sm-7'>
          <Select
            name='tenant'
            value={this.props.tenants?.find(x => x.id === this.props.tenantId) ?? null}
            options={this.props.tenants}
            onChange={this.onTenantChanged}
            isLoading={this.props.tenantsLoading}
            isClearable={false}
            components={{ LoadingIndicator: ((e) => <ClipLoader size={20} />) }}
          />
        </div>
      </div>
    )

    return (
      <div className='container-fluid' style={{ marginLeft: 0, marginRight: 0 }}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }} className='form-horizontal row'>
          <div style={{ flex: 1 }} />
          {companySelect}
          {tenantSelect}
        </div>
      </div>
    )
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentSelect)
