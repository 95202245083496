import React from 'react'
import { RewardSettings } from '../../types/RewardSettings'
import Select from 'react-select'
import Ap21RewardsProgramFilter from './Ap21RewardsProgramFilter'

const providers = [
  { label: 'Select provider', value: 0 },
  { label: 'Apparel21', value: 1 }
]

interface OwnProps {
    onChange: (settings: RewardSettings) => void
    settings: RewardSettings
}

interface DispatchProps {
}

interface StateProps {
    settings: RewardSettings
}

type Props = StateProps & OwnProps & DispatchProps

interface State {
    settings: RewardSettings
}

export default class RewardProvider extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      settings: !this.props.settings ? new RewardSettings(this.props.settings) : this.props.settings
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ ...this.state, settings: nextProps.settings })
  }

  change = (newSettings) => {
    this.setState({ ...this.state, settings: newSettings })
    this.props.onChange(newSettings)
  }

  changeProvider = (name, id) => {
    const newSettings = { ...this.state.settings, provider: id, providerName: name }
    this.change(newSettings)
  }

  render () {
    let ap21RewardsProgramFilter
    if (this.state.settings && this.state.settings.provider === 1) {
      ap21RewardsProgramFilter = (<div><Ap21RewardsProgramFilter /></div>)
    }
    return (
      <div className='panel panel-default'>
        <div className='panel-heading clearfix'>
          <h3 className='panel-title pull-left'>Rewards Provider</h3>
        </div>
        <div className='panel-body'>
          <div className='form-group row'>
            <div className='col-sm-5' style={{ maxWidth: 300 }}>
              <Select
                name='rewardProvider'
                value={providers?.find(x => x.value === this.state.settings?.provider)}
                options={providers}
                onChange={(e: any) => this.changeProvider(e.label, e.value)}
                isClearable={false}
                defaultValue={{ value: 0, label: 'Select provider'}}
              />
            </div>
          </div>
        </div>
        {ap21RewardsProgramFilter}
      </div>
    )
  }
}
