import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { useParams, useNavigate } from 'react-router-dom'
import {
  loadLandlords, landlordsUpdated, saveLandlord, deleteLandlord
} from '../actions/landlordActions'
import Landlord from '../types/Landlord'
import TextInput from './common/TextInput'

const LandlordDetails = (props) => {
  const { landLordId } = useParams()
  const navigate = useNavigate()

  const [state, setState] = useState({
    id: '',
    modalIsOpen: false,
    modalError: '',
    name: '',
    deletingId: '-1'
  })

  useEffect(() => {
    if (props.landlords === undefined || props.landlords.length === 0) {
      props.loadLandlords()
    } else {
      setLandLordState(props.landlords)
    }
  }, [props.landlords])

  useEffect(() => {
    if (props.landlordSaved) {
      changeState('id', props.id)
    }
    if (props.landlordsLoaded) {
      setLandLordState(props.landlords)
    }
    if (props.landlordDeleted) {
      navigate('/landlords')
      onNew()
    }
  }, [props.landlordSaved, props.landlordsLoaded, props.landlordDeleted])

  const onChange = (event) => {
    changeState(event.target.name, event.target.value)
  }

  const onDelete = (event) => {
    event.preventDefault()
    openModal()
  }

  const onNew = () => {
    setState({
      id: '',
      name: '',
      modalIsOpen: false,
      modalError: '',
      deletingId: '-1'
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()
    props.saveLandlord(state)
  }

  const setLandLordState = (landlords) => {
    if (landLordId && landLordId !== 'new' && landLordId !== '') {
      const landLord = landlords.filter((x) => x.id === landLordId)
      if (landLord !== null && landLord.length === 1) {
        populateExisting(landLord[0])
      }
    } else {
      onNew()
    }
  }

  const deleteAndCloseModal = (event) => {
    event.preventDefault()
    props.deleteLandlord(state)
  }

  const openModal = () => {
    setState({ ...state, modalIsOpen: true, modalError: '' })
  }

  const closeModal = () => {
    setState({ ...state, modalIsOpen: false, modalError: '' })
  }

  const populateExisting = (row) => {
    setState({
      id: row.id,
      name: row.name,
      modalIsOpen: false,
      modalError: '',
      deletingId: '-1'
    })
  }

  const changeState = (name, value) => {
    setState({ ...state, [name]: value })
  }

  const renderModal = () => {
    const deleteMessage = `Please confirm you wish to delete landlord "${state.name}" `
    return (
      <Modal show={state.modalIsOpen} onHide={closeModal}>
        <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <Modal.Title style={{ marginRight: 'auto'}}>Delete</Modal.Title>
          {/* Custom Close Button */}
          <Button type='button' className='close' aria-label='Close' onClick={closeModal} style={{ border: 'none', boxShadow: 'none' }}>
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{deleteMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-danger'
            onClick={deleteAndCloseModal}
          >
            Delete
          </button>
          <button
            className='btn btn-default pull-left'
            onClick={closeModal}
          >
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  const spinner = (<ClipLoader size={40} />)
  const submit = (
    <button
      style={{ marginRight: 10 }}
      className='btn btn-primary'
      onClick={onSubmit}
    >
      Save
    </button>
  )

  let deleteBtn = null
  if (state.id) {
    deleteBtn = (
      <button
        className='btn btn-danger'
        onClick={onDelete}
      >
        Delete
      </button>
    )
  }

  const formData = () => (
    <div>
      <form>
        <TextInput
          maxLength={100}
          name='name'
          label='Name'
          value={state.name}
          onChange={onChange}
          style={{ width: 400 }}
        />
        <div>
          {submit}
          {deleteBtn}
        </div>
      </form>
      {renderModal()}
    </div>
  )

  return (
    <div>
      <h3>{`Landlord Details ${state.name || (props.landlordsLoading ? '' : 'New')}`}</h3>
      {props.landlordsLoading ? spinner : formData()}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    landlordSaved: state.landlord.landlordSaved,
    landlordDeleted: state.landlord.landlordDeleted,
    id: state.landlord.id,
    landlords: state.landlord.landlordLoading ? undefined : state.landlord.landlords,
    error: state.landlord.error,
    landlordsLoading: state.landlord.landlordsLoading,
    landlordsLoaded: state.landlord.landlordsLoaded
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    landlordsUpdated,
    loadLandlords,
    saveLandlord,
    deleteLandlord
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandlordDetails)
