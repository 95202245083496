import {
  CLEAR_MENUS, DELETE_MENU_ERROR, DELETE_MENU_FINISHED,
  DELETE_MENU_STARTED,
  DELETE_MENU_SUCCESS, MENU_ADD,
  MENU_API_STARTED, MENU_COMPANY_SELECT, MENU_LOADED, MENU_NAME_CHANGED,
  MENU_ORDERCHANGE, MENU_REDIRECT_LISTING, MENU_REORDER, MENU_TENANT_SELECT,
  MENUS_LOADED, MENUS_LOADING, MENUS_SAVED, MENUS_SAVING, MENUS_UPDATED, RULE_ADD, RULE_CHANGE_OPERATOR,
  RULE_CHANGE_TAGGROUP, RULE_CHANGE_TAGS, RULE_DELETE, SET_DELETE_MENU_SUCCESS, SUBMENU_ADD, SUBMENU_DELETE, SUBMENU_EXPAND, SUBMENU_MATCH,
  SUBMENU_TITLE_CHANGE, TAGS_LOADED
} from '../actions/menuActions'
import {
  COMPANY_SELECTED, TENANT_SELECTED
} from '../actions/sessionActions'
import { Menu, Rule, RuleChild, SubFilter } from '../types/Menu'
import initialState from './initialState'

function expandSubFilter (subFilters: SubFilter[], id: any) {
  const subFilter = subFilters.find(p => p.id === id)
  if (subFilter.parentSubFilterId === '0' && subFilter.expanded) {
    return subFilters
  }
  let altered = subFilters.filter(p => p.parentSubFilterId === subFilter.parentSubFilterId
        && p.id !== id).map(p => { return { ...p, expanded: false } })
  let ret = [
    ...subFilters.filter(p => p.parentSubFilterId !== subFilter.parentSubFilterId),
    ...altered,
    {
      ...subFilter,
      expanded: !subFilter.expanded
    }

  ]
  return subFilters.map(p => ret.find(q => q.id === p.id))
}

function deleteSubFilter (subFilters: SubFilter[], id: any) {
  let deleted = [subFilters.find(p => p.id === id)]
  if (deleted[0].parentSubFilterId === '0' && deleted[0].expanded === true) {
    let subFilter = subFilters.find(p => p.parentSubFilterId === '0' && id !== p.id)
    if (subFilter !== undefined) {
      subFilters = expandSubFilter(subFilters, subFilter.id)
    }
  }
  deleted = [
    ...deleted,
    ...subFilters.filter(p => deleted.some(q => q.id === p.parentSubFilterId))
  ]
  deleted = [
    ...deleted,
    ...subFilters.filter(p => deleted.some(q => q.id === p.parentSubFilterId))
  ]
  return subFilters.filter(p => !deleted.some(q => q.id === p.id))

}

export default function menuReducer (state = initialState.menu, action: any) {
  switch (action.type) {
    case SUBMENU_EXPAND:
    {
      return {
        ...state,
        menu: {
          ...state.menu,
          subFilters: expandSubFilter(state.menu.subFilters, action.id)
        }
      }
    }

    case MENU_REDIRECT_LISTING:
      // browserHistory.push('/navigations')
      return state

    case MENU_ADD: {
      const menu = new Menu()
      menu.tenantId = action.Id
      let subFilter = new SubFilter({ parentSubFilterId: '0' })
      let subFilters = [subFilter]

      return {
        ...state,
        menu: { ...menu, subFilters: expandSubFilter(subFilters, subFilter.id) }
      }
    }
    case MENUS_SAVED: {
      return {
        ...state,
        menuSaving: false,
        menuSaved: action.success,
        menuSavedId: action.result.menuId
      }
    }

    case MENUS_SAVING: {
      return {
        ...state,
        menuSaving: true
      }
    }
    case MENU_LOADED:
    {
      return {
        ...state,
        menu: action.menu,
        menusLoading: false,
        menusLoaded: true,
        menuDeleted: false,
        menuSaving: false
      }

    }
    case MENUS_LOADED:
    {
      const newMenus: any = []
      let i = 0
      action.menus.forEach((y: any) => {
        const newMenu = new Menu(y)
        newMenus.push(newMenu)
      })

      return {
        ...state,
        menus: newMenus,
        menuSaved: false,
        menuDeleted: false,
        menusLoading: false,
        menusLoaded: true
      }
    }
    case MENU_LOADED:
      return { ...state, error: '', menuSaved: false, menuDeleted: false }
    case TAGS_LOADED:
      return { ...state, error: '', menuLoaded: false, menu: { ...state.menu, tagGroups: action.tags } }

    case MENUS_LOADING:
      return { ...state, menusLoading: true, menus: undefined }
    case CLEAR_MENUS:
    {
      const newMenus: any = []
      return {
        ...state,
        menus: newMenus,
        menusLoading: false,
        menusLoaded: false,
        menuSaving: false
      }
    }
    case MENU_COMPANY_SELECT:
      return { ...state, menu: { ...state.menu, companyId: action.id } }

    case MENU_TENANT_SELECT:      
      return { ...state, menu: { ...state.menu, tenantId: action.id } }

    case COMPANY_SELECTED:
    {
      return { ...state, menu: { ...state.menu, companyId: action.companyId ? action.companyId.value : '' } }

    }
    case TENANT_SELECTED:
    {
      return { ...state, menu: { ...state.menu, tenantId: action.tenantId ? action.tenantId.value : '' } }
    }

    case MENUS_UPDATED:
      return { ...state, menus: action.menus }
    case DELETE_MENU_SUCCESS:
    {
      // browserHistory.push('/menus')
      // const newMenus = []
      // state.menus.filter(x => x.id != action.id).forEach((y) => {
      //     newMenus.push(new Menu(y))
      // })
      return { ...state, menuDeletedSuccess: true }
    }
    case DELETE_MENU_ERROR:
      return { ...state, error: action.error, menuSaved: false, menuDeleted: false }
    case DELETE_MENU_STARTED:
      return { ...state, menuDeleted: true, menuDeletedSuccess: false }
    case SET_DELETE_MENU_SUCCESS:
      return { ...state, menuDeletedSuccess: action.value }
    case DELETE_MENU_FINISHED:
      return { ...state, menuDeleted: false }
    case MENU_API_STARTED:
      return { ...state, error: '', menuSaved: false, menuDeleted: false }

    case SUBMENU_ADD: {

      let subFilter = new SubFilter({ parentSubFilterId: action.parentSubFilterId })
      let subFilters = [...state.menu.subFilters, subFilter]

      return { ...state, menu: { ...state.menu, subFilters: expandSubFilter(subFilters, subFilter.id) } }
    }

    case SUBMENU_TITLE_CHANGE:
      let { title, id } = action
      return {
        ...state,
        menu: {
          ...state.menu,
          subFilters: [
            ...state.menu.subFilters.map((p: any) => p.id === id ? { ...p, title: title } : p)
          ]
        }
      }
    case SUBMENU_DELETE: {
      const subFilters = deleteSubFilter(state.menu.subFilters, action.id)

      const rules = state.menu.rules.filter((p: any) => subFilters.some(q => q.id === p.subFilterId))
      const ruleChilds = state.menu.ruleChilds.filter((p: any) => rules.some((q: any) => q.id === p.ruleId))
      return {
        ...state,
        menu: {
          ...state.menu,
          subFilters,
          rules,
          ruleChilds
        }
      }
    }

    case MENU_NAME_CHANGED: {
      let { name } = action
      return {
        ...state,
        menu: {
          ...state.menu,
          name: name
        }
      }
    }

    case SUBMENU_MATCH: {
      return {
        ...state,
        menu: {
          ...state.menu,
          subFilters: [
            ...state.menu.subFilters.map((p: any) => p.id === action.id ? { ...p, isMatchingAll: action.all } : p)
          ]
        }
      }
    }
    case MENU_ORDERCHANGE:
    {
      let { items, parentSubFilterId } = action
      let subFilters = [
        ...state.menu.subFilters.filter((p: any) => p.parentSubFilterId !== parentSubFilterId),
        ...items.map((p: any) => state.menu.subFilters.find((q: any) => q.id === p))
      ]

      return {
        ...state,
        menu: {
          ...state.menu,
          subFilters: subFilters.filter((p) => p !== undefined)
        }
      }
    }
    case MENU_REORDER: {
      let { newIndex, item } = action
      let srcSubFilters: any = state.menu.subFilters.filter((p: any) => p.parentSubFilterId === item.parentSubFilterId)
      newIndex--
      if (newIndex > srcSubFilters.length - 1)
        return state
      const previousIndex = srcSubFilters.indexOf(item)
      const removed = [
        ...srcSubFilters.slice(0, previousIndex),
        ...srcSubFilters.slice(previousIndex + 1)
      ]
      return {
        ...state,
        menu: {
          ...state.menu,
          subFilters: [
            ...state.menu.subFilters.filter((p: any) => p.parentSubFilterId !== item.parentSubFilterId),
            ...removed.slice(0, newIndex),
            srcSubFilters[previousIndex],
            ...removed.slice(newIndex)
          ]
        }
      }

    }

    case RULE_ADD:
    {
      return {
        ...state,
        menu: {
          ...state.menu,
          rules: [
            ...state.menu.rules,
            new Rule({ subFilterId: action.subFilterId })
          ]
        }
      }
    }

    case RULE_CHANGE_TAGGROUP:
    {
      return {
        ...state,
        menu: {
          ...state.menu,
          rules: [
            ...state.menu.rules.map((p: any) => {
              if (p.id === action.ruleId)
                return {
                  ...p,
                  tagGroupId: action.tagGroup
                }
              else
                return p
            })
          ],

          ruleChilds: [
            ...state.menu.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId)
          ]
        }
      }
    }

    case RULE_CHANGE_OPERATOR:
    {
      return {
        ...state,
        menu: {
          ...state.menu,
          rules: [
            ...state.menu.rules.map((p: any) => {
              if (p.id === action.ruleId)
                return {
                  ...p,
                  operatorType: action.operator
                }
              else
                return p
            })
          ],
          ruleChilds: [
            ...state.menu.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId)
          ]
        }
      }
    }

    case RULE_DELETE:
    {
      return {
        ...state,
        menu: {
          ...state.menu,
          rules: [
            ...state.menu.rules.filter((p: any) => p.id !== action.id)
          ],
          ruleChilds: [
            ...state.menu.ruleChilds.filter((p: any) => p.ruleId !== action.id)
          ]
        }
      }
    }
    case RULE_CHANGE_TAGS:
      return {
        ...state,
        menu: {
          ...state.menu,
          ruleChilds: [
            ...state.menu.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId),
            ...action.tags.map((p: any) => new RuleChild({ ruleId: action.ruleId, tagId: p }))
          ]
        }
      }
    default:
      return state
  }
}
