import { Route, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import App from './components/App'
import HomePage from './components/HomePage'
import LogInPage from './components/LoginPage'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'
import LandlordPage from './components/LandlordPage'
import LandlordDetails from './components/LandlordDetails'
import LandlordList from './components/LandlordList'
import AdminUserList from './components/AdminUserList'
import AdminUserDetails from './components/AdminUserDetails'
import AdminUserPage from './components/AdminUserPage'
import CompanyPage from './components/CompanyPage'
import CompanyDetails from './components/CompanyDetails'
import CompanyList from './components/CompanyList'
import DevicesPage from './components/DevicesPage'
import MenuPage from './components/MenuPage'
import MenuList from './components/MenuList'
import ApplyTemplateList from './components/ApplyTemplateList'
import MenuDetail from './components/MenuDetails'
import ChangePasswordPage from './components/ChangePasswordPage'
import auth from './auth/authenticator'
import ProductPage from './components/ProductPage'
import ProductList from './components/ProductList'
import ProductDetail from './components/ProductDetail'
import PrinterTemplateList from './components/ReceiptFormats/PrinterTemplateList'
import PrinterTemplateDetail from './components/ReceiptFormats/PrinterTemplateDetail'
import StoreTemplateList from './components/Store/StoreTemplateList'
import StoreTemplateDetail from './components/Store/StoreTemplateDetail'
import CustomerTemplateList from './components/Customer/CustomerTemplateList'
import CustomerTemplateDetail from './components/Customer/CustomerTemplateDetail'
import DataExplorer from './components/DataExplorer/DataExplorer'
import AppInfoPage from './components/AppInfoPage'

// Wrapper for requiring login
function RequireAuth ({ children, redirectTo, caller }) {
  if (auth.loggedIn()) {
    return children 
  } else {
    return <Navigate to={redirectTo} />
  }
}

// Wrapper for requiring login
function RequireLogout ({ children, redirectTo, caller }) {
  if (auth.loggedIn()) {
    return <Navigate to={redirectTo} />
  } else {
    return children
  }
}

// Wrapper for requiring specific roles
function RequireRole ({ children, roles, redirectTo }) {
  const isAuthorized = roles.some(role => auth.loggedIn(role))
  return isAuthorized ? children : <Navigate to={redirectTo} />
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} element={<RequireAuth redirectTo='/login' caller={'HOME PAGE'}><HomePage /></RequireAuth>} />

      {/* Auth routes */}
      <Route path='login' element={<RequireLogout redirectTo='/' caller={'LOGIN PAGE'}><LogInPage /></RequireLogout>} />
      <Route path='forgotpassword' element={<ForgotPassword />} />
      <Route path='resetpassword' element={<ResetPassword />} />
      <Route path='setpassword' element={<ResetPassword />} />
      
      {/* Navigation routes */}
      <Route path='navigations' element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'>
        <MenuPage><MenuList /></MenuPage></RequireRole>}
      />
      <Route path='navigations/:menuId' element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'>
        <MenuPage><MenuDetail /></MenuPage></RequireRole>} 
      />

      {/* Devices and other routes */}
      <Route path='devices' element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><DevicesPage /></RequireRole>} />
      <Route path='applytemplate' element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><ApplyTemplateList /></RequireRole>} />
      <Route path='dataexplorer' element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><DataExplorer /></RequireRole>} />
      
      {/* Product templates */}
      <Route
        path='products'
        element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><ProductPage><ProductList /></ProductPage></RequireRole>}
      />
      <Route
        path='products/:productId'
        element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><ProductDetail /></RequireRole>}
      />

      {/* Docket formats */}
      <Route
        path='receipts'
        element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><PrinterTemplateList /></RequireRole>}
      />
      <Route
        path='receipts/:templateId'
        element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><PrinterTemplateDetail /></RequireRole>}
      />

      {/* Customer templates */}
      <Route
        path='customer'
        element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><CustomerTemplateList /></RequireRole>}
      />
      <Route 
        path='customer/:customerId'
        element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><CustomerTemplateDetail /></RequireRole>}
      />

      {/* Store templates */}
      <Route
        path='store'
        element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><StoreTemplateList /></RequireRole>}
      />
      <Route
        path='store/:templateId'
        element={<RequireRole roles={['admin', 'companyAdmin']} redirectTo='/login'><StoreTemplateDetail /></RequireRole>}
      />

      {/* Landlord routes */}
      <Route path='landlords' element={<RequireRole roles={['superuser']} redirectTo='/login'>
        <LandlordPage><LandlordList /></LandlordPage></RequireRole>}
      />
      <Route path=':landLordId' element={<RequireRole roles={['superuser']} redirectTo='/login'>
        <LandlordPage><LandlordDetails /></LandlordPage></RequireRole>}
      />

      {/* Admin user routes */}
      <Route path='admin-users' element={<RequireAuth redirectTo='/login' caller={'admin user PAGE'}>
        <AdminUserPage><AdminUserList /></AdminUserPage></RequireAuth>} 
      />
      <Route path='admin-users/:userId' element={<RequireAuth redirectTo='/login' caller={'admin user PAGE'}>
        <AdminUserPage><AdminUserDetails /></AdminUserPage></RequireAuth>} 
      />

      {/* Company routes */}
      <Route path='companies' element={<RequireAuth redirectTo='/login' caller={'COMPANY PAGE'}><CompanyPage><CompanyList /></CompanyPage></RequireAuth>} />
      <Route path='companies/:companyId' element={<RequireAuth redirectTo='/login' caller={'COMPANY PAGE'}><CompanyDetails /></RequireAuth>} />

      {/* Other routes */}
      <Route path='changePassword' element={<RequireAuth redirectTo='/login' caller={'CHANGEP PAGE'}><ChangePasswordPage /></RequireAuth>} />
      <Route path='appinfo' element={<RequireRole roles={['superuser']} redirectTo='/login'><AppInfoPage /></RequireRole>} />
    </Route>
  )
)

const AppRoutes = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default AppRoutes
