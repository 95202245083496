import { toast } from 'react-toastify'

const standardToast = {
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

export function showErrorToast (message) {

  let errorMessage = 'An unknown error occurred'
  if (typeof message === 'string') {
    errorMessage = message
  } else if (message.message && typeof message.message === 'string') {
    errorMessage = message.message
  } else if (message.description && typeof message.description === 'string') {
    errorMessage = message.description
  } else if (message.response?.data && typeof message.response.data === 'string') {
    errorMessage = message.response.data
  }

  toast.error(errorMessage, { ...standardToast, autoClose: false, position: 'top-center' })
}

export function showSuccessToast (message) {
  toast.success(message, standardToast)
}

export function showInfoToast (message) {
  toast.info(message, standardToast)
}