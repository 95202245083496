import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import moment from 'moment'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import Clipboard from 'react-clipboard.js'
import { connect } from 'react-redux'
import { OverlayTrigger, Tooltip, ListGroup, ListGroupItem, Modal, Button } from 'react-bootstrap'
import { loadCompanys, saveCompany, deleteCompany } from '../actions/companyActions'
import {
  loadTenants, addTenant, updateTenant, clearTenants, deleteTenant, regenerateTenantApiKey,
  changeIsTestEnvironment, deleteTenantFromCompany, toggleFeature
} from '../actions/tenantActions'
import TextInput from './common/TextInput'
import Tenant from '.././types/Tenant'
import { loadLandlords } from '../actions/landlordActions'
import * as Toast from '../utils/Toast'
import Feature from '../types/Feature'
import ToggleSwitch from './common/ToggleSwitch'
import { useOutletContext, useParams } from 'react-router-dom'

const CompanyDetails = (props) => {
  const { companyId } = useParams()
  const setContext: any = useOutletContext()
  
  const [state, setState] = useState({
    id: '',
    modalIsOpen: false,
    modalTenantIsOpen: false,
    modalApiKeyIsOpen: false,
    modalError: '',
    name: '',
    deletingId: '',
    sourceId: '',
    landlord: props.claims.role !== 'superuser' ? props.claims.landlord : '',
    landlordId: props.claims.role !== 'superuser' ? props.claims.landlordId : '',
    deletedTenants: [],
    apiIndex: null,
    tenant: null
  })

  useEffect(() => {
    if (props.companys === undefined) {
      props.loadCompanys()
      props.loadLandlords()
    } else {
      setCompanyState(props.companys)
      props.loadLandlords()
    }
  }, [])

  useEffect(() => {
    if (setContext && state.name) {
      setContext({ name: state.name })
    }
  }, [state.name])

  useEffect(() => {
    if (props.companyDeleted) {
      onNew()
    }
  }, [props.companyDeleted])

  useEffect(() => {
    if (props.companySaved) {
      changeState('id', props.id)
      props.loadTenants(props.id, true)
    }
  }, [props.companySaved])

  useEffect(() => {
    if (props.tenantsSaved) {
      props.loadTenants(props.id)
    }
  }, [props.tenantsSaved])

  useEffect(() => {
    if (props.tenantsLoading) {
      setCompanyState(props.companys)
    }
  }, [props.companysLoaded])

  const onChange = (event) => {
    return changeState(event.target.name, event.target.value)
  }

  const onDelete = (event) => {
    event.preventDefault()
    openModal()
  }

  const onLandlordChanged = (val) => {
    if (val) {
      changeState('landlord', val.label)
      changeState('landlordId', val.value)
    }
  }

  const onNew = () => {
    setState({
      ...state,
      id: '',
      name: '',
      modalIsOpen: false,
      modalTenantIsOpen: false,
      modalApiKeyIsOpen: false,
      modalError: '',
      deletingId: '',
      sourceId: '',
      landlord: props.claims.role !== 'superuser' ? props.claims.landlord : '',
      landlordId: props.claims.role !== 'superuser' ? props.claims.landlordId : '',
      deletedTenants: []
    })
    props.clearTenants(true)
  }

  const onSubmit = (event) => {
    event.preventDefault()
    props.saveCompany(props.claims, state, props.tenants)
  }

  const onChangeTenant = (value, index) => {
    props.updateTenant(value, index)
    return true
  }

  const onRemoveTenant = (event, id, index, name) => {
    event.preventDefault()
    if (id === '') {
      let cId = state.id
      props.deleteTenantFromCompany({ id: '', cId: cId }, index)
    } else
      openTenantModal({ id: id, index: index, name: name })
  }

  const onCopySuccess = () => {
    Toast.showSuccessToast('Copied to clipboard!')
  }

  const onApiKeyRegenerate = () => {
    const tenantId = props.tenants[state.apiIndex].id
    props.regenerateTenantApiKey(state.apiIndex, tenantId)
    closeApiKeyModal()
    return true
  }

  const openApiKeyModal = (event, index) => {
    event.preventDefault()
    setState({ ...state, modalApiKeyIsOpen: true, apiIndex: index })
  }

  const closeApiKeyModal = () => {
    setState({ ...state, modalApiKeyIsOpen: false, apiIndex: null })
  }

  const onIsTestEnvironmentChange = (index) => {
    props.changeIsTestEnvironment(index)
    return true
  }

  const setCompanyState = (companys) => {
    props.clearTenants()
    if (companyId && companyId !== 'new' && companyId !== '') {
      const company = companys.filter((x) => {
        return x.id === companyId
      })
      if (company !== null && company.length === 1) {
        return populateExisting(company[0])
      }
    }
    return onNew()
  }

  const deleteAndCloseModal = (event) => {
    event.preventDefault()
    props.deleteCompany(state)
  }

  const openModal = () => {
    setState({ ...state, modalIsOpen: true, modalError: '' })
  }

  const closeModal = () => {
    setState({ ...state, modalIsOpen: false, modalError: '' })
  }

  const deleteAndCloseTenantModal = (event) => {
    event.preventDefault()
    setState({ ...state, modalTenantIsOpen: false })
    props.deleteTenantFromCompany({ id: state.tenant.id, cId: state.id }, state.tenant.index)
    setState({ ...state, modalTenantIsOpen: false, tenant: '' })
  }

  const openTenantModal = (tenant) => {
    setState({ ...state, modalTenantIsOpen: true, tenant: tenant })
  }

  const closeTenantModal = () => {
    setState({ ...state, modalTenantIsOpen: false, tenant: '' })
  }

  const populateExisting = (company) => {
    setState({
      ...state,
      id: company.id,
      name: company.name,
      modalIsOpen: false,
      modalTenantIsOpen: false,
      modalApiKeyIsOpen: false,
      modalError: '',
      deletingId: '',
      sourceId: company.sourceId,
      landlord: company.landlord,
      landlordId: company.landlordId,
      deletedTenants: []
    })
    props.loadTenants(company.id, true)
  }

  const changeState = (name, value) => {
    return setState({ ...state, [name]: value })
  }

  const addTenant = (event) => {
    event.preventDefault()
    props.addTenant()
  }

  const deleteTenant = (event, index) => {
    event.preventDefault()
    const newDeletedTenants = state.deletedTenants.slice()
    const toDelete = new Tenant(props.tenants[index])
    newDeletedTenants.push(toDelete)
    setState({ ...state, deletedTenants: newDeletedTenants })
    props.deleteTenant(index)
  }

  const renderApiKeyModal = () => {
    const warningMessage = `Are you sure you want to change the key?
    Changing the key will require the data synchronisation settings in the ERP to be updated with the new value.`
    return (
      <Modal show={state.modalApiKeyIsOpen} onHide={closeApiKeyModal}>
        <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <Modal.Title style={{ marginRight: 'auto'}}>WARNING</Modal.Title>
          {/* Custom Close Button */}
          <Button type='button' className='close' aria-label='Close' onClick={closeModal} style={{ border: 'none', boxShadow: 'none' }}>
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{warningMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-primary'
            onClick={onApiKeyRegenerate}
          >
            Confirm Key Change
          </button>
          <button
            className='btn btn-default pull-left'
            onClick={closeApiKeyModal}
          >
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
  
  const renderModal = () => {
    const deleteMessage = `Please confirm you wish to delete Company "${state.name}" `
    return (
      <Modal show={state.modalIsOpen} onHide={closeModal}>
        <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <Modal.Title style={{ marginRight: 'auto'}}>Delete</Modal.Title>
          {/* Custom Close Button */}
          <Button type='button' className='close' aria-label='Close' onClick={closeModal} style={{ border: 'none', boxShadow: 'none' }}>
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{deleteMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' onClick={deleteAndCloseModal}>
            Delete
          </button>
          <button className='btn btn-default pull-left' onClick={closeModal}>
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  const renderDeleteTenatModal = () => {
    const tenantName = state.tenant === null || state.tenant === undefined ? '' : state.tenant.name
    const deleteMessage = `Please confirm you wish to delete "${tenantName}".
    This environment will be removed immediately and you will not be able to undo this action.`
    return (
      <Modal show={state.modalTenantIsOpen} onHide={closeTenantModal}>
        <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <Modal.Title style={{ marginRight: 'auto'}}>Delete</Modal.Title>
          {/* Custom Close Button */}
          <Button type='button' className='close' aria-label='Close' onClick={closeTenantModal} style={{ border: 'none', boxShadow: 'none' }}>
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{deleteMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' onClick={deleteAndCloseTenantModal}>
            Delete
          </button>
          <button className='btn btn-default pull-left' onClick={closeTenantModal}>
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  const renderTenantsForm = () => {
    const inlineSpinner = (
      <div style={{ marginLeft: '20px', height: '15px', display: 'inline-block' }}>
        <ClipLoader size={15} />
      </div>
    )
    let tenantsSelect = null
    tenantsSelect = props.tenants.map((x: Tenant, index: number) => (
      <div
        className='list-group-item'
        style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}
        key={`env_${index}`}
      >
        <div className='form-group'>
          <label htmlFor='#environment'>Name: &nbsp; </label>
          <input
            type='text'
            id='environment'
            name='environment'
            placeholder='Environment name'
            value={x.name}
            onChange={(e) => onChangeTenant(e.target.value, index)}
            maxLength={100}
            disabled={props.claims.role === 'companyAdmin'}
            className='form-control'
            style={{ width: '450px' }}
          />
          {x.id === '' ? (
            ''
          ) : (
            <div style={{ padding: '10px 0 0 0' }}>
              <b>Key:&nbsp;</b> {x.displayApiKey}
              <OverlayTrigger placement='bottom' overlay={<Tooltip id='copy'>Copy to clipboard</Tooltip>}>
                <span>
                  <Clipboard
                    data-clipboard-text={x.apiKey}
                    className='btn-link btn btn-xs'
                    data-original-title='Copy to clipboard'
                    data-toggle='tooltip'
                    data-placement='bottom'
                    onSuccess={onCopySuccess}
                  >
                    <i style={{ padding: '0 10px' }} className='material-icons'>
                      content_copy
                    </i>
                  </Clipboard>
                </span>
              </OverlayTrigger>
              <OverlayTrigger placement='bottom' overlay={<Tooltip id='new'>Generate new key</Tooltip>}>
                <button className='btn-link btn btn-xs' onClick={(e) => openApiKeyModal(e, index)}>
                  <i className='material-icons'>autorenew</i>
                </button>
              </OverlayTrigger>
              <p>
                <small>
                  Created by {x.createdByName} on {moment(x.createdDate).format('LL')}
                </small>
                {props.claims.role === 'companyAdmin' ? null : (
                  <div>
                    <small>Database name: Tenant_{x.id}</small>
                  </div>
                )}
              </p>
            </div>
          )}
          <div style={{ width: '80px', marginRight: '10px', marginBottom: '12px', marginTop: '10px' }}>
            <ToggleSwitch
              checked={!x.isTestEnvironment}
              disabled={x.id === '' ? false : true}
              onChange={() => onIsTestEnvironmentChange(index)}
              checkedIconText={'LIVE'}
              uncheckedIconText={'TEST'}
              // When switch is disabled, display green/grey for live/test
              switchStyle={{
                background: x.id === '' ? null : x.isTestEnvironment ? '#B2B9BC' : '#188718',
                opacity: x.id === '' ? null : 0.6
              }}
            />
          </div>
          {x.features.filter((y) => y.showInFeatureList && !y.isInDevelopment).length > 0 && <b>Features:</b>}
          <ListGroup style={{ marginBottom: '10px' }}>
            {x.features.map(
              (y) => y.showInFeatureList && !y.isInDevelopment && renderFeaturebox(index, y)
            )}
          </ListGroup>
          {x.features.filter((y) => y.showInFeatureList && y.isInDevelopment).length > 0 && (
            <b>Features In Development:</b>
          )}
          <ListGroup style={{ marginBottom: '10px' }}>
            {x.features.map(
              (y) => y.showInFeatureList && y.isInDevelopment && renderFeaturebox(index, y)
            )}
          </ListGroup>
          <b>Webhooks:</b>
          <ListGroup style={{ marginBottom: '10px' }}>{renderWebhook('Shippit', x.shippitWebhookUrl)}</ListGroup>
        </div>
        {props.claims.role !== 'companyAdmin' && (
          <OverlayTrigger placement='bottom' overlay={<Tooltip id='delete'>Delete environment</Tooltip>}>
            {x.tenantDeleting ? (
              <button
                className='btn-link btn btn-xs'
                disabled={true}
                style={{ alignSelf: 'center' }}
                onClick={(e) => onRemoveTenant(e, x.id, index, x.name)}
              >
                {inlineSpinner}
              </button>
            ) : (
              <button
                className='btn-link btn'
                style={{ alignSelf: 'center' }}
                onClick={(e) => onRemoveTenant(e, x.id, index, x.name)}
              >
                <i className='text-danger glyphicon glyphicon-remove' />
              </button>
            )}
          </OverlayTrigger>
        )}
      </div>
    ))

    return (
      <div className='panel panel-default'>
        <div className='panel-heading clearfix'>
          <h3 className='panel-title pull-left'>Manage environments</h3>
        </div>
        <div className='panel-body'>
          <form>{tenantsSelect}</form>
        </div>
        {props.claims.role !== 'companyAdmin' && (
          <div>
            <button className='btn-link-dashed btn-block  btn btn-link' onClick={(event) => addTenant(event)}>
              Create new environment
            </button>
          </div>
        )}
      </div>
    )
  }

  const renderFeaturebox = (index: number, feature: Feature) => {
    return (
      <ListGroupItem
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          width: '450px',
          paddingBottom: '7px',
          paddingTop: '7px'
        }}
        key={feature.id}
      >
        <label style={{ flex: 1, maxWidth: '300px', wordBreak: 'normal' }}>{feature.name}</label>
        <ToggleSwitch
          checked={feature.isEnabled}
          onChange={() => props.toggleFeature(index, feature.id)}
          checkedIconText={'Enabled'}
          uncheckedIconText={'Disabled'}
          switchStyle={{ marginLeft: '20px', width: '80px' }}
        />
      </ListGroupItem>
    )
  }

  const renderWebhook = (name: string, url: string) => {
    return (
      <ListGroupItem
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          width: '100%',
          paddingBottom: '7px',
          paddingTop: '7px'
        }}
        key={name}
      >
        <div style={{ padding: '10px 0 0 0' }}>
          <b>{name}:&nbsp;</b> {url}
          <OverlayTrigger placement='bottom' overlay={<Tooltip id='copy'>Copy to clipboard</Tooltip>}>
            <span>
              <Clipboard
                data-clipboard-text={url}
                className='btn-link btn btn-xs'
                data-original-title='Copy to clipboard'
                data-toggle='tooltip'
                data-placement='bottom'
                onSuccess={onCopySuccess}
              >
                <i style={{ padding: '0 10px' }} className='material-icons'>
                  content_copy
                </i>
              </Clipboard>
            </span>
          </OverlayTrigger>
        </div>
      </ListGroupItem>
    )
  }

  const renderCompanyForm = () => {
    if (props.claims.role !== 'superuser' && props.claims.role !== 'admin') {
      return null
    }

    let landlordSelect = null
    if (props.claims.role === 'superuser') {
      landlordSelect = (
        <div>
          <label htmlFor='landlord'>Landlord</label>
          <Select
            name='landlord'
            value={props.landlords.find(x => x.value === state.landlordId)}
            options={props.landlords}
            onChange={onLandlordChanged}
            styles={{
              control: (provided: any) => ({
                ...provided,
                maxWidth: 400
              })
            }}
            isClearable={false}
          />
        </div>
      )
    }

    return (
      <form>
        <div>
          <TextInput
            maxLength={100}
            name='name'
            label='Company Name'
            value={state.name}
            onChange={onChange}
            style={{ width: 400 }}
          />
          {landlordSelect}
        </div>
      </form>
    )
  }
  
  let spinner = null
  if (props.tenantsLoading || props.companysLoading) {
    spinner = (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
        <ClipLoader size={40} />
      </div>
    )
  }

  let error = null
  if (props.error) {
    error = (
      <div style={{ maxWidth: 500 }}>
        <p className='alert alert-danger'>{props.error}</p>
      </div>
    )
  }
  const submit = (
    <button
      className='btn btn-primary pull-right'
      onClick={onSubmit}
      disabled={props.companysLoading || props.tenantsLoading}
    >
      Save
    </button>
  )
  const formData = () => {
    return (
      <div>
        {error}
        {renderCompanyForm()}
        {renderTenantsForm()}
        {renderModal()}
        {renderApiKeyModal()}
        {renderDeleteTenatModal()}
      </div>
    )
  }
  return (
    <div style={{ maxWidth: '1000px' }}>
      {submit}
      <h3>{`Company Details ${state.name || (props.tenantsLoading || props.companysLoading ? '' : 'New')}`}</h3>
      {props.tenantsLoading || props.companysLoading ? spinner : formData()}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    error: state.company.error,
    claims: state.session,
    companySaved: state.company.companySaved,
    companyDeleted: state.company.companyDeleted,
    id: state.company.id,
    landlords: state.landlord.landlords === undefined ?
      [] : state.landlord.landlords.map((x) => { return { value: x.id, label: x.name } }),
    companysLoaded: state.company.companysLoaded,
    tenants: state.tenant.tenants,
    tenantsSaved: state.tenant.saved,
    tenantsLoading: state.tenant.loading,
    companysLoading: state.company.companysLoading,
    companys: state.company.companysLoading ? undefined : state.company.companys
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    saveCompany,
    deleteCompany,
    loadTenants,
    addTenant,
    updateTenant,
    changeIsTestEnvironment,
    toggleFeature,
    clearTenants,
    deleteTenant,
    loadLandlords,
    regenerateTenantApiKey,
    loadCompanys,
    deleteTenantFromCompany
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDetails)
