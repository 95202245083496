export class Template {
  id: number
  menuId: string
  productFilterId: string
  printerTemplateId: string
  generalTemplateId: string
  customerTemplateId: string
  name: string
  code: string
  sourceId: string
  active: boolean
  nextDocketNumber: number
  navigation: string
  products: string
  printerTemplate: string
  generalTemplate: string
  customers: string
  promotions: string
  showEditor: boolean
  checked: boolean

  constructor (template?: Template) {
    this.showEditor = false
    this.checked = false
    if (template !== undefined) {
      this.id = template.id
      this.menuId = template.menuId
      this.productFilterId = template.productFilterId
      this.customerTemplateId = template.customerTemplateId
      this.name = template.name
      this.code = template.code
      this.sourceId = template.sourceId
      this.active = template.active
      this.nextDocketNumber = template.nextDocketNumber
      this.navigation = template.navigation
      this.products = template.products
      this.customers = template.customers
      this.promotions = template.promotions
      this.printerTemplateId = template.printerTemplateId
      this.printerTemplate = template.printerTemplate
      this.generalTemplateId = template.generalTemplateId
      this.generalTemplate = template.generalTemplate

    } else {
      this.id = 0
      this.menuId = ''
      this.name = ''
      this.code = ''
      this.sourceId = ''
      this.active = false
      this.nextDocketNumber = 0
      this.productFilterId = ''
      this.customerTemplateId = ''
      this.navigation = ''
      this.customers = ''
      this.promotions = ''
      this.products = ''
      this.printerTemplateId = ''
      this.printerTemplate = ''
      this.generalTemplateId = ''
      this.generalTemplate = ''
    }
  }
}

export class TemplateHeader {
  id: string
  name: string
  modifiedByName: string
  modifiedById: string
  modified: Date
  constructor (templateHeader?: TemplateHeader) {
    if (templateHeader !== undefined) {
      this.id = templateHeader.id
      this.name = templateHeader.name
      this.modifiedByName = templateHeader.modifiedByName
      this.modifiedById = templateHeader.modifiedById
      this.modified = templateHeader.modified
    }
  }
}

export enum ShopUpdateType {
     MenuId = 0,
     ProductFilterId = 1,
     PrinterTemplateId = 2,
     StoreTemplateId = 3,
     CustomerTemplateId = 4,
     Promotions = 5,
}
