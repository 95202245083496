import appInfoApi from '../api/AppInfoApi'
import { showErrorToast } from '../utils/Toast'

export const ANZ_BLADE_INFO_LOADING = 'ANZ_BLADE_INFO_LOADING'
export const ANZ_BLADE_INFO_LOADED = 'ANZ_BLADE_INFO_LOADED'
export const SET_ANZ_BLADE_CURRENT_VERSION = 'SET_ANZ_BLADE_CURRENT_VERSION'

export function appInfoLoading () {
  return {type: ANZ_BLADE_INFO_LOADING}
}

export function appInfoLoaded (currentVersion: string, activityLog: string[]) {
  return {type: ANZ_BLADE_INFO_LOADED, currentVersion, activityLog}
}

export function loadAppInfo () {
  return (dispatch: any) => {
    dispatch(appInfoLoading())
    return appInfoApi.loadAppInfo().then((response) => {
      if (response) {
        dispatch(appInfoLoaded(response.currentVersion, response.activityLog))
      }
    }).catch(err => {
      showErrorToast(err)
    })
  }
}

export function addNewCurrentVersion (version: string) {
  return (dispatch: any) => {
    dispatch(appInfoLoading())
    return appInfoApi.addNewCurrentVersion(version).then((response) => {
      if (response) {
        dispatch(appInfoLoaded(response.currentVersion, response.activityLog))
      }
    }).catch(err => {
      showErrorToast(err)
    })
  }
}