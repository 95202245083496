
import {
  LANDLORDS_LOADED, LANDLORDS_UPDATED, SAVE_LANDLORD_ERROR,
  SAVE_LANDLORD_SUCCESS, DELETE_LANDLORD_SUCCESS,
  DELETE_LANDLORD_ERROR, LANDLORD_API_STARTED,
  LANDLORD_LOADED, LANDLORDS_LOADING, CLEAR_LANDLORDS
} from '../actions/landlordActions'
import initialState from './initialState'
import Landlord from '../types/Landlord'

export default function landlordReducer (state = initialState.landlord, action: any) {
  switch (action.type) {
    case LANDLORDS_LOADED:
    {
      const newLandlords: any = []
      let i = 0
      action.landlords.forEach((y: any) => {
        const newLandlord = new Landlord(y)
        newLandlord.sequence = i
        i += 1
        newLandlords.push(newLandlord)
      })

      return {
        ...state,
        landlords: newLandlords,
        landlordSaved: false,
        landlordDeleted: false,
        landlordsLoading: false,
        landlordsLoaded: true
      }
    }
    case LANDLORD_LOADED:
      return { ...state, error: '', landlordSaved: false, landlordDeleted: false }
    case LANDLORDS_LOADING:
      return { ...state, landlordsLoading: true, landlords: undefined }
    case CLEAR_LANDLORDS:
    {
      const newLandlords: any = []
      return {
        ...state,
        landlords: newLandlords,
        landlordsLoading: false,
        landlordsLoaded: false
      }
    }
    case LANDLORDS_UPDATED:
      return { ...state, landlords: action.landlords }
    case SAVE_LANDLORD_ERROR:
      return { ...state, error: action.error }
    case SAVE_LANDLORD_SUCCESS:
    {
      const newLandlords = []
      let added = false

      state.landlords.forEach((y: any) => {
        if (y.id === action.landlordDetails.id) {
          newLandlords.push(new Landlord(action.landlordDetails))
          added = true
        } else {
          newLandlords.push(new Landlord(y))
        }
      })

      if (!added) {
        const newLandlord = new Landlord(action.landlordDetails)
        newLandlord.sequence = newLandlords.length + 1
        newLandlords.push(newLandlord)
      }

      return { ...state, landlords: newLandlords, error: '', landlordSaved: true, id: action.landlordDetails.id }
    }
    case DELETE_LANDLORD_SUCCESS:
    {
      const newLandlords: any = []
      state.landlords.filter((x: any) => x.id !== action.id).forEach((y) => {
        newLandlords.push(new Landlord(y))
      })
      return { ...state, error: '', landlords: newLandlords, landlordDeleted: true }
    }
    case DELETE_LANDLORD_ERROR:
      return { ...state, error: action.error, landlordSaved: false, landlordDeleted: false }
    case LANDLORD_API_STARTED:
      return { ...state, error: '', landlordSaved: false, landlordDeleted: false }
    default:
      return state
  }
}
