import Tenant from './Tenant'
export default class Company {

  id: string
  name: string
  landlordId: string
  landlord: string
  createdById: string
  createdBy: string
  created: string
  createdDate: Date | null
  modifiedById: string
  modifiedBy: string
  modified: string
  modifiedDate: Date | null
  sourceId: string
  sequence: number | null
  deletedTenants: any[]
  environmentsCount: number

  constructor (company: any) {
    if (company) {
      this.id = company.id
      this.name = company.name
      this.landlordId = company.landlordId
      this.landlord = company.landlord
      this.createdById = company.createdById
      this.createdBy = company.createdBy
      this.createdDate = new Date(company.createdDate)
      this.created = this.createdBy + ' on ' + this.createdDate.toLocaleDateString()
      this.modifiedById = company.modifiedById
      this.modifiedDate = company.modifiedDate ? new Date(company.modifiedDate) : null
      this.modifiedBy = company.modifiedBy
      this.modified = company.modifiedBy ? this.modifiedBy + ' on ' + this.modifiedDate?.toLocaleDateString() : ''

      this.sourceId = company.sourceId
      this.sequence = company.sequence
      this.deletedTenants = company.deletedTenants
      this.environmentsCount = company.environmentsCount
    } else {
      this.id = ''
      this.name = ''
      this.landlordId = ''
      this.landlord = ''
      this.createdById = ''
      this.createdBy = ''
      this.createdDate = null
      this.modifiedById = ''
      this.modifiedBy = ''
      this.createdDate = null
      this.sourceId = ''
      this.sequence = null
      this.deletedTenants = []
      this.environmentsCount = 0
    }
  }
}
