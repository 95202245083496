import React from 'react'
import { StoreTemplate } from '../../types/StoreTemplate'
import EmailProvider from './EmailProvider'
import ShippingProvider from './ShippingProvider'
import { featureIsEnabledForSelectedTenant, FeatureId } from '../../utils/FeatureFlags'
import RewardProvider from './RewardProvider'
import ZipPaymentProvider from './ZipPaymentProvider'

interface Props {
    onChange: (storeTemplate: StoreTemplate) => void
    storeTemplate: StoreTemplate
}

interface State {
    storeTemplate: StoreTemplate
}

export default class ThirdPartyIntegrations extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      storeTemplate: this.props.storeTemplate === null ? new StoreTemplate(this.props.storeTemplate) : this.props.storeTemplate
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ ...this.state, storeTemplate: nextProps.storeTemplate })
  }

  saveTemplate = (template) => {
    this.setState({ ...this.state, storeTemplate: template })
    this.props.onChange(template)
  }

  changeMailSettings = (settings) => {
    const template = { ...this.state.storeTemplate, emailSettings: settings }
    this.saveTemplate(template)
  }

  changeRewardSettings = (settings) => {
    const template = { ...this.state.storeTemplate, rewardSettings: settings }
    this.saveTemplate(template)
  }
  changeShippingSettings = (settings) => {
    const template = { ...this.state.storeTemplate, shippingSettings: settings }
    this.saveTemplate(template)
  }
  changeZipSettings = (settings) => {
    const template = { ...this.state.storeTemplate, zipPaymentSettings: settings }
    this.saveTemplate(template)
  }

  render () {
    let emailProvider = null
    emailProvider =
           <div className='panel panel-default'>
             <EmailProvider
               settings={this.state.storeTemplate.emailSettings}
               onChange={this.changeMailSettings}
             />
           </div>

    let shippingProvider = null
    if (featureIsEnabledForSelectedTenant(FeatureId.OrderPacking)) {
      shippingProvider =
                <ShippingProvider
                  settings={this.state.storeTemplate.shippingSettings}
                  onChange={this.changeShippingSettings}
                />
    }
    let rewardProvider = null
    if (featureIsEnabledForSelectedTenant(FeatureId.Rewards)) {
      rewardProvider =
                <RewardProvider
                  settings={this.state.storeTemplate.rewardSettings}
                  onChange={this.changeRewardSettings}
                />
    }
    let zipSettings = null
    if (featureIsEnabledForSelectedTenant(FeatureId.ZipPayment)) {
      zipSettings =
                <ZipPaymentProvider
                  settings={this.state.storeTemplate.zipPaymentSettings}
                  onChange={this.changeZipSettings}
                />
    }
    return (
      <div>
        <h4>
                    3rd Party Integrations
        </h4>
        {emailProvider}
        {shippingProvider}
        {rewardProvider}
        {zipSettings}

      </div>)
  }
}