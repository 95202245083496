import { showErrorToast } from '../utils/Toast'

export default class ApiMethods {
  public static processFetch (request: any) {
    let ok = true
    let statusText = ''

    return fetch(request)
      .then((response) => {
        ok = response.ok
        if (response.status === 401) {
          // console.log('auth failure')
          // browserHistory.push('/Login')
          window.location.href = '/Login'
          throw Error('Authentication failure')
          // showErrorToast('Authentication Failure')
        }
        if (response.status === 403) {
          // console.log('forbidden access')
          throw Error('Forbidden access')
          // showErrorToast('Forbidden Access')
        }

        statusText = response.statusText
        return response
      })
      .then((response) => {
        if (ok && request.method === 'DELETE') {
          return {
            ok: 'ok'
          }
        }
        return response
      })
      .then((responseObject: any) => {
        let ret = undefined
        try {
          ret = responseObject.json()
        } catch (e) {
          console.log(e)
        }
        return ret
      })
      .then(r => {
        if (!ok) {
          // console.log('processFetch error', r)
          throw r.description
          // if (typeof r.description === 'string') {
          //   showErrorToast(r.description)
          // }
        }
        return r
      })
      .catch((error) => {
        // console.log('processFetch error', error)
        throw error
        // if (typeof error === 'string') {
        //   // showErrorToast(error)
        //   throw error
        // } else if (error.message && typeof error.message === 'string') {
        //   // showErrorToast(error.message)
        //   throw error.message
        // } else if (error.description && typeof error.description === 'string') {
        //   // showErrorToast(error.description)
        //   throw error.description
        // } else if (error.response?.data && typeof error.response.data === 'string') {
        //   // showErrorToast(error.response.data)
        //   throw error.response.data
        // } else {
        //   // showErrorToast('An unknown error occurred')
        //   throw 'An unknown error occurred'
        // }
      })
  }
}
