import moment from 'moment'
import {
  TENANTS_LOADED, UPDATE_TENANT, CLEAR_TENANTS, DELETE_TENANT, ADD_TENANT,
  SAVE_TENANTS_STARTED, SAVE_TENANTS_SUCCESS, SAVE_TENANTS_ERROR, TENANTS_LOADING,
  REGENERATE_TENANT_APIKEY, REMOVE_TENANT, TENANTS_LOADING_FAILED, CHANGE_IS_TEST_ENVIRONMENT,
  DELETE_TENANT_STARTED, DELETE_TENANT_FINISHED, TOGGLE_FEATURE
} from '../actions/tenantActions'
import { SAVE_COMPANY_ERROR } from '../actions/companyActions'
import initialState from './initialState'
import Tenant from '.././types/Tenant'

export default function tenantReducer (state = initialState.tenant, action: any) {
  switch (action.type) {
    case TENANTS_LOADING_FAILED: {
      return {
        ...state,
        loading: false
      }
    }
    case TENANTS_LOADING:
      return {
        ...state,
        tenants: [],
        loading: true
      }
    case TENANTS_LOADED:
    {
      const newTenants: any = []
      if (action.tenants) {
        let i = 0
        let apiKey = ''
        action.tenants.forEach((y: any) => {
          const newTenant = new Tenant(y)
          newTenant.sequence = i
          apiKey = newTenant.apiKey
          if (newTenant.apiKey === '') {
            newTenant.displayApiKey = ''
          } else {
            const result1 = Array(apiKey.length - 4).join('*')
            const result2 = apiKey.substring(apiKey.length - 4, apiKey.length)
            newTenant.displayApiKey = result1.concat(result2)
          }
          i += 1
          newTenants.push(newTenant)
        })
      }

      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: '',
        loading: false
      }
    }
    case ADD_TENANT:
    {
      const newTenants: any = state.tenants.slice()
      const newTenant = new Tenant()
      newTenant.createdDate = moment().format('YYYY MM DD')
      newTenants.push(newTenant)
      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: ''

      }
    }
    case UPDATE_TENANT:
    {
      const newTenants: any = state.tenants.slice()
      newTenants[action.index].name = action.name
      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: ''

      }
    }
    case CHANGE_IS_TEST_ENVIRONMENT:
    {
      const newTenants: any = state.tenants.slice()
      newTenants[action.index].isTestEnvironment = !newTenants[action.index].isTestEnvironment
      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: ''
      }
    }
    case TOGGLE_FEATURE:
    {
      const updatedTenant = new Tenant(state.tenants[action.index])
      const feature = updatedTenant.features.find(x => x.id === action.featureId)
      feature.isEnabled = !feature.isEnabled
      feature.changed = true

      const newTenants: Tenant[] = state.tenants.slice()
      newTenants.splice(action.index, 1, updatedTenant)
      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: ''
      }
    }
    case REGENERATE_TENANT_APIKEY:
    {
      const newTenants: any = state.tenants.slice()
      newTenants[action.index].apiKey = action.apiKey
      const result1 = Array(action.apiKey.length - 4).join('*')
      const result2 = action.apiKey.substring(action.apiKey.length - 4, action.apiKey.length)
      newTenants[action.index].displayApiKey = result1.concat(result2)
      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: ''

      }
    }
    case REMOVE_TENANT:
    {
      const newTenants = state.tenants.slice()
      newTenants.splice(action.index, 1)
      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: ''
      }
    }
    case DELETE_TENANT:
    {
      const newTenants = state.tenants.slice()
      newTenants.splice(action.index, 1)
      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: ''
      }
    }
    case CLEAR_TENANTS:
    {
      const newTenants = []
      if (action.addEmpty) {
        const newTenant = new Tenant()
        newTenant.createdDate = moment().format('YYYY MM DD')
        newTenants.push(newTenant)
      }

      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: ''
      }
    }

    case SAVE_TENANTS_STARTED:
    {
      return {
        ...state,
        saved: false,
        error: '',
        loading: true
      }
    }

    case SAVE_TENANTS_SUCCESS:
    {
      return {
        ...state,
        saved: true,
        error: '',
        loading: false
      }
    }
    case SAVE_COMPANY_ERROR:
    {
      return {
        ...state,
        loading: false
      }
    }
    case SAVE_TENANTS_ERROR:
    {
      return {
        ...state,
        saved: false,
        error: action.error,
        loading: false
      }
    }
    case DELETE_TENANT_FINISHED:
    {
      const newTenants: any = state.tenants.slice()
      newTenants[action.index].tenantDeleting = false
      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: ''
      }
    }
    case DELETE_TENANT_STARTED:
    {
      const newTenants: any = state.tenants.slice()
      newTenants[action.index].tenantDeleting = true
      return {
        ...state,
        tenants: newTenants,
        saved: false,
        error: ''
      }
    }
    default:
      return state
  }
}
