import AnyOfFilter from './AnyOfFilter'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { togglePackType } from '../../actions/storeActions'

function mapStateToProps (state) {
  return {
    heading: 'Pack Type',
    labelKey: 'packTypeName',
    valueKey: 'packTypeId',
    options: state.store.packTypes,
    title: 'Pack Type'
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    toggleOptions: togglePackType
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AnyOfFilter)