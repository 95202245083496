import React from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  loadLandlords
} from '../actions/landlordActions'
import { Outlet } from 'react-router-dom'

class LandlordPage extends React.Component <any, any> {
  componentDidMount () {
    this.props.loadLandlords()
  }

  render () {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

function mapStateToProps (state: any) {
  return {
    claims: state.session
  }
}

function mapDispatchToProps (dispatch: any) {
  return bindActionCreators({
    loadLandlords
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandlordPage)
