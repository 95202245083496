import moment from 'moment'
import {
  ACTIVITY_LOGS_LOADED, ACTIVITY_LOGS_LOADING,
  ACTIVITY_NOTE_ADD, ACTIVITY_NOTE_UPDATE,
  ACTIVITY_NOTE_SAVE_SUCCESS, ACTIVITY_NOTE_SAVING,
  ACTIVITY_NOTE_CANCEL, CLEAR_ACTIVITY_LOGS,
  ACTIVITY_NOTE_DELETE_SUCCESS, ACTIVITY_NOTE_DELETING
} from '../actions/activityLogActions'
import initialState from './initialState'
import ActivityLog from '../types/ActivityLog'
import ActivityNote from '../types/ActivityNote'

export default function activityLogReducer (state = initialState.activityLog, action: any) {
  switch (action.type) {
    case ACTIVITY_LOGS_LOADED:
    {
      const newActivityLogs: any = []
      if (Array.isArray(action.activityLogs)) {
        Array.prototype.forEach.call(action.activityLogs, (y) => {
          const newActivityLog = new ActivityLog(y)
          newActivityLogs.push(newActivityLog)
        })
      }
      return {
        ...state,
        activityLogs: newActivityLogs,
        activityNoteSaved: false,
        activityLogsLoading: false,
        activityLogssLoaded: true
      }
    }
    case ACTIVITY_LOGS_LOADING:
      return { ...state, activityLogsLoading: true, activityLogs: undefined }
    case ACTIVITY_NOTE_ADD:
    {
      const item = state.activityLogs.find((p: any) => p.id === action.id)
      const activityNotes = item.activityNotes.slice()
      const activityNote = new ActivityNote()
      activityNote.id = '0'
      activityNote.activityLogId = action.id
      activityNote.modifiedDate = moment().format('YYYY MM DD')
      activityNotes.push(activityNote)

      const previousIndex = state.activityLogs.indexOf(item)
      const updated = [
        ...state.activityLogs.slice(0, previousIndex),
        { ...item, activityNotes: activityNotes, addDisable: true },
        ...state.activityLogs.slice(previousIndex + 1)
      ]
      return {
        ...state,
        activityLogs: updated
      }
    }
    case ACTIVITY_NOTE_UPDATE:
    {
      const newActivityLogs: any = []
      const item = state.activityLogs.find((p: any) => p.id === action.activityNote.activityLogId)
      if (Array.isArray(item.activityNotes)) {
        Array.prototype.forEach.call(item.activityNotes, (y) => {
          let newActivityNote = null
          if (action.activityNote.id === y.id) {
            newActivityNote = new ActivityNote(action.activityNote)
          } else {
            newActivityNote = new ActivityNote(y)
          }
          newActivityLogs.push(newActivityNote)
        })
      }

      const previousIndex = state.activityLogs.indexOf(item)
      const updated = [
        ...state.activityLogs.slice(0, previousIndex),
        { ...item, activityNotes: newActivityLogs },
        ...state.activityLogs.slice(previousIndex + 1)
      ]
      return {
        ...state,
        activityLogs: updated
      }
    }
    case ACTIVITY_NOTE_SAVING:
    {
      const newActivityLogs: any = []
      const item = state.activityLogs.find((p: any) => p.id === action.activityNote.activityLogId)
      if (Array.isArray(item.activityNotes)) {
        Array.prototype.forEach.call(item.activityNotes, (y) => {
          let newActivityNote: any = null
          if (y.id === '0') {
            newActivityNote = new ActivityNote(action.activityNote)
            newActivityNote.activityNoteSaving = true
          } else {
            newActivityNote = new ActivityNote(y)
          }
          newActivityLogs.push(newActivityNote)
        })
      }

      const previousIndex = state.activityLogs.indexOf(item)
      const updated = [
        ...state.activityLogs.slice(0, previousIndex),
        { ...item, activityNotes: newActivityLogs },
        ...state.activityLogs.slice(previousIndex + 1)
      ]
      return {
        ...state,
        activityLogs: updated
      }
    }
    case ACTIVITY_NOTE_SAVE_SUCCESS:
    {
      const newActivityLogs: any = []
      const item = state.activityLogs.find((p: any) => p.id === action.activityNote.activityLogId)
      if (Array.isArray(item.activityNotes)) {
        Array.prototype.forEach.call(item.activityNotes, (y) => {
          let newActivityNote: any = null
          if (y.id === '0') {
            newActivityNote = new ActivityNote(action.activityNote)
            newActivityNote.activityNoteSaving = false
          } else {
            newActivityNote = new ActivityNote(y)
          }
          newActivityLogs.push(newActivityNote)
        })
      }

      const previousIndex = state.activityLogs.indexOf(item)
      const updated = [
        ...state.activityLogs.slice(0, previousIndex),
        { ...item, activityNotes: newActivityLogs, addDisable: false },
        ...state.activityLogs.slice(previousIndex + 1)
      ]
      return {
        ...state,
        activityLogs: updated
      }
    }

    case ACTIVITY_NOTE_CANCEL:
    {
      const newActivityLogs: any = []
      const item = state.activityLogs.find((p: any) => p.id === action.activityNote.activityLogId)

      item.activityNotes.filter((x: any) => x.id !== '0').forEach((y: any) => {
        newActivityLogs.push(new ActivityNote(y))
      })

      const previousIndex = state.activityLogs.indexOf(item)
      const updated = [
        ...state.activityLogs.slice(0, previousIndex),
        { ...item, activityNotes: newActivityLogs, addDisable: false },
        ...state.activityLogs.slice(previousIndex + 1)
      ]
      return {
        ...state,
        activityLogs: updated
      }
    }
    case ACTIVITY_NOTE_DELETE_SUCCESS:
    {
      const newActivityLogs: any = []
      const item = state.activityLogs.find((p: any) => p.id === action.activityNote.activityLogId)

      item.activityNotes.filter((x: any) => x.id !== action.activityNote.id).forEach((y: any) => {
        newActivityLogs.push(new ActivityNote(y))
      })

      const previousIndex = state.activityLogs.indexOf(item)
      const updated = [
        ...state.activityLogs.slice(0, previousIndex),
        { ...item, activityNotes: newActivityLogs, addDisable: false },
        ...state.activityLogs.slice(previousIndex + 1)
      ]
      return {
        ...state,
        activityLogs: updated
      }
    }
    case ACTIVITY_NOTE_DELETING:
    {
      const newActivityLogs: any = []
      const item = state.activityLogs.find((p: any) => p.id === action.activityNote.activityLogId)
      if (Array.isArray(item.activityNotes)) {
        Array.prototype.forEach.call(item.activityNotes, (y) => {
          let newActivityNote: any = null
          if (y.id === action.activityNote.id) {
            newActivityNote = new ActivityNote(action.activityNote)
            newActivityNote.activityNoteDeleting = true
          } else {
            newActivityNote = new ActivityNote(y)
          }
          newActivityLogs.push(newActivityNote)
        })
      }

      const previousIndex = state.activityLogs.indexOf(item)
      const updated = [
        ...state.activityLogs.slice(0, previousIndex),
        { ...item, activityNotes: newActivityLogs },
        ...state.activityLogs.slice(previousIndex + 1)
      ]
      return {
        ...state,
        activityLogs: updated
      }
    }
    case CLEAR_ACTIVITY_LOGS:
    {
      const newActivityLogs: any = []
      return {
        ...state,
        activityLogs: newActivityLogs,
        activityLogsLoading: false,
        activityLogsLoaded: false }
    }
    default:
      return state
  }
}
