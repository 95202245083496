import React, { useState, useEffect, useCallback, useReducer, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withRouter from '../Utils/WithRouter'
import { Tab, Tabs } from 'react-bootstrap'
import { ClipLoader } from 'react-spinners'
import { StoreTemplate, DiscountType, PaymentType, Ap21RewardsProgram, RetailTagFilter } from '../../types/StoreTemplate'
import { Carrier } from '../../types/Carrier'
import { PackType } from '../../types/PackType'
import * as Toast from '../../utils/Toast'
import { deleteAsync, putAsync, postAsync, getAsync } from '../../api/httpClient'
import TemplateDetailHeader from '../TemplateDetailHeader'
import LogoChooser from './LogoChooser'
import WatermarkChooser from './WatermarkChooser'
import Access from './Access'
import ThirdPartyIntegrations from './ThirdPartyIntegrations'
import StockTransfer from './StockTransfer'
import OrderPacking from './OrderPacking'
import StockTransferOut from './StockTransferOut'
import { FloorToDoor } from './FloorToDoor'
import Rule from './StoreRule'
import { load, storeTenantSelect, clear, toggleDiscountType } from '../../actions/storeActions'
import SubFilterItem from './StoreSubFilter'
import { Filter } from '../../types/Menu'
import DiscountTypeFilter from './DiscountTypeFilter'
import CarrierFilter from './CarrierFilter'
import PackTypeFilter from './PackTypeFilter'
import PaymentTypeFilter from './PaymentTypeFilter'
import { featureIsEnabledForSelectedTenant, FeatureId } from '../../utils/FeatureFlags'
import { POSSettings } from '../../types/POSSettings'
import RetailTagQuestionFilter from './RetailTagQuestionFilter'
import Feature from '../../types/Feature'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

interface DispatchProps {
  tenantId: string
  companyId: string
  userId: string
  userName: string
  filter: Filter
  discountTypes: DiscountType[]
  paymentTypes: PaymentType[]
  load: any
  carriers: Carrier[]
  ap21RewardsPrograms: Ap21RewardsProgram[]
  packTypes: PackType[]
  posSettings: POSSettings
  retailTagFilter: RetailTagFilter[]
  storeTenantSelect: any
  clear: any
  toggleDiscountType: any
  featureFlags: Feature[]
  warehouseFilter: Filter
}

interface OwnProps {
  params?: {
    templateId: string
  }
}

type Props = DispatchProps & OwnProps

const StoreTemplateDetails: React.FC<Props & any> = (props) => {
  const navigate = useNavigate()
  const { templateId } = useParams()
  const setContext: any = useOutletContext()
  const [template, setTemplate] = useState(new StoreTemplate())
  const [templateLoading, setTemplateLoading] = useState(false)
  const [templateSaving, setTemplateSaving] = useState(false)
  const [templateDeleting, setTemplateDeleting] = useState(false)
  const [activeTab, setActiveTab] = useState('Branding')
  const tenantIdRef = useRef(props.tenantId)

  useEffect(() => {
    console.log('useeffect []')
    if (templateId === 'new') {
      let newTemplate = new StoreTemplate()
      newTemplate.tenantId = props.tenantId
      newTemplate.companyId = props.companyId
      newTemplate.modifiedById = props.userId
      newTemplate.modifiedByName = props.userName
      newTemplate.printLogoId = ''
      newTemplate.watermarkLogoId = ''
      props.clear()
      setTemplate(newTemplate)
      if (newTemplate.tenantId) {
        props.storeTenantSelect(newTemplate.tenantId)
      }
    } else {
      loadTemplate()
    }
  }, [])

  useEffect(() => {
    if (setContext && template?.name) {
      console.log('useeffect setcontext')
      setContext({ name: template.name })
    }
  }, [template?.name])

  useEffect(() => {

    if (props.tenantId !== undefined && props.tenantId !== '' && props.tenantId !== null && props.tenantId !== tenantIdRef.current) {
      tenantIdRef.current = props.tenantId
      navigate('/store')
    }
    let filter = { ...props.filter }
    filter.rules = filter.rules.filter((p) => !(!p.tagGroupId || p.operatorType === undefined))

    let warehouseFilter = { ...props.warehouseFilter }
    warehouseFilter.rules = warehouseFilter.rules.filter((p) => !(!p.tagGroupId || p.operatorType === undefined))

    console.log('useeffect setTemplate')
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      stockEnquiryFilter: filter,
      transferOutSettings: {
        ...prevTemplate.transferOutSettings,
        warehouseFilter: warehouseFilter
      }
    }))
  }, [props.tenantId, props.filter, props.warehouseFilter])

  const loadTemplate = useCallback(() => {
    console.log('loadTemplate callback')
    setTemplateLoading(true)
    let requestParams = {
      url: `/api/tenant/${props.tenantId}/GeneralTemplates/${templateId}`,
      succeededCallback: (template) => {
        const storeTemplate = new StoreTemplate(template)
        setTemplate(storeTemplate)
        setTemplateLoading(false)
        props.load(
          storeTemplate.stockEnquiryFilter,
          template.discountTypeFilters,
          template.packTypeFilters,
          template.carrierFilters,
          template.ap21RewardsProgramFilters,
          template.paymentTypeFilters,
          template.retailTagFilter,
          storeTemplate.transferOutSettings.warehouseFilter,
          template.floorToDoorSettings
        )
      },
      failedCallback: (error) => {
        Toast.showErrorToast(error)
        navigate('/store')
      }
    }
    getAsync(requestParams)
  }, [props.tenantId, templateId])

  const validateTemplate = () => {
    const { filter, paymentTypes } = props
    if (filter.rules.some((p) => p.tagGroupId === '' && !filter.ruleChilds.some((q) => q.ruleId === p.id))) {
      return 'One or more rules are incomplete'
    }
    if (paymentTypes.length > 0 && paymentTypes.filter((p) => p.selected).length === 0) {
      return 'Please select at least one payment type'
    }
    if (template.emailSettings.provider !== 0 && template.emailSettings.apiKey === '') {
      return 'API Key is required for email provider'
    }
    if (template.emailSettings.provider === 2 && template.emailSettings.domain === '') {
      return 'Domain is required for mailgun email provider'
    }
    return ''
  }

  const saveTemplate = (newTemplate) => {
    setTemplateSaving(true)
    const validationError = validateTemplate()
    if (validationError !== '') {
      setTemplateSaving(false)
      Toast.showErrorToast(validationError)
      return
    }
    let requestParams = {
      url: '',
      succeededCallback: (successTemplate) => {
        const storeTemplate = new StoreTemplate(successTemplate)
        Toast.showSuccessToast('Template saved')
        setTemplate(storeTemplate)
        setTemplateSaving(false)
        props.load(
          storeTemplate.stockEnquiryFilter,
          successTemplate.discountTypeFilters,
          successTemplate.packTypeFilters,
          successTemplate.carrierFilters,
          successTemplate.ap21RewardsProgramFilters,
          successTemplate.paymentTypeFilters,
          successTemplate.retailTagFilter,
          storeTemplate.transferOutSettings.warehouseFilter
        )
      },
      failedCallback: (error) => {
        Toast.showErrorToast(error)
        setTemplateSaving(false)
      }
    }

    let temp = { ...newTemplate } as any
    temp.discountTypeFilters = props.discountTypes.filter((p) => p.selected) ?? []
    temp.packTypeFilters = props.packTypes.filter((p) => p.selected) ?? []
    temp.carrierFilters = props.carriers.filter((p) => p.selected) ?? []
    temp.ap21RewardsProgramFilters = props.ap21RewardsPrograms.filter((p) => p.selected) ?? []
    temp.paymentTypeFilters = props.paymentTypes.filter((p) => p.selected) ?? []
    temp.retailTagFilter = props.retailTagFilter

    if (newTemplate.id) {
      requestParams.url = `/api/tenant/${props.tenantId}/GeneralTemplates/${newTemplate.id}`
      putAsync(temp, requestParams)
    } else {
      requestParams.url = `/api/tenant/${props.tenantId}/GeneralTemplates`
      postAsync(temp, requestParams)
    }
  }

  const deleteTemplate = () => {
    setTemplateDeleting(true)
    let requestParams = {
      url: `/api/tenant/${props.tenantId}/GeneralTemplates/${template.id}`,
      succeededCallback: () => {
        Toast.showSuccessToast('Template deleted')
        navigate('/store')
      },
      failedCallback: (error) => {
        setTemplateDeleting(false)
        Toast.showErrorToast(error)
      }
    }
    deleteAsync(requestParams)
  }

  const onSave = (name: string) => {
    const newTemplate = { ...template, name }
    setTemplate(newTemplate)
    saveTemplate(newTemplate)
  }

  const onDelete = () => {
    deleteTemplate()
  }

  const onSelectLogo = (id: string) => {
    console.log('onSelectLog printLogoId', id)
    setTemplate((prevTemplate) => ({ ...prevTemplate, printLogoId: id }))
  }

  const onSelectWatermark = (id: string) => {
    setTemplate((prevTemplate) => ({ ...prevTemplate, watermarkLogoId: id }))
  }

  const onPOSChange = (posSettings) => {
    setTemplate((prevTemplate) => ({ ...prevTemplate, posSettings }))
  }

  const renderBranding = () => (
    <LogoChooser tenantId={props.tenantId} onSelectLogo={onSelectLogo} selectedLogoId={template.printLogoId} />
  )

  const renderWatermark = () => (
    <WatermarkChooser tenantId={props.tenantId} onSelectLogo={onSelectWatermark} selectedLogoId={template.watermarkLogoId} />
  )

  const renderIntegrations = () => {
    return (
      <ThirdPartyIntegrations 
        storeTemplate={template} 
        onChange={(newTemplate) => {
          setTemplate(newTemplate)
        }}
      />
    )
  }

  const renderStockTransfers = () => (
    <StockTransfer storeTemplate={template} onChange={(newTemplate) => setTemplate(newTemplate)} />
  )

  const renderStockTransferOut = () => (
    <StockTransferOut
      storeTemplate={template}
      carriers={props.carriers}
      packTypes={props.packTypes}
      onChange={(newTemplate) => setTemplate(newTemplate)}
    />
  )

  const renderOrderPacking = () => (
    <OrderPacking storeTemplate={template} onChange={(newTemplate) => setTemplate(newTemplate)} />
  )

  const renderFloorToDoor = () => {
    return <FloorToDoor storeTemplate={template} onChange={(newTemplate) => setTemplate(newTemplate)} />
  }

  const renderFilters = () => {
    const { tenantId, filter } = props
    const subFilter = tenantId && filter.subFilter ? (
      <div key={filter.subFilter.id}>
        <SubFilterItem
          item={filter.subFilter}
          group=''
          key={'storeFilter'}
          seq={0}
          max={1}
          SingleRule={<Rule subFilterId={filter.subFilter.id} />}
        />
      </div>
    ) : (
      ''
    )

    const discountTypesPanel = <DiscountTypeFilter />
    const paymentTypesPanel = <PaymentTypeFilter />
    const retailTagQuestion = <RetailTagQuestionFilter />

    let carrierPanel = null
    let packTypesPanel = null
    if (featureIsEnabledForSelectedTenant(FeatureId.OrderPacking)) {
      carrierPanel = <CarrierFilter />
      packTypesPanel = <PackTypeFilter />
    }

    return (
      <div>
        <h4>Filters</h4>
        <p>Filtering stores list for stock enquiry and manual discounts by applying rules.</p>
        {subFilter}
        {discountTypesPanel}
        {paymentTypesPanel}
        {carrierPanel}
        {packTypesPanel}
        {retailTagQuestion}
      </div>
    )
  }

  const renderSecurity = () => (
    <div className='row'>
      <div className='col-md-6'>
        <Access
          title={'Login and security settings'}
          storeTemplate={template}
          onChange={(newTemplate) => setTemplate(newTemplate)}
        />
      </div>
    </div>
  )

  const spinner = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
      <ClipLoader size={40} />
    </div>
  )

  const style = {
    minWidth: '100%',
    maxWidth: '100%',
    height: 600
  }

  const transfersEnabled = props.featureFlags?.find((x: Feature) => x.name === 'Stock Receipts').isEnabled
  const transferOutEnabled = props.featureFlags?.find((x: Feature) => x.name === 'Stock Transfer Out').isEnabled
  const orderPackingEnabled = props.featureFlags?.find((x: Feature) => x.name === 'Order Packing').isEnabled
  const floorToDoorEnabled = props.featureFlags?.find((x: Feature) => x.name === 'Floor to Door').isEnabled

  return (
    <div className='container-fluid'>
      {templateLoading ? (
        spinner
      ) : (
        <div>
          <TemplateDetailHeader
            title={'Store'}
            onSave={onSave}
            onDelete={onDelete}
            name={template.name}
            hideDeleteButton={template.id === undefined}
            templateSaving={templateSaving}
            templateDeleting={templateDeleting}
            listUrl='store'
          />
          <Tabs
            id='tabs'
            activeKey={activeTab}
            onSelect={(key) => setActiveTab(key)}
            style={{ paddingTop: '15px', backgroundColor: '#FFF', zIndex: 5 }}
          >
            <Tab eventKey='Branding' title='Branding' />
            <Tab eventKey='Watermark' title='Watermark' />
            <Tab eventKey='Filters' title='Filters' />
            <Tab eventKey='Security' title='Access' />
            <Tab eventKey='Integrations' title='3rd Party Integrations' />
            {orderPackingEnabled && <Tab eventKey='OrderPacking' title='Order Packing' />}
            {transfersEnabled && <Tab eventKey='Stock' title='Stock Receipting' />}
            {transferOutEnabled && <Tab eventKey='StockTransferOut' title='Stock Transfers Out' />}
            {floorToDoorEnabled && <Tab eventKey='FloorToDoor' title='Floor To Door' />}
          </Tabs>
          {activeTab === 'Branding'
            ? renderBranding()
            : activeTab === 'Watermark'
              ? renderWatermark()
              : activeTab === 'Filters'
                ? renderFilters()
                : activeTab === 'Security'
                  ? renderSecurity()
                  : activeTab === 'OrderPacking'
                    ? renderOrderPacking()
                    : activeTab === 'Stock'
                      ? renderStockTransfers()
                      : activeTab === 'StockTransferOut'
                        ? renderStockTransferOut()
                        : activeTab === 'FloorToDoor' 
                          ? renderFloorToDoor()
                          : renderIntegrations()}
        </div>
      )}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    tenantId: state.session.tenantId,
    companyId: state.session.companyId,
    userId: state.session.userId,
    userName: state.session.name,
    filter: state.store.stockEnquiryFilter,
    discountTypes: state.store.discountTypes,
    packTypes: state.store.packTypes,
    carriers: state.store.carriers,
    ap21RewardsPrograms: state.store.ap21RewardsPrograms,
    paymentTypes: state.store.paymentTypes,
    posSettings: state.store.posSettings,
    retailTagFilter: state.store.retailTagTypes,
    featureFlags: state.session?.selectedTenantId?.features,
    warehouseFilter: state.store.transferOutWarehouseFilter
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      load,
      storeTenantSelect,
      clear,
      toggleDiscountType
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreTemplateDetails)
