import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React from 'react'
import auth from '../auth/authenticator'
import { sessionTimeout } from '../actions/sessionActions'
import withRouter from './Utils/WithRouter'

declare global {
  interface Window {
    sess_inactivity: number
  }
}
class Inactivity extends React.Component<any, any> {

  private activityTimer: number | null = null

  constructor (props) {
    super(props)
    this.state = { timeout: window.sess_inactivity }
  }

  componentDidMount () {
    this.startActivityTimer()
    document.addEventListener('mousemove', this.resetActivityTimer)
    document.addEventListener('keypress', this.resetActivityTimer)
    document.addEventListener('click', this.resetActivityTimer)
  }

  componentWillUnmount () {
    this.clearActivityTimer()
    document.removeEventListener('mousemove', this.resetActivityTimer)
    document.removeEventListener('keypress', this.resetActivityTimer)
    document.removeEventListener('click', this.resetActivityTimer)
  }

  startActivityTimer = () => {
    if (this.state.timeout) {
      const timeoutMilliseconds = this.state.timeout * 60 * 1000
      this.activityTimer = window.setTimeout(this.logout, timeoutMilliseconds)
    }
  }

  resetActivityTimer = () => {
    this.clearActivityTimer()
    this.startActivityTimer()
  }

  clearActivityTimer = () => {
    if (this.activityTimer) {
      window.clearTimeout(this.activityTimer)
      this.activityTimer = null
    }
  }

  logout = async () => {
    if (auth.getLoggedInToken()) {
      alert('You have been inactive and will now be logged out.')
      await this.props.sessionTimeout()
      this.props.navigate('/Login')
    }
  }

  render () {
    return null // Render nothing (hidden component)
  }
}

function mapStateToProps (state) {
  return {
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ sessionTimeout: sessionTimeout }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Inactivity))