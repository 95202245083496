
import React from 'react'
import * as $ from 'jquery'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { loginUser, forgotPassword, logoutUser } from '../actions/sessionActions'
import TextInput from './common/TextInput'
import SecuredTextInput from './common/SecuredTextInput'
import Captcha from './common/Captcha'
import withRouter from './Utils/WithRouter'

declare const grecaptcha: any
class LoginPage extends React.Component<any, any> {

  recaptchaRef: any
  constructor (props) {
    super(props)
    this.state = { credentials: { email: this.props.email, password: '', twoFactorPin: '', resend: false },
      captchaVerified: false, shake: false, isLoaded: false}
    this.recaptchaRef = null
  }

  componentDidMount () {
    this.props.logoutUser()
    this.setState({isLoaded: true})
  }

  componentDidUpdate (prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (
      !prevProps.logged_in && this.props.logged_in 
      && this.props.login_verify === '' && prevProps.login_verify !== ''
    ) {
      this.props.navigate('/')
    } else if (this.props.login_verify === '' && !this.props.logged_in && prevProps.response_loading && !this.props.response_loading) {
      this.setState({captchaVerified: false})
      if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
        grecaptcha?.reset()
      }
    }
  }

  setRecaptchaRef = (ref) => {
    this.recaptchaRef = ref
  }

  onChange = (event) => {
    const field = event.target.name
    const credentials = this.state.credentials
    credentials[field] = event.target.value
    return this.setState({ credentials })
  }

  onSubmit = async () => {
    if (this.state.captchaVerified === true || process.env.REACT_APP_ENVIRONMENT === 'local') {
      await this.props.loginUser(this.state.credentials)  
    } else {
      this.setState({ shake: true })
      setTimeout(() => {
        this.setState({ shake: false })
      }, 500)
    }
  }

  onResend = async () => {
    const copyCreds = { ...this.state.credentials }
    copyCreds.twoFactorPin = ''
    copyCreds.resend = true
    await this.props.loginUser(copyCreds)
  }

  onForgot = () => {
    this.props.navigate('/forgotpassword')
  }

  onCaptchaVerified = () => {
    this.setState({captchaVerified: true})
  }

  onCaptchaExpired = () => {
    this.setState({captchaVerified: false})
  }

  render () {
    const inputStyle = {

    }
    const isIE = navigator.appName === 'Microsoft Internet Explorer'
      || !!(navigator.userAgent.match(/Trident/)
        || navigator.userAgent.match(/rv:11/))
      || (typeof $.browser !== 'undefined' && $.browser.msie === 1)
    const inlineSpinner = (<div style={{ marginLeft: '20px', height: '15px', display: 'inline-block' }}>
      <ClipLoader size={15} /></div>)
    return (
      <div className='text-center login'>
        {isIE ?
          <div className='alert alert-danger'> Internet Explorer is not supported</div> :
          <div>
            <img src='/images/EnvimoLogoNormal.png' style={{paddingBottom: 30}}/>
            <br />
            <div className='panel panel-default login-control' style={{width: 350}}>
              {!this.props.login_verify ?
                <form className='text-left'>
                  {
                    this.props.login_error && <div className='alert alert-danger' role='alert'>{this.props.login_error} </div>
                  }
                  <SecuredTextInput
                    maxLength={100}
                    name='email'
                    label='Email'
                    value={this.state.credentials.email}
                    onChange={this.onChange}
                    style={inputStyle}
                  />
                  <SecuredTextInput
                    maxLength={50}
                    name='password'
                    label='Password'
                    type='password'
                    value={this.state.credentials.password}
                    onChange={this.onChange}
                    style={inputStyle}
                  />
                  {/* Make sure to check in admin portal API if you want to test the captcha and remove this local condition. Check: #IF DEBUG */}
                  {process.env.REACT_APP_ENVIRONMENT !== 'local' &&
                    <Captcha
                      isLoaded={this.state.isLoaded}
                      setRef={this.setRecaptchaRef}
                      onCallback={this.onCaptchaVerified}
                      onExpiredCallback={this.onCaptchaExpired}
                      shake={this.state.shake}
                    />
                  }
                  {this.props.response_loading ? <button
                    type='submit'
                    value='Log In'
                    className='btn btn-primary btn-block'
                    disabled={true}
                  >Log In {inlineSpinner} </button> : <input
                    type='submit'
                    value='Log In'
                    className='btn btn-primary btn-block'
                    onClick={this.onSubmit}
                  />}
                </form>
                : <form className='text-left'>
                  { !this.props.login_error ?
                    <div className='alert alert-success' role='alert'>{this.props.login_verify}</div>
                    :
                    this.props.login_error && <div className='alert alert-danger' role='alert'>{this.props.login_error} </div>
                  }
                  <TextInput
                    maxLength={100}
                    name='twoFactorPin'
                    label='Two-Factor PIN'
                    value={this.state.credentials.twoFactorPin}
                    onChange={this.onChange}
                    style={inputStyle}
                  />
                  { this.props.response_loading && this.state.credentials.resend === false ?
                    <button
                      type='submit'
                      value='Verify'
                      className='btn btn-primary btn-verify'
                      disabled={true}
                    >Verify {inlineSpinner} </button>
                    : <input
                      type='submit'
                      value='Verify'
                      className='btn btn-primary btn-verify'
                      onClick={this.onSubmit}
                    /> }
                  { this.props.response_loading && this.state.credentials.resend === true ?
                    <a
                      role='button'
                      className='a-resend a-resend-disabled'
                    >Resend Two-Factor PIN {inlineSpinner}</a>
                    :
                    <a
                      role='button'
                      className='a-resend'
                      onClick={this.onResend}
                    >Resend Two-Factor PIN</a> }
                </form>
              }
            </div>
            <a
              role='button'
              onClick={this.onForgot}
            >Forgot password?</a>
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    login_verify: state.session.login_verify,
    response_loading: state.session.response_loading,
    login_error: state.session.login_error,
    email: state.session.email,
    logged_in: state.session.logged_in
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ loginUser, forgotPassword, logoutUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage))
