import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input } from './../common/Input'
import {
  FormGroup, Accordion,
  Card
} from 'react-bootstrap'

import { Customer } from '../../types/Customer'
import {
  selectValidationProvider, enableValidation, setValidationApiKey
} from '../../actions/customerActions'
import ToggleSwitch from '../common/ToggleSwitch'

interface IStateProps {
    customer: Customer
}

interface IDispatchProps {
    selectValidationProvider, enableValidation, setValidationApiKey
}

interface IOwnProps {
    disabled: boolean
}

export class AddressValidation extends React.Component<IOwnProps & IStateProps & IDispatchProps, {}> {
  shouldComponentUpdate (nextProps: object, nextState) {
    for (const prop in nextProps) {
      if (nextProps[prop] !== this.props[prop]) {
        return true
      }
    }
    return false
  }

  render () {
    let { validationEnabled, googleApiKey, googleApiKeyEnabled, kleberApiKey, kleberApiKeyEnabled } = this.props.customer
    let { disabled } = this.props
    let key = 0
    return (
      <div className='' >
        <div className={'panel panel-default'}>
          <div className='panel-heading'>
                        Use Address Validation Service
            <div style={{ width: '80px', display: 'inline-block' }} className='pull-right'>
              <ToggleSwitch
                checked={validationEnabled}
                disabled={disabled}
                onChange={() => this.props.enableValidation(!validationEnabled)}
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
              />
            </div>
          </div>
          <div className='panel-body'>
            <FormGroup className={!validationEnabled || disabled ? 'text-muted' : ''}>
              <div>
                Kleber DataTools
                {/* <Panel collapsible={true} expanded={kleberApiKeyEnabled} style={{ border: 0 }}>
                  <div>
                                        Api key
                    <Input
                      change={(p) => this.props.setValidationApiKey(p)}
                      value={kleberApiKey}
                      type='text'
                      disabled={!validationEnabled || disabled}
                    />
                  </div>
                </Panel> */}
                <Accordion defaultActiveKey={kleberApiKeyEnabled ? '0' : undefined}>
                  <Card style={{ border: 0 }}>
                    <Accordion.Header>
                      <Accordion.Button style={{ width: '100%', textAlign: 'left' }}>
                        Api Key
                      </Accordion.Button>
                    </Accordion.Header>
                    <Accordion.Collapse eventKey='0'>
                      <Card.Body>
                        <Input
                          change={(p) => this.props.setValidationApiKey(p)}
                          value={kleberApiKey}
                          type='text'
                          disabled={!validationEnabled || disabled}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </FormGroup>
          </div>
        </div>
      </div>)
  }
}

function mapStateToProps (state): IStateProps {
  return {
    customer: state.customer.customer
  }
}

function mapDispatchToProps (dispatch): IDispatchProps {
  return bindActionCreators({
    selectValidationProvider, enableValidation, setValidationApiKey
  }, dispatch)
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(AddressValidation)
