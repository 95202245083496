import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { PRODUCT_FILTER, CUSTOMER_FILTER, Rule, RuleChild, SubFilter, TagGroup } from './../types/Menu'
import { Input } from './common/Input'

import {
  Button, Col,
  DropdownButton, Form,
  FormGroup,
  OverlayTrigger,
  Popover, ProgressBar, Row, Tooltip
} from 'react-bootstrap'
import { SortableJS as Sortable } from './react-sortable'
import Handle from './SVGHandle'

import {
  addSubFilter, ChangeGroupItemOrder, ChangeGroupOrder,
  changeSubFilterTitle, deleteSubFilter, expandSubFilter,
  GroupRemove, ReorderGroup, ReorderGroupItem, subFilterMatch
} from '../actions/menuActions'
import {
  default as Rules, DispatchProps as IDispatchProductProps, OwnProps as IOwnProductProps,
  Rules as ProductRules, StateProps as IStateRuleProductProps
} from './Rules'

import {
  addRule, changeRuleOperator, changeRuleTagGroup, changeRuleTags, deleteRule
} from '../actions/productActions'

interface IStateProps { SubFilter }
export interface IDispatchProps {
    subFilterMatch, deleteSubFilter?, onGroupChange?, onGroupReorder?,
    onGroupReverseOrder?, onGroupRemove?, addSubFilter?,
    changeSubFilterTitle?, expandSubFilter?
}
interface IOwnProps { group, id }

const mapStateToProps = (state, ownprops: IOwnProps) => {
  return {

    SubFilter: state.menu.menu.subFilters.filter((p) => p.parentSubFilterId === ownprops.id)
  }
}

const mapStateForItemToProps = (state, ownprops: IOwnProps) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGroupChange: (state, parentSubFilterId) => dispatch(ChangeGroupOrder(state, parentSubFilterId)),
    onGroupReorder: (state, newIndex, parentSubFilterId) =>
      dispatch(ReorderGroup(state, newIndex, parentSubFilterId)),
    onGroupItemChange: (state, parent) => dispatch(ChangeGroupItemOrder(state, parent)),
    onGroupItemReorder: (state, newIndex, parent) => dispatch(ReorderGroupItem(state, newIndex, parent)),
    onGroupRemove: (id) => dispatch(GroupRemove(id)),
    addSubFilter: (id) => dispatch(addSubFilter(id)),
    deleteSubFilter: (id) => dispatch(deleteSubFilter(id)),
    changeSubFilterTitle: (id, title) => dispatch(changeSubFilterTitle(id, title)),
    subFilterMatch: (id, all) => dispatch(subFilterMatch(id, all)),
    expandSubFilter: (id) => dispatch(expandSubFilter(id))
  }
}
// OV- Rule
export interface ITSubFilterOwnProps { key, item: SubFilter, max, group, seq, SingleRule?}
// //////////////////////////////////////////////////////////////
export class TSubFilterItem extends React.Component<
    & IDispatchProps & ITSubFilterOwnProps, {}> {
  shouldComponentUpdate (nextProps: object, nextState) {
    for (const prop in nextProps) {
      if (nextProps[prop] !== this.props[prop]) {
        return true
      }
    }
    return false
  }
  render () {
    const { seq, item, max, onGroupReorder, group,
      // tslint:disable-next-line:no-shadowed-variable
      subFilterMatch, changeSubFilterTitle, deleteSubFilter, addSubFilter, expandSubFilter } = this.props
    const val = item
    const subMenuFilterHeader = (
      <div className='sortable--container'>
        <i className='js-grouphandle sort-handle'>
          <Handle />
        </i>

        <span className='input-group sortable--input'>
          <span className='input-group-addon' id='basic-addon1'>{group === '' ? '' : group + '.'}</span>
          <Input
            type='number'
            min='1'
            max={max}
            value={seq + 1}
            className=''
            required={true}
            placeholder='Sequence'
            submit={(newIndex) => { onGroupReorder(item, newIndex, item) }}
          />
        </span>

        <Input type='text'
          placeholder='Title'
          maxLength={14}
          required={true}
          focus={val.title === ''}
          className='sortable--label'
          value={val.title}
          change={(value) => changeSubFilterTitle(val.id, value)}
        />

        <OverlayTrigger placement='bottom' overlay={<Tooltip id='remove'>Remove</Tooltip>}>
          <Button
            onClick={() => deleteSubFilter(val.id)}
            className='pull-right'
            variant='link'
          >
            <i className='bi bi-x text-danger' />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip id='expand'>{val.expanded ? 'Collapse' : 'Expand'}</Tooltip>}
        >
          <Button onClick={() => expandSubFilter(val.id)}
            className='pull-right' variant='link'
          >
            <i className='material-icons'>{val.expanded ? 'expand_less' : 'expand_more'} </i>
          </Button>
        </OverlayTrigger>
      </div>)
    const groupId = (group === '' ? '' : group + '.') + (seq + 1)

    const depth = group.split('.').length

    const productFilterHeader = (
      val.title
    )
    const { SingleRule } = this.props
    const ruleBody = (
      SingleRule ?
        SingleRule : <Rules subFilterId={val.id} />
    )
    return (
      <div className='' >
        <div className={depth === 2 ? 'panel panel-info' : 'panel panel-default'}>
          <div className='panel-heading clearfix'>
            {SingleRule ? <h3 className='panel-title pull-left'>{productFilterHeader}</h3> :
              <div className='panel-title pull-left'>{subMenuFilterHeader}</div>}
          </div>
          <div className={'panel-collapse collapse ' + (val.expanded || SingleRule ? 'in' : '')}>
            <div className='panel-body'>
              <FormGroup style={{ display: 'flex', flexDirection: 'row'}}>
                <Form.Check
                  inline={true}
                  onChange={() => subFilterMatch(val.id, true)}
                  checked={val.isMatchingAll}
                  type='radio'
                  id={`match-all-${val.id}`}
                  label='Match All'
                />              
                <Form.Check
                  inline={true}
                  onChange={() => subFilterMatch(val.id, false)}
                  checked={!val.isMatchingAll}
                  type='radio'
                  id={`match-any-${val.id}`}
                  label='Match Any'
                  style={{ marginLeft: '10px' }}
                />
              </FormGroup>
              {ruleBody}
              <br />
              {SingleRule ? '' : <SubFilterClass id={val.id} group={groupId} />}
            </div>
          </div>
        </div>

      </div>)
  }
}

class TSubFilterTabItem extends React.Component<{ key, item: SubFilter, max, group, seq } & IDispatchProps, {}> {
  shouldComponentUpdate (nextProps: object, nextState) {
    for (const prop in nextProps) {
      if (nextProps[prop] !== this.props[prop])
        return true
    }
    return false
  }
  render () {
    const { seq, item, max, onGroupReorder, group,
      // tslint:disable-next-line:no-shadowed-variable
      subFilterMatch, changeSubFilterTitle, deleteSubFilter, addSubFilter, expandSubFilter } = this.props
    const val = item
    const groupId = (group === '' ? '' : group + '.') + (seq + 1)

    const depth = group.split('.').length

    return (
      <div role='tabpanel' className={item.expanded ? 'tab-pane active' : 'tab-pane'} >
        <Form style={{ display: 'flex', flexDirection: 'row'}}>
          <Form.Check inline={true}
            onChange={() => subFilterMatch(val.id, true)}
            checked={val.isMatchingAll}
            type='radio'
            label='Match All'
            id='match-all'
          />
          <Form.Check inline={true}
            onChange={() => subFilterMatch(val.id, false)}
            checked={!val.isMatchingAll}
            type='radio'
            label='Match Any'
            style={{ marginLeft: '10px' }}
            id='match-any'
          />
        </Form>
        <Rules subFilterId={val.id} />
        <br />
        <SubFilterClass id={val.id} group={groupId} />
      </div>)
  }
}

// class TSubFilterTabHeaderItem extends React.Component<{ key, item: SubFilter, max, group, seq } & IDispatchProps, {}> {
//   shouldComponentUpdate (nextProps: object, nextState) {
//     for (const prop in nextProps) {
//       if (nextProps[prop] !== this.props[prop])
//         return true
//     }
//     return false
//   }
//   render () {
//     const { seq, item, max, onGroupReorder,
//       // tslint:disable-next-line:no-shadowed-variable
//       group, subFilterMatch, changeSubFilterTitle, deleteSubFilter, addSubFilter, expandSubFilter } = this.props
//     const val = item
//     const popoverClickRootClose = (
//       <Popover id='popover-trigger-click-root-close' title='Are you sure you want to delete this menu?'>
//         <Row>
//           <Col sm={6} >
//             <Button
//               onClick={(e) => {
//                 e.preventDefault()
//                 e.stopPropagation()
//                 const trig = this.refs.trig as any
//                 trig.hide()
//               }
//               }
//               variant='default'
//               // block={true}
//             >
//               No
//             </Button>
//           </Col>
//           <Col sm={6}>
//             <Button
//               onClick={(e) => {
//                 e.preventDefault()
//                 e.stopPropagation()
//                 const trig = this.refs.trig as any
//                 trig.hide()
//                 deleteSubFilter(val.id)
//               }
//               }
//               variant='danger'
//               // block={true}
//             >
//               Yes
//             </Button>
//           </Col>
//         </Row>
//       </Popover>
//     )
//     const header = (
//       <div className='sortable--container'>
//         <i className='js-grouphandle sort-handle'>
//           <Handle />
//         </i>

//         <Input type='text'
//           placeholder='Title'
//           maxLength={14}
//           required={true}
//           focus={val.title === '' && val.expanded}
//           className='sortable--label'
//           value={val.title}
//           change={(value) => changeSubFilterTitle(val.id, value)}
//           autoResize={true}
//           autoResizeMinWidth={80}
//           autoResizeMaxWidth={180}
//         />
//         <OverlayTrigger
//           trigger='click'
//           rootClose={true}
//           placement='bottom'
//           overlay={popoverClickRootClose}
//           // ref='trig'
//         >
//           <Button variant='link'><i className='bi bi-x text-danger' /></Button>
//         </OverlayTrigger>
//       </div>)
//     const groupId = (group === '' ? '' : group + '.') + (seq + 1)

//     const depth = group.split('.').length

//     return <a role='tab' data-toggle='tab' onClick={() => this.expandSubFilter(val.id)}>{header}</a>

//   }

//   expandSubFilter = (id) => {
//     this.props.expandSubFilter(id)
//   }
// }

const TSubFilterTabHeaderItem = React.memo(({
  key,
  item,
  max,
  group,
  seq,
  onGroupReorder,
  subFilterMatch,
  changeSubFilterTitle,
  deleteSubFilter,
  addSubFilter,
  expandSubFilter
}: any) => {
  const val = item
  const [showPopover, setShowPopover] = useState(false)
  const popoverRef = useRef(null)

  const handleNoClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowPopover(false)
  }

  const handleYesClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowPopover(false)
    deleteSubFilter(val.id)
  }

  const handleExpandSubFilter = () => {
    expandSubFilter(val.id)
  }

  const popoverClickRootClose = (
    <Popover
      id='popover-trigger-click-root-close'
      title='Are you sure you want to delete this menu?'
      ref={popoverRef}
    >
      <Row>
        <Col sm={6}>
          <Button onClick={handleNoClick} variant='default'>
            No
          </Button>
        </Col>
        <Col sm={6}>
          <Button onClick={handleYesClick} variant='danger'>
            Yes
          </Button>
        </Col>
      </Row>
    </Popover>
  )

  const header = (
    <div className='sortable--container'>
      <i className='js-grouphandle sort-handle'>
        <Handle />
      </i>

      <Input
        type='text'
        placeholder='Title'
        maxLength={14}
        required
        focus={val.title === '' && val.expanded}
        className='sortable--label'
        value={val.title}
        change={(value) => changeSubFilterTitle(val.id, value)}
        autoResize
        autoResizeMinWidth={80}
        autoResizeMaxWidth={180}
      />

      <OverlayTrigger
        trigger='click'
        rootClose
        placement='bottom'
        overlay={popoverClickRootClose}
        show={showPopover}
        onToggle={() => setShowPopover(!showPopover)}
      >
        <Button variant='link'><i className='bi bi-x text-danger' /></Button>
      </OverlayTrigger>
    </div>
  )

  const groupId = (group === '' ? '' : group + '.') + (seq + 1)
  const depth = group.split('.').length

  return (
    <a role='tab' data-toggle='tab' onClick={handleExpandSubFilter}>
      {header}
    </a>
  )
})

TSubFilterTabHeaderItem.displayName = 'TSubFilterTabHeaderItem'


const SubFilterItem = connect<{}, IDispatchProps,
    { key, item: SubFilter, max, group, seq }>(null, mapDispatchToProps)(TSubFilterItem)

const SubFilterTabItem = connect<{}, IDispatchProps,
    { key, item: SubFilter, max, group, seq }>(null, mapDispatchToProps)(TSubFilterTabItem)

const SubFilterTabHeaderItem = connect<{}, IDispatchProps,
    { key, item: SubFilter, max, group, seq }>(null, mapDispatchToProps)(TSubFilterTabHeaderItem)

class SubFilterB extends React.Component<IStateProps & IDispatchProps & IOwnProps, {}> {

  shouldComponentUpdate (nextProps: object, nextState) {
    for (const prop in nextProps) {
      if (nextProps[prop] !== this.props[prop])
        return true
    }

    return false
  }

  render () {

    // tslint:disable-next-line:no-shadowed-variable
    const { subFilterMatch, deleteSubFilter, SubFilter, id, group,
      onGroupChange, onGroupReorder, onGroupReverseOrder, onGroupRemove,
      // tslint:disable-next-line:no-shadowed-variable
      addSubFilter, changeSubFilterTitle, expandSubFilter } = this.props
    let sortable = null // sortable instance

    const subFilters = SubFilter.filter((p) => p.parentSubFilterId === id)

    const listItems = subFilters.map((val, key) =>
      group
        ? (
          <div data-id={val.id} key={key}>
            <SubFilterItem group={group} item={val} key={key} max={subFilters.length} seq={key} /></div>
        )
        : (
          <li data-id={val.id} key={key} className={val.expanded ? 'active' : ''}>
            <SubFilterTabHeaderItem group={group} item={val} key={key} max={subFilters.length} seq={key} />
          </li>))
    if (!group && subFilters.length !== 6) {
      listItems.push((
        <li key={9}
          onClick={() => addSubFilter(id)}
        >
          <a role='button' data-toggle='tab' className='btn btn-link text-only'><b>+</b></a>
        </li>
      ))
    }
    const listDetails = group ? [] :
      subFilters.map((val, key) =>
        <SubFilterTabItem group={group} item={val} key={key} max={subFilters.length} seq={key} />)
    const depth = group.split('.').length
    const width = depth !== 3 ? ((subFilters.length / 6) * 100) + '%' : '0%'

    const sortBody = (
      depth === 3 || group === '' ? '' :
        (
          <div className=''>
            <div className='text-center' >

              <Button
                onClick={() => addSubFilter(id)}
                disabled={subFilters.length === 6 && depth !== 2}
                variant='link'
                className={'btn-link-dashed btn-block ' + (depth === 1 ? 'btn-link-dashed-default' : 'btn-link-dashed-info')}
              >
                {depth === 1 ? 'Add Menu' : 'Add Submenu'}
              </Button>
            </div>
          </div>
        )
    )
    const ref = (c) => {
      if (c) {
        sortable = c.sortable
      }
    }
    const orderFunc = (order) => {
      onGroupChange(order, id)
    }
    return (
      <div>
        <Sortable
          // Sortable options (https://github.com/RubaXa/Sortable#options)
          options={{

            group,
            animation: 150,
            handle: '.js-grouphandle'

          }}

          // [Optional] Use ref to get the sortable instance
          // https://facebook.github.io/react/docs/more-about-refs.html#the-ref-callback-attribute
          ref={ref}

          // [Optional] A tag to specify the wrapping element. Defaults to "div".
          tag={group ? 'div' : 'ul'}
          className={group ? '' : 'nav nav-tabs'}

          // [Optional] The onChange method allows you to implement a controlled component and keep
          // DOM nodes untouched. You have to change state to re-render the component.
          // @param {Array} order An ordered array of Types defined by the `data-id` attribute.
          // @param {Object} sortable The sortable instance.
          // @param {Event} evt The event object.
          onChange={orderFunc}
        >{listItems}</Sortable>
        <p />
        {listDetails.length !== 0 ? <div className='tab-content'> {listDetails} </div> : ''}
        {sortBody}
      </div>)
  }
}

const SubFilterClass = connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(SubFilterB)

export default SubFilterClass
