import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loadData, loadNextPage } from '../../actions/dataExplorerActions'
import { ObjectInspector } from 'react-inspector'

interface StateProps {
    data: any
}
interface DispatchProps {
    loadData: () => void
    loadNextPage: () => void
}

class ObjectExplorer extends React.Component<StateProps & DispatchProps, {}> {
  render () {
    return (
      <ObjectInspector table={true} data={this.props.data} />
    )
  }
}

function mapStateToProps (state) {
  return {
    data: state.dataExplorer.data
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ loadData, loadNextPage }, dispatch)
}

export default connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(ObjectExplorer)
