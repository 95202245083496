import moment from 'moment'

import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'

import Select from 'react-select'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import { showErrorToast } from '../../utils/Toast'
import {
  dataExplorerIdChange,
  dataExplorerTypeChange,
  loadData

} from '../../actions/dataExplorerActions'
import ObjectExplorer from './ObjectExplorer'
import EmptyState from '../common/EmptyState'

interface IDispatchProps {
    dataExplorerIdChange,
    dataExplorerTypeChange,
    loadData
}

interface IStateProps {
    id: string,
    dataExplorerType: string,
    // templatesLoading: boolean,
    // claims: any,
    tenantId: string,
    companyId: string
}

interface IOwnProps {
}
interface IState {
    // idToSearch: string,
    // repositorySelected: number,
}
class DataExplorer extends React.Component<IDispatchProps & IStateProps & IOwnProps, IState> {

  defaultProps = {
    templates: null,
    bulkTemplates: null,
    claims: {}
  }
  dataExplorerOptions =
    [
      { value: 1, label: 'Customer' },
      { value: 2, label: 'Navigation' },
      { value: 3, label: 'Products' }
    ]
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  OnSearchClick = (event) => {
    event.preventDefault()
    if (this.props.dataExplorerType === '') {
      showErrorToast('Please select repository type')
    }
  }

  onChange (event) {
    this.props.dataExplorerTypeChange(event.value)
  }

  onIdToSearchChange = (event) => {
    this.props.dataExplorerIdChange(event.target.value)
  }

  renderTemplate = () => {

    const spinner = (<ClipLoader size={40} />)
    return (
      <div style={{ padding: 5, position: 'relative' }} ref='parent'>
        <h3>Data Explorer</h3>
        <div style={{ width: '100%' }} >
          <div className='row' >
            <div className='col-sm-8 col-xs-6'>
              <div className='col-xs-6'>
                <Select
                  name='repositoryType'
                  value={this.props.dataExplorerType}
                  options={this.dataExplorerOptions.map((x) => x.label)}
                  onChange={(e) => this.onChange(e)}
                  styles={
                    {
                      control: (provided: any) => ({
                        ...provided,
                        maxWidth: 400
                      })
                    }
                  }
                  isClearable={false}
                />
              </div>
              <div className='col-xs-6'>
                <input
                  maxLength={100}
                  name='idToSearch'
                  value={this.props.id}
                  onChange={(e) => this.onIdToSearchChange(e)}
                  className='form-control'
                  placeholder='Search by id...'
                />
              </div>
            </div>
            <div className='col-sm-4 col-xs-6 text-right'>
              <Button
                variant='primary'
                style={{ margin: 5, textAlign: 'center' }}
                disabled={this.props.dataExplorerType === ''}
                onClick={this.props.loadData}
              >
                                Search
              </Button>
            </div>
          </div>
          <hr />
          <ObjectExplorer/>
        </div>
      </div>
    )
  }

  render () {

    return (
      (this.props.companyId && this.props.tenantId) ?
        (
          <div className='container-fluid' >
            {this.renderTemplate()}
          </div>
        ) :
        (
          <div style={{ maxWidth: 500, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <p className='alert alert-danger'>Select Company and Enviroment</p></div>
        )
    )
  }
}

function mapStateToProps (state): IStateProps {
  return {
    id: state.dataExplorer.id,
    dataExplorerType: state.dataExplorer.dataExplorerType,
    tenantId: state.session.tenantId,
    companyId: state.session.companyId
  }
}

function mapDispatchToProps (dispatch): IDispatchProps {
  return bindActionCreators({
    dataExplorerIdChange,
    dataExplorerTypeChange,
    loadData
  }, dispatch)
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataExplorer)
