import customerApi from '../api/CustomerApi'
import { Customer } from './../types/Customer'
import { showErrorToast, showSuccessToast } from '../utils/Toast'
import { func } from 'prop-types'

export const CUSTOMER_ADD = 'CUSTOMER_ADD'
export const CUSTOMER_NAME_CHANGED = 'CUSTOMER_NAME_CHANGED'
export const CUSTOMER_REMOVE = 'CUSTOMER_REMOVE'
export const CUSTOMER_COMPANY_SELECT = 'CUSTOMER_COMPANY_SELECT'
export const CUSTOMER_TENANT_SELECT = 'CUSTOMER_TENANT_SELECT'
export const CUSTOMERS_LOADING = 'CUSTOMERS_LOADING'
export const CUSTOMERS_LOADED = 'CUSTOMERS_LOADED'
export const CUSTOMERS_SAVING = 'CUSTOMERS_SAVING'
export const CUSTOMERS_SAVED = 'CUSTOMERS_SAVED'
export const SET_CUSTOMERS_SAVED = 'SET_CUSTOMERS_SAVED'
export const CUSTOMER_LOADED = 'CUSTOMER_LOADED'
export const CUSTOMER_REDIRECT_LISTING = 'CUSTOMER_REDIRECT_LISTING'
export const CUSTOMER_INVALID = 'CUSTOMER_INVALID'

export const CUSTOMER_GROUP_SET_ENABLED = 'CUSTOMER_GROUP_SET_ENABLED'
export const CUSTOMER_FIELD_SET_MANDATORY = 'CUSTOMER_FIELD_SET_MANDATORY'

export const CUSTOMERS_UPDATED = 'CUSTOMERS_UPDATED'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const SET_DELETE_CUSTOMER_SUCCESS = 'SET_DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR'
export const CUSTOMER_API_STARTED = 'CUSTOMER_API_STARTED'
export const CUSTOMER_TAGS_LOADED = 'CUSTOMER_TAGS_LOADED'
export const CLEAR_CUSTOMERS = 'CLEAR_CUSTOMERS'

export const CUSTOMER_RULE_DELETE = 'CUSTOMER_RULE_DELETE'
export const CUSTOMER_RULE_ADD = 'CUSTOMER_RULE_ADD'
export const CUSTOMER_RULE_CHANGE_TAGGROUP = 'CUSTOMER_RULE_CHANGE_TAGGROUP'
export const CUSTOMER_RULE_CHANGE_TAGS = 'CUSTOMER_RULE_CHANGE_TAGS'
export const CUSTOMER_RULE_CHANGE_OPERATOR = 'CUSTOMER_RULE_CHANGE_OPERATOR'

export const DELETE_CUSTOMER_STARTED = 'DELETE_CUSTOMER_STARTED'
export const DELETE_CUSTOMER_FINISHED = 'DELETE_CUSTOMER_FINISHED'

export const CUSTOMER_SUBMENU_MATCH = 'CUSTOMER_SUBMENU_MATCH'
export const CUSTOMER_CLEAR = 'CUSTOMER_CLEAR'

export const CUSTOMER_ADDRESS_VALIDATION_ENABLE = 'CUSTOMER_ADDRESS_VALIDATION_ENABLE'
export const CUSTOMER_ADDRESS_VALIDATION_SELECT = 'CUSTOMER_ADDRESS_VALIDATION_SELECT'
export const CUSTOMER_ADDRESS_VALIDATION_APIKEY = 'CUSTOMER_ADDRESS_VALIDATION_APIKEY'

export const TOGGLE_CHECK_FOR_ATTACHED_CUSTOMER_ENABLED = 'TOGGLE_CHECK_FOR_ATTACHED_CUSTOMER_ENABLED'

export function enableValidation (enable: boolean) {
  return { type: CUSTOMER_ADDRESS_VALIDATION_ENABLE, enable }
}

export function selectValidationProvider (provider: string) {
  return { type: CUSTOMER_ADDRESS_VALIDATION_SELECT, provider }
}

export function setValidationApiKey (key: any) {
  return { type: CUSTOMER_ADDRESS_VALIDATION_APIKEY, key }
}

export function customerApiSaveStarted () {
  return { type: CUSTOMERS_SAVING }
}

export function customerApiSaved (result: Customer | null, success: boolean) {
  return { type: CUSTOMERS_SAVED, result, success }
}

export function subFilterMatch (id: any, all: boolean) {
  return { type: CUSTOMER_SUBMENU_MATCH, id, all }
}

export function clear () {
  return { type: CUSTOMER_CLEAR }
}
export function changeRuleTagGroup (ruleId: number, tagGroup: string) {
  return { type: CUSTOMER_RULE_CHANGE_TAGGROUP, ruleId, tagGroup }
}

export function changeRuleTags (ruleId: number, tags: string[]) {
  return { type: CUSTOMER_RULE_CHANGE_TAGS, ruleId, tags }
}

export function changeRuleOperator (ruleId: number, operator: number) {
  return { type: CUSTOMER_RULE_CHANGE_OPERATOR, ruleId, operator }
}

export function customerCompanySelect (id: any) {
  return { type: CUSTOMER_COMPANY_SELECT, id }
}

export function deleteRule (id: number) {
  return { type: CUSTOMER_RULE_DELETE, id }
}
export function addRule (subFilterId: number) {
  return { type: CUSTOMER_RULE_ADD, subFilterId }
}

export function customersLoaded (customers: any) {
  return { type: CUSTOMERS_LOADED, customers }
}

export function customerLoaded (customer: any) {
  return { type: CUSTOMER_LOADED, customer }
}

export function customerInvalidate () {
  return { type: CUSTOMER_INVALID }
}

export function tagsLoaded (tags: any) {
  return { type: CUSTOMER_TAGS_LOADED, tags }
}

export function customersLoading () {
  return { type: CUSTOMERS_LOADING }
}

export function updateGroupEnabled (group: string, enabled: boolean, fieldName?: string) {
  return { type: CUSTOMER_GROUP_SET_ENABLED, group, enabled, fieldName }
}

export function updateFieldMandatory (group: string, mandatory: boolean, fieldName?: string) {
  return { type: CUSTOMER_FIELD_SET_MANDATORY, group, fieldName, mandatory }
}

export function updateCheckForAttachedCustomerEnabled () {
  return { type: TOGGLE_CHECK_FOR_ATTACHED_CUSTOMER_ENABLED }
}
export function saveCustomer (customer: Customer) {
  return (dispatch: any, getState: any) => {
    if (!customer.name) {
      showErrorToast('Name cannot be blank')
      dispatch(customerInvalidate())

      return
    }

    if (customer.subFilter && customer.subFilter.title === '') {
      showErrorToast('Sub Menu title can not be empty')
      dispatch(customerInvalidate())

      return
    }

    const data = {
      ...customer,
      rules: customer.rules.filter((p) => !(!p.tagGroupId || p.operatorType === undefined))
    }

    if (data.rules.some((p) => p.tagGroupId === ''
      || p.operatorType === undefined)
      || data.rules.some((p) => !data.ruleChilds.some((q) => q.ruleId === p.id))) {
      showErrorToast('One or more rules are incomplete')
      dispatch(customerInvalidate())

      return
    }

    dispatch(customerApiSaveStarted())
    return customerApi.saveCustomer(data, getState().session.tenantId).then((response) => {
      try {
        if (response.id) {
          showSuccessToast('Customer saved')
          dispatch(customerApiSaved(response, true))
          dispatch(loadCustomerDetail(response.id, getState().session.tenantId))
        } else if (response.error) {
          dispatch(customerApiSaved(null, false))
          showErrorToast(response.error)
        } else {
          dispatch(customerApiSaved(null, false))
          showErrorToast(response)
        }
      } catch (e) {
        dispatch(customerApiSaved(null, false))
        showErrorToast('Unknown error occured')
      }
    }).catch((p) => {
      dispatch(customerApiSaved(null, false))
      showErrorToast(p)
    })
  }
}

export function CustomerAdd (id: any) {
  return { type: CUSTOMER_ADD, Id: id }
}

export function NameChange (name: any) {
  return { type: CUSTOMER_NAME_CHANGED, name }
}

export function redirectToListing () {
  return { type: CUSTOMER_REDIRECT_LISTING }
}

export function customersApiDeleteStarted () {
  return { type: DELETE_CUSTOMER_STARTED }
}
export function customersApiDeleteFinished () {
  return { type: DELETE_CUSTOMER_FINISHED }
}
export function loadCustomers (tenantId: any) {
  return (dispatch: any) => {
    dispatch(customersLoading())
    return customerApi.loadCustomers(tenantId).then((response) => {
      if (response) {
        dispatch(customersLoaded(response))
      }
    }).catch(() => {
      dispatch(customersLoaded([]))
    })
  }
}

export function customerTenantSelect (id: any) {
  return (dispatch: any) => {
    dispatch({ type: CUSTOMER_TENANT_SELECT, id })
    return customerApi.loadTags(id).then((response) => {
      if (response) {
        dispatch(tagsLoaded(response))
      }
    }).catch(() => {
      // do nothing
    })
  }
}

export function loadCustomerDetail (customerId: any, tenantId: any) {
  return (dispatch: any, getState: any) => {
    dispatch(customersLoading())

    return customerApi.loadCustomerDetail(customerId, tenantId).then((response) => {
      if (response) {
        const sessionState = getState().session

        dispatch(customerLoaded(new Customer(response)))
      }
    }).catch(() => {
      dispatch(customersLoaded([]))
    })
  }
}
export function deleteCustomerSuccess (id: any) {
  return { type: DELETE_CUSTOMER_SUCCESS, id }
}

export function deleteCustomer (customer: any) {
  return (dispatch: any, getState: any) => {
    dispatch(customersApiDeleteStarted())
    return customerApi.deleteCustomer(customer.id, getState().session.tenantId).then((response) => {
      if (response) {
        showErrorToast(response)
      } else {
        showSuccessToast(`Customer ${customer.name} has been deleted`)
        dispatch(deleteCustomerSuccess(customer.id))
      }
      dispatch(customersApiDeleteFinished())
    }).catch((p) => {
      showErrorToast(p)
      dispatch(customersApiDeleteFinished())
    })
  }
}

export function clearCustomers () {
  return { type: CLEAR_CUSTOMERS }
}

export function setCustomerDeletedSuccess (value: boolean) {
  return { type: SET_DELETE_CUSTOMER_SUCCESS, value }
}

export function setCustomerSaved (value: boolean) {
  return { type: SET_CUSTOMERS_SAVED, value }
}