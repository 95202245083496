export default class ActivityNote {
  id: string
  note: string
  modifiedById: string
  modifiedDate: string | null
  activityLogId: string
  userName: string
  constructor (activityNote?: any) {
    if (activityNote) {
      this.id = activityNote.id
      this.note = activityNote.note
      this.modifiedById = activityNote.modifiedById
      this.modifiedDate = activityNote.modifiedDate
      this.activityLogId = activityNote.activityLogId
      this.userName = activityNote.userName
    } else {
      this.id = ''
      this.note = ''
      this.modifiedById = ''
      this.modifiedDate = null
      this.userName = ''
      this.activityLogId = ''
    }
  }
}