import productApi from './../api/ProductApi'
import { ProductFilter } from './../types/Product'
import * as Toast from '../utils/Toast'

export const PRODUCT_ADD = 'PRODUCT_ADD'
export const PRODUCT_NAME_CHANGED = 'PRODUCT_NAME_CHANGED'
export const PRODUCT_REMOVE = 'PRODUCT_REMOVE'
export const PRODUCT_COMPANY_SELECT = 'PRODUCT_COMPANY_SELECT'
export const PRODUCT_TENANT_SELECT = 'PRODUCT_TENANT_SELECT'
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING'
export const PRODUCTS_LOADED = 'PRODUCTS_LOADED'
export const PRODUCTS_SAVING = 'PRODUCTS_SAVING'
export const PRODUCTS_SAVED = 'PRODUCTS_SAVED'
export const PRODUCT_LOADED = 'PRODUCT_LOADED'
export const PRODUCT_REDIRECT_LISTING = 'PRODUCT_REDIRECT_LISTING'
export const PRODUCT_INVALID = 'PRODUCT_INVALID'

export const PRODUCTS_UPDATED = 'PRODUCTS_UPDATED'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR'
export const PRODUCT_API_STARTED = 'PRODUCT_API_STARTED'
export const PRODUCT_TAGS_LOADED = 'PRODUCT_TAGS_LOADED'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const SET_DELETE_PRODUCT_SUCCESS = 'SET_DELETE_PRODUCT_SUCCESS'

export const PRODUCT_RULE_DELETE = 'PRODUCT_RULE_DELETE'
export const PRODUCT_RULE_ADD = 'PRODUCT_RULE_ADD'
export const PRODUCT_RULE_CHANGE_TAGGROUP = 'PRODUCT_RULE_CHANGE_TAGGROUP'
export const PRODUCT_RULE_CHANGE_TAGS = 'PRODUCT_RULE_CHANGE_TAGS'
export const PRODUCT_RULE_CHANGE_OPERATOR = 'PRODUCT_RULE_CHANGE_OPERATOR'

export const DELETE_PRODUCT_STARTED = 'DELETE_PRODUCT_STARTED'
export const DELETE_PRODUCT_FINISHED = 'DELETE_PRODUCT_FINISHED'

export const PRODUCT_SUBMENU_MATCH = 'PRODUCT_SUBMENU_MATCH'

export function productApiSaveStarted () {
  return { type: PRODUCTS_SAVING }
}

export function productApiSaved (result: ProductFilter | null, success: boolean) {
  return { type: PRODUCTS_SAVED, result, success }
}

export function subFilterMatch (id: any, all: boolean) {
  return { type: PRODUCT_SUBMENU_MATCH, id, all }
}

export function changeRuleTagGroup (ruleId: number, tagGroup: string) {
  return { type: PRODUCT_RULE_CHANGE_TAGGROUP, ruleId, tagGroup }
}

export function changeRuleTags (ruleId: number, tags: string[]) {
  return { type: PRODUCT_RULE_CHANGE_TAGS, ruleId, tags }
}

export function changeRuleOperator (ruleId: number, operator: number) {
  return { type: PRODUCT_RULE_CHANGE_OPERATOR, ruleId, operator }
}

export function productCompanySelect (id: any) {
  return { type: PRODUCT_COMPANY_SELECT, id }
}

export function deleteRule (id: number) {
  return { type: PRODUCT_RULE_DELETE, id }
}
export function addRule (subFilterId: number) {
  return { type: PRODUCT_RULE_ADD, subFilterId }
}

export function productsLoaded (products: any) {
  return { type: PRODUCTS_LOADED, products }
}

export function productLoaded (product: any) {
  return { type: PRODUCT_LOADED, product }
}

export function productInvalidate () {
  return { type: PRODUCT_INVALID }
}

export function tagsLoaded (tags: any) {
  return { type: PRODUCT_TAGS_LOADED, tags }
}

export function productsLoading () {
  return { type: PRODUCTS_LOADING }
}

export function saveProduct (product: ProductFilter, tenantId: any) {
  return (dispatch: any) => {
    if (!product.name || product.name.trim().length === 0) {
      Toast.showErrorToast('Name cannot be blank')
      dispatch(productInvalidate())

      return
    }

    if (product.subFilter && product.subFilter.title === '') {
      Toast.showErrorToast('Sub Menu title can not be empty')
      dispatch(productInvalidate())

      return
    }

    const data = {
      ...product,
      rules: product.rules.filter((p) => !(!p.tagGroupId || p.operatorType === undefined))
    }

    if (data.rules.some((p) => p.tagGroupId === ''
      || p.operatorType === undefined)
      || data.rules.some((p) => !data.ruleChilds.some((q) => q.ruleId === p.id))) {
      Toast.showErrorToast('One or more rules are incomplete')
      dispatch(productInvalidate())
      return
    }

    dispatch(productApiSaveStarted())
    return productApi.saveProduct(data, tenantId).then((response) => {
      try {
        if (response.id) {
          Toast.showSuccessToast('Product saved')
          dispatch(productApiSaved(response, true))
          dispatch(loadProductDetail(response.id, tenantId))
        } else if (response.error) {
          dispatch(productApiSaved(null, false))
          Toast.showErrorToast(response.error)
        } else {
          dispatch(productApiSaved(null, false))
          Toast.showErrorToast(response)
        }
      } catch (e) {
        dispatch(productApiSaved(null, false))
        Toast.showErrorToast('Unknown error occured')
      }
    }).catch((p) => {
      dispatch(productApiSaved(null, false))
      Toast.showErrorToast(p)
    })
  }
}

export function ProductAdd (id: any) {
  return { type: PRODUCT_ADD, Id: id }
}

export function NameChange (name: any) {
  return { type: PRODUCT_NAME_CHANGED, name }
}

export function redirectToListing () {
  return { type: PRODUCT_REDIRECT_LISTING }
}

export function productsApiDeleteStarted () {
  return { type: DELETE_PRODUCT_STARTED }
}
export function productsApiDeleteFinished () {
  return { type: DELETE_PRODUCT_FINISHED }
}
export function loadProducts (tenantId: any) {
  return (dispatch: any) => {
    dispatch(productsLoading())
    return productApi.loadProducts(tenantId).then((response) => {
      if (response) {
        dispatch(productsLoaded(response))
      }
    }).catch(() => {
      dispatch(productsLoaded([]))
    })
  }
}

export function productTenantSelect (id: any) {
  return (dispatch: any) => {
    dispatch({ type: PRODUCT_TENANT_SELECT, id })
    return productApi.loadTags(id).then((response) => {
      if (response) {
        dispatch(tagsLoaded(response))
      }
    }).catch(() => {
      // do nothing
    })
  }
}

export function loadProductDetail (productId: any, tenantId: any) {
  return (dispatch: any, getState: any) => {
    dispatch(productsLoading())

    return productApi.loadProductDetail(productId, tenantId).then((response) => {
      if (response) {
        const sessionState = getState().session

        dispatch(productLoaded(new ProductFilter(response)))
      }
    }).catch(() => {
      dispatch(productsLoaded([]))
    })
  }
}
export function deleteProductSuccess (id: any) {
  return { type: DELETE_PRODUCT_SUCCESS, id }
}

export function deleteProduct (product: any, tenantId: any) {
  return (dispatch: any) => {
    dispatch(productsApiDeleteStarted())
    return productApi.deleteProduct(product.id, tenantId).then((response) => {
      if (response) {
        Toast.showErrorToast(response)
      } else {
        Toast.showSuccessToast(`Product ${product.name} has been deleted`)
        dispatch(deleteProductSuccess(product.id))
      }
      dispatch(productsApiDeleteFinished())
    }).catch((p) => {
      Toast.showErrorToast(p)
      dispatch(productsApiDeleteFinished())
    })
  }
}

export function clearProducts () {
  return { type: CLEAR_PRODUCTS }
}

export function setProductDeletedSuccess (value: boolean) {
  return { type: SET_DELETE_PRODUCT_SUCCESS, value }
}