import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input } from './../common/Input'
import { FieldGroup, FieldOption } from '../../types/Customer'


import {
  updateFieldMandatory,
  updateGroupEnabled
} from '../../actions/customerActions'
import ToggleSwitch from '../common/ToggleSwitch'

interface IStateProps {

}

interface IDispatchProps {
    updateFieldMandatory,
    updateGroupEnabled
}

interface IOwnProps {
    group: FieldGroup
    children?: any
}

export class CustomerField extends React.Component<IOwnProps & IStateProps & IDispatchProps, {}> {
  shouldComponentUpdate (nextProps: object, nextState) {
    for (const prop in nextProps) {
      if (nextProps[prop] !== this.props[prop]) {
        return true
      }
    }
    return false
  }

  render () {
    let { group } = this.props
    const enable = group.fieldOptions.every(p => p.enabled)
    let key = 0
    return (
      <div className='' >
        <div className={'panel panel-default'}>
          <div className='panel-heading clearfix'>
            <h3 className='panel-title pull-left'>{this.props.group.group}</h3>
            <div className='pull-right' style={{ width: '80px', display: 'inline-block' }}>
              <ToggleSwitch
                checked={enable}
                onChange={() => this.props.updateGroupEnabled(group.group, !enable)}
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
              />
            </div>
          </div>
          <div className={'panel-collapse collapse in'}>
            <div className='panel-body'>
              <div className='col-xs-12 col-md-4'>
                {
                  this.props
                    .group
                    .fieldOptions
                    .map(p => <MultiRequiredField
                      key={key++}
                      fieldOption={p}
                      group={group}
                      enable={enable}
                      updateFieldMandatory={this.props.updateFieldMandatory}
                    />)
                }
              </div>
              <div className='col-xs-12 col-md-8'>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>

      </div>)
  }
}

class MultiRequiredField extends React.Component<{ fieldOption: FieldOption, group: FieldGroup, enable: boolean, updateFieldMandatory }, {}> {
  shouldComponentUpdate (nextProps: object, nextState) {
    for (const prop in nextProps) {
      if (nextProps[prop] !== this.props[prop]) {
        return true
      }
    }
    return false
  }

  render () {
    let { fieldOption, group, enable } = this.props

    return <div className='row mg-bottom'>
      {fieldOption.name}
      <div style={{ width: '80px', display: 'inline-block' }} className='pull-right'>
        <ToggleSwitch
          checked={fieldOption.mandatory}
          disabled={!enable}
          onChange={() => this.props.updateFieldMandatory(group.group, !fieldOption.mandatory, fieldOption.name)}
          checkedIconText={'Required'}
          uncheckedIconText={'Optional'}
        />
      </div>
    </div>

  }
}

function mapStateToProps (state): IStateProps {
  return {
  }
}

function mapDispatchToProps (dispatch): IDispatchProps {
  return bindActionCreators({
    updateFieldMandatory,
    updateGroupEnabled
  }, dispatch)
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(CustomerField)
