
import React from 'react'
import { connect } from 'react-redux'
import { Nav, NavDropdown } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { sessionTimeout, changePassword } from '../actions/sessionActions'
import Inactivity from './Inactivity'
import withRouter from './Utils/WithRouter'
import Breadcrumbs from './common/BreadCrumbs'

class Header extends React.Component<any, any> {
  changeState = (name, value) => {
    return this.setState({ ...this.state, [name]: value })
  }

  onChangePasswrd = () => {
    this.props.navigate('/ChangePassword')
    this.props.changePassword()    
  }

  onSessionTimeout = () => {
    this.props.sessionTimeout()
    this.props.navigate('/Login')
  }

  render () {
    const userTitle = `${this.props.name} (${this.props.role})`
    let loginInfo = (
      <NavDropdown title=' ' key=' ' id={`dropdown-basic-${userTitle}`} style={{ listStyle: 'none' }} className='invisible'>
        <NavDropdown.Item />
      </NavDropdown>
    )
    let { sidePanelCollapsed, toggleSidePanel } = this.props
    if (this.props.logged_in) {
      loginInfo = (
        <NavDropdown 
          title={
            <>
              {userTitle} <span className='caret' />
            </>
          } 
          key={userTitle} 
          id={`dropdown-basic-${userTitle}`} 
          style={{ listStyle: 'none' }} 
        >
          <NavDropdown.Item eventKey='1' onClick={this.onSessionTimeout}>Log Out</NavDropdown.Item>
          <NavDropdown.Item eventKey='2' onClick={this.onChangePasswrd}>Change Password</NavDropdown.Item>
        </NavDropdown >
      )
    }
    return (
      <header
        className='panel panel-default'
        style={{
          display: !this.props.logged_in ? 'none' : 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 20px',
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: '65px',
          borderRadius: 0
        }}
      >
        <div
          style={{ justifyContent: 'space-between',alignItems: 'center', padding: 0, flexDirection: 'row', display: 'flex' }}
        >
          <i className={'glyphicon glyphicon-' + (sidePanelCollapsed ? 'menu-hamburger' : 'chevron-left')}
            role='button' style={{ paddingRight: 10, fontSize: 20, flexGrow: 0, marginTop: -3 }} onClick={toggleSidePanel}
          />
          <Breadcrumbs name={this.props.titleName} />
        </div>
        <ul className='pull-right' style={{ marginBottom: 0 }}>
          {loginInfo}
        </ul>
        { window.sess_inactivity ?
          <Inactivity /> : '' }
      </header>
    )
  }
}

function mapStateToProps (state) {
  return {
    logged_in: state.session.logged_in,
    email: state.session.email,
    name: state.session.name,
    role: state.session.role
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    sessionTimeout,
    changePassword
  },
  dispatch)
}
// adding { pure: false } is a workaround for our state issues not setting
// the active state on the naviagtion tabs
// https://github.com/reactjs/react-redux/blob/master/docs/troubleshooting.md
export default connect<any, any,
  { routes, sidePanelCollapsed: boolean, toggleSidePanel: any }>
  (mapStateToProps, mapDispatchToProps, null)(withRouter(Header))
