import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import Select from 'react-select'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import { Template, ShopUpdateType } from '../types/Template'

import {
  saveTemplate,
  templateShowEditor,
  templatesNavigationSelected
} from '../actions/templateActions'

const sidebarSubHeader = {
  padding: '0 20px',
  display: 'block'
}

interface IStateProps {
    // col: Template,
    templatesLoading: boolean
    navigations: any
    products: any
    printerTemplates: any
    generalTemplates: any
    customerTemplates: any
    templateSaving: boolean
    template: Template
    tenantId: string
}
interface IDispatchProps {
    templatesNavigationSelected: any
    saveTemplate: any
    templateShowEditor: any
}

interface IOwnProps {
    index: any,
    search: any
}

const mapStateToProps = (state: any, ownprops: IOwnProps) => {
  return {
    templatesLoading: state.template.templatesLoading,
    template: state.template.templatesLoading ? undefined : state.template.templates[ownprops.index],
    navigations: state.template.templatesLoading ? undefined : state.template.navigations,
    products: state.template.templatesLoading ? undefined : state.template.products,
    printerTemplates: state.template.templatesLoading ? undefined : state.template.printerTemplates,
    generalTemplates: state.template.templatesLoading ? undefined : state.template.generalTemplates,
    customerTemplates: state.template.templatesLoading ? undefined : state.template.customerTemplates,
    templateSaving: state.template.templateSaving,
    tenantId: state.session.tenantId
  }
}

function mapDispatchToProps (dispatch: any) {
  return bindActionCreators(
    {
      templatesNavigationSelected,
      templateShowEditor,
      saveTemplate
    },
    dispatch
  )
}

class NavigationSelect extends React.Component<
    IStateProps & IDispatchProps & IOwnProps,
    any> {

  // Define the styles object
  customStyles = {
    control: (provided: any) => ({
      ...provided,
      maxWidth: 400
    })
    // Add other styles as needed
  }

  constructor (props: any) {
    super(props)
    this.state = {
      open: true
    }
  }

  // shouldComponentUpdate(nextProps: any, nextState: any) {
  //   for (const prop in nextProps) {
  //     if (nextProps[prop] !== this.props[prop]) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }
  onNavigationChanged = (val: any, index: any, shopUpdateType: ShopUpdateType) => {
    if (val) {
      this.props.templatesNavigationSelected(val, index, shopUpdateType)
    }
  }

  onRowClick = (id: any, e: any) => {
    this.props.templateShowEditor(id)
  }

  onSaveClick = (col: any, tenantId: any, e: any) => {
    this.props.saveTemplate(col, tenantId)
  }

  render () {

    const inlineSpinner = <ClipLoader size={15} />

    const col = this.props.template
    let filter = true
    if (this.props.search !== '') {
      filter = (col.name.search(new RegExp(this.props.search, 'i')) !== -1 ||
        col.generalTemplate.search(new RegExp(this.props.search, 'i')) !== -1 ||
        col.navigation.search(new RegExp(this.props.search, 'i')) !== -1 ||
        col.products.search(new RegExp(this.props.search, 'i')) !== -1 ||
        col.printerTemplate.search(new RegExp(this.props.search, 'i')) !== -1 ||
        col.customers.search(new RegExp(this.props.search, 'i')) !== -1) ||
        col.showEditor
    }
    return filter ? (col.showEditor) ? (
      <tr key={col.id} >
        <td key={`name${col.id}`}>
          {col.name}
        </td>
        <td key={'general{col.id}'}>
          <Select
            name='generalEdit'
            value={this.props.generalTemplates?.find(x => x.value === col.generalTemplateId)}
            options={this.props.generalTemplates}
            onChange={(e) => this.onNavigationChanged(e, col.id, ShopUpdateType.StoreTemplateId)}
            styles={this.customStyles}
            isClearable={false}
          />
        </td>
        <td key={'navigation{col.id}'}>
          <Select
            name='navigationEdit'
            value={this.props.navigations?.find(x => x.value === col.menuId)}
            options={this.props.navigations}
            onChange={(e: any) => this.onNavigationChanged(e, col.id, ShopUpdateType.MenuId)}
            styles={this.customStyles}
            isClearable={false}
          />
        </td>
        <td key={'products{col.id}'}>
          <Select
            name='productEdit'
            value={this.props.products?.find(x => x.value === col.productFilterId)}
            options={this.props.products}
            onChange={(e) => this.onNavigationChanged(e, col.id, ShopUpdateType.ProductFilterId)}
            styles={this.customStyles}
            isClearable={false}
          />
        </td>
        <td key={'printer{col.id}'}>
          <Select
            name='printerEdit'
            value={this.props.printerTemplates?.find(x => x.value === col.printerTemplateId)}
            options={this.props.printerTemplates}
            onChange={(e: any) => this.onNavigationChanged(e, col.id, ShopUpdateType.PrinterTemplateId)}
            styles={this.customStyles}
            isClearable={false}
          />
        </td>
        <td key={'customers{col.id}'}>
          <Select
            name='customerEdit'
            value={this.props.customerTemplates?.find(x => x.value === col.customerTemplateId)}
            options={this.props.customerTemplates}
            onChange={(e: any) => this.onNavigationChanged(e, col.id, ShopUpdateType.CustomerTemplateId)}
            styles={this.customStyles}
            isClearable={false}
          /> 
        </td>
        <td className='text-right'>
          <Button
            variant='primary'
            onClick={(e) => this.onSaveClick(col, this.props.tenantId, e)}
            disabled={this.props.templateSaving}
          >
            Save {this.props.templateSaving && inlineSpinner}
          </Button>
        </td>

      </tr>
    ) : (
      <tr key={col.id} onClick={(e) => this.onRowClick(col.id, e)}>
        <td key={`name${col.id}`}>{col.name}</td>
        <td key={'general{col.id}'}>{col.generalTemplate}</td>
        <td key={'navigation{col.id}'}>{col.navigation} </td>
        <td key={'products{col.id}'}>{col.products} </td>
        <td key={'printers{col.id}'}>{col.printerTemplate} </td>
        <td key={'customers{col.id}'}>{col.customers} </td>
        <td className='text-right' />
      </tr>)
      : false
  }
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(NavigationSelect)
