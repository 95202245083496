import React from 'react'
import { connect } from 'react-redux'
import { IDispatchProps as ISubFilterDispatchProps, TSubFilterItem, ITSubFilterOwnProps } from './../SubFilter'
import { subFilterMatch } from '../../actions/storeActions'

const mapDispatchToSubItemProps = (dispatch) => {
  return {
    subFilterMatch: (id, all) => dispatch(subFilterMatch(id, all))
  }
}
export default connect<{}, ISubFilterDispatchProps, ITSubFilterOwnProps >(null, mapDispatchToSubItemProps)(TSubFilterItem)