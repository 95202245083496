import templateApi from './../api/TemplatesApi'
import { Template, ShopUpdateType } from './../types/Template'
import * as Toast from '../utils/Toast'

export const TEMPLATE_ADD = 'TEMPLATE_ADD'
export const TEMPLATES_LOADING = 'TEMPLATES_LOADING'
export const TEMPLATES_LOADED = 'TEMPLATES_LOADED'
export const TEMPLATES_NAVIGATION_LOADED = 'TEMPLATES_NAVIGATION_LOADED'
export const TEMPLATES_PRODUCT_FILTER_LOADED = 'TEMPLATES_PRODUCT_FILTER_LOADED'
export const TEMPLATES_PRINTER_TEMPLATES_LOADED = 'TEMPLATES_PRINTER_TEMPLATES_LOADED'
export const TEMPLATES_GENERAL_TEMPLATES_LOADED = 'TEMPLATES_GENERAL_TEMPLATES_LOADED'
export const TEMPLATES_CUSTOMER_TEMPLATES_LOADED = 'TEMPLATES_CUSTOMER_TEMPLATES_LOADED'
export const TEMPLATE_SAVING = 'TEMPLATE_SAVING'
export const TEMPLATE_SAVED = 'TEMPLATE_SAVED'
export const TEMPLATE_LOADED = 'TEMPLATE_LOADED'

export const TEMPLATES_UPDATED = 'TEMPLATES_UPDATED'
export const TEMPLATE_SHOW_EDITOR = 'TEMPLATE_SHOW_EDITOR'
export const TEMPLATE_API_STARTED = 'TEMPLATE_API_STARTED'
export const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE'
export const TEMPLATE_NAVIGATION_SELECTED = 'TEMPLATE_NAVIGATION_SELECTED'
export const TEMPLATE_BULK_APPLY_STARTED = 'TEMPLATE_BULK_APPLY_STARTED'
export const TEMPLATE_BULK_APPLIED_FINISHED = 'TEMPLATE_BULK_APPLIED_FINISHED'
export const BULK_TEMPLATE_NAVIGATION_TYPE_SELECTED = 'BULK_TEMPLATE_NAVIGATION_TYPE_SELECTED'
export const BULK_TEMPLATE_CHECKED_UNCHECKED = 'BULK_TEMPLATE_CHECKED_UNCHECKED'
export const BULK_TEMPLATE_OPEN_MODAL = 'BULK_TEMPLATE_OPEN_MODAL'

export const SET_CURRENT_TEMPLATE_NAME = 'SET_CURRENT_TEMPLATE_NAME'
export const CLEAR_CURRENT_TEMPLATE_NAME = 'CLEAR_CURRENT_TEMPLATE_NAME'

export function templateApiSaveStarted () {
  return { type: TEMPLATE_SAVING }
}

export function templateOpenModal () {
  return { type: BULK_TEMPLATE_OPEN_MODAL }
}

export function templateBulkCheckedUnchecked (checked: boolean, id: string = '-1') {
  return { type: BULK_TEMPLATE_CHECKED_UNCHECKED, id, checked }
}

export function templateApplyBulkStarted () {
  return { type: TEMPLATE_BULK_APPLY_STARTED }
}

export function templateApplyBulkFinished () {
  return { type: TEMPLATE_BULK_APPLIED_FINISHED }
}

export function templateApiSaved (result: boolean) {
  return { type: TEMPLATE_SAVED, result }
}
export function templatesLoading () {
  return { type: TEMPLATES_LOADING }
}

export function templateShowEditor (id: any) {
  return { type: TEMPLATE_SHOW_EDITOR, id}
}

export function clearTemplates () {
  return { type: CLEAR_TEMPLATE }
}
export function bulkNavigationTypeSelected (value: any) {
  return { type: BULK_TEMPLATE_NAVIGATION_TYPE_SELECTED, value }
}
export function saveTemplate (template: Template, tenantId: string) {
  return (dispatch: any) => {
    dispatch(templateApiSaveStarted())
    return templateApi.saveTemplate(template, tenantId).then((response) => {
      try {
        if (response.id) {
          // dispatch(saveMenuSuccess(response))
          Toast.showSuccessToast('Templates applied')
          dispatch(loadTemplates(tenantId))

        } else if (response.error) {
          dispatch(templateApiSaved(false))
          Toast.showErrorToast(response.error)
        } else {
          // dispatch(saveMenuError(response))
          dispatch(templateApiSaved(false))
          Toast.showErrorToast(response)
        }
      } catch (e) {
        // do nothing
      }
    }).catch((p) => {
      dispatch(templateApiSaved(false))
      Toast.showErrorToast(p)
    })
  }
}

export function templateLoaded (template: any) {
  return { type: TEMPLATES_LOADED, template }
}
export function templatesLoaded (templates: any) {
  return { type: TEMPLATES_LOADED, templates }
}
export function templatesNavigationLoaded (navigations: any) {
  return { type: TEMPLATES_NAVIGATION_LOADED, navigations }
}

export function templatesProductFilterLoaded (products: any) {
  return { type: TEMPLATES_PRODUCT_FILTER_LOADED, products }
}

export function templatesPrinterTemplatesLoaded (printerTemplates: any) {
  return { type: TEMPLATES_PRINTER_TEMPLATES_LOADED, printerTemplates }
}

export function templatesGeneralTemplatesLoaded (generalTemplates: any) {
  return { type: TEMPLATES_GENERAL_TEMPLATES_LOADED, generalTemplates }
}

export function templatesCustomerTemplatesLoaded (customerTemplates: any) {
  return { type: TEMPLATES_CUSTOMER_TEMPLATES_LOADED, customerTemplates }
}

export function templatesNavigationSelected (val: any, id: any, shopUpdateType: ShopUpdateType) {
  return { type: TEMPLATE_NAVIGATION_SELECTED, val, id, shopUpdateType }
}

export function TemplateAdd (id: any) {
  return { type: TEMPLATE_ADD, Id: id }
}
export function loadTemplates (tenantId: any) {
  return (dispatch: any) => {
    dispatch(templatesLoading())
    return templateApi.loadTemplates(tenantId).then((response) => {
      if (response) {
        dispatch(templatesLoaded(response.templates))
        dispatch(templatesNavigationLoaded(response.menus))
        dispatch(templatesProductFilterLoaded(response.productFilters))
        dispatch(templatesPrinterTemplatesLoaded(response.printerTemplates))
        dispatch(templatesGeneralTemplatesLoaded(response.generalTemplates))
        dispatch(templatesCustomerTemplatesLoaded(response.customerTemplates))
      }
    }).catch(() => {
      dispatch(templatesLoaded([]))
      dispatch(templatesNavigationLoaded([]))
      dispatch(templatesProductFilterLoaded([]))
      dispatch(templatesPrinterTemplatesLoaded([]))
      dispatch(templatesGeneralTemplatesLoaded([]))
      dispatch(templatesCustomerTemplatesLoaded([]))
    })
  }
}
export function applyBulkTemplate (shopIds: any, update: any, updateId: any, tenantId: any) {
  return (dispatch: any) => {
    dispatch(templateApplyBulkStarted())
    return templateApi.applyBulkTemplate(shopIds, update, updateId, tenantId).then((response) => {
      if (response) {
        Toast.showSuccessToast('Template Applied')
        dispatch(loadTemplates(tenantId))
      }
    }).catch(() => {
      Toast.showErrorToast('Apply bulk template failed')
      dispatch(templateApplyBulkFinished())
    })
  }
}

export function setCurrentTemplateName (name: string) {
  return (dispatch: any) => {
    dispatch({ type: SET_CURRENT_TEMPLATE_NAME, name})
  }
}

export function clearCurrentTemplateName () {
  return (dispatch: any) => {
    dispatch({ type: CLEAR_CURRENT_TEMPLATE_NAME })
  }
}
