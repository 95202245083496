import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { POSSettings } from '../../types/POSSettings'
import { StoreTemplate } from '../../types/StoreTemplate'

import Select from 'react-select'
import ToggleSwitch from '../common/ToggleSwitch'

interface OwnProps {
  onChange: (storeTemplate: StoreTemplate) => void
   storeTemplate: StoreTemplate
   title: string
}

interface DispatchProps {
}

interface StateProps {
  requireLoginForEachTransaction: boolean
  autoLockDuration: number
  isConncessionStore: boolean
}

type Props = StateProps & OwnProps

interface State {
   storeTemplate: StoreTemplate
}

class Access extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      storeTemplate: this.props.storeTemplate === null ? new StoreTemplate(this.props.storeTemplate) : this.props.storeTemplate
    }
  }

  savePosSettings = (posSettings: POSSettings) => {
    const template = {
      ...this.state.storeTemplate,
      posSettings: { ...posSettings }
    }
    this.setState({ ...this.state, storeTemplate: template })
    this.props.onChange(template)
  }

  changeAutoLockDownDuration = (val) => {
    const posSettings = {
      ...this.state.storeTemplate.posSettings,
      autoLockDuration: val.value
    }
    this.savePosSettings(posSettings)
  }

  saveIsConcessionStore = (isConcessionStore: boolean) => {
    const template = {
      ...this.state.storeTemplate,
      isConcessionStore: isConcessionStore
    }
    this.setState({ ...this.state, storeTemplate: template })
    this.props.onChange(template)
  }

  changeRequireLoginForEachTransaction = () => {
    const posSettings = {
      ...this.state.storeTemplate.posSettings,
      requireLoginForEachTransaction: !this.state.storeTemplate.posSettings.requireLoginForEachTransaction
    }
    this.savePosSettings(posSettings)
  }

  changeIsConcessionStore = () => {
    const isConcessionStore = !this.state.storeTemplate.isConcessionStore
    this.saveIsConcessionStore(isConcessionStore)
  }

  render () {
    const roles = [
      { value: 60, label: '1 minute' },
      { value: 120, label: '2 minutes' },
      { value: 180, label: '3 minutes' },
      { value: 240, label: '4 minutes' },
      { value: 300, label: '5 minutes' },
      { value: 600, label: '10 minutes' },
      { value: 7200, label: '2 hours' }
    ]
    let duration = this.state.storeTemplate.posSettings.autoLockDuration
    if (duration === 0) {
      duration = 7200
    }
    return (
      <div>
        <h4>
          {this.props.title}
        </h4>
        <div style={{ display: 'flex' }}>
          <div>
            <h5 className='text-info'>Require login for each transaction</h5>
            <p>User must enter valid PIN for each transaction</p>
          </div>
          <div style={{ width: 50, marginTop: 20, marginLeft: 100 }}>
            <ToggleSwitch
              checked={this.state.storeTemplate.posSettings.requireLoginForEachTransaction}
              disabled={false}
              onChange={() => this.changeRequireLoginForEachTransaction()}
              checkedIconText={''}
              uncheckedIconText={''}
              switchStyle={{ alignSelf: 'center' }}
            />
          </div>
        </div>
        <div style={{ width: 300 }}>
          <div>
            <h5 className='text-info'>Auto-lock duration</h5>
            <p>Automatically lock device after the set amount of time elapses. User must enter PIN to unlock</p>
          </div>
          <div>
            <Select
              name='role'
              value={{ value: duration, label: duration.toString() }}
              options={roles}
              onChange={this.changeAutoLockDownDuration}
              isClearable={false}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{width: 330}}>
            <h5 className='text-info'>Concession Store</h5>
            <p>Disables sales functionality for concession stores</p>
          </div>
          <div style={{ width: 50, marginTop: 20, marginLeft: 100 }}>
            <ToggleSwitch
              checked={this.state.storeTemplate.isConcessionStore}
              disabled={false}
              onChange={() => this.changeIsConcessionStore()}
              checkedIconText={''}
              uncheckedIconText={''}
              switchStyle={{ alignSelf: 'center', width: 50 }}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect<StateProps, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(Access as any)