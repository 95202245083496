export default class Feature {
  id: string = ''
  name: string = ''
  isEnabled: boolean = false
  showInFeatureList: boolean = false
  isInDevelopment: boolean = false
  changed: boolean = false
  constructor (feature?: Feature) {
    if (feature) {
      this.id = feature.id
      this.name = feature.name
      this.isEnabled = feature.isEnabled
      this.showInFeatureList = feature.showInFeatureList
      this.isInDevelopment = feature.isInDevelopment
      this.changed = feature.changed
    }
  }
}
