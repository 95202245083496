import { store } from '../index'

export const featureIsEnabledForSelectedTenant = (featureId: FeatureId) => {
  const feature = store.getState().session?.selectedTenantId?.features.find((x: any) => x.id === featureId)
  if (feature)
    return feature.isEnabled
  else
    return false
}

export enum FeatureId {
  OrderPacking = 2,
  Rewards = 3,
  Transfers = 4,
  ZipPayment = 5,
  StockTransfersOut = 6,
  MultiStoreSelection = 7,
  AusPost = 8,
  Returns = 9,
  FloorToDoor = 10,
  AdhocReceipts = 11
}
