import React from 'react'
import { ClipLoader } from 'react-spinners'
import { Button } from 'react-bootstrap'
// Ace text editor
import AceEditor from 'react-ace'
import 'brace/mode/handlebars'
import 'brace/theme/textmate'
import { dataPoint } from '../../types/DataPoint'
import TextInput from '../common/TextInput'
import ConfirmModal from '../common/ConfirmModal'

const style = {
  minWidth: '100%',
  maxWidth: '100%',
  height: '620px'
}

interface DispatchProps {
}

interface OwnProps {
    type: string
    htmlBody: string
    default: string
    emailFrom: string
    emailSubject: string
    changeFormat: (text: string) => void
    changeEmailSubject: (text: string) => void
    changeEmailFrom: (text: string) => void
    dataPoints: dataPoint[]
}

interface State {
    htmlBody: string
    emailFrom: string
    emailSubject: string
    showConfirmModal: boolean
}

type Props = DispatchProps & OwnProps

export default class EmailTemplateEditor extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      htmlBody: '',
      emailFrom: '',
      emailSubject: '',
      showConfirmModal: false
    }
  }

  componentWillMount () {
    this.setState({ ...this.state, htmlBody: this.props.htmlBody, emailFrom: this.props.emailFrom, emailSubject: this.props.emailSubject })
  }

  showConfirmModal = () => {
    this.setState({ ...this.state, showConfirmModal: true })
  }

  hideConfirmModal = () => {
    this.setState({ ...this.state, showConfirmModal: false })
  }

  generatePoints = (points: dataPoint[], rootNode: boolean, id: number) => {
    // Convert the data into a format where we render one tag per point
    let convertedPoints: dataPoint[] = []
    points.forEach(point => {
      convertedPoints.push({ code: point.code, text: point.text })
      if (point.subPoints)
        convertedPoints.push({ subPoints: point.subPoints })
    })

    return <ul key={`${this.props.type}pointUl${id}`} className={rootNode ? 'list-unstyled' : null}>
      {convertedPoints.map((point, index) => point.subPoints ? this.generatePoints(point.subPoints, false, index) : this.renderPoint(point))}
    </ul>
  }

  renderPoint = (point: dataPoint) =>
    <li key={`${this.props.type}point${point.code}`}><code>{point.code}</code>{point.text ? ' - ' + point.text : ''}</li>

  changeFormat = (text: string) => {
    this.setState({ ...this.state, htmlBody: text })
    this.props.changeFormat(text)
  }
  changeEmailSubject = (text: string) => {
    this.setState({ ...this.state, emailSubject: text })
    this.props.changeEmailSubject(text)
  }
  changeEmailFrom = (text: string) => {
    this.setState({ ...this.state, emailFrom: text })
    this.props.changeEmailFrom(text)
  }

  loadDefault = (event) => {
    event.preventDefault()
    this.setState({...this.state, htmlBody: this.props.default, showConfirmModal: false})
    this.props.changeFormat(this.props.default)
  }

  renderModal = () => {
    return (
      <ConfirmModal
        show={this.state.showConfirmModal}
        onHide={this.hideConfirmModal}
        onCancel={this.hideConfirmModal}
        onConfirm={this.loadDefault}
        title={'Load default template'}
        message={'Are you sure you want to load the default template? You will lose any changes you made.'}
      />
    )
  }

  render () {
    const spinner = <ClipLoader size={40} />

    return (
      <div className='row'>
        <div className='col-md-10'>
          <div className='grid' style={{ margin: 10 }}>
            <div className='row'>
              <TextInput
                maxLength={256}
                name='emailFrom'
                label='From Address'
                value={this.state.emailFrom}
                onChange={(value) => this.changeEmailFrom(value.target.value)}
                style={{ minWidth: 100 }}
              />
            </div>
            <div className='row'>
              <TextInput
                maxLength={256}
                name='emailSubject'
                label='Subject'
                value={this.state.emailSubject}
                onChange={(value) => this.changeEmailSubject(value.target.value)}
                style={{ minWidth: 100 }}
              />
            </div>
          </div>
        </div>
        <div className='col-md-8'>
          <h3>Body Html Format</h3>
          <AceEditor
            ref='editor'
            mode='handlebars'
            theme='textmate'
            name='scriptText'
            fontSize={15}
            width='100%'
            focus={false}
            height={style.height.toString()}
            showPrintMargin={false}
            value={this.state.htmlBody}
            onChange={(text) => this.changeFormat(text)}
            defaultValue={this.props.default}
          />
          <div style={{position: 'absolute', top: 24, right: 50}}>
            <Button
              variant='primary'
              style={{ marginLeft: 5, textAlign: 'center', padding: 5, width: 32 }}
              onClick={(event) => {
                event.stopPropagation()
                this.showConfirmModal()
              }}
              className='pull-left'
            >
              <i className='material-icons'>restore_page</i>
            </Button>
          </div>
        </div>
        <div className='col-md-4'>
          <h3>
            <p>Data available for use</p>
          </h3>
          <div style={{ ...style, overflowY: 'scroll' }}>
            {this.generatePoints(this.props.dataPoints, true, -1)}
          </div>
        </div>
        {this.renderModal()}
      </div>)
  }
}