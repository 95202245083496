import React, { useState, useEffect } from 'react'
import { showErrorToast } from '../../utils/Toast'

const ErrorHandler = ({ children }) => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const handleError = (error) => {
      setHasError(true)
      showErrorToast('An unexpected error occurred.')
    }

    // Listen to unhandled errors globally
    window.addEventListener('error', (event) => {
      handleError(event.error)
    })

    window.addEventListener('unhandledrejection', (event) => {
      handleError(event.reason)
    })

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener('error', handleError)
      window.removeEventListener('unhandledrejection', handleError)
    }
  }, []) // Empty dependency array ensures this effect runs once

  if (hasError) {
    return null // No fallback UI, just a toast notification
  }

  return children
}

export default ErrorHandler
