import { ANZ_BLADE_INFO_LOADING, ANZ_BLADE_INFO_LOADED, SET_ANZ_BLADE_CURRENT_VERSION } from '../actions/appInfoActions'
import initialState from './initialState'

export default function deviceReducer (state = initialState.appInfo, action: any) {
  switch (action.type) {
    case ANZ_BLADE_INFO_LOADING:
      return {...state, isLoading: true}
    case ANZ_BLADE_INFO_LOADED:
      let activityLog: any = []
      action.activityLog.map( (x: string) => {
        activityLog.push(x)
      })
      return {...state, isLoading: false, activityLog: activityLog, currentVersion: action.currentVersion}
    default:
      return state
  }
}
