import * as $ from 'jquery'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import { ClipLoader } from 'react-spinners'
import { PrinterTemplate } from '../../types/PrinterTemplate'
import * as Toast from '../../utils/Toast'
import { deleteAsync, putAsync, postAsync, getAsync } from '../../api/httpClient'
import {
  defaultSaleReceiptFormat, defaultSaleReturnsReceiptFormat, defaultDigitalSaleReceiptFormat,
  defaultDigitalSaleReturnsReceiptFormat,
  defaultOrderReceiptFormat, defaultDigitalEmailBodyFormat, defaultDigitalEmailReturnsBodyFormat,
  defaultEmailSubject, defaultReturnsEmailSubject, defaultTransferOutReceiptFormat
} from './DefaultPrinterFormats'
import TemplateDetailHeader from '../TemplateDetailHeader'
import DocumentTemplateEditor from './DocumentTemplateEditor'
import EmailTemplateEditor from './EmailTemplateEditor'
import { receiptDataPoints, returnsReceiptDataPoints, extraOrderReceiptDataPoints, logoDataPoint,
  physicalReceiptDataPoints, transferOutDataPoints } from './DataPoints'
import { featureIsEnabledForSelectedTenant, FeatureId } from '../../utils/FeatureFlags'
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const style = {
  minWidth: '100%',
  maxWidth: '100%',
  height: '620px'
}

const PrinterTemplateDetails = (props) => {
  const [template, setTemplate] = useState(new PrinterTemplate())
  const [templateLoading, setTemplateLoading] = useState(false)
  const [templateSaving, setTemplateSaving] = useState(false)
  const [templateDeleting, setTemplateDeleting] = useState(false)
  const [activeFormat, setActiveFormat] = useState('')
  const [activeTopTab, setActiveTopTab] = useState('receipt')
  const nodeRef = useRef(null)
  const navigate = useNavigate()
  const { templateId } = useParams()
  const tenantIdRef = useRef(props.tenantId)
  const templateLoadingRef = useRef(props.templateLoading)

  useEffect(() => {
    if (props.tenantId === undefined || props.tenantId === '' || props.tenantId === null) {
      navigate('/')
    } else if (templateId === 'new') {
      let newTemplate = new PrinterTemplate()
      newTemplate.tenantId = props.tenantId
      newTemplate.companyId = props.companyId
      newTemplate.modifiedById = props.userId
      newTemplate.modifiedByName = props.userName
      newTemplate.receiptFormat = defaultSaleReceiptFormat
      newTemplate.digitalReceiptFormat = defaultDigitalSaleReceiptFormat
      newTemplate.digitalEmailFormat = defaultDigitalEmailBodyFormat
      newTemplate.transferOutReceiptFormat = defaultTransferOutReceiptFormat
      newTemplate.emailFrom = ''
      newTemplate.emailSubject = defaultEmailSubject
      newTemplate.orderReceiptFormat = defaultOrderReceiptFormat
      newTemplate.returnsReceiptFormat = defaultSaleReturnsReceiptFormat
      newTemplate.returnsDigitalReceiptFormat = defaultDigitalSaleReturnsReceiptFormat
      newTemplate.returnsDigitalEmailFormat = defaultDigitalEmailReturnsBodyFormat
      newTemplate.returnsEmailFrom = ''
      newTemplate.returnsEmailSubject = defaultReturnsEmailSubject
      setTemplate(newTemplate)
    } else if (templateId) {
      loadTemplate()
    }
  }, [])

  useEffect(() => {
    if (props.template) {
      setTemplate({ ...props.template })
    }

  }, [props.template])

  useEffect(() => {
    if (props.tenantId !== undefined && props.tenantId !== '' && props.tenantId !== null && props.tenantId !== tenantIdRef.current) {
      tenantIdRef.current = props.tenantId
      navigate('/receipts')
    }
  }, [props.tenantId])

  useEffect(() => {
    const node = nodeRef.current
    if (node && !templateLoading && templateLoadingRef.current) {
      templateLoadingRef.current = false
      setTimeout(() => $(node).find('.ace_scrollbar-v')?.scrollTop(0), 100)
    }
  }, [templateLoading])

  const loadTemplate = () => {
    setTemplateLoading(true)
    let requestParams = {
      url: `/api/tenant/${props.tenantId}/printerTemplate/${templateId}`,
      succeededCallback: (template) => {
        setTemplate(template)
        setTemplateLoading(false)
      },
      failedCallback: (error) => {
        Toast.showErrorToast(error)
        navigate('/receipts')
      }
    }
    getAsync(requestParams)
  }

  const saveTemplate = (templateIn: PrinterTemplate) => {
    setTemplateSaving(true)
    let requestParams = {
      url: '',
      succeededCallback: (template) => {
        Toast.showSuccessToast('Template saved')
        setTemplate(template)
        setTemplateSaving(false)
      },
      failedCallback: (error) => {
        Toast.showErrorToast(error)
        setTemplateSaving(false)
      }
    }

    if (templateIn.id) {
      requestParams.url = `/api/tenant/${props.tenantId}/printerTemplate/${templateIn.id}`
      putAsync(templateIn, requestParams)
    } else {
      requestParams.url = `/api/tenant/${props.tenantId}/printerTemplate`
      postAsync(templateIn, requestParams)
    }
  }

  const deleteTemplate = () => {
    setTemplateDeleting(true)
    let requestParams = {
      url: `/api/tenant/${props.tenantId}/printerTemplate/${template.id}`,
      succeededCallback: () => {
        Toast.showSuccessToast('Template deleted')
        navigate('/receipts')
      },
      failedCallback: (error) => {
        setTemplateDeleting(false)
        Toast.showErrorToast(error)
      }
    }
    deleteAsync(requestParams)
  }

  const onSave = (name) => {
    const newTemplate = { ...template, name }
    setTemplate(newTemplate)
    saveTemplate(newTemplate)
  }

  const onDelete = () => {
    deleteTemplate()
  }

  const selectActiveFormat = (key) => {
    setActiveFormat(key)
  }

  const selectActiveTopTab = (key) => {
    setActiveTopTab(key)
  }

  const spinner = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
      <ClipLoader size={40} />
    </div>
  )
  let orderReceiptNavItem = null
  if (featureIsEnabledForSelectedTenant(FeatureId.OrderPacking)) {
    orderReceiptNavItem = (
      <Nav.Link eventKey='orderReceipt'>
        Customer Order Docket
      </Nav.Link>
    )
  }
  let returnsReceiptNavItem = null
  if (featureIsEnabledForSelectedTenant(FeatureId.Returns)) {
    returnsReceiptNavItem = (
      <Nav.Link eventKey='returnsReceipt'>
        Sales Returns Docket
      </Nav.Link>
    )
  }
  let transfersOutNavItem = null
  if (featureIsEnabledForSelectedTenant(FeatureId.StockTransfersOut)) {
    transfersOutNavItem = (
      <Nav.Link eventKey='transferOutReceipt'>
        Transfer Out Docket
      </Nav.Link>
    )
  }
  return (
    <div className='container-fluid' ref={nodeRef}>
      {
        templateLoading ? spinner :
          <div>
            <TemplateDetailHeader
              title={'Docket'}
              onSave={onSave}
              onDelete={onDelete}
              name={template.name}
              hideDeleteButton={template.id === undefined}
              templateSaving={templateSaving}
              templateDeleting={templateDeleting}
              listUrl='receipts'
            />
            <hr />
            <Tab.Container id='tabs-with-dropdown' defaultActiveKey='receipt' onSelect={selectActiveTopTab}>
              <Row className='clearfix'>
                <div style={{ display: 'flex', flex: '1', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 15, marginRight: 15 }}>
                  <div style={{ display: 'flex', flex: '1', flexDirection: 'row' }}>
                    <Nav variant='tabs' activeKey='receipt'>
                      <Nav.Link eventKey='receipt'>
                        Sales Docket
                      </Nav.Link>
                      {returnsReceiptNavItem}
                      {orderReceiptNavItem}
                      {transfersOutNavItem}
                    </Nav>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {(activeTopTab === 'receipt' && activeFormat !== 'print') &&
                      <a href='https://handlebarsjs.com/guide/expressions.html' target='_blank' rel='noreferrer'>
                        <Button variant='primary'>Guide</Button>
                      </a>
                    }
                    {!(activeTopTab === 'receipt' && activeFormat !== 'print') &&
                      <a href='/guide/receiptformat' target='_blank'>
                        <Button variant='primary'>Guide</Button>
                      </a>
                    }
                  </div>
                </div>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey='receipt'>
                      <Tab.Container id='tabs-Template' defaultActiveKey='print' onSelect={selectActiveFormat}>
                        <Row className='clearfix'>
                          <div style={{
                            display: 'flex', flex: '1', flexDirection: 'row',
                            justifyContent: 'space-between', marginLeft: 15, marginRight: 15
                          }}
                          >
                            <div style={{ display: 'flex', flex: '1', flexDirection: 'row', paddingTop: 30,
                              paddingBottom: 30 }}
                            >
                              <Nav variant='tabs'>
                                <Nav.Link eventKey='print'>
                                  Print
                                </Nav.Link>
                                <Nav.Link eventKey='digital'>
                                  Digital
                                </Nav.Link>
                                <Nav.Link eventKey='emailSetup'>
                                  Email Setup
                                </Nav.Link>
                              </Nav>
                            </div>
                          </div>
                          <Col sm={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey='print'>
                                <DocumentTemplateEditor
                                  type='orderPrint'
                                  template={template.receiptFormat}
                                  default={defaultSaleReceiptFormat}
                                  change={(text) => setTemplate( { ...template, receiptFormat: text })}
                                  dataPoints={[...extraOrderReceiptDataPoints, ...logoDataPoint,
                                    ...receiptDataPoints, ...physicalReceiptDataPoints]}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey='digital'>
                                <DocumentTemplateEditor
                                  type='digital'
                                  template={template.digitalReceiptFormat}
                                  default={defaultDigitalSaleReceiptFormat}
                                  change={(text) => setTemplate({ ...template, digitalReceiptFormat: text })}
                                  dataPoints={[...logoDataPoint, ...receiptDataPoints]}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey='emailSetup'>
                                <EmailTemplateEditor
                                  type='digital'
                                  htmlBody={template.digitalEmailFormat}
                                  default={defaultDigitalEmailBodyFormat}
                                  emailFrom={template.emailFrom}
                                  emailSubject={template.emailSubject}
                                  changeFormat={(text) => setTemplate({ ...template, digitalEmailFormat: text })}
                                  changeEmailSubject={(text) => setTemplate({ ...template, emailSubject: text })}
                                  changeEmailFrom={(text) => setTemplate({ ...template, emailFrom: text })}
                                  dataPoints={receiptDataPoints}
                                />
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey='returnsReceipt'>                      
                      <Tab.Container id='tabs-Template' defaultActiveKey='print' onSelect={selectActiveFormat}>
                        <Row className='clearfix'>
                          <div style={{
                            display: 'flex', flex: '1', flexDirection: 'row',
                            justifyContent: 'space-between', marginLeft: 15, marginRight: 15
                          }}
                          >
                            <div style={{ display: 'flex', flex: '1', flexDirection: 'row', paddingTop: 30,
                              paddingBottom: 30 }}
                            >
                              <Nav variant='tabs'>
                                <Nav.Link eventKey='print'>
                                  Print
                                </Nav.Link>
                                <Nav.Link eventKey='digital'>
                                  Digital
                                </Nav.Link>
                                <Nav.Link eventKey='emailSetup'>
                                  Email Setup
                                </Nav.Link>
                              </Nav>
                            </div>
                          </div>
                          <Col sm={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey='print'>
                                <DocumentTemplateEditor
                                  type='print'
                                  template={template.returnsReceiptFormat}
                                  default={defaultSaleReturnsReceiptFormat}
                                  change={(text) => setTemplate({ ...template, returnsReceiptFormat: text })}
                                  dataPoints={[...logoDataPoint, ...returnsReceiptDataPoints, ...physicalReceiptDataPoints]}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey='digital'>
                                <DocumentTemplateEditor
                                  type='digital'
                                  template={template.returnsDigitalReceiptFormat}
                                  default={defaultDigitalSaleReturnsReceiptFormat}
                                  change={(text) => setTemplate({ ...template, returnsDigitalReceiptFormat: text })}
                                  dataPoints={[...logoDataPoint, ...returnsReceiptDataPoints]}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey='emailSetup'>
                                <EmailTemplateEditor
                                  type='digital'
                                  htmlBody={template.returnsDigitalEmailFormat}
                                  default={defaultDigitalEmailReturnsBodyFormat}
                                  emailFrom={template.returnsEmailFrom}
                                  emailSubject={template.returnsEmailSubject}
                                  changeFormat={(text) => setTemplate({ ...template, returnsDigitalEmailFormat: text })}
                                  changeEmailSubject={(text) => setTemplate({ ...template, returnsEmailSubject: text })}
                                  changeEmailFrom={(text) => setTemplate({ ...template, returnsEmailFrom: text })}
                                  dataPoints={returnsReceiptDataPoints}
                                />
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey='orderReceipt'>
                      <Tab.Container id='tabs-Template' defaultActiveKey='print' onSelect={selectActiveFormat}>
                        <Row className='clearfix'>
                          <div style={{
                            display: 'flex', flex: '1', flexDirection: 'row',
                            justifyContent: 'space-between', marginLeft: 15, marginRight: 15
                          }}
                          >
                            <div style={{ display: 'flex', flex: '1', flexDirection: 'row', paddingTop: 30,
                              paddingBottom: 30 }}
                            >
                              <Nav variant='tabs'>
                                <Nav.Link eventKey='print'>
                                  Print
                                </Nav.Link>
                              </Nav>
                            </div>
                          </div>
                          <Col sm={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey='print'>
                                <DocumentTemplateEditor
                                  type='orderPrint'
                                  template={template.orderReceiptFormat}
                                  default={defaultOrderReceiptFormat}
                                  change={(text) => setTemplate({ ...template, orderReceiptFormat: text })}
                                  dataPoints={[...extraOrderReceiptDataPoints, ...logoDataPoint,
                                    ...receiptDataPoints, ...physicalReceiptDataPoints]}
                                />
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey='transferOutReceipt'>
                      <Tab.Container id='tabs-Template' defaultActiveKey='print' onSelect={selectActiveFormat}>
                        <Row className='clearfix'>
                          <div style={{
                            display: 'flex', flex: '1', flexDirection: 'row',
                            justifyContent: 'space-between', marginLeft: 15, marginRight: 15
                          }}
                          >
                            <div style={{ display: 'flex', flex: '1', flexDirection: 'row', paddingTop: 30,
                              paddingBottom: 30 }}
                            >
                              <Nav variant='tabs'>
                                <Nav.Link eventKey='print'>
                                  Print
                                </Nav.Link>
                              </Nav>
                            </div>
                          </div>
                          <Col sm={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey='print'>
                                <DocumentTemplateEditor
                                  type='transferOutReceipt'
                                  template={template.transferOutReceiptFormat}
                                  default={defaultTransferOutReceiptFormat}
                                  change={(text) => setTemplate({ ...template, transferOutReceiptFormat: text })}
                                  dataPoints={[...logoDataPoint, ...transferOutDataPoints]}
                                />
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
      }
    </div>
  )
}

function mapStateToProps (state) {
  return {
    tenantId: state.session.tenantId,
    companyId: state.session.companyId,
    userId: state.session.userId,
    userName: state.session.name
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PrinterTemplateDetails)