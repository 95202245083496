import React from 'react'
import { connect } from 'react-redux'
import { Filter, Rule, RuleChild, SubFilter, TagGroup } from '../../types/Menu'

import {
  DispatchProps, OwnProps,
  Rules as StoreRule, StateProps
} from './../Rules'

import {
  addRule, changeRuleOperator, changeRuleTagGroup, changeRuleTags, deleteRule
} from '../../actions/storeActions'

const mapDispatchToStoreRuleProps = (dispatch): DispatchProps => {
  return {
    addRule: (subMenuId) => dispatch(addRule(subMenuId)),
    changeRuleOperator: (ruleId, operator) => dispatch(changeRuleOperator(ruleId, operator)),
    changeRuleTagGroup: (ruleId, tagGroup) => dispatch(changeRuleTagGroup(ruleId, tagGroup)),
    changeRuleTags: (ruleId, tags) => dispatch(changeRuleTags(ruleId, tags)),
    deleteRule: (id) => dispatch(deleteRule(id))
  }
}

function mapStateToStoreRuleProps (state): StateProps {
  return {
    rules: state.store.stockEnquiryFilter.rules,
    ruleChilds: state.store.stockEnquiryFilter.ruleChilds,
    tagGroups: state.store.stockEnquiryFilter.tagGroups
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToStoreRuleProps,
  mapDispatchToStoreRuleProps
)(StoreRule)