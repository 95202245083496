import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import {
  loadAllUsers, userLoaded
} from '../actions/userActions'
import withRouter from './Utils/WithRouter'
import { logoutUser } from '../actions/sessionActions'
import Table from './common/Table'
import EmptyState from './common/EmptyState'

const columnsSuperuser = [
  { key: 'userName', name: 'User Name', sortable: true, resizable: true },
  { key: 'email', name: 'Email', sortable: true, resizable: true },
  { key: 'role', name: 'Role', sortable: true, resizable: true },
  { key: 'landlord', name: 'Landlord', sortable: true, resizable: true },
  { key: 'companiesJoined', name: 'Companies', sortable: true, resizable: true }
]

const columnsAdmin = [
  { key: 'userName', name: 'User Name', sortable: true, resizable: true },
  { key: 'email', name: 'Email', sortable: true, resizable: true },
  { key: 'role', name: 'Role', sortable: true, resizable: true },
  { key: 'companiesJoined', name: 'Companies', sortable: true, resizable: true }
]

class AdminUserList extends React.Component<any, any> {

  columns = []

  constructor (props) {
    super(props)
    this.state = {
      search: ''
    }
  }

  onChange (event) {
    return this.setState({ ...this.state, [event.target.name]: event.target.value })
  }

  onNew = () => {
    this.props.userLoaded()
    this.props.navigate('/admin-users/new')
  }

  onRefreshListing = () => {
    this.props.loadAllUsers()
  }

  onRowClick = (id) => {
    this.props.userLoaded()
    this.props.navigate(`/admin-users/${id}`)
  }

  getRows () {
    if (this.state.search === '') {       
      return this.props.users ? this.props.users : [] 
    }

    if (this.props.claims.role === 'superuser') {
      return this.props.users.filter(p => p.userName.search(new RegExp(this.state.search, 'i')) !== -1
        || p.email.search(new RegExp(this.state.search, 'i')) !== -1
        || p.role.search(new RegExp(this.state.search, 'i')) !== -1
        || p.companiesJoined.search(new RegExp(this.state.search, 'i')) !== -1
        || p.landlord.search(new RegExp(this.state.search, 'i')) !== -1)
    }

    return this.props.users.filter(p => p.userName.search(new RegExp(this.state.search, 'i')) !== -1
      || p.email.search(new RegExp(this.state.search, 'i')) !== -1
      || p.role.search(new RegExp(this.state.search, 'i')) !== -1
      || p.companiesJoined.search(new RegExp(this.state.search, 'i')) !== -1)
  }

  render () {
    if (this.props.claims.role === 'superuser') {
      this.columns = columnsSuperuser
    } else {
      this.columns = columnsAdmin
    }
    let spinner
    let dataGrid
    if (this.props.usersLoading) {
      spinner = (<ClipLoader size={40} />)
    } else {
      const colHeads = this.columns.map(p => <th key={p.key}>{p.name}</th>)
      const colBody = this.getRows().map((col) => {
        return (
          <tr key={col.id} onClick={(e) => this.onRowClick(col.id)}>
            {this.columns.map(p => 
              <td key={p.key}>
                {col[p.key]}
              </td>
            )}
            <td className='text-right'>
              <button className='btn btn-link' onClick={e => this.onRowClick(col.id)}>
                <i className='glyphicon glyphicon-chevron-right' />
              </button>
            </td>
          </tr>
        )
      })
      dataGrid = () => {
        const rows = this.getRows()
        return rows?.length && rows.length > 0 ? 
          <Table data={colBody} height={this.state.height}>          
            <thead>
              <tr>
                {colHeads}
                <th />
              </tr>
            </thead>
            <tbody>
              {colBody}
            </tbody>
          </Table> :
          <EmptyState message={this.props.users.length ? 'No users found' : 'No users available'} />
      }
    }

    return (
      <div>
        <h3>Users</h3>
        <div className='row' >
          <div className='col-sm-4 col-xs-6'>
            <div className='right-inner-addon '>
              <i className='text-muted glyphicon glyphicon-search' />
              <input
                maxLength={100}
                name='search'
                value={this.state.search}
                onChange={e => this.onChange(e)}
                className='form-control'
                placeholder='Search…'
              />
            </div>
          </div>
          <div className='col-sm-8 col-xs-6 text-right'>
            <button
              style={{ margin: 5, textAlign: 'center' }}
              className='btn btn-primary'
              onClick={this.onNew}
            >
              Create new user
            </button>
          </div>
        </div>
        <hr />
        <div className='table-responsive' 
          style={
            this.props.usersLoading ? {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              justifyItems: 'center',
              overflow: 'hidden'
            } : {} 
          }
        >
          {this.props.usersLoading ? spinner : dataGrid()}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    users: state.user.usersLoading ? undefined : state.user.users,
    claims: state.session,
    usersLoading: state.user.usersLoading
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    loadAllUsers,
    logoutUser,
    userLoaded
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminUserList))
