import authenticator from '../auth/authenticator'
import Company from '../types/Company'
import Tenant from '../types/Tenant'
import { GetApiRequest } from '../utils/ApiUtils'
import fetcher from './ApiMethods'

export default class CompanyApi {
  public static saveCompany (company: Company) {
    const payload = {
      Id: company.id,
      LandlordId: company.landlordId,
      Name: company.name,
      SourceId: company.sourceId
    }

    const callingMethod = company.id ? 'PUT' : 'POST'
    const uri = company.id ? `/api/companies/${company.id}` : '/api/companies'
    const request = GetApiRequest(uri, {
      method: callingMethod,
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  public static updateTenants (company: Company, tenants: Tenant[]) {
    const payload = {
      companyId: company.id,
      tenantsToAddUpdate: tenants.map(x => new Tenant(x)), // Get new copies of each Tenant as we mutate them before sending
      tenantsToDelete: company.deletedTenants
    }
    // Only send features to update if they have been changed
    payload.tenantsToAddUpdate.forEach(x => x.features = x.features.filter(y => y.changed))

    const uri = '/api/adminportaltenants'
    const request = GetApiRequest(uri, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  public static updateTenantFeatures (company: Company, tenants: Tenant[]) {
    const payload = {
      companyId: company.id,
      tenantsToAddUpdate: tenants.map(x => new Tenant(x)) // Get new copies of each Tenant as we mutate them before sending
    }
    // Only send features to update if they have been changed.
    // Still hit the server if no tenants are changing as the code calling this function expects a fetch shaped promise as the return result
    payload.tenantsToAddUpdate.forEach(x => x.features = x.features.filter(y => y.changed))
    payload.tenantsToAddUpdate = payload.tenantsToAddUpdate.filter(x => x.features.length > 0)

    const uri = '/api/adminportaltenants/features'
    const request = GetApiRequest(uri, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  static loadCompanys () {
    const request = GetApiRequest('/api/companies', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static deleteCompany (id: number) {
    const request = GetApiRequest(`/api/companies/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })
    return fetcher.processFetch(request)
  }

  public static deleteTenant (id: string) {

    const request = GetApiRequest(`/api/adminportaltenants/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }
}
