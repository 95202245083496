
import * as Toast from '../utils/Toast'
import dataExplorerApi from '../api/DataExplorerApi'

export const DATA_LOADING = 'DATA_LOADING'
export const DATA_LOADED = 'DATA_LOADED'

export const DATA_EXPLORER_ID_CHANGE = 'DATA_EXPLORER_ID_CHANGE'
export const DATA_EXPLORER_TYPE_CHANGE = 'DATA_EXPLORER_TYPE_CHANGE'

export function dataExplorerIdChange (id: string) {
  return { type: DATA_EXPLORER_ID_CHANGE, id }
}
export function dataExplorerTypeChange (dataExplorerType: string) {
  return { type: DATA_EXPLORER_TYPE_CHANGE, dataExplorerType }
}

export function dataLoadStarted () {
  return { type: DATA_LOADING }
}

export function dataLoaded (data: any, page: any, hasMorePages: boolean) {
  return { type: DATA_LOADED, data, page }
}

export function loadData () {
  return (dispatch: any, getState: any) => {
    let { tenantId } = getState().session
    let { dataExplorerType, id } = getState().dataExplorer
    let type = dataExplorerType === 1 ? 'Customer' : ''
    const pageRows = 50
    dispatch(dataLoadStarted())
    return dataExplorerApi.loadData(tenantId, type, id, 1, pageRows).then((response) => {
      if (response) {
        dispatch(dataLoaded(response, 1, response.length === pageRows))
      }
    }).catch(() => {
      dispatch(dataLoaded([], 1, false))
    })
  }

}

export function loadNextPage () {
  return (dispatch: any, getState: any) => {
    let { tenantId } = getState().session
    let { type, id, page } = getState().dataExplorer
    const pageRows = 50
    dispatch(dataLoadStarted())
    return dataExplorerApi.loadData(tenantId, type, id, ++page, pageRows).then((response) => {
      if (response) {
        dispatch(dataLoaded(response, page, response.length === pageRows))
      }
    }).catch(() => {
      dispatch(dataLoaded([], page, false))
    })
  }
}