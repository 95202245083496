import React from 'react'
import { StoreTemplate } from '../../types/StoreTemplate'

import { TransferSettings } from '../../types/TransferSettings'
import ToggleSwitch from '../common/ToggleSwitch'

interface Props {
    onChange: (storeTemplate: StoreTemplate) => void
    storeTemplate: StoreTemplate
}

interface State {
    storeTemplate: StoreTemplate
}

export default class StockTransfer extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      storeTemplate: this.props.storeTemplate === null ? new StoreTemplate(this.props.storeTemplate) : this.props.storeTemplate
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ ...this.state, storeTemplate: nextProps.storeTemplate })
  }

  saveTransferSettings = (transferSettings: TransferSettings) => {
    const template = {
      ...this.state.storeTemplate,
      transferSettings: { ...transferSettings }
    }
    this.setState({ ...this.state, storeTemplate: template })
    this.props.onChange(template)
  }

  changeActive = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      active: !this.state.storeTemplate.transferSettings.active
    }
    this.saveTransferSettings(transferSettings)
  }

  changeTranReceiptAll = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      transferReceiptAll: !this.state.storeTemplate.transferSettings.transferReceiptAll
    }
    this.saveTransferSettings(transferSettings)
  }

  changeTransferOverReceiptAllow = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      transferOverReceiptAllow: !this.state.storeTemplate.transferSettings.transferOverReceiptAllow
    }
    this.saveTransferSettings(transferSettings)
  }

  changeTransferReceiptOtherSku = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      transferReceiptOtherSku: !this.state.storeTemplate.transferSettings.transferReceiptOtherSku
    }
    this.saveTransferSettings(transferSettings)
  }

  changePoReceiptAll = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      poReceiptAll: !this.state.storeTemplate.transferSettings.poReceiptAll
    }
    this.saveTransferSettings(transferSettings)
  }

  changePoOverReceiptAllow = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      poOverReceiptAllow: !this.state.storeTemplate.transferSettings.poOverReceiptAllow
    }
    this.saveTransferSettings(transferSettings)
  }

  changePoReceiptOtherSku = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      poReceiptOtherSku: !this.state.storeTemplate.transferSettings.poReceiptOtherSku
    }
    this.saveTransferSettings(transferSettings)
  }

  changeTransferForceScanning = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      transferForceScanning: !this.state.storeTemplate.transferSettings.transferForceScanning
    }
    this.saveTransferSettings(transferSettings)
  }
  changePoForceScanning = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      poForceScanning: !this.state.storeTemplate.transferSettings.poForceScanning
    }
    this.saveTransferSettings(transferSettings)
  }
  changeTransferOneShotCarton = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      transferOneShotCarton: !this.state.storeTemplate.transferSettings.transferOneShotCarton
    }
    this.saveTransferSettings(transferSettings)
  }
  changePoOneShotCarton = () => {
    const transferSettings = {
      ...this.state.storeTemplate.transferSettings,
      poOneShotCarton: !this.state.storeTemplate.transferSettings.poOneShotCarton
    }
    this.saveTransferSettings(transferSettings)
  }

  render () {
    let setting = null
    if (this.state.storeTemplate.transferSettings.active) {
      setting = (<div className='panel panel-default'>
        <div className='panel-heading clearfix'>
          <h3 className='panel-title pull-left'>Settings</h3>
        </div>
        <div className='panel-body' style={{ margin: 15 }}>
          <div className='form-group row'>
            <div className='col-sm-7' />
            <div className='col-sm-2' >
              <label className='panel-title'>Transfers</label>
            </div>
            <div className='col-sm-3' style={{ paddingLeft: 45}}>
              <label className='panel-title' >Purchase Orders</label>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm-7' >
              <label className='panel-title'>Receipt All</label>
            </div>
            <div className='col-sm-2' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferSettings.transferReceiptAll}
                onChange={() => this.changeTranReceiptAll()}
              />
            </div>
            <div className='col-sm-1' />
            <div className='col-sm-1' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferSettings.poReceiptAll}
                onChange={() => this.changePoReceiptAll()}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm-7' >
              <label className='panel-title'>Over Receipt Allowed</label>
            </div>
            <div className='col-sm-2' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferSettings.transferOverReceiptAllow}
                onChange={() => this.changeTransferOverReceiptAllow()}
              />
            </div>
            <div className='col-sm-1' />
            <div className='col-sm-1' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferSettings.poOverReceiptAllow}
                onChange={() => this.changePoOverReceiptAllow()}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm-7' >
              <label className='panel-title'>Receipt Other SKU Allowed</label>
            </div>
            <div className='col-sm-2' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferSettings.transferReceiptOtherSku}
                onChange={() => this.changeTransferReceiptOtherSku()}
              />
            </div>
            <div className='col-sm-1' />
            <div className='col-sm-1' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferSettings.poReceiptOtherSku}
                onChange={() => this.changePoReceiptOtherSku()}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm-7' >
              <label className='panel-title'>Force Scanning</label>
            </div>
            <div className='col-sm-2' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferSettings.transferForceScanning}
                onChange={() => this.changeTransferForceScanning()}
              />
            </div>
            <div className='col-sm-1' />
            <div className='col-sm-1' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferSettings.poForceScanning}
                onChange={() => this.changePoForceScanning()}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm-7' >
              <label className='panel-title'>Receive Cartons Once Only</label>
            </div>
            <div className='col-sm-2' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferSettings.transferOneShotCarton}
                onChange={() => this.changeTransferOneShotCarton()}
              />
            </div>
            <div className='col-sm-1' />
            <div className='col-sm-1' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferSettings.poOneShotCarton}
                onChange={() => this.changePoOneShotCarton()}
              />
            </div>
          </div>
        </div>
      </div>)
    }
    return (
      <div>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <div className='form-group row' style={{ margin: 15 }}>
              <div className='col-sm-9' >
                <h5 className='text-info'>Stock Receipts</h5>
              </div>
              <div className='col-sm-2' style={{ width: 120, margin: 1, paddingTop: 10, marginLeft: 40 }}>
                <ToggleSwitch
                  checked={this.state.storeTemplate.transferSettings.active}
                  onChange={() => this.changeActive()}
                  checkedIconText={'Enabled'}
                  uncheckedIconText={'Disabled'}
                />
              </div>
            </div>
          </div>
        </div>
        {setting}
      </div>)
  }
}