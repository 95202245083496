import React from 'react'

import { connect } from 'react-redux'
import EmptyState from './common/EmptyState'
import { ClipLoader } from 'react-spinners'
class HomePage extends React.Component<any, any> {
  compponentDidMount () {
    console.log('HomePage compponentDidMount')
  }

  changeState = (name: any, value: any) => {
    return this.setState({ ...this.state, [name]: value })
  }

  render () {
    // Last sync / last error
    // Licenses
    // Active stores
    let homeDisplay = (<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ClipLoader size={40} /></div>)
    if (this.props.logged_in) {
      homeDisplay = (
        <EmptyState message='Welcome to Envimo Admin'
          imagePath='/images/Dashboard_Oct19.png'
        />
      )
    }
    return (
      <div>{ homeDisplay } </div>
    )
  }
}

function mapStateToProps (state: any) {
  return {
    logged_in: state.session.logged_in
  }
}

export default connect<any, any, {}>(
  mapStateToProps,
)(HomePage)
