import React from 'react'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  loadMenus
} from '../actions/menuActions'
import { Outlet } from 'react-router-dom'

interface MenuPageProps {
  loadMenus: () => void;
  claims: any;
  logged_in: boolean;
}

class MenuPage extends React.Component<MenuPageProps & any, {}> {
  componentDidMount () {
    
    // loadMenus("2");
  }

  render () {
    const display = (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ClipLoader size={40} />
      </div>
    )
    return (
      <div>
        {this.props.logged_in ?
          this.props.children
          : display }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    claims: state.session,
    logged_in: state.session.logged_in
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    loadMenus
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuPage)
