import React from 'react'
import { connect } from 'react-redux'
import { PRODUCT_FILTER, Rule, RuleChild, SubFilter, TagGroup } from '../../types/Menu'

import {
  DispatchProps, OwnProps,
  Rules as CustomerRule, StateProps
} from './../Rules'

import {
  addRule, changeRuleOperator, changeRuleTagGroup, changeRuleTags, deleteRule
} from '../../actions/customerActions'

const mapDispatchToCustomerRuleProps = (dispatch): DispatchProps => {
  return {
    addRule: (subMenuId) => dispatch(addRule(subMenuId)),
    changeRuleOperator: (ruleId, operator) => dispatch(changeRuleOperator(ruleId, operator)),
    changeRuleTagGroup: (ruleId, tagGroup) => dispatch(changeRuleTagGroup(ruleId, tagGroup)),
    changeRuleTags: (ruleId, tags) => dispatch(changeRuleTags(ruleId, tags)),
    deleteRule: (id) => dispatch(deleteRule(id))
  }
}

function mapStateToCustomerRuleProps (state): StateProps {
  return {
    rules: state.customer.customer.rules,
    ruleChilds: state.customer.customer.ruleChilds,
    tagGroups: state.customer.customer.tagGroups
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToCustomerRuleProps,
  mapDispatchToCustomerRuleProps
)(CustomerRule)