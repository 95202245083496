import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input } from './../common/Input'
import { FieldGroup, FieldOption } from '../../types/Customer'
import {
  Form,
  FormGroup

} from 'react-bootstrap'
import {
  updateFieldMandatory,
  updateGroupEnabled
} from '../../actions/customerActions'
import ToggleSwitch from '../common/ToggleSwitch'

interface IStateProps {

}

interface IDispatchProps {
    updateFieldMandatory,
    updateGroupEnabled
}

interface IOwnProps {
    group: FieldGroup
}

enum Format {
    FullDate,
    DateAndMonth,
    MonthOnly
}
export class CustomerField extends React.Component<IOwnProps & IStateProps & IDispatchProps, {}> {
  shouldComponentUpdate (nextProps: object, nextState) {
    for (const prop in nextProps) {
      if (nextProps[prop] !== this.props[prop]) {
        return true
      }
    }
    return false
  }

  changeFormat (format: Format) {
    switch (format) {
      case Format.FullDate:
        this.props.updateGroupEnabled(this.props.group.group, true)
        break

      case Format.DateAndMonth:
        this.props.updateGroupEnabled(this.props.group.group, true)
        this.props.updateGroupEnabled(this.props.group.group, false, this.props.group.fieldOptions[2].name)
        break

      case Format.MonthOnly:
        this.props.updateGroupEnabled(this.props.group.group, false)
        this.props.updateGroupEnabled(this.props.group.group, true, this.props.group.fieldOptions[1].name)
        break

      default:
        break
    }
  }
  render () {
    let { group } = this.props
    const mandatory = group.fieldOptions.every(p => p.mandatory)
    let key = 0
    let isFullDate: boolean; let isDateAndMonth: boolean; let isMonthOnly: boolean; let isAllDisable: boolean = false
    if (group.fieldOptions.every(p => p.enabled)) {
      isFullDate = true
      isDateAndMonth = false
      isMonthOnly = false
    } else if (group.fieldOptions[0].enabled && group.fieldOptions[1].enabled) {
      isFullDate = false
      isDateAndMonth = true
      isMonthOnly = false
    } else if (group.fieldOptions[1].enabled) {
      isFullDate = false
      isDateAndMonth = false
      isMonthOnly = true
    } else if (group.fieldOptions.every(p => !p.enabled)) {
      isAllDisable = true
      isFullDate = false
      isDateAndMonth = false
      isMonthOnly = false
    }

    return (
      <div className='' >
        <div className={'panel panel-default'}>
          <div className='panel-heading clearfix'>
            <h3 className='panel-title pull-left'>{this.props.group.group}</h3>
            <div className='pull-right' style={{ width: '80px', display: 'inline-block' }}>
              <ToggleSwitch
                checked={!isAllDisable}
                onChange={() => this.props.updateGroupEnabled(group.group, isAllDisable)}
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
              />
            </div>
            <div className='pull-right' style={{ width: '80px', display: 'inline-block', marginRight: '10px' }}>
              <ToggleSwitch
                checked={mandatory}
                disabled={isAllDisable}
                onChange={() => this.props.updateFieldMandatory(group.group, !mandatory)}
                checkedIconText={'Required'}
                uncheckedIconText={'Optional'}
              />
            </div>
          </div>
          <div className={'panel-collapse collapse ' + (this.props.group.fieldOptions.length !== 1 ? 'in' : '')}>
            <div className='panel-body'>
              <Form className={isAllDisable ? 'text-muted' : ''}>
                <div>
                  <Form.Check
                    disabled={isAllDisable}
                    onChange={() => this.changeFormat(Format.FullDate)}
                    checked={isFullDate}
                    type='radio'
                    label='Date, month and year'
                    id='fullDate'
                  />
                </div>
                <div>
                  <Form.Check
                    disabled={isAllDisable}
                    onChange={() => this.changeFormat(Format.DateAndMonth)}
                    checked={isDateAndMonth}
                    type='radio'
                    label='Date and month'
                    id='dateAndMonth'
                  />
                </div>
                <div>
                  <Form.Check
                    disabled={isAllDisable}
                    onChange={() => this.changeFormat(Format.MonthOnly)}
                    checked={isMonthOnly}
                    type='radio'
                    label='Month only'
                    id='monthOnly'
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>)
  }
}

function mapStateToProps (state): IStateProps {
  return {
  }
}

function mapDispatchToProps (dispatch): IDispatchProps {
  return bindActionCreators({
    updateFieldMandatory,
    updateGroupEnabled
  }, dispatch)
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(CustomerField)
