import { REGPINS_LOADED, DEVICES_LOADED, DEVICE_DELETED, REGPIN_GENERATED,
  REGPINS_LOADING, DEVICES_LOADING, CLEAR_DEVICES } from '../actions/deviceActions'
import initialState from './initialState'
import RegPin from '../types/RegistrationPin'
import Device from '../types/Device'

export default function deviceReducer (state = initialState.device, action: any) {
  switch (action.type) {
    case REGPINS_LOADED:
    {
      const pins: any = []
      if (action.regPins) {
        action.regPins.forEach((y: any) => {
          const pin = new RegPin(y)
          pins.push(pin)
        })
      }

      return {
        ...state,
        regPins: pins,
        regPinsLoading: false
      }
    }
    case REGPIN_GENERATED:
    {
      const pins = []
      state.regPins.forEach((y) => {
        const pin = new RegPin(y)
        pins.push(pin)
      })
      pins.push(new RegPin(action.newPin))

      return {
        ...state,
        regPins: pins,
        regPinsLoading: false
      }
    }
    case REGPINS_LOADING:
      return {
        ...state,
        regPinsLoading: true
      }
    case DEVICES_LOADING:
      return {
        ...state,
        devicesLoading: true
      }
    case DEVICES_LOADED:
    {
      const newDevices: any = []
      if (action.devices) {
        action.devices.forEach((y: any) => {
          const device = new Device(y)

          newDevices.push(device)
        })
      }
      return {
        ...state,
        devices: newDevices,
        devicesLoading: false
      }
    }
    case DEVICE_DELETED:
    {
      const newDevices: any = []
      state.devices.forEach((d: Device) => {
        if (d.id !== action.deviceId) {
          newDevices.push(d)
        }
      })

      return {
        ...state,
        devices: newDevices
      }
    }
    case CLEAR_DEVICES:
    {
      return { ...state, regPins: [], devices: [], regPinsLoading: false, devicesLoading: false, selectedCompanyId: '', selectedTenantId: '' }
    }
    default:
      return state
  }
}
