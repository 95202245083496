import React from 'react'
import TemplateList from './../TemplateList'
import {ShopUpdateType} from '../../types/Template'

class StoreTemplateList extends React.Component {

  render () {
    return (
      <TemplateList title='Store Templates' templateType={ShopUpdateType.StoreTemplateId}
        description='Edit and manage store settings'
        url='/store'
      >
        Customise store logos, settings for filters and security etc..
      </TemplateList>
    )
  }
}

export default StoreTemplateList