import userApi from '../api/UserApi'
import { sessionTimeout } from '../actions/sessionActions'
import * as Toast from '../utils/Toast'

export const USERS_LOADED = 'USERS_LOADED'
export const USERS_UPDATED = 'USERS_UPDATED'
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS'
export const SAVE_USER_ERROR = 'SAVE_USER_ERROR'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'
export const USER_API_STARTED = 'USER_API_STARTED'
export const USER_LOADED = 'USER_LOADED'
export const USERS_LOADING = 'USERS_LOADING'
export const CLEAR_USERS = 'CLEAR_USERS'
export const SET_USER_SAVED = 'SET_USER_SAVED'
export const SET_USERS_LOADED = 'SET_USERS_LOADED'
export const SET_USER_DELETED = 'SET_USER_DELETED'
export const SET_USER_SAVING = 'SET_USER_SAVING'
export const SET_USER_DELETING = 'SET_USER_DELETING'
export const SET_ERROR = 'SET_ERROR'

export function userApiStarted () {
  return { type: USER_API_STARTED }
}

export function clearUsers () {
  return { type: CLEAR_USERS }
}
export function saveUserSuccesss (userDetails: any) {
  return { type: SAVE_USER_SUCCESS, userDetails }
}

export function userLoaded () {
  return { type: USER_LOADED }
}
export function usersLoading () {
  return { type: USERS_LOADING }
}

export function saveUserError (error: any) {
  return { type: SAVE_USER_ERROR, error }
}

export function deleteUserSuccess (id: any) {
  return { type: DELETE_USER_SUCCESS, id }
}

export function deleteUserError (error: any) {
  return { type: DELETE_USER_ERROR, error }
}

export function usersLoaded (users: any) {
  return { type: USERS_LOADED, users }
}

export function setUserSaved (value: boolean) {
  return { type: SET_USER_SAVED, value }
}

export function setUsersLoaded (value: boolean) {
  return { type: SET_USERS_LOADED, value }
}

export function setError (value: string) {
  return { type: SET_ERROR, value }
}

export function setUserDeleted (value: boolean) {
  return { type: SET_USER_DELETED, value }
}

export function setUserSaving (value: boolean) {
  return { type: SET_USER_SAVING, value }
}

export function setUserDeleting (value: boolean) {
  return { type: SET_USER_DELETING, value }
}

export function usersUpdated (users: any) {
  return { type: USERS_UPDATED, users }
}

export function loadAllUsers () {
  return (dispatch: any) => {
    dispatch(usersLoading())
    return userApi.loadUsers().then((response) => {
      if (response) {
        dispatch(usersLoaded(response))
      }
    }).catch((p) => {
      Toast.showErrorToast(p)
    })
  }
}

export function saveUser (claims: any, user: any) {
  return (dispatch: any) => {
    if (!user.firstName) {
      return Toast.showErrorToast('First Name cannot be blank')
    }
    if (!user.lastName) {
      return Toast.showErrorToast('Last Name cannot be blank')
    }
    if (!user.email) {
      return Toast.showErrorToast('Email cannot be blank')
    }

    if (claims.role === 'superuser') {
      if (!user.role) {
        return Toast.showErrorToast('Role cannot be blank')
      }
      if (!user.landlord) {
        return Toast.showErrorToast('Landlord cannot be blank')
      }
    }
    if (user.roleIndex === 'companyAdmin' && (user.companies === null
      || user.companies === undefined
      || user.companies.length === 0 ||
      user.companies === undefined || user.companies.length === 1 ) ) {
      return Toast.showErrorToast('Company cannot be blank')
    }
    dispatch(userApiStarted())
    return userApi.saveUser(claims, user).then((response) => {
      if (response.id) {
        dispatch(saveUserSuccesss(response))
        Toast.showSuccessToast('User saved')
      } else if (response.error) {
        dispatch(saveUserError(response.error))
      } else {
        dispatch(saveUserError(response))
      }
    }).catch((p) => {
      dispatch(saveUserError(''))
      Toast.showErrorToast(p)
    })
  }
}

export function deleteUser (user: any, adminId: any) {
  return (dispatch: any) => {
    dispatch(deleteUserError(''))
    dispatch(setUserDeleting(true))
    return userApi.deleteUser(user.id).then(() => {
      dispatch(deleteUserSuccess(user.id))
      Toast.showSuccessToast(`User ${user.email} has been deleted`)
      if (adminId === user.id) {
        dispatch(sessionTimeout())
      }
    }).catch((p) => {
      dispatch(deleteUserError(p))
      dispatch(setUserDeleting(false))
      Toast.showErrorToast(p)
    })
  }
}
