// tslint:disable:max-line-length
export const defaultSaleReceiptFormat = `{{image Logo}}
{{font1}}{{center}}{{#if IsReprint}}
{{bold:on}}***REPRINT***{{bold:off}}

{{/if}}Store Hours: 9am-10pm, closed Sun.

{{font2}}{{#if Shop.Name}}{{bold:on}}{{Shop.Name}}{{bold:off}}{{/if}}{{#if Shop.Address.Address1}}
{{Shop.Address.Address1}}{{/if}}{{#if Shop.Address.Address2}}
{{Shop.Address.Address2}}{{/if}}
{{Shop.Address.City}} {{Shop.Address.State}} {{Shop.Address.PostCode}}{{#if Shop.Phone}}
Ph. {{Shop.Phone}}{{/if}}{{#if Shop.Email}}
{{Shop.Email}}{{/if}}
ABN 1234567890
{{left}}________________________________________________________
{{#fit 15 ' '}}Docket No.{{/fit}}{{#fit 13 ' '}}Served By{{/fit}}{{#fit 9 ' '}}Till No.{{/fit}}{{#fit 19 ' ' 'true'}}Date/Time{{/fit}}
{{#fit 15 ' '}}{{DocketNumber}}{{/fit}}{{#fit 12 ' '}}{{#fit 10}}{{SalesRep.FirstName}}{{/fit}} {{#fit 1}}{{SalesRep.LastName}}{{/fit}}{{/fit}} {{#fit 9 ' '}}{{Device.Id}}{{/fit}}{{#fit 19 ' ' 'true'}}{{formatDate TimeOfSale 'dd/MM/yyyy h:mm tt'}}{{/fit}}{{#if OwnerShop}}
{{#fit 20 ' ' 'false'}}On behalf of store:{{/fit}}{{#fit 36 ' ' 'false' }}{{OwnerShop.StoreNumber}} {{OwnerShop.Name}}{{/fit}}{{/if}}{{#if Customer}}
________________________________________________________
{{bold:on}}Customer Details:{{bold:off}}
{{Customer.FirstName}} {{Customer.LastName}}{{#if Customer.Email}}
Email: {{Customer.Email}}{{/if}}{{#if Customer.Phone}}
Ph. {{Customer.Phone}}{{/if}}{{/if}}
________________________________________________________
{{#if DocketNumber}}{{bold:on}}Product Description:{{bold:off}}
{{#each Details}}  {{Product.StyleName}} {{Product.Colour}} {{Product.Size}}
{{#fit 46 ' '}}  {{Product.StyleCode}} {{Price}} x {{Quantity}} unit(s){{/fit}}{{#fit 10 ' ' 'true'}}{{Amount}}{{/fit}}
{{#each Discounts}}{{bold:on}}    Discount: {{bold:off}}{{#fit 26 ' '}}{{Type}}{{/fit}}{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}{{#fit 9 ' ' 'true'}}{{Amount}}{{/fit}}
{{/each}}{{#each Promotions}}{{bold:on}}    Promotion: {{bold:off}}{{#fit 25 ' '}}{{Type}}{{/fit}}{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}{{#fit 9 ' ' 'true'}}{{Amount}}{{/fit}}
{{/each}}{{#each Loyalties}}{{bold:on}}    Loyalty: {{bold:off}}{{#fit 27 ' '}}{{Type}}{{/fit}}{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}{{#fit 9 ' ' 'true'}}{{Amount}}{{/fit}}
{{/each}}{{/each}}
{{bold:on}}Total Units: {{TotalUnits}}
{{#fit 42 ' ' 'true'}}Total Inc Tax{{/fit}}{{#fit 14 ' ' 'true'}}{{TotalPrice}}{{/fit}}{{bold:off}}
{{#fit 42 ' ' 'true'}}Total Tax{{/fit}}{{#fit 14 ' ' 'true'}}{{TotalTax}}{{/fit}}{{bold:off}}{{#if TotalDiscount}}
{{#fit 42 ' ' 'true'}}Total Discount{{/fit}}{{#fit 14 ' ' 'true'}}{{TotalDiscount}}{{/fit}}{{/if}}
{{bold:on}}Payment Details:{{bold:off}}
{{bold:off}}{{#each Payments}}  {{#fit 20 ' '}}{{PaymentType}}{{/fit}}{{#fit 34 ' ' 'true'}}{{Amount}}{{/fit}}
{{/each}}
{{center}}{{barcode DocketNumber}}
{{/if}}
{{center}}{{#each ReceiptPrintLines}}{{Line}}
{{/each}}
{{#if DocketNumber}}________________________________________________________
No exchanges, credits, or refunds will be issued without
proof of purchase. Garments presented for return must be
in original condition and returned within 30 days of
purchase.{{/if}}`

export const defaultSaleReturnsReceiptFormat = `{{image Logo}}
{{font1}}{{center}}{{#if IsReprint}}
{{bold:on}}***REPRINT***{{bold:off}}

{{/if}}Store Hours: 9am-10pm, closed Sun.

{{font2}}{{#if Shop.Name}}{{bold:on}}{{Shop.Name}}{{bold:off}}{{/if}}{{#if Shop.Address.Address1}}
{{Shop.Address.Address1}}{{/if}}{{#if Shop.Address.Address2}}defaultEmailSubject
{{Shop.Address.Address2}}{{/if}}
{{Shop.Address.City}} {{Shop.Address.State}} {{Shop.Address.PostCode}}{{#if Shop.Phone}}
Ph. {{Shop.Phone}}{{/if}}{{#if Shop.Email}}
{{Shop.Email}}{{/if}}
ABN 1234567890
{{left}}________________________________________________________
{{#if HasReturn}}
{{bold:on}}{{center}}Original Store: {{OriginalStorecode}}{{bold:off}}
{{bold:on}}{{center}}Original Docket No: {{OriginalDocketNumber}}{{bold:off}}

{{/if}}
{{#if IsReturnFromOrder}}
{{bold:on}}{{center}}Customer Order Number: {{OriginalOrderNumber}}{{bold:off}}
{{bold:on}}{{center}}Reference Number: {{OriginalOrderNumber}}{{bold:off}}
{{/if}}

{{#fit 15 ' '}}Docket No.{{/fit}}{{#fit 13 ' '}}Served By{{/fit}}{{#fit 9 ' '}}Till No.{{/fit}}{{#fit 19 ' ' 'true'}}Date/Time{{/fit}}
{{#fit 15 ' '}}{{DocketNumber}}{{/fit}}{{#fit 12 ' '}}{{#fit 10}}{{SalesRep.FirstName}}{{/fit}} {{#fit 1}}{{SalesRep.LastName}}{{/fit}}{{/fit}} {{#fit 9 ' '}}{{Device.Id}}{{/fit}}{{#fit 19 ' ' 'true'}}{{formatDate TimeOfSale 'dd/MM/yyyy h:mm tt'}}{{/fit}}{{#if OwnerShop}}
{{#fit 20 ' ' 'false'}}On behalf of store:{{/fit}}{{#fit 36 ' ' 'false' }}{{OwnerShop.StoreNumber}} {{OwnerShop.Name}}{{/fit}}{{/if}}{{#if Customer}}
________________________________________________________
{{bold:on}}Customer Details:{{bold:off}}
{{Customer.FirstName}} {{Customer.LastName}}{{#if Customer.Email}}
Email: {{Customer.Email}}{{/if}}{{#if Customer.Phone}}
Ph. {{Customer.Phone}}{{/if}}{{/if}}
________________________________________________________
{{#if DocketNumber}}
{{left}}{{bold:on}}Product Description:{{bold:off}}
{{#each Details}}  {{Product.StyleName}} {{Product.Colour}} {{Product.Size}}
{{#fit 46 ' '}}  {{Product.StyleCode}} {{Price}} x {{Quantity}} unit(s){{/fit}}{{#fit 10 ' ' 'true'}}{{Amount}}{{/fit}}
{{#if HasReason}}{{bold:on}}    Return Reason: {{bold:off}}{{ReturnReason}}{{/if}}
{{#each Discounts}}{{bold:on}}    Discount: {{bold:off}}{{#fit 26 ' '}}{{Type}}{{/fit}}{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}{{#fit 9 ' ' 'true'}}-{{Amount}}{{/fit}}
{{/each}}{{#each Promotions}}{{bold:on}}    Promotion: {{bold:off}}{{#fit 25 ' '}}{{Type}}{{/fit}}{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}{{#fit 9 ' ' 'true'}}-{{Amount}}{{/fit}}
{{/each}}{{#each Loyalties}}{{bold:on}}    Loyalty: {{bold:off}}{{#fit 27 ' '}}{{Type}}{{/fit}}{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}{{#fit 9 ' ' 'true'}}-{{Amount}}{{/fit}}
{{/each}}{{/each}}
{{bold:on}}Total Units: {{TotalUnits}}
{{#if HasReturn}}{{bold:on}}Total Returns: {{TotalReturns}}{{/if}}
{{#fit 42 ' ' 'true'}}Total Inc Tax{{/fit}}{{#fit 14 ' ' 'true'}}{{TotalPrice}}{{/fit}}{{bold:off}}
{{#fit 42 ' ' 'true'}}Total Tax{{/fit}}{{#fit 14 ' ' 'true'}}{{TotalTax}}{{/fit}}{{bold:off}}{{#if TotalDiscount}}
{{#fit 42 ' ' 'true'}}Total Discount{{/fit}}{{#fit 14 ' ' 'true'}}-{{TotalDiscount}}{{/fit}}{{/if}}
{{bold:on}}Payment Details:{{bold:off}}
{{bold:off}}{{#each Payments}}  {{#fit 20 ' '}}{{PaymentType}}{{/fit}}{{#fit 34 ' ' 'true'}}{{Amount}}{{/fit}}
{{/each}}
{{#if HasReturn}}

{{bold:on}}Customer Signature: ....................................{{bold:off}}

{{bold:on}}Salesperson Signature: .................................{{bold:off}}

{{bold:on}}Date: ..................................................{{bold:off}}
{{/if}}

{{center}}{{barcode DocketNumber}}
{{/if}}
{{center}}{{#each ReceiptPrintLines}}{{Line}}
{{/each}}
{{#if DocketNumber}}________________________________________________________
No exchanges, credits, or refunds will be issued without
proof of purchase. Garments presented for return must be
in original condition and returned within 30 days of
purchase.{{/if}}`

export const defaultOrderReceiptFormat = `{{image Logo}}
{{font1}}{{center}}{{#if IsReprint}}
{{bold:on}}***REPRINT***{{bold:off}}

{{/if}}Store Hours: 9am-10pm, closed Sun.

{{font2}}{{#if Shop.Name}}{{bold:on}}{{Shop.Name}}{{bold:off}}{{/if}}{{#if Shop.Address.Address1}}
{{Shop.Address.Address1}}{{/if}}{{#if Shop.Address.Address2}}
{{Shop.Address.Address2}}{{/if}}
{{Shop.Address.City}} {{Shop.Address.State}} {{Shop.Address.PostCode}}{{#if Shop.Phone}}
Ph. {{Shop.Phone}}{{/if}}{{#if Shop.Email}}
{{Shop.Email}}{{/if}}
ABN 1234567890
{{left}}________________________________________________________
{{center}}{{bold:on}}Order No: {{OrderNumber}}{{bold:off}}

{{left}}{{#fit 15 ' '}}Docket No.{{/fit}}{{#fit 13 ' '}}Served By{{/fit}}{{#fit 9 ' '}}Till No.{{/fit}}{{#fit 19 ' ' 'true'}}Date/Time{{/fit}}
{{#fit 15 ' '}}{{DocketNumber}}{{/fit}}{{#fit 12 ' '}}{{#fit 10}}{{SalesRep.FirstName}}{{/fit}} {{#fit 1}}{{SalesRep.LastName}}{{/fit}}{{/fit}} {{#fit 9 ' '}}{{Device.Id}}{{/fit}}{{#fit 19 ' ' 'true'}}{{formatDate TimeOfSale 'dd/MM/yyyy h:mm tt'}}{{/fit}}{{#if OwnerShop}}
{{#fit 20 ' ' 'false'}}On behalf of store:{{/fit}}{{#fit 36 ' ' 'false' }}{{OwnerShop.StoreNumber}} {{OwnerShop.Name}}{{/fit}}{{/if}}{{#if Customer}}
________________________________________________________
{{bold:on}}Customer Details:{{bold:off}}
{{Customer.FirstName}} {{Customer.LastName}}{{#if Customer.Email}}
Email: {{Customer.Email}}{{/if}}{{#if Customer.Phone}}
Ph. {{Customer.Phone}}{{/if}}{{/if}}
________________________________________________________
{{#if DocketNumber}}{{bold:on}}Product Description:{{bold:off}}
{{#each Details}}  {{Product.StyleName}} {{Product.Colour}} {{Product.Size}}
{{#fit 46 ' '}}  {{Product.StyleCode}} {{Price}} x {{Quantity}} unit(s){{/fit}}{{#fit 10 ' ' 'true'}}{{Amount}}{{/fit}}
{{#each Discounts}}{{bold:on}}    Discount: {{bold:off}}{{#fit 26 ' '}}{{Type}}{{/fit}}{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}{{#fit 9 ' ' 'true'}}-{{Amount}}{{/fit}}
{{/each}}{{#each Promotions}}{{bold:on}}    Promotion: {{bold:off}}{{#fit 25 ' '}}{{Type}}{{/fit}}{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}{{#fit 9 ' ' 'true'}}-{{Amount}}{{/fit}}
{{/each}}{{#each Loyalties}}{{bold:on}}    Loyalty: {{bold:off}}{{#fit 27 ' '}}{{Type}}{{/fit}}{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}{{#fit 9 ' ' 'true'}}-{{Amount}}{{/fit}}
{{/each}}{{/each}}
{{bold:on}}Total Units: {{TotalUnits}}
{{#fit 42 ' ' 'true'}}Total Inc Tax{{/fit}}{{#fit 14 ' ' 'true'}}{{TotalPrice}}{{/fit}}{{bold:off}}
{{#fit 42 ' ' 'true'}}Total Tax{{/fit}}{{#fit 14 ' ' 'true'}}{{TotalTax}}{{/fit}}{{bold:off}}{{#if TotalDiscount}}
{{#fit 42 ' ' 'true'}}Total Discount{{/fit}}{{#fit 14 ' ' 'true'}}-{{TotalDiscount}}{{/fit}}{{/if}}
{{bold:on}}Payment Details:{{bold:off}}
{{bold:off}}{{#each Payments}}  {{#fit 20 ' '}}{{PaymentType}}{{/fit}}{{#fit 34 ' ' 'true'}}{{Amount}}{{/fit}}
{{/each}}

{{center}}{{barcode DocketNumber}}
{{/if}}
{{center}}{{#each ReceiptPrintLines}}{{Line}}
{{/each}}
{{#if DocketNumber}}________________________________________________________
No exchanges, credits, or refunds will be issued without
proof of purchase. Garments presented for return must be
in original condition and returned within 30 days of
purchase.{{/if}}`

export const defaultDigitalSaleReceiptFormat = `<html><head><style>html{height: 0;}body{width: 500px;font-family: 'Consolas';font-size: 16px;}h4{text-align: center;}h3{text-align: center;}.centered_div {text-align: center;}.bold_text{font-weight: bold;}.logo_container {border: 0px solid transparent;}.barcode_container{text-align: center;}.watermark_container {background-repeat: repeat-y;background-position: center;width: inherit;background-image: watermark_to_be_replaced_with_link;position: absolute;height: 100%;opacity: 0.2;}.headerTable{width: 100%;}th{text-align:left;}.productsTable{width:100%;padding-left: 20px;}.priceTd{text-align: right;}.totalsTable{width: 100%;text-align: right;}.productTd{padding-left: 20px;}.preserveSpaces{white-space: pre;}</style></head>
<body>
    <div class = 'watermark_container'></div>
    <div class='logo_container'>{{image Logo}}</div>
    <h3> Store Hours: 9am-10pm, closed Sun.</h3>
    <div class='centered_div'>
        {{#if Shop.Name}}<div class = 'bold_text'>{{Shop.Name}}</div>{{/if}}
        {{#if Shop.Address.Address1}}<div>{{Shop.Address.Address1}}</div>{{/if}}
        {{#if Shop.Address.Address2}}<div>{{Shop.Address.Address2}}</div>{{/if}}
        <div>{{Shop.Address.City}} {{Shop.Address.State}} {{Shop.Address.PostCode}}</div>
        {{#if Shop.Phone}}<div>Ph. {{Shop.Phone}}</div>{{/if}}
        {{#if Shop.Email}}<div>{{Shop.Email}}</div>{{/if}}
        ABN 1234567890
    </div>
    <div>
    _________________________________________________________
    {{#if DocketNumber}}
        <div>
            <table class = 'headerTable'>
                <tr>
                    <th>Docket No.</th><th>Served By</th><th>Till No.</th><th>Date/Time</th>
                </tr>
                <tr>
                    <td>{{#fit 15 ' '}}{{DocketNumber}}{{/fit}}</td>
                    <td>{{#fit 13 ' '}}{{SalesRep.FirstName}}{{/fit}}</td>
                    <td>{{#fit 9 ' '}}{{Device.Id}}{{/fit}}</td>
                    <td>{{#fit 19 ' ' 'true'}}{{formatDate TimeOfSale 'dd/MM/yyyy h:mm tt'}}{{/fit}}</td>
                </tr>
            </table>
        </div>
    {{else}}
        <div>
            <table class = 'headerTable'>
                <tr>
                    <th>Served By</th><th>Till No.</th><th>Date/Time</th>
                </tr>
                <tr>
                    <td>{{#fit 28 ' '}}Served By{{/fit}}</td>
                    <td>{{#fit 9 ' '}}Till No.{{/fit}}</td>
                    <td>{{#fit 19 ' ' 'true'}}Date/Time{{/fit}}</td>
                </tr>
            </table>
        </div>
    {{/if}}
    {{#if OwnerShop}}
       <div>
          <table class = 'headerTable'>
              <tr>
                  <td>{{#fit 20 ' ' 'false'}}On behalf of store:{{/fit}}</td>
                  <td>{{#fit 36 ' ' 'false'}}{{OwnerShop.StoreNumber}} {{OwnerShop.Name}}{{/fit}}</td>
              </tr>
          </table>
      </div>
    {{/if}}
    {{#if Customer}}
        <div>_________________________________________________________</div>
        <div class= 'bold_text'> Customer Details:</div>
        <div>{{Customer.FirstName}} {{Customer.LastName}}</div>
        {{#if Customer.Email}}
            <div>Email: {{Customer.Email}}</div>
        {{/if}}
        {{#if Customer.Phone}}
            <div>Ph. {{Customer.Phone}}</div>
        {{/if}}
    {{/if}}
        <div>_________________________________________________________</div>
    {{#if DocketNumber}}
    <div class = 'bold_text'>Product Description:</div>
    <table class = 'productsTable'>
        {{#each Details}}
            <tr><td>{{Product.StyleName}} {{Product.Colour}} {{Product.Size}}</td></tr>
            <tr><td class = 'productTd'>  {{#fit 44 ' '}}{{Product.StyleCode}} {{Price}} x {{Quantity}} unit(s){{/fit}}</td><td></td><td class = 'priceTd'>{{#fit 10 ' ' 'true'}}{{Amount}}{{/fit}}</td></tr>
            {{#each Discounts}}
                <tr><td class = 'productTd'><span class='bold_text'>Discount: </span>{{#fit 26 ' '}}{{Type}}{{/fit}}</td><td>{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}</td><td class = 'priceTd'>{{#fit 10 ' ' 'true'}}-{{Amount}}{{/fit}}</td></tr>
            {{/each}}
            {{#each Promotions}}
        		<tr><td class = 'productTd'><span class='bold_text'>Promotion: </span>{{#fit 25 ' '}}{{Type}}{{/fit}}</td><td>{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}</td><td class = 'priceTd'>{{#fit 10 ' ' 'true'}}-{{Amount}}{{/fit}}</td></tr>
        	{{/each}}
        	{{#each Loyalties}}
        		<tr><td class = 'productTd'><span class='bold_text'>Loyalty: </span>{{#fit 27 ' '}}{{Type}}{{/fit}}</td><td>{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}</td><td class = 'priceTd'>{{#fit 10 ' ' 'true'}}-{{Amount}}{{/fit}}</td></tr>
        	{{/each}}
        {{/each}}
    </table>
    <div><span class='bold_text'>Total Units: {{TotalUnits}}</span>
        <table class = 'totalsTable'>
            <tr><td><span class='bold_text'>{{#fit 42 ' ' 'true'}}Total Inc Tax{{/fit}}</span></td><td>{{#fit 14 ' ' 'true'}}{{TotalPrice}}{{/fit}}</td></tr>
            <tr><td>{{#fit 42 ' ' 'true'}}Total Tax{{/fit}}{{#fit 14 ' ' 'true'}}</td><td>{{TotalTax}}{{/fit}}</td></tr>
        {{#if TotalDiscount}}
            <tr><td>{{#fit 42 ' ' 'true'}}Total Discount{{/fit}}</td><td>{{#fit 14 ' ' 'true'}}-{{TotalDiscount}}{{/fit}}</td></tr>
        {{/if}}
        </table>
        <div><span class='bold_text'>Payment Details:</span></div>
        <table class = 'productsTable'>
        {{#each Payments}}
            <tr><td>  {{#fit 20 ' '}}{{PaymentType}}{{/fit}}</td><td class = 'priceTd'>{{#fit 34 ' ' 'true'}}{{Amount}}{{/fit}}</td></tr>
        {{/each}}
        </table>
    </div>
    <div class='barcode_container'>{{barcode DocketNumber}}</div>
    {{/if}}
    <div class = 'centered_div'>
        <p></p>
        {{#each ReceiptPrintLines}}
        <div class = 'preserveSpaces'>{{Line}}</div>
        {{/each}}
        {{#if DocketNumber}}
        <p></p>
    <div>_________________________________________________________</div>
    <div>No exchanges, credits, or refunds will be issued without
    proof of purchase. Garments presented for return must be
    in original condition and returned within 30 days of
    purchase.</div>
    </div>{{/if}}
</body>
</html>`

export const defaultDigitalSaleReturnsReceiptFormat = `<html><head><style>html{height: 0;}body{width: 500px;font-family: 'Consolas';font-size: 16px;}h4{text-align: center;}h3{text-align: center;}.centered_div {text-align: center;}.bold_text{font-weight: bold;}.logo_container {border: 0px solid transparent;}.barcode_container{text-align: center;}.watermark_container {background-repeat: repeat-y;background-position: center;width: inherit;background-image: watermark_to_be_replaced_with_link;position: absolute;height: 100%;opacity: 0.2;}.headerTable{width: 100%;}th{text-align:left;}.productsTable{width:100%;padding-left: 20px;}.priceTd{text-align: right;}.totalsTable{width: 100%;text-align: right;}.productTd{padding-left: 20px;}.reasonTd{padding-left: 40px;}.preserveSpaces{white-space: pre;}</style></head>
<body>
    <div class = 'watermark_container'></div>
    <div class='logo_container'>{{image Logo}}</div>
    <h3> Store Hours: 9am-10pm, closed Sun.</h3>
    <div class='centered_div'>
        {{#if Shop.Name}}<div class = 'bold_text'>{{Shop.Name}}</div>{{/if}}
        {{#if Shop.Address.Address1}}<div>{{Shop.Address.Address1}}</div>{{/if}}
        {{#if Shop.Address.Address2}}<div>{{Shop.Address.Address2}}</div>{{/if}}
        <div>{{Shop.Address.City}} {{Shop.Address.State}} {{Shop.Address.PostCode}}</div>
        {{#if Shop.Phone}}<div>Ph. {{Shop.Phone}}</div>{{/if}}
        {{#if Shop.Email}}<div>{{Shop.Email}}</div>{{/if}}
        ABN 1234567890
    </div>
    <div>
    _________________________________________________________
    {{#if HasReturn}}
      <div class='centered_div'>
        <div><b>Original Store: </b>{{OriginalStoreCode}}</div>
        <div><b>Original Docket No: </b>{{OriginalDocketNumber}}</div>
        <div>&nbsp;</div>
      </div>
    {{/if}}
    {{#if IsReturnFromOrder}}
      <div class='centered_div'>
          <div><b>Customer Order Number: </b>{{OriginalOrderNumber}}</div>
          <div><b>Reference Number: </b>{{OriginalOrderNumber}}</div>
          <div>&nbsp;</div>
      </div>
    {{/if}}
    {{#if DocketNumber}}
        <div>
            <table class = 'headerTable'>
                <tr>
                    <th>Docket No.</th><th>Served By</th><th>Till No.</th><th>Date/Time</th>
                </tr>
                <tr>
                    <td>{{#fit 15 ' '}}{{DocketNumber}}{{/fit}}</td>
                    <td>{{#fit 13 ' '}}{{SalesRep.FirstName}}{{/fit}}</td>
                    <td>{{#fit 9 ' '}}{{Device.Id}}{{/fit}}</td>
                    <td>{{#fit 19 ' ' 'true'}}{{formatDate TimeOfSale 'dd/MM/yyyy h:mm tt'}}{{/fit}}</td>
                </tr>
            </table>
        </div>
    {{else}}
        <div>
            <table class = 'headerTable'>
                <tr>
                    <th>Served By</th><th>Till No.</th><th>Date/Time</th>
                </tr>
                <tr>
                    <td>{{#fit 28 ' '}}Served By{{/fit}}</td>
                    <td>{{#fit 9 ' '}}Till No.{{/fit}}</td>
                    <td>{{#fit 19 ' ' 'true'}}Date/Time{{/fit}}</td>
                </tr>
            </table>
        </div>
    {{/if}}
    {{#if OwnerShop}}
       <div>
          <table class = 'headerTable'>
              <tr>
                  <td>{{#fit 20 ' ' 'false'}}On behalf of store:{{/fit}}</td>
                  <td>{{#fit 36 ' ' 'false'}}{{OwnerShop.StoreNumber}} {{OwnerShop.Name}}{{/fit}}</td>
              </tr>
          </table>
      </div>
    {{/if}}
    {{#if Customer}}
        <div>_________________________________________________________</div>
        <div class= 'bold_text'> Customer Details:</div>
        <div>{{Customer.FirstName}} {{Customer.LastName}}</div>
        {{#if Customer.Email}}
            <div>Email: {{Customer.Email}}</div>
        {{/if}}
        {{#if Customer.Phone}}
            <div>Ph. {{Customer.Phone}}</div>
        {{/if}}
    {{/if}}
        <div>_________________________________________________________</div>
    {{#if DocketNumber}}
    <div class = 'bold_text'>Product Description:</div>
    <table class = 'productsTable'>
        {{#each Details}}
            <tr><td>{{Product.StyleName}} {{Product.Colour}} {{Product.Size}}</td></tr>
            <tr><td class = 'productTd'>  {{#fit 44 ' '}}{{Product.StyleCode}} {{Price}} x {{Quantity}} unit(s){{/fit}}</td><td></td><td class = 'priceTd'>{{#fit 10 ' ' 'true'}}{{Amount}}{{/fit}}</td></tr>
            {{#if HasReason}}
                <tr><td class = 'reasonTd'><b>Return Reason: </b>{{ReturnReason}}</td></tr>
            {{/if}}
            {{#each Discounts}}
                <tr><td class = 'productTd'><span class='bold_text'>Discount: </span>{{#fit 26 ' '}}{{Type}}{{/fit}}</td><td>{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}</td><td class = 'priceTd'>{{#fit 10 ' ' 'true'}}{{Amount}}{{/fit}}</td></tr>
            {{/each}}
            {{#each Promotions}}
        		<tr><td class = 'productTd'><span class='bold_text'>Promotion: </span>{{#fit 25 ' '}}{{Type}}{{/fit}}</td><td>{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}</td><td class = 'priceTd'>{{#fit 10 ' ' 'true'}}{{Amount}}{{/fit}}</td></tr>
        	{{/each}}
        	{{#each Loyalties}}
        		<tr><td class = 'productTd'><span class='bold_text'>Loyalty: </span>{{#fit 27 ' '}}{{Type}}{{/fit}}</td><td>{{#fit 7 ' ' 'true'}}{{Percent}}%{{/fit}}</td><td class = 'priceTd'>{{#fit 10 ' ' 'true'}}{{Amount}}{{/fit}}</td></tr>
        	{{/each}}
        {{/each}}
    </table>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div><span class='bold_text'>Total Units: {{TotalUnits}}</span>
    {{#if HasReturn}}
      <div><span class='bold_text'>Total Returns: {{TotalReturns}}</span>
    {{/if}}
        <table class = 'totalsTable'>
            <tr><td><span class='bold_text'>{{#fit 42 ' ' 'true'}}Total Inc Tax{{/fit}}</span></td><td>{{#fit 14 ' ' 'true'}}{{TotalPrice}}{{/fit}}</td></tr>
            <tr><td>{{#fit 42 ' ' 'true'}}Total Tax{{/fit}}{{#fit 14 ' ' 'true'}}</td><td>{{TotalTax}}{{/fit}}</td></tr>
        {{#if TotalDiscount}}
            <tr><td>{{#fit 42 ' ' 'true'}}Total Discount{{/fit}}</td><td>{{#fit 14 ' ' 'true'}}{{TotalDiscount}}{{/fit}}</td></tr>
        {{/if}}
        </table>
        <div><span class='bold_text'>Payment Details:</span></div>
        <table class = 'productsTable'>
        {{#each Payments}}
            <tr><td>  {{#fit 20 ' '}}{{PaymentType}}{{/fit}}</td><td class = 'priceTd'>{{#fit 34 ' ' 'true'}}{{Amount}}{{/fit}}</td></tr>
        {{/each}}
        </table>
    </div>
    <div class='barcode_container'>{{barcode DocketNumber}}</div>
    {{/if}}
    <div class = 'centered_div'>
        <p></p>
        {{#each ReceiptPrintLines}}
        <div class = 'preserveSpaces'>{{Line}}</div>
        {{/each}}
        {{#if DocketNumber}}
        <p></p>
    <div>_________________________________________________________</div>
    <div>No exchanges, credits, or refunds will be issued without
    proof of purchase. Garments presented for return must be
    in original condition and returned within 30 days of
    purchase.</div>
    </div>{{/if}}
</body>
</html>`

export const defaultDigitalEmailBodyFormat = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width">
        <title>Digital receipt</title>
        <style media="all" type="text/css">
@media only screen and (max-width: 640px) {
  table[class=body] h1,
  table[class=body] h2,
  table[class=body] h3,
  table[class=body] h4 {
    font-weight: 600 !important;
  }
  table[class=body] h1 {
    font-size: 22px !important;
  }
  table[class=body] h2 {
    font-size: 18px !important;
  }
  table[class=body] h3 {
    font-size: 16px !important;
  }
  table[class=body] .content,
  table[class=body] .wrapper {
    padding: 10px !important;
  }
  table[class=body] .container {
    padding: 0 !important;
    width: 100% !important;
  }
  table[class=body] .btn table,
  table[class=body] .btn a {
    width: 100% !important;
  }
  table[class=body] .signature td {
    display: inline-block !important;
  }
  table[class=body] .banner .icon {
    display: none;
    padding: 0;
  }
  table[class=body] .banner .text {
    text-align: center !important;
  }
}
</style>
        <!--[if mso 12]>
            <style type="text/css">
            	.flexibleContainer{display:block !important; width:100% !important;}
            </style>
        <![endif]-->
        <!--[if mso 14]>
            <style type="text/css">
            	.flexibleContainer{display:block !important; width:100% !important;}
            </style>
        <![endif]-->
    </head>
    <body class="body" style="margin: 0; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; height: 100% !important; line-height: 1.6em; -webkit-font-smoothing: antialiased; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; width: 100% !important; background-color: #e4f6ff;">
    	<center>
        	<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background-color: #e4f6ff;" bgcolor="#e4f6ff">
            	<tr>
                	<td align="center" valign="top" id="bodyCell" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;">
                    	<!-- EMAIL CONTAINER // -->
                    	<table border="0" cellpadding="0" cellspacing="0" width="600" id="emailBody" class="container" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 600px; display: block; margin: 0 auto !important; max-width: 600px; padding: 20px;">
						<tr>
						  <td align="center" valign="top" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;">
						   <!-- CENTERING TABLE // -->
						   <table border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;">
						     <tr>
						       <td align="center" valign="top" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;">
						         <!-- FLEXIBLE CONTAINER // -->
						         <table border="0" cellpadding="0" cellspacing="0" width="100%" class="flexibleContainer" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;">
						           <tr>
						             <td align="center" valign="top" width="600" class="flexibleContainerCell" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;">
						              <!-- CONTENT TABLE // -->
						              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;">
						                <tr>
						                  <td valign="top" class="textContent" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;">
<table class="main" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background: #ffffff; border-radius: 4px; padding: 10px; border: 5px solid #d2e9f7;" width="100%">
  <tr>
    <td class="wrapper" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top; padding: 20px;" valign="top">
      <table style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
        <tr>
          <td style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;" valign="top">
            <img src="http://res.cloudinary.com/apparel21/image/upload/v1450841564/email/envimo.png" width="230" height="60" style="-ms-interpolation-mode: bicubic; max-width: 100%; height: 60px; margin-bottom: 30px;margin-top: 20px;" alt="ENVIMO">
            <h4 style="color: #231f20; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-weight: bold; line-height: 1.4em; margin: 0; margin-bottom: 47px; font-size: 20px;">
              Purchase receipt from {{Shop.Name}}.
            </h4>
			<p style="font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 16px; font-weight: normal; margin: 0; margin-bottom: 21px;">
              Hi {{Customer.FirstName}},
            </p>
            <p style="font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 40px;">
              Thank you for your purchase at shop {{Shop.Name}}. Please find the digital copy of the purchase receipt attached.
            </p>
            <p style="font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 16px; font-weight: normal; margin: 0; margin-bottom: 15px;">
              Kind Regards,
              <br>
              The team at {{Shop.Name}}
            </p>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>

<style>
@media only screen and (max-width: 480px) {
  body {
    width: 100% !important;
    min-width: 100% !important;
  }
  table[id="emailBody"], table[class="flexibleContainer"] {
    width: 100% !important;
  }
  img[class="flexibleImage"] {
    height: auto !important;
    width: 100% !important;
  }
  table[class="emailButton"] {
    width: 100% !important;
  }
  td[class="buttonContent"] {
    padding: 0 !important;
  }
  td[class="buttonContent"] a {
    padding: 15px !important;
  }
  td[class="textContentLast"], td[class="imageContentLast"] {
    padding-top: 20px !important;
  }
  table[class="banner"] td[class="icon"] {
    display: none !important;
  }
  table[class="banner"] td[class="text"] {
    text-align: center !important;
  }
  td[id="bodyCell"] {
    padding-top: 10px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
</style>

						                  </td>
						                </tr>
						              </table>
						              <!-- // CONTENT TABLE -->
						            </td>
						          </tr>
						        </table>
						        <!-- // FLEXIBLE CONTAINER -->
						      </td>
						    </tr>
						  </table>
						  <!-- // CENTERING TABLE -->
						</td>
						</tr>
						<!-- // MODULE ROW -->
                        </table>
                    	<!-- // EMAIL CONTAINER -->
                    </td>
                </tr>
            </table>
        </center>
        <style type="text/css">
@media only screen and (max-width: 480px) {
  body {
    width: 100% !important;
    min-width: 100% !important;
  }
  table[id="emailBody"], table[class="flexibleContainer"] {
    width: 100% !important;
  }
  img[class="flexibleImage"] {
    height: auto !important;
    width: 100% !important;
  }
  table[class="emailButton"] {
    width: 100% !important;
  }
  td[class="buttonContent"] {
    padding: 0 !important;
  }
  td[class="buttonContent"] a {
    padding: 15px !important;
  }
  td[class="textContentLast"], td[class="imageContentLast"] {
    padding-top: 20px !important;
  }
  td[id="bodyCell"] {
    padding-top: 10px !important;
    padding-Right: 10px !important;
    padding-Left: 10px !important;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
}
</style>
    </body>
</html>`

export const defaultDigitalEmailReturnsBodyFormat = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width">
        <title>Digital receipt</title>
        <style media="all" type="text/css">
@media only screen and (max-width: 640px) {
  table[class=body] h1,
  table[class=body] h2,
  table[class=body] h3,
  table[class=body] h4 {
    font-weight: 600 !important;
  }
  table[class=body] h1 {
    font-size: 22px !important;
  }
  table[class=body] h2 {
    font-size: 18px !important;
  }
  table[class=body] h3 {
    font-size: 16px !important;
  }
  table[class=body] .content,
  table[class=body] .wrapper {
    padding: 10px !important;
  }
  table[class=body] .container {
    padding: 0 !important;
    width: 100% !important;
  }
  table[class=body] .btn table,
  table[class=body] .btn a {
    width: 100% !important;
  }
  table[class=body] .signature td {
    display: inline-block !important;
  }
  table[class=body] .banner .icon {
    display: none;
    padding: 0;
  }
  table[class=body] .banner .text {
    text-align: center !important;
  }
}
</style>
        <!--[if mso 12]>
            <style type="text/css">
            	.flexibleContainer{display:block !important; width:100% !important;}
            </style>
        <![endif]-->
        <!--[if mso 14]>
            <style type="text/css">
            	.flexibleContainer{display:block !important; width:100% !important;}
            </style>
        <![endif]-->
    </head>
    <body class="body" style="margin: 0; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; height: 100% !important; line-height: 1.6em; -webkit-font-smoothing: antialiased; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; width: 100% !important; background-color: #e4f6ff;">
    	<center>
        	<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background-color: #e4f6ff;" bgcolor="#e4f6ff">
            	<tr>
                	<td align="center" valign="top" id="bodyCell" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;">
                    	<!-- EMAIL CONTAINER // -->
                    	<table border="0" cellpadding="0" cellspacing="0" width="600" id="emailBody" class="container" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 600px; display: block; margin: 0 auto !important; max-width: 600px; padding: 20px;">
						<tr>
						  <td align="center" valign="top" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;">
						   <!-- CENTERING TABLE // -->
						   <table border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;">
						     <tr>
						       <td align="center" valign="top" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;">
						         <!-- FLEXIBLE CONTAINER // -->
						         <table border="0" cellpadding="0" cellspacing="0" width="100%" class="flexibleContainer" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;">
						           <tr>
						             <td align="center" valign="top" width="600" class="flexibleContainerCell" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;">
						              <!-- CONTENT TABLE // -->
						              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;">
						                <tr>
						                  <td valign="top" class="textContent" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;">
<table class="main" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background: #ffffff; border-radius: 4px; padding: 10px; border: 5px solid #d2e9f7;" width="100%">
  <tr>
    <td class="wrapper" style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top; padding: 20px;" valign="top">
      <table style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
        <tr>
          <td style="box-sizing: border-box; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;" valign="top">
            <img src="http://res.cloudinary.com/apparel21/image/upload/v1450841564/email/envimo.png" width="230" height="60" style="-ms-interpolation-mode: bicubic; max-width: 100%; height: 60px; margin-bottom: 30px;margin-top: 20px;" alt="ENVIMO">
            <h4 style="color: #231f20; font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-weight: bold; line-height: 1.4em; margin: 0; margin-bottom: 47px; font-size: 20px;">
              Receipt from {{Shop.Name}}.
            </h4>
			<p style="font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 16px; font-weight: normal; margin: 0; margin-bottom: 21px;">
              Hi {{Customer.FirstName}},
            </p>
            <p style="font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 40px;">
              Thank you for your purchase/refund/return at shop {{Shop.Name}}. Please find the digital copy of the receipt attached.
            </p>
            <p style="font-family: Avenir, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 16px; font-weight: normal; margin: 0; margin-bottom: 15px;">
              Kind Regards,
              <br>
              The team at {{Shop.Name}}
            </p>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>

<style>
@media only screen and (max-width: 480px) {
  body {
    width: 100% !important;
    min-width: 100% !important;
  }
  table[id="emailBody"], table[class="flexibleContainer"] {
    width: 100% !important;
  }
  img[class="flexibleImage"] {
    height: auto !important;
    width: 100% !important;
  }
  table[class="emailButton"] {
    width: 100% !important;
  }
  td[class="buttonContent"] {
    padding: 0 !important;
  }
  td[class="buttonContent"] a {
    padding: 15px !important;
  }
  td[class="textContentLast"], td[class="imageContentLast"] {
    padding-top: 20px !important;
  }
  table[class="banner"] td[class="icon"] {
    display: none !important;
  }
  table[class="banner"] td[class="text"] {
    text-align: center !important;
  }
  td[id="bodyCell"] {
    padding-top: 10px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
</style>

						                  </td>
						                </tr>
						              </table>
						              <!-- // CONTENT TABLE -->
						            </td>
						          </tr>
						        </table>
						        <!-- // FLEXIBLE CONTAINER -->
						      </td>
						    </tr>
						  </table>
						  <!-- // CENTERING TABLE -->
						</td>
						</tr>
						<!-- // MODULE ROW -->
                        </table>
                    	<!-- // EMAIL CONTAINER -->
                    </td>
                </tr>
            </table>
        </center>
        <style type="text/css">
@media only screen and (max-width: 480px) {
  body {
    width: 100% !important;
    min-width: 100% !important;
  }
  table[id="emailBody"], table[class="flexibleContainer"] {
    width: 100% !important;
  }
  img[class="flexibleImage"] {
    height: auto !important;
    width: 100% !important;
  }
  table[class="emailButton"] {
    width: 100% !important;
  }
  td[class="buttonContent"] {
    padding: 0 !important;
  }
  td[class="buttonContent"] a {
    padding: 15px !important;
  }
  td[class="textContentLast"], td[class="imageContentLast"] {
    padding-top: 20px !important;
  }
  td[id="bodyCell"] {
    padding-top: 10px !important;
    padding-Right: 10px !important;
    padding-Left: 10px !important;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
}
</style>
    </body>
</html>`

export const defaultEmailSubject = 'Please find attached your receipt for docket number {{DocketNumber}}'

export const defaultReturnsEmailSubject = 'Please find attached your receipt for docket number {{DocketNumber}}'

export const defaultTransferOutReceiptFormat = `{{image Logo}}

{{font1}}{{center}}{{bold:on}}TRANSFER OUT{{bold:off}}
{{font2}}{{left}}________________________________________________________
{{center}}{{bold:on}}Transfer No: {{TransferNumber}}{{bold:off}}
{{center}}{{bold:on}}Con Note No: {{#if ConNoteNumber}}{{ConNoteNumber}}{{/if}}{{bold:off}}

{{left}}{{#fit 15 ' '}}Docket No.{{/fit}}{{#fit 13 ' '}}Operator{{/fit}}{{#fit 9 ' '}}Till No.{{/fit}}{{#fit 19 ' ' 'true'}}Date/Time{{/fit}}
{{#fit 15 ' '}}{{DocketNumber}}{{/fit}}{{#fit 12 ' '}}{{#fit 10}}{{SalesRep.FirstName}}{{/fit}} {{#fit 1}}{{SalesRep.LastName}}{{/fit}}{{/fit}} {{#fit 9 ' '}}{{Device.Id}}{{/fit}}{{#fit 19 ' ' 'true'}}{{formatDate TimeOfReceipt 'dd/MM/yyyy h:mm tt'}}{{/fit}}{{#if OwnerShop}}
{{#fit 20 ' ' 'false'}}On behalf of store:{{/fit}}{{#fit 36 ' ' 'false' }}{{OwnerShop.StoreNumber}} {{OwnerShop.Name}}{{/fit}}{{/if}}
________________________________________________________
{{StoreNumber}}
{{bold:on}}Sent From:{{bold:off}}
  {{font2}}{{bold:on}}{{#if Shop.StoreNumber}}{{Shop.StoreNumber}} {{/if}}{{#if Shop.Name}}{{Shop.Name}}{{/if}}{{bold:off}}{{#if Shop.Address.Address1}}
  {{Shop.Address.Address1}}{{/if}}{{#if Shop.Address.Address2}}
  {{Shop.Address.Address2}}{{/if}}
  {{Shop.Address.City}} {{Shop.Address.State}} {{Shop.Address.PostCode}}{{#if Shop.Phone}}
  Ph. {{Shop.Phone}}{{/if}}{{#if Shop.Email}}
  {{Shop.Email}}{{/if}}

Authorised By: {{SalesRep.Code}}

Date: {{formatDate TimeOfReceipt 'dd/MM/yyyy h:mm tt'}}
________________________________________________________

{{bold:on}}Deliver To:{{bold:off}}
  {{font2}}{{bold:on}}{{#if To.StoreNumber}}{{To.StoreNumber}} {{/if}}{{#if To.Name}}{{To.Name}}{{/if}}{{bold:off}}{{#if To.Address.Address1}}
  {{To.Address.Address1}}{{/if}}{{#if To.Address.Address2}}
  {{To.Address.Address2}}{{/if}}
  {{To.Address.City}} {{To.Address.State}} {{To.Address.PostCode}}{{#if To.Phone}}
  Ph. {{To.Phone}}{{/if}}{{#if To.Email}}
  {{To.Email}}{{/if}}

________________________________________________________

{{bold:on}}Carrier:{{bold:off}} {{#if Carrier}}{{Carrier}}{{/if}}

{{bold:on}}Consignment Note:{{bold:off}} {{#if ConNoteNumber}}{{ConNoteNumber}}{{/if}}

{{bold:on}}Signature:{{bold:off}}


{{bold:on}}Date:{{bold:off}}

________________________________________________________

{{#if TransferNumber}}{{bold:on}}{{#fit 46 ' '}}Product Description:{{/fit}} Qty{{bold:off}}
{{#each Details}}{{#fit 46 ' '}}{{Product.StyleName}} {{Product.Colour}} {{Product.Size}}{{/fit}} {{Quantity}}
{{/each}}
{{bold:on}}{{#fit 46 ' '}}Total Units:{{/fit}} {{TotalUnits}}

{{center}}{{barcode DocketNumber}}
{{/if}}

`