import authenticator from '../auth/authenticator'
import { GetApiRequest } from '../utils/ApiUtils'
import fetcher from './ApiMethods'

export default class DeviceApi {

  static loadDevices (tenantId: number) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/devices`, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static deactivateDevice (deviceId: number, tenantId: number) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/devices/${deviceId}`, {
      method: 'DELETE',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

}
