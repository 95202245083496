import menuApi from './../api/MenusApi'
import { Menu } from './../types/Menu'
import { loadTenants, sessionCompanySelection } from './sessionActions'
import * as Toast from '../utils/Toast'

export const MENU_ORDERCHANGE = 'MENU_ORDERCHANGE'
export const MENU_REORDER = 'MENU_REORDER'
export const MENU_ITEM_ORDERCHANGE = 'MENU_ITEM_ORDERCHANGE'
export const MENU_ITEM_REORDER = 'MENU_ITEM_REORDER'
export const MENU_ADD = 'MENU_ADD'
export const MENU_REVERSE = 'MENU_REVERSE'
export const MENU_NAME_CHANGED = 'MENU_NAME_CHANGED'
export const MENU_REMOVE = 'MENU_REMOVE'
export const MENU_COMPANY_SELECT = 'MENU_COMPANY_SELECT'
export const MENU_TENANT_SELECT = 'MENU_TENANT_SELECT'
export const MENUS_LOADING = 'MENUS_LOADING'
export const MENUS_LOADED = 'MENUS_LOADED'
export const MENUS_SAVING = 'MENUS_SAVING'
export const MENUS_SAVED = 'MENUS_SAVED'
export const MENU_LOADED = 'MENU_LOADED'
export const MENU_REDIRECT_LISTING = 'MENU_REDIRECT_LISTING'
export const MENU_INVALID = 'MENU_INVALID'

export const MENUS_UPDATED = 'MENUS_UPDATED'
export const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS'
export const DELETE_MENU_ERROR = 'DELETE_MENU_ERROR'
export const MENU_API_STARTED = 'MENU_API_STARTED'
export const TAGS_LOADED = 'TAGS_LOADED'
export const CLEAR_MENUS = 'CLEAR_MENUS'
export const SET_DELETE_MENU_SUCCESS = 'SET_DELETE_MENU_SUCCESS'

export const SUBMENU_ADD = 'SUBMENU_ADD'
export const SUBMENU_TITLE_CHANGE = 'SUBMENU_TITLE_CHANGE'
export const SUBMENU_DELETE = 'SUBMENU_DELETE'
export const SUBMENU_MATCH = 'SUBMENU_MATCH'
export const SUBMENU_EXPAND = 'SUBMENU_EXPAND'

export const RULE_DELETE = 'RULE_DELETE'
export const RULE_ADD = 'RULE_ADD'
export const RULE_CHANGE_TAGGROUP = 'RULE_CHANGE_TAGGROUP'
export const RULE_CHANGE_TAGS = 'RULE_CHANGE_TAGS'
export const RULE_CHANGE_OPERATOR = 'RULE_CHANGE_OPERATOR'

export const DELETE_MENU_STARTED = 'DELETE_MENU_STARTED'
export const DELETE_MENU_FINISHED = 'DELETE_MENU_FINISHED'

export function menuApiSaveStarted () {
  return { type: MENUS_SAVING }
}

export function menuApiSaved (result: Menu | null, success: boolean) {
  return { type: MENUS_SAVED, result, success }
}

export function subFilterMatch (id: any, all: boolean) {
  return { type: SUBMENU_MATCH, id, all }
}

export function changeRuleTagGroup (ruleId: number, tagGroup: string) {
  return { type: RULE_CHANGE_TAGGROUP, ruleId, tagGroup }
}

export function changeRuleTags (ruleId: number, tags: string[]) {
  return { type: RULE_CHANGE_TAGS, ruleId, tags }
}

export function changeRuleOperator (ruleId: number, operator: number) {
  return { type: RULE_CHANGE_OPERATOR, ruleId, operator }
}

export function menuCompanySelect (id: any) {
  return { type: MENU_COMPANY_SELECT, id }
}

export function addSubFilter (parentSubFilterId: number) {
  return { type: SUBMENU_ADD, parentSubFilterId }
}

export function deleteSubFilter (id: number) {
  return { type: SUBMENU_DELETE, id }
}
export function deleteRule (id: number) {
  return { type: RULE_DELETE, id }
}
export function addRule (subFilterId: number) {
  return { type: RULE_ADD, subFilterId }
}

export function changeSubFilterTitle (id: number, title: string) {
  return { type: SUBMENU_TITLE_CHANGE, id, title }
}

export function menusLoaded (menus: any) {
  return { type: MENUS_LOADED, menus }
}

export function menuLoaded (menu: any) {
  return { type: MENU_LOADED, menu }
}

export function menuInvalidate () {
  return { type: MENU_INVALID }
}

export function tagsLoaded (tags: any) {
  return { type: TAGS_LOADED, tags }
}

export function menusLoading () {
  return { type: MENUS_LOADING }
}

export function ChangeGroupOrder (state: any, parentSubFilterId: any) {
  return { type: MENU_ORDERCHANGE, items: state, parentSubFilterId }
}

export function ReorderGroup (item: any, newIndex: any, parentSubFilterId: any) {
  return { type: MENU_REORDER, item, newIndex, parentSubFilterId }
}

export function expandSubFilter (id: any) {
  return { type: SUBMENU_EXPAND, id }
}

export function saveMenu (menu: Menu) {
  return (dispatch: any) => {
    if (!menu.name || menu.name.trim().length === 0) {
      Toast.showErrorToast('Name cannot be blank')
      dispatch(menuInvalidate())

      return
    }

    if (menu.subFilters.some(p => (!p.title || p.title.trim().length === 0))) {
      Toast.showErrorToast('Sub Menu title can not be empty')
      dispatch(menuInvalidate())
      return
    }

    const data = {
      ...menu,
      rules: menu.rules.filter((p) => !(!p.tagGroupId || p.operatorType === undefined))
    }

    if (data.rules.some(p => p.tagGroupId === ''
      || p.operatorType === undefined)
      || data.rules.some(p => !data.ruleChilds.some(q => q.ruleId === p.id))) {
      Toast.showErrorToast('One or more rules are incomplete')
      dispatch(menuInvalidate())

      return
    }

    dispatch(menuApiSaveStarted())
    return menuApi.saveMenu(data).then((response) => {
      try {
        if (response.id) {
          Toast.showSuccessToast('Menu saved')
          dispatch(menuApiSaved(response, true))
          dispatch(loadMenuDetail(response.menuId, menu.tenantId))

        } else if (response.error) {
          dispatch(menuApiSaved(null, false))
          Toast.showErrorToast(response.error)
        } else {
          // dispatch(saveMenuError(response))
          dispatch(menuApiSaved(null, false))
          Toast.showErrorToast(response)
        }
      } catch (e) {
        dispatch(menuApiSaved(null, false))
        Toast.showErrorToast('Unknown error occured')
      }
    }).catch((p) => {
      dispatch(menuApiSaved(null, false))
      Toast.showErrorToast(p)
    })
  }
}

export function ChangeGroupItemOrder (state: any, parent: any) {
  return { type: MENU_ITEM_ORDERCHANGE, state, parent }
}

export function ReorderGroupItem (item: any, newIndex: any, parent: any) {
  return { type: MENU_ITEM_REORDER, item, newIndex, parent }
}

export function MenuAdd (id: any) {
  return { type: MENU_ADD, Id: id }
}

export function GroupRemove (id: any) {
  return { type: MENU_REMOVE, Id: id }
}

export function GroupReverseOrder (parent: any) {
  return { type: MENU_REVERSE, parent }

}

export function NameChange (name: any) {
  return { type: MENU_NAME_CHANGED, name }
}

export function redirectToListing () {
  return { type: MENU_REDIRECT_LISTING }
}

export function menusApiDeleteStarted () {
  return { type: DELETE_MENU_STARTED }
}
export function menusApiDeleteFinished () {
  return { type: DELETE_MENU_FINISHED }
}
export function loadMenus (tenantId: any) {
  return (dispatch: any) => {
    dispatch(menusLoading())
    return menuApi.loadMenus(tenantId).then((response) => {
      if (response) {
        dispatch(menusLoaded(response))
      }
    }).catch(() => {
      dispatch(menusLoaded([]))
    })
  }
}

export function menuTenantSelect (id: any) {
  return (dispatch: any) => {
    dispatch({ type: MENU_TENANT_SELECT, id })
    return menuApi.loadTags(id).then((response) => {
      if (response) {
        dispatch(tagsLoaded(response))
      }
    }).catch(() => {
      // dispatch(menusLoaded([]))
    })
  }
}

export function loadMenuDetail (menuId: any, tenantId: any) {
  return (dispatch: any, getState: any) => {
    dispatch(menusLoading())

    return menuApi.loadMenuDetail(menuId, tenantId).then((response) => {
      if (response) {
        const sessionState = getState().session
        if (sessionState.companyId !== response.companyId) {
          const selectCompany = sessionState.companys.map((x: any) => ({ value: x.id, label: x.name }))
            .find((y: any) => y.value === response.companyId)
          const selectedTenant = sessionState.tenants.map((x: any) => ({ ...x, value: x.id, label: x.name }))
            .find((x: any) => x.id === response.tenantId)
          dispatch(sessionCompanySelection(selectCompany))
          dispatch(loadTenants(response.companyId, response.tenantId))
        }

        dispatch(menuLoaded(new Menu(response)))
      }
    }).catch(() => {
      dispatch(menusLoaded([]))
    })
  }
}
export function deleteMenuSuccess (id: any) {
  return { type: DELETE_MENU_SUCCESS, id }
}

export function deleteMenu (menu: any) {
  return (dispatch: any) => {
    dispatch(menusApiDeleteStarted())
    return menuApi.deleteMenu(menu.id, menu.tenantId).then((response) => {
      if (response) {
        Toast.showErrorToast(response)
      } else {
        Toast.showSuccessToast(`Menu ${menu.name} has been deleted`)
        dispatch(deleteMenuSuccess(menu.id))
      }
      dispatch(menusApiDeleteFinished())
    }).catch((p) => {
      Toast.showErrorToast(p)
      dispatch(menusApiDeleteFinished())
    })
  }
}
export function clearMenus () {
  return { type: CLEAR_MENUS }
}

export function setMenuDeletedSuccess (value: boolean) {
  return { type: SET_DELETE_MENU_SUCCESS, value }
}