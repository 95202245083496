import regPinApi from '../api/RegistrationPinApi'
import deviceApi from '../api/DeviceApi'
import { loadActivityLogs } from './activityLogActions'
import { showErrorToast } from '../utils/Toast'

export const REGPIN_GENERATED = 'REGPIN_GENERATED'

export const REGPINS_LOADED = 'REGPINS_LOADED'
export const REGPINS_LOADING = 'REGPINS_LOADING'

export const DEVICES_LOADED = 'DEVICES_LOADED'
export const DEVICES_LOADING = 'DEVICES_LOADING'
export const DEVICES_LOADING_FAILED = 'DEVICES_LOADING_FAILED'

export const DEVICE_DELETED = 'DEVICE_DELETED'

export const CLEAR_DEVICES = 'CLEAR_DEVICES'

export function clearDevicesPage () {
  return { type: CLEAR_DEVICES }
}

export function newRegPinGenerated (newPin: any) {
  return { type: REGPIN_GENERATED, newPin }
}

export function regPinsLoaded (regPins: any) {
  return { type: REGPINS_LOADED, regPins }
}

export function devicesLoaded (devices: any) {
  return { type: DEVICES_LOADED, devices }
}

export function regPinsLoading () {
  return { type: REGPINS_LOADING }
}

export function devicesLoading () {
  return { type: DEVICES_LOADING }
}
export function devicesLoadingDailed () {
  return { type: DEVICES_LOADING_FAILED }
}

export function deviceDeleted (deviceId: any) {
  return { type: DEVICE_DELETED, deviceId }
}

export function generateNewRegPin (tenantId: any) {
  return (dispatch: any) => {
    dispatch(regPinsLoading())
    return regPinApi.newPin(tenantId).then((response) => {
      if (response) {
        dispatch(newRegPinGenerated(response))
        dispatch(loadActivityLogs('Devices', tenantId))
      }
    }).catch((err) => {
      showErrorToast(err)
    })
  }
}

export function loadRegPins (tenantId: any) {
  return (dispatch: any) => {
    dispatch(regPinsLoading())
    return regPinApi.loadRegPins(tenantId).then((response) => {
      if (Array.isArray(response)) {
        dispatch(regPinsLoaded(response))
      } else {
        dispatch(regPinsLoaded([]))
      }
    }).catch((err) => {
      showErrorToast(err)
    })
  }
}

export function clearRegPins () {
  return (dispatch: any) => {
    dispatch(regPinsLoaded([]))
  }
}

export function clearDevices () {
  return (dispatch: any) => {
    dispatch(devicesLoaded([]))
  }
}

export function loadDevices (tenantId: any) {
  return (dispatch: any) => {
    dispatch(devicesLoading())
    return deviceApi.loadDevices(tenantId).then((response) => {
      if (Array.isArray(response)) {
        dispatch(devicesLoaded(response))
      } else {
        dispatch(devicesLoaded([]))
      }
    }).catch(() => {
      dispatch(devicesLoaded([]))
    })
  }
}

export function deactivateDevice (deviceId: any, tenantId: any) {
  return (dispatch: any) => {
    return deviceApi.deactivateDevice(deviceId, tenantId).then(() => {
      dispatch(deviceDeleted(deviceId))
      dispatch(loadActivityLogs('Devices', tenantId))
    }).catch(err => {
      showErrorToast(err)
    })
  }
}
