import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'

interface Props {
  onHide: (event?: any) => void
  show?: boolean
  message?: string
  onConfirm?: (event?: any) => void
  onCancel?: (event?: any) => void
}

const DeleteModal: React.FC<Props> = ({ onHide, show, message, onConfirm, onCancel }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
        <Modal.Title style={{ marginRight: 'auto'}}>Delete</Modal.Title>
        {/* Custom Close Button */}
        <Button type='button' className='close' aria-label='Close' onClick={onHide} style={{ border: 'none', boxShadow: 'none' }}>
          <span aria-hidden='true'>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <p>{message ? message : 'Are you sure you want to delete?'}</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          style={{ margin: 5, textAlign: 'center' }}
          className='btn btn-danger'
          onClick={onConfirm}
        >
          Delete
        </button>
        <button
          style={{ margin: 5, textAlign: 'center' }}
          className='btn btn-default pull-left'
          onClick={onCancel}
        >
          <span className='text'>Cancel</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteModal
