import authenticator from '../auth/authenticator'
import fetcher from './ApiMethods'
import { Customer } from '../types/Customer'
import { flatMap, map } from 'lodash'
import { GetApiRequest } from '../utils/ApiUtils'

export default class CustomerApi {
  static saveCustomer (customerFilter: Customer, tenantId: any) {
    const fieldOptions = flatMap(customerFilter.groups,
      item => map(item.fieldOptions,
        fieldOption => ({ group: item.group, ...fieldOption, fieldName: fieldOption.name, name: undefined })))

    const payload = {
      ...customerFilter,
      fieldOptions,
      groups: undefined as any
    }
    const isNew = (customerFilter.id)
    const uri = customerFilter.id ? `/api/tenant/${tenantId}/CustomerTemplates/${customerFilter.id}` :
      `/api/tenant/${tenantId}/CustomerTemplates`
    const request = GetApiRequest(uri, {
      method: isNew ? 'PUT' : 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  static loadCustomerDetail (customer: any, tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/CustomerTemplates/${customer}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })
    const request2 = GetApiRequest(`/api/tenant/${tenantId}/DataExplorer/Customer`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })
    fetcher.processFetch(request2)
    return fetcher.processFetch(request)
  }

  static loadCustomers (tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/CustomerTemplates`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static loadTags (tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/CustomerTemplates/GetTagGroups`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static deleteCustomer (id: number, tenantId: string) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/CustomerTemplates/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })
    return fetcher.processFetch(request)
  }
}
