import authenticator from '../auth/authenticator'
import { GetApiRequest } from '../utils/ApiUtils'
import fetcher from './ApiMethods'

export default class StoreApi {

  static loadTags (tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/generalTemplates/GetTagGroups`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static loadDiscountTypes (tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/generalTemplates/GetDiscountTypes`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }
  static loadPaymentTypes (tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/generalTemplates/GetPaymentTypes`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }
}
