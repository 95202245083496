
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React from 'react'
import { ClipLoader } from 'react-spinners'

import SecuredTextInput from './common/SecuredTextInput'
import PasswordStrength from './common/PasswordStrength'
import { ChangeMyPassword } from '../actions/sessionActions'
import withRouter from './Utils/WithRouter'

class ChangePasswordPage extends React.Component <any, any> {
  constructor (props) {
    super(props)
    this.state = { credentials: { currentPwd: '', pwd: '', confirmPwd: '' }, strength: '' }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidUpdate (prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.logged_in !== this.props.logged_in && this.props.logged_in === false) {
      this.props.navigate('/Login')
    }
  }

  async onSubmit (event) {
    event.preventDefault()
    await this.props.changeMyPassword(
      this.state.credentials.currentPwd,
      this.state.credentials.pwd,
      this.state.credentials.confirmPwd
    )    
  }

  onChange (event) {
    const field = event.target.name
    const credentials = { ...this.state.credentials }
    credentials[field] = event.target.value
    return this.setState({ ...this.state, credentials })
  }

  handleChangeStrength = (pwStrength) => {
    this.setState({ strength: pwStrength })
  }

  render () {
    const inputStyle = {
    }
    const state = this.props.forget_success ? { credentials: { currentPwd: '', pwd: '', confirmPwd: '' } } : this.state
    const display = (<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ClipLoader size={40} /></div>)
    return (
      <div>
        {this.props.logged_in ?
          <div style={{ padding: 20 }} className='text-center col-sm-8 col-sm-offset-2  col-md-6 col-md-offset-3 col-lg-4  col-lg-offset-4'>
            <h2 >Change your password<br /></h2>
            <br />
            <form className='text-left'>
              <div className='alert alert-warning'>
                        The password must be between 6–20 characters and contain a mix of:

                <ul>
                  <li> Letters (Aa–Zz)</li>
                  <li> Digits (0–9)</li>
                  <li> Non-alphanumeric characters (i.e. !, $, ?, % and etc.)</li>
                </ul>
                <br/>
                        For security reasons, you will be logged out after changing password.
              </div>
              {this.props.forget_error &&
            <div className='alert alert-danger'>{this.props.forget_error}</div>

              }
              <SecuredTextInput
                maxLength={20}
                name='currentPwd'
                label='Current Password'
                type='password'
                value={state.credentials.currentPwd}
                onChange={e => this.onChange(e)}
                style={inputStyle}
              />
              <SecuredTextInput
                maxLength={20}
                name='pwd'
                label='Password'
                type='Password'
                value={state.credentials.pwd}
                onChange={e => this.onChange(e)}
                style={inputStyle}
              />
              <SecuredTextInput
                maxLength={20}
                name='confirmPwd'
                label='Confirm Password'
                type='Password'
                value={state.credentials.confirmPwd}
                onChange={e => this.onChange(e)}
                style={inputStyle}
              />
              <PasswordStrength
                password={this.state.credentials.pwd}
                onChangeStrength={this.handleChangeStrength}
              />
              { this.state.strength === 'weak' || this.state.strength === '' ?
                <input
                  type='submit'
                  value='Change Password'
                  className='btn btn-primary btn-block'
                  disabled={true}
                /> :
                <input
                  type='submit'
                  value='Change Password'
                  className='btn btn-primary btn-block'
                  onClick={this.onSubmit}
                />
              }
              <br />
              <br />
            </form>
          </div> : display } </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    forget_success: state.session.forgot_success,
    forget_error: state.session.forgot_error,
    logged_in: state.session.logged_in
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ changeMyPassword: ChangeMyPassword }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePasswordPage))
