import { v1 as uuidv1 } from 'uuid'
export class Menu {
  id: string
  name: string
  userName: string | null
  modifiedById: number
  modifiedOn: Date | null
  subFilters: SubFilter[]
  tenantId: string
  companyId: string
  rules: Rule[]
  ruleChilds: RuleChild[]
  tagGroups: TagGroup[]
  constructor (menu?: Menu) {
    if (menu !== undefined) {
      this.id = menu.id
      this.name = menu.name
      this.userName = menu.userName
      this.modifiedById = menu.modifiedById
      this.modifiedOn = menu.modifiedOn
      this.subFilters = menu.subFilters.map((p: any) => new SubFilter(p))
      if (this.subFilters.length) { this.subFilters.find((p) => p.parentSubFilterId === '0').expanded = true }
      this.ruleChilds = menu.ruleChilds.map((p: any) => new RuleChild(p))
      this.rules = menu.rules.map((p: any) => new Rule(p))
      this.tenantId = menu.tenantId
      this.companyId = menu.companyId
      this.tagGroups = menu.tagGroups
    } else {
      this.id = '0'
      this.name = ''
      this.userName = null
      this.modifiedById = 0
      this.modifiedOn = null
      this.subFilters = []
      this.ruleChilds = []
      this.rules = []
    }
  }
}
let subFilterId = 0
export class SubFilter {
  id: string
  parentSubFilterId: string
  isMatchingAll: boolean
  title: string
  expanded: boolean
  type: string = MENU_FILTER
  constructor (subFilter: any) {
    this.id = subFilter.id || (uuidv1()).toString()
    this.isMatchingAll = subFilter.isMatchingAll === undefined ? true : subFilter.isMatchingAll
    this.title = subFilter.title || ''
    this.parentSubFilterId = subFilter.parentSubFilterId
    this.expanded = subFilter.type === PRODUCT_FILTER || subFilter.type === STORE_FILTER
    this.type = subFilter.type
  }

}
let ruleIds = 0

export class Rule {
  id: string
  subFilterId: string
  tagGroupId: string
  operatorType: number
  constructor (o: any) {
    this.id = o.id || uuidv1().toString()
    this.subFilterId = o.subFilterId
    this.tagGroupId = o.tagGroupId
    this.operatorType = o.operatorType || 0
  }
}
let ruleChildId = 0

export class RuleChild {
  id: string
  ruleId: string
  tagId: string
  constructor (o: any) {
    this.id = o.id || uuidv1().toString()
    this.ruleId = o.ruleId
    this.tagId = o.tagId
  }
}

export interface TagGroup {
    id: string
    title: string
    tags: Tag[]
}

export interface Tag {
    id: string
    title: string
}

export interface Filter {
    subFilterId: string
    subFilter: SubFilter
    rules: Rule[]
    ruleChilds: RuleChild[]
    tagGroups?: TagGroup[]
    expanded: boolean
}
export const CUSTOMER_FILTER = 'C'
export const PRODUCT_FILTER = 'P'
export const MENU_FILTER = 'M'
export const STORE_FILTER = 'S'