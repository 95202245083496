import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import Header from './Header'
import Sidebar from './Sidebar'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EnvironmentSelect from './EnvironmentSelect'
import { checkUserLoggedIn } from '../actions/sessionActions'
import * as WebFont from 'webfontloader'
import { useLocation, Outlet } from 'react-router-dom'

import '../css/site.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import ErrorHandler from './common/ErrorHandler'

const sidebarWidth = 200

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const gtag = (...args: any[]) => {
  window.dataLayer.push(args)
}

const App = (props) => {
  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()
  const [context, setContext] = useState({ name: '' })

  const registerWithGA = () => {
    try {
      gtag('send', {
        hitType: 'pageview'
      })
      gtag('event', 'pageview', {
        location: location.pathname,
        EnvimoUserRole: props.role,
        EnvimoUser: props.email,
        EnvimoCompany: props.company,
        EnvimoLandlord: props.landlord
      })
    } catch (e) {
      setTimeout(() => registerWithGA(), 50)
    }
  }

  const loadFont = () => {
    WebFont.load({
      google: {
        families: ['Muli:400,800:latin-ext', 'Material+Icons']
      }
    })
  }

  const registerConfig = () => {
    try {
      gtag('js', new Date())
      gtag('config', 'UA-102204527-1', {
        send_page_view: false,
        custom_map: {
          dimension1: 'EnvimoUserRole',
          dimension2: 'EnvimoUser',
          dimension3: 'EnvimoCompany',
          dimension4: 'EnvimoLandlord'
        }
      })
    } catch (e) {
      setTimeout(() => registerWithGA(), 50)
    }
  }

  useEffect(() => {
    loadFont()
    registerConfig()
    registerWithGA()
    props.checkUserLoggedIn()
  }, [])

  useEffect(() => {
    if (location.pathname !== props.location?.pathname && props.isIOS) {
      setCollapsed(true)
    }
  }, [location.pathname, props.location?.pathname, props.isIOS])

  const headerToggle = () => {
    setCollapsed(!collapsed)
  }

  const width = collapsed || !props.isLoggedIn ? 0 : sidebarWidth
  const style: any = {
    width: `calc(100% - ${width}px)`,
    minHeight: '100vh',
    float: 'right',
    backgroundColor: '#E4F6FF',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: 'url("/images/Envimo-bg.png")',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat'
  }

  return (
    <ErrorHandler>
      <Sidebar width={width} headerClick={headerToggle} />
      <div
        style={style}
        onClick={() => props.isIOS && !collapsed ? headerToggle() : null}
        className='main'
      >
        <Header
          routes={props.routes}
          sidePanelCollapsed={collapsed}
          toggleSidePanel={headerToggle}
          titleName={context.name}
        />
        {props.isLoggedIn && props.role !== 'superuser' ? <EnvironmentSelect /> : null}
        {!props.isLoggedIn ? (
          <Outlet />
        ) : (
          <div
            style={{
              flexGrow: 1,
              flexShrink: 0,
              flexBasis: 'auto',
              margin: '0 20px 20px',
              padding: 20,
              width: 'auto',
              borderRadius: 5
            }}
            className='panel panel-default'
          >
            <Outlet context={setContext}/>
          </div>
        )}
      </div>
      <ToastContainer />
    </ErrorHandler>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      checkUserLoggedIn
    },
    dispatch
  )
}

export default connect(
  (state: any) => ({
    isIOS: state.session.isIOS,
    isLoggedIn: state.session.logged_in,
    role: state.session.role,
    email: state.session.email,
    landlord: state.session.landlord,
    company: state.session.company
  }),
  mapDispatchToProps
)(App)