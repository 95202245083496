import api from '../api/StoreApi'
export const STORE_RULE_DELETE = 'STORE_RULE_DELETE'
export const STORE_RULE_ADD = 'STORE_RULE_ADD'
export const STORE_RULE_CHANGE_TAGGROUP = 'STORE_RULE_CHANGE_TAGGROUP'
export const STORE_RULE_CHANGE_TAGS = 'STORE_RULE_CHANGE_TAGS'
export const STORE_RULE_CHANGE_OPERATOR = 'STORE_RULE_CHANGE_OPERATOR'
export const STORE_SUBMENU_MATCH = 'STORE_SUBMENU_MATCH'
export const STORE_TENANT_SELECT = 'STORE_TENANT_SELECT'
export const STORE_TAGS_LOADED = 'STORE_TAGS_LOADED'
export const STORE_DISCOUNTTYPES_LOADED = 'STORE_DISCOUNTTYPES_LOADED'
export const STORE_DISCOUNTTYPE_TOGGLE = 'STORE_DISCOUNTTYPE_TOGGLE'
export const STORE_PACKTYPE_TOGGLE = 'STORE_PACKTYPE_TOGGLE'
export const STORE_CARRIER_TOGGLE = 'STORE_CARRIER_TOGGLE'
export const STORE_PAYMENTTYPE_TOGGLE = 'STORE_PAYMENTTYPE_TOGGLE'
export const STORE_AP21REWARDSPROGRAM_TOGGLE = 'STORE_AP21REWARDSPROGRAM_TOGGLE'
export const STORE_PAYMENTTYPES_LOADED = 'STORE_PAYMENTTYPES_LOADED'
export const STORE_RULE_LOAD = 'STORE_RULE_LOAD'
export const STORE_CLEAR = 'STORE_CLEAR'
export const STORE_RETAILTAGTYPE_TOGGLE = 'STORE_RETAILTAGTYPE_TOGGLE'

export const STORE_TRANSFEROUT_WAREHOUSE_RULE_DELETE = 'STORE_TRANSFEROUT_WAREHOUSE_RULE_DELETE'
export const STORE_TRANSFEROUT_WAREHOUSE_RULE_ADD = 'STORE_TRANSFEROUT_WAREHOUSE_RULE_ADD'
export const STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_TAGGROUP = 'STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_TAGGROUP'
export const STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_TAGS = 'STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_TAGS'
export const STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_OPERATOR = 'STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_OPERATOR'
export const STORE_TRANSFEROUT_WAREHOUSE_SUBMENU_MATCH = 'STORE_TRANSFEROUT_WAREHOUSE_SUBMENU_MATCH'

export function changeRuleTagGroup (ruleId: number, tagGroup: string) {
  return { type: STORE_RULE_CHANGE_TAGGROUP, ruleId, tagGroup }
}

export function changeRuleTags (ruleId: number, tags: string[]) {
  return { type: STORE_RULE_CHANGE_TAGS, ruleId, tags }
}

export function changeRuleOperator (ruleId: number, operator: number) {
  return { type: STORE_RULE_CHANGE_OPERATOR, ruleId, operator }
}

export function deleteRule (id: number) {
  return { type: STORE_RULE_DELETE, id }
}
export function addRule (subFilterId: number) {
  return { type: STORE_RULE_ADD, subFilterId }
}

export function subFilterMatch (id: any, all: boolean) {
  return { type: STORE_SUBMENU_MATCH, id, all }
}

export function load (
  stockEnquiryFilter: any,
  discountTypes: any,
  packTypes: any,
  carriers: any,
  ap21RewardsPrograms: any,
  paymentTypes: any,
  retailTagTypes: any,
  warehouseSubFilter: any,
  floorToDoorSettings: any
) {
  return { type: STORE_RULE_LOAD, stockEnquiryFilter, discountTypes, packTypes, carriers, ap21RewardsPrograms, paymentTypes,
    retailTagTypes, warehouseSubFilter, floorToDoorSettings }
}

export function toggleDiscountType (selectedDiscountTypeIds: string[]) {
  return (dispatch: any, getState: any) => {
    const toggled = getState().store.discountTypes.find((p: any) => {
      const isSelected = selectedDiscountTypeIds.some(q => p.discountTypeId === q)
      return isSelected !== p.selected
    })
    dispatch({ type: STORE_DISCOUNTTYPE_TOGGLE, discountTypeId: toggled.discountTypeId })
  }
}
export function toggleAp21RewardsProgram (selectedAp21ProgramId: string) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: STORE_AP21REWARDSPROGRAM_TOGGLE, ap21RewardsProgramId: selectedAp21ProgramId })
  }
}

export function toggleRetailTagType (selectedRetailTagTypeId: string[]) {
  return (dispatch: any, getState: any) => {

    if (selectedRetailTagTypeId === null 
      || selectedRetailTagTypeId === undefined
      || (selectedRetailTagTypeId && selectedRetailTagTypeId.length === 1 
          && (selectedRetailTagTypeId[0] === undefined || selectedRetailTagTypeId[0] === null)
      )
    ) {
      dispatch({ type: STORE_RETAILTAGTYPE_TOGGLE, selectedRetailTagTypeId: null })
    } else {
      dispatch({ type: STORE_RETAILTAGTYPE_TOGGLE, selectedRetailTagTypeId: selectedRetailTagTypeId[0] })
    }
  }
}

export function togglePaymentType (selectedPaymentTypeIds: string[]) {
  return (dispatch: any, getState: any) => {
    const toggled = getState().store.paymentTypes.find((p: any) => {
      const isSelected = selectedPaymentTypeIds.some(q => p.paymentTypeId === q)
      return isSelected !== p.selected
    })
    dispatch({ type: STORE_PAYMENTTYPE_TOGGLE, paymentTypeId: toggled.paymentTypeId })
  }
}
export function togglePackType (selectedPackTypeIds: string[]) {
  return (dispatch: any, getState: any) => {
    const toggled = getState().store.packTypes.find((p: any) => {
      const isSelected = selectedPackTypeIds.some(q => p.packTypeId === q)
      return isSelected !== p.selected
    })
    dispatch({ type: STORE_PACKTYPE_TOGGLE, packTypeId: toggled.packTypeId })
  }
}

export function toggleCarrier (selectedCarrierIds: string[]) {
  return (dispatch: any, getState: any) => {
    const toggled = getState().store.carriers.find((p: any) => {
      const isSelected = selectedCarrierIds.some(q => p.carrierId === q)
      return isSelected !== p.selected
    })
    dispatch({ type: STORE_CARRIER_TOGGLE, carrierId: toggled.carrierId })
  }
}

export function clear () {
  return { type: STORE_CLEAR }
}

export function storeTenantSelect (id: any) {
  return (dispatch: any) => {
    dispatch({ type: STORE_TENANT_SELECT, id })

    api.loadDiscountTypes(id).then(r => r && dispatch(discountTypesLoaded(r))).catch()

    api.loadPaymentTypes(id).then(r => r && dispatch(paymentTypesLoaded(r))).catch()

    return api.loadTags(id).then((response) => {
      if (response) {
        dispatch(tagsLoaded(response))
      }
    }).catch(() => {
      // do nothing
    })

  }
}

export function tagsLoaded (tags: any) {
  return { type: STORE_TAGS_LOADED, tags }
}

export function discountTypesLoaded (discountTypes: any) {
  return { type: STORE_DISCOUNTTYPES_LOADED, discountTypes }
}

export function paymentTypesLoaded (paymentTypes: any) {
  return { type: STORE_PAYMENTTYPES_LOADED, paymentTypes }
}

export function changeTransferOutWarehouseRuleTagGroup (ruleId: number, tagGroup: string) {
  return { type: STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_TAGGROUP, ruleId, tagGroup }
}

export function changeTransferOutWarehouseRuleTags (ruleId: number, tags: string[]) {
  return { type: STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_TAGS, ruleId, tags }
}

export function changeTransferOutWarehouseRuleOperator (ruleId: number, operator: number) {
  return { type: STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_OPERATOR, ruleId, operator }
}

export function deleteTransferOutWarehouseRule (id: number) {
  return { type: STORE_TRANSFEROUT_WAREHOUSE_RULE_DELETE, id }
}
export function addTransferOutWarehouseRule (subFilterId: number) {
  return { type: STORE_TRANSFEROUT_WAREHOUSE_RULE_ADD, subFilterId }
}

export function subFilterTransferOutWarehouseMatch (id: any, all: boolean) {
  return { type: STORE_TRANSFEROUT_WAREHOUSE_SUBMENU_MATCH, id, all }
}