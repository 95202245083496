import jwtDecode from 'jwt-decode'
import sessionApi from '../api/SessionApi'
import { showErrorToast } from '../utils/Toast'

export default class Auth {

  static loggedIn (role?: any) {
    if (localStorage.user_session) {
      const claim = this.getUserSession()
      if (!claim) {
        return false
      }
      return !(!!role && claim.role !== role)
    }
    return false
  }

  static logOut () {
    localStorage.removeItem('user_session')
    sessionStorage.clear()
  }

  static async serverLogOut () {
    if (localStorage.user_session && sessionStorage.getItem('loggedIn')) {
      try {
        await sessionApi.invalidateSession()
      } catch (error) {
        showErrorToast(error)
      }
    }
  }

  static getLoggedInToken () {
    return localStorage.user_session
  }

  static async isValidSession () {
    let isValid = false
    await sessionApi.validateSession().then((response) => {
      if (response.success) {
        isValid = true
      }
    }).catch((error) => {
      showErrorToast(error)
    })
    return isValid
  }

  static getUserSession () {
    if (sessionStorage.getItem('loggedIn')) {
      const sess = localStorage.user_session
      if (sess) {
        const claims: any = jwtDecode(localStorage.user_session)
        const dateNow = new Date()
        const trunc = (x: any) => x - x % 1
        const now = trunc(dateNow.getTime() / 1000)
        if (claims.exp < now) {
          this.logOut()
          return null
        }
        return claims
      }
      return null
    } else {
      this.logOut()
      this.serverLogOut()
      return null
    }
  }

  static saveUserSession (value: any) {
    localStorage.setItem('user_session', value)
    sessionStorage.setItem('loggedIn', 'true')
  }
}
