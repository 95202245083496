import React from 'react'
import { Image } from 'react-bootstrap'

export interface Props {
  message?: string,
  imagePath?: string
}

export interface State {
}

export default class EmptyState extends React.Component<Props, State> {
  render () {
    return (
      <div style={{textAlign: 'center', marginTop: 100 }}>
        <Image src={this.props.imagePath ? this.props.imagePath : '/images/EmptyState.png'}/>
        {this.props.message ? <h3 className='text-info'>{this.props.message}</h3> : ''}
      </div>
    )
  }
}