import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input } from './../common/Input'
import { FieldGroup, FieldOption } from '../../types/Customer'


import {
  updateFieldMandatory,
  updateGroupEnabled
} from '../../actions/customerActions'
import ToggleSwitch from '../common/ToggleSwitch'

interface IStateProps {

}

interface IDispatchProps {
    updateFieldMandatory,
    updateGroupEnabled
}

interface IOwnProps {
    group: FieldGroup
}

export class CustomerField extends React.Component<IOwnProps & IStateProps & IDispatchProps, {}> {
  shouldComponentUpdate (nextProps: object, nextState) {
    for (const prop in nextProps) {
      if (nextProps[prop] !== this.props[prop]) {
        return true
      }
    }
    return false
  }

  render () {
    let { group } = this.props
    const enable = group.fieldOptions.every(p => p.enabled)
    const noValidation = group.fieldOptions.every(p => p.noValidation)
    let key = 0
    const alwaysRequired = group.group === 'First Name' || group.group === 'Last Name'
    return (
      <div className='' >
        <div className={'panel panel-default'}>
          <div className='panel-heading'>
            {this.props.group.group}
            <div className='pull-right' style={{ width: '80px', display: 'inline-block' }}>
              <ToggleSwitch
                checked={enable || alwaysRequired}
                onChange={() => this.props.updateGroupEnabled(group.group, !enable)}
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                disabled={alwaysRequired}
              />
            </div>
            {noValidation ? null :
              <div className='pull-right' style={{ width: '80px', display: 'inline-block', marginRight: '10px' }}>
                <ToggleSwitch
                  checked={this.props.group.fieldOptions[0].mandatory || alwaysRequired}
                  disabled={!enable || alwaysRequired}
                  onChange={() => this.props.updateFieldMandatory(group.group,
                    !this.props.group.fieldOptions[0].mandatory,
                    this.props.group.fieldOptions[0].name)}
                  checkedIconText={'Required'}
                  uncheckedIconText={'Optional'}
                /></div>
            }
          </div>
        </div>

      </div>)
  }
}

function mapStateToProps (state): IStateProps {
  return {
  }
}

function mapDispatchToProps (dispatch): IDispatchProps {
  return bindActionCreators({
    updateFieldMandatory,
    updateGroupEnabled
  }, dispatch)
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(CustomerField)
