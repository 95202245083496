export class TransferSettings {
  id: string | null
  active: boolean
  transferReceiptAll: boolean
  transferOverReceiptAllow: boolean
  transferReceiptOtherSku: boolean
  transferForceScanning: boolean
  transferOneShotCarton: boolean

  poReceiptAll: boolean
  poOverReceiptAllow: boolean
  poReceiptOtherSku: boolean
  poForceScanning: boolean
  poOneShotCarton: boolean

  constructor (transferSettings?: TransferSettings) {
    if (transferSettings !== undefined && transferSettings !== null) {
      this.id = transferSettings.id
      this.active = transferSettings.active
      this.transferReceiptAll = transferSettings.transferReceiptAll
      this.transferOverReceiptAllow = transferSettings.transferOverReceiptAllow
      this.transferReceiptOtherSku = transferSettings.transferReceiptOtherSku
      this.transferForceScanning = transferSettings.transferForceScanning
      this.transferOneShotCarton = transferSettings.transferOneShotCarton

      this.poReceiptAll = transferSettings.poReceiptAll
      this.poOverReceiptAllow = transferSettings.poOverReceiptAllow
      this.poReceiptOtherSku = transferSettings.poReceiptOtherSku
      this.poForceScanning = transferSettings.poForceScanning
      this.poOneShotCarton = transferSettings.poOneShotCarton
    } else {
      this.id = null
      this.active = false
      this.transferReceiptAll = false
      this.transferOverReceiptAllow = false
      this.transferReceiptOtherSku = false
      this.transferForceScanning = false
      this.transferOneShotCarton = false

      this.poReceiptAll = false
      this.poOverReceiptAllow = false
      this.poReceiptOtherSku = false
      this.poForceScanning = false
      this.poOneShotCarton = false
    }
  }
}