import { PackType } from './PackType'
import { Carrier } from './Carrier'
import { Filter, SubFilter, STORE_FILTER, RuleChild, Rule } from './Menu'

export class TransferOutSettings {
  id: string
  active: boolean
  packTypes: PackType[]
  carriers: Carrier[]
  forceScanning: boolean
  requestPackAll: boolean
  requestOverPackAllow: boolean
  requestProcessOnceOnly: boolean
  warehouseFilter: Filter
  constructor (settings?: TransferOutSettings) {
    if (settings !== undefined && settings !== null) {
      this.active = settings.active
      this.carriers = settings.carriers
      this.packTypes = settings.packTypes
      this.forceScanning = settings.forceScanning
      this.requestPackAll = settings.requestPackAll
      this.requestOverPackAllow = settings.requestOverPackAllow
      this.requestProcessOnceOnly = settings.requestProcessOnceOnly
      this.warehouseFilter = {
        subFilter: settings.warehouseFilter.subFilter === null ?
          new SubFilter({
            type: STORE_FILTER,
            title: 'New Transfer Destination-Warehouse/Store Filter'
          }) :
          new SubFilter(settings.warehouseFilter.subFilter),
        expanded: !!settings.warehouseFilter.subFilter,
        subFilterId: settings.warehouseFilter.subFilterId,
        ruleChilds: settings.warehouseFilter.ruleChilds.map((p) => new RuleChild(p)),
        rules: settings.warehouseFilter.rules.map((p) => new Rule(p))
      }
      if (settings.warehouseFilter.tagGroups?.length && settings.warehouseFilter.tagGroups.length > 0)
        this.warehouseFilter.tagGroups = settings.warehouseFilter.tagGroups
    } else {
      this.active = false
      this.carriers = [] as Carrier[]
      this.packTypes = [] as PackType[]
      this.forceScanning = false
      this.requestPackAll = false
      this.requestOverPackAllow = false
      this.requestProcessOnceOnly = false
      this.warehouseFilter = {
        subFilter: new SubFilter({
          id: 0, isMatchingAll: true, parentSubFilterId: 0,
          type: STORE_FILTER, title: 'New Transfer Destination-Warehouse/Store Filter', expanded: true
        }),
        ruleChilds: [],
        rules: [],
        subFilterId: '',
        tagGroups: [],
        expanded: true
      }
    }
  }
}