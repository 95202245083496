
import React from 'react'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { forgotPasswordEmail, forgotBack } from '../actions/sessionActions'
import SecuredTextInput from './common/SecuredTextInput'
import Captcha from './common/Captcha'
import withRouter from './Utils/WithRouter'

declare const grecaptcha: any
class ForgotPassword extends React.Component<any, any> {
  recaptchaRef: any

  constructor (props) {
    super(props)
    this.state = { credentials: { email: this.props.email }, captchaVerified: false, shake: false, isLoaded: false }
    this.recaptchaRef = null
    this.setRecaptchaRef = this.setRecaptchaRef.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {    
    this.setState({isLoaded: true})
  }

  setRecaptchaRef (ref) {
    this.recaptchaRef = ref
  }

  onChange (event) {
    const field = event.target.name
    const credentials = this.state.credentials
    credentials[field] = event.target.value
    return this.setState({ credentials })
  }

  async onSubmit (event) {
    event.preventDefault()
    if (this.state.captchaVerified === true || process.env.REACT_APP_ENVIRONMENT === 'local') {
      await this.props.forgotPasswordEmail(this.state.credentials)
      if (!this.props.forget_success) {
        this.setState({captchaVerified: false})
        if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
          grecaptcha?.reset()
        }
      }
    } else {
      this.setState({ shake: true })
      setTimeout(() => {
        this.setState({ shake: false })
      }, 500)
    }
  }

  onBack (event) {
    event.preventDefault()
    this.props.forgotBack()
    this.props.navigate('/Login')
  }

  onCaptchaVerified = () => {
    this.setState({captchaVerified: true})
  }

  onCaptchaExpired = () => {
    this.setState({captchaVerified: false})
  }

  render () {
    const inputStyle = {

    }
    const inlineSpinner = (<div style={{ marginLeft: '20px', height: '15px', display: 'inline-block' }}>
      <ClipLoader size={15} /></div>)
    return (
      <div className='text-center login'>
        <img src='/images/EnvimoLogoNormal.png' style={{ paddingBottom: 30 }} />
        <div className='panel panel-default login-control' style={{ width: 350 }}>
          <h3 className='text-primary text-left'>Reset your password</h3>
          {this.props.forget_success !== true ?
            <p className='text-left'>
              Enter your email address and we will send you a link to reset your password.
            </p>
            : '' }
          {this.props.forget_success !== true ?
            <form className='text-left'>
              {this.props.forget_error ?
                <div className='alert alert-danger' role='alert'>{this.props.forget_error}</div>
                : ''
              }
              <SecuredTextInput
                maxLength={100}
                name='email'
                label='Email'
                value={this.state.credentials.email}
                onChange={this.onChange}
                style={inputStyle}
              />
              {process.env.REACT_APP_ENVIRONMENT !== 'local' &&
                <Captcha
                  isLoaded={this.state.isLoaded}
                  setRef={this.setRecaptchaRef}
                  onCallback={this.onCaptchaVerified}
                  onExpiredCallback={this.onCaptchaExpired}
                  shake={this.state.shake}
                />
              }
              {this.props.response_loading ? <button
                type='submit'
                value='Send password reset email'
                className='btn btn-primary btn-block'
                disabled={true}
              >Send password reset email {inlineSpinner} </button> :
                <input
                  type='submit'
                  value='Send password reset email'
                  className='btn btn-primary btn-block'
                  onClick={this.onSubmit}
                />}
            </form>
            : <form>
              <div className='alert alert-success' role='alert'>{this.props.forget_error}</div>
              <input
                type='submit'
                value='Return to login'
                className='btn btn-primary'
                onClick={e => this.onBack(e)}
              />
            </form>
          }
        </div>
        {this.props.forget_success !== true ? <a
          role='button'
          onClick={e => this.onBack(e)}
        >Login</a> : ''}
      </div>

    )
  }
}

function mapStateToProps (state) {
  return {
    email: state.session.email,
    response_loading: state.session.response_loading,
    forget_success: state.session.forgot_success,
    forget_error: state.session.forgot_error
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ forgotPasswordEmail, forgotBack }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword))
