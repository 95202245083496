import * as client from './promiseClient'

export type LogoBase = {
  id: string
  name: string
}

export type WebLogo = LogoBase & {
  base64Bitmap: string
}

export const uploadLogo = (tenantId: string, logo: string | ArrayBuffer): Promise<LogoBase> => {
  const formData = new FormData()
  if (typeof logo === 'string') {
    // Use it directly as a string
    formData.append('file', new Blob([logo]), 'file')
  } else if (logo instanceof ArrayBuffer) {
    // Convert ArrayBuffer to Blob and append to FormData
    const blob = new Blob([logo])
    formData.append('file', blob, 'file')
  }
  // Convert string to Blob and append to FormData with a filename
  // formData.append('file', new Blob([logo], { type: 'text/plain' }), 'file.txt');

  const request = client.createRequest('POST', formData)
  const headers = request.headers as Record<string, string>
  headers['Accept'] = 'application/json'
  return client.executeRequest(request, `/api/tenant/${tenantId}/logos/create`)
    .then(response => {
      return client.parseJsonResponse<LogoBase>(response)
    })
}

export const deleteLogo = (tenantId: string, id: string): Promise<void> => {
  return client.deleteJson(`/api/tenant/${tenantId}/logos/${id}`)
}

export const fetchLogoList = (tenantId: string): Promise<LogoBase[]> => {
  return client.getJson(`/api/tenant/${tenantId}/logos`)
}

export const fetchWebLogo = (tenantId: string, logoId: string): Promise<WebLogo> => {
  return client.getJson(`/api/tenant/${tenantId}/logos/${logoId}`)
}