import React from 'react'

const TextInput = ({ name, label, onChange, placeholder = undefined,
  disabled = false, value, error = undefined, type = 'text', style = {}, maxLength = 500, className = undefined }) => {
  let wrapperClass = 'form-group'
  if (error && error.length > 0) {
    wrapperClass += ' has-error'
  }

  return (<div className={wrapperClass} >
    <label htmlFor={name}>{label} </label>
    <div className='field'>
      <input
        type={type}
        name={name}
        style={style}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        maxLength={maxLength}
        className={className + ' form-control'}
        autoComplete='off'
      />
      {
        error && <div className='alert alert-danger'>{error} </div>}
    </div>
  </div>)

}

export default TextInput
