import React from 'react'

const Handle = ({ className = '' }) => {
  return (
    <svg className={className} width='20' height='18' viewBox='0 0 10 18' xmlns='http://www.w3.org/2000/svg'>
      <title>sortable handle</title>
      <path
        d={`M0 3.5c0 .275.04.5.09.5h3.82c.05 0
          .09-.225.09-.5v-3c0-.275-.04-.5-.09-.5H.09C.04 0 0 .225 0 .5v3zm0 7c0
          .275.04.5.09.5h3.82c.05 0 .09-.225.09-.5v-3c0-.275-.04-.5-.09-.5H.09C.04
          7 0 7.225 0 7.5v3zm0 7c0 .275.04.5.09.5h3.82c.05 0 .09-.225.09-.5v-3c0-.275-.04-.5-.09-.5H.09c-.05 0-.09.225-.09.5v3zm6-14c0
          .275.04.5.09.5h3.82c.05 0 .09-.225.09-.5v-3c0-.275-.04-.5-.09-.5H6.09C6.04 0 6 .225 6 .5v3zm0 7c0 .275.04.5.09.5h3.82c.05
          0 .09-.225.09-.5v-3c0-.275-.04-.5-.09-.5H6.09c-.05 0-.09.225-.09.5v3zm0 7c0 .275.04.5.09.5h3.82c.05
          0 .09-.225.09-.5v-3c0-.275-.04-.5-.09-.5H6.09c-.05
          0-.09.225-.09.5v3z`}
        fill='#B2B9BC'
        fillRule='evenodd'
      />
    </svg>
  )
}

Handle.displayName = 'SVGHandle'

export default Handle