import AnyOfFilter from './AnyOfFilter'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { togglePaymentType } from '../../actions/storeActions'

function mapStateToProps (state) {
  return {
    heading: 'Payment Type',
    labelKey: 'paymentTypeName',
    valueKey: 'paymentTypeId',
    options: state.store.paymentTypes,
    title: 'Payments'
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    toggleOptions: togglePaymentType
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AnyOfFilter)