export default class User {

  id: string
  email: string
  password: string
  roleId: string
  roleIndex: string
  role: string
  landlordId: string
  landlord: string
  createdById: string
  createdDate: Date | null
  companies: any
  sequence: number | null
  companiesJoined: string | null
  firstName: string
  lastName: string
  userName: string
  constructor (user: any) {
    if (user) {
      this.id = user.id
      this.email = user.email
      this.password = user.password
      this.roleId = user.roleId
      this.roleIndex = user.roleIndex
      this.role = user.role
      this.landlordId = user.landlordId
      this.landlord = user.landlord
      this.createdById = user.createdById
      this.createdDate = user.createdDate
      this.companies = user.companies
      this.sequence = user.sequence
      this.companiesJoined = this.companiesString()
      this.firstName = user.firstName
      this.lastName = user.lastName
      this.userName = user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : ''
    } else {
      this.id = ''
      this.email = ''
      this.password = ''
      this.roleId = ''
      this.roleIndex = ''
      this.role = ''
      this.landlordId = ''
      this.landlord = ''
      this.createdById = ''
      this.createdDate = null
      this.companies = []
      this.sequence = null
      this.companiesJoined = null
      this.firstName = ''
      this.lastName = ''
      this.userName = ''
    }
  }

  companiesString () {
    return this.companies.map((elem: any) => {
      return elem.name
    }).join(', ')
  }
}
