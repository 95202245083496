import React from 'react'
import TemplateList from '../TemplateList'
import {ShopUpdateType} from '../../types/Template'

class PrinterTemplateList extends React.Component {

  render () {
    return (
      <TemplateList title='Docket Format Templates' description='Create format for store dockets'
        templateType={ShopUpdateType.PrinterTemplateId} url='/receipts'
      >
        Customise content and structure of information on your docket.
      </TemplateList>
    )
  }
}

export default PrinterTemplateList
