import React from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'

const menuList = [
  {
    header: 'Account',
    items: [
      {
        title: 'Landlords',
        route: '/landlords',
        role: ['superuser']
      },
      {
        title: 'Companies',
        route: '/companies',
        role: ['superuser', 'admin', 'companyAdmin']
      },
      {
        title: 'Users',
        route: '/admin-users',
        role: []
      },
      {
        title: 'App Info',
        route: '/appinfo',
        role: ['superuser']
      }
    ]
  },
  {
    header: 'Templates',
    items: [
      {
        title: 'Store',
        route: '/store',
        role: ['companyAdmin', 'admin']
      },
      {
        title: 'Navigations',
        route: '/navigations',
        role: ['companyAdmin', 'admin']
      },
      {
        title: 'Products',
        route: '/products',
        role: ['companyAdmin', 'admin']
      },
      {
        title: 'Docket Formats',
        route: '/receipts',
        role: ['companyAdmin', 'admin']
      },
      {
        title: 'Customer',
        route: '/customer',
        role: ['companyAdmin', 'admin']
      }
    ]
  },
  {
    header: 'Store setup',
    items: [
      {
        title: 'Devices',
        route: '/devices',
        role: ['companyAdmin', 'admin']
      },
      {
        title: 'Apply templates',
        route: '/applytemplate',
        role: ['companyAdmin', 'admin']
      }
    ]
  }
]

class Sidebar extends React.Component<any, { headerClick, width: number }> {
  getMenu () {
    const menu = []
    if (!this.props.claims.logged_in) {
      return ''
    }
    menuList.forEach((x, index) => {
      const list = this.getListings(x.items)
      if (list.length > 0) {
        menu.push(
          (<section className='section' key={index}>
            <strong className='subheader'>
              {x.header}
            </strong>
            {list}
          </section>))
      }
    })

    return menu
  }
  getListings (listings) {
    const role = this.props.claims.role

    return listings
      .filter(x => this.roleFilter(role, x.role))
      .map((x, index) => {
        let activeStyleSelector = 'active'
        if (x.route === '/') {
          activeStyleSelector = ''
        }
        return (
          <div key={index} style={{ textDecoration: 'none' }}>
            {
              ((x.title === 'Navigations' || x.title === 'Devices' || x.title === 'Apply templates'
                || x.title === 'Products' || x.title === 'Receipts' || x.title === 'Customer' || x.title === 'Store') &&
                (!this.props.claims ||
                  (this.props.claims.companyId === '' || this.props.claims.companyId === undefined
                    || this.props.claims.tenantId === '' || this.props.claims.tenantId === undefined))) ?
                <NavLink
                  to={x.route} className={({ isActive }) => (isActive ? 'active' : 'disabled')}
                  data-toggle='tooltip' title='Select Company and Enviroment'
                >
                  {x.title}
                </NavLink> : <NavLink to={x.route} className={({ isActive }) => (isActive ? activeStyleSelector : '')} >
                  {x.title}
                </NavLink>
            }
          </div>
        )
      })
  }

  roleFilter (userRole, permissions) {
    if (permissions.length === 0) {
      return true
    }
    return permissions.some((x) => {
      return (userRole === x)
    })
  }

  render () {
    let width = this.props.width

    const menu = this.getMenu()
    return (
      <nav className={'nav ' + (width ? '' : 'collapsed')}>
        <div className='head' role='button' onClick={this.props.headerClick}>
          <img src={'/images/EnvimoLogoWhite.png'} />
        </div>
        {menu}
      </nav>)
  }

}
function mapStateToProps (state) {
  return {
    claims: state.session
  }
}

export default connect<any, any, { width: number, headerClick: any }>(
  mapStateToProps,
)(Sidebar)
