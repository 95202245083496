import AnyOfFilter from './AnyOfFilter'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleRetailTagType } from '../../actions/storeActions'

function mapStateToProps (state) {
  return {
    heading: 'Prompt at end of Sale',
    labelKey: 'retailTagFilterName',
    valueKey: 'retailTagFilterId',
    options: state.store.retailTagTypes,
    title: 'Prompt at end of Sale',
    singleSelect: true
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    toggleOptions: toggleRetailTagType
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AnyOfFilter)