import Switch from 'react-switch'

interface ToggleSwitchProps {
  checked: boolean
  onChange: () => void
  checkedIcon?: any
  uncheckedIcon?: any
  width?: number
  handleDiameter?: number,
  checkedIconStyle?: React.CSSProperties,
  uncheckedIconStyle?: React.CSSProperties,
  checkedIconText?: string,
  uncheckedIconText?: string
  switchStyle?: React.CSSProperties
  disabled?: boolean
}

const ToggleSwitch = ({
  checked,
  onChange,
  width,
  handleDiameter,
  checkedIcon,
  uncheckedIcon,
  checkedIconStyle,
  uncheckedIconStyle,
  checkedIconText,
  uncheckedIconText,
  switchStyle,
  disabled
}: ToggleSwitchProps) => {
  return (
    <Switch
      className={`custom-toggle-react-switch ${disabled ? 'disabled' : ''}`}
      checked={checked}
      onChange={onChange}
      checkedIcon={checkedIcon ?? 
      <span style={{ marginLeft: 5, fontSize: 12, color: 'white', ...checkedIconStyle }}>
        {checkedIconText}
      </span>
      }
      uncheckedIcon={uncheckedIcon ?? 
      <span style={{ position: 'absolute' , right: 4, top: 5, fontSize: 12, color: 'white', ...uncheckedIconStyle }}>
        {uncheckedIconText}
      </span>
      }
      width={width ?? 80}
      handleDiameter={handleDiameter ?? 18}
      style={switchStyle}
      disabled={disabled}
    />
  )
}

export default ToggleSwitch