import React from 'react'
import { ShippingSettings } from '../../types/ShippingSettings'

import TextInput from '../common/TextInput'
import Select from 'react-select'
import { FeatureId, featureIsEnabledForSelectedTenant } from '../../utils/FeatureFlags'
import ToggleSwitch from '../common/ToggleSwitch'

interface OwnProps {
  onChange: (settings: ShippingSettings) => void
  settings: ShippingSettings
}

interface StateProps {
  settings: ShippingSettings
}

type Props = StateProps & OwnProps

interface State {
  settings: ShippingSettings
}

export default class ShippingProvider extends React.Component<Props, State> {
  providers = [
    { label: 'Select provider', value: 0 },
    { label: 'Shippit', value: 1 },
    { label: 'AusPost', value: 2 }
  ]

  constructor (props) {
    super(props)
    this.state = {
      settings: !this.props.settings ? new ShippingSettings(this.props.settings) : this.props.settings
    }

    const index = this.providers.findIndex(i => i.label === 'AusPost')

    if (!featureIsEnabledForSelectedTenant(FeatureId.AusPost) && this.state.settings.provider !== 2) {
      this.providers.splice(index, 1)
    }
  }

  changeApiUrl = (url) => {
    const newSettings = { ...this.state.settings, apiUrl: url }
    this.change(newSettings)
  }
  change = (newSettings) => {
    this.setState({ ...this.state, settings: newSettings })
    this.props.onChange(newSettings)
  }
  changeShippingProvider = (name, id) => {
    const newSettings = { ...this.state.settings, provider: id, providerName: name, apiUrl: '', automaticOrderManifesting: false }
    this.change(newSettings)
  }
  toggleAutomaticOrderManifesting = () => {
    const newSettings = { ...this.state.settings, automaticOrderManifesting: !this.state.settings.automaticOrderManifesting }
    this.change(newSettings)
  }
  changeDomain = (value) => {
    const newSettings = { ...this.state.settings, domain: value }
    this.change(newSettings)
  }

  renderApiUrl = () => {
    return (
      <div className='col-sm-10'>
        <TextInput
          maxLength={100}
          name='apiUrl'
          label='API Url'
          value={this.state.settings.apiUrl}
          onChange={(e) => this.changeApiUrl(e.target.value)}
        />
      </div>
    )
  }
  renderAutomaticManifesting = () => {
    return (
      <div className='col-sm-10'>
        <label>Automatic order manifesting:</label>
        <ToggleSwitch
          checked={this.state.settings.automaticOrderManifesting}
          onChange={() => this.toggleAutomaticOrderManifesting()}
          checkedIconText={'Enabled'}
          uncheckedIconText={'Disabled'}
          switchStyle={{ marginLeft: '40px', width: '80px' }}
        />
      </div>
    )
  }
  renderShippit = () => {
    let apiSetting = this.renderApiUrl()
    let automticManifesting = this.renderAutomaticManifesting()
    return (
      <div className='panel panel-default'>
        {apiSetting}
        {automticManifesting}
      </div>
    )
  }

  renderAustraliaPost = () => {
    return this.renderApiUrl()
  }

  render () {
    let providerSettings = null
    switch (this.state.settings.provider) {
      case 1:
        providerSettings = this.renderShippit()
        break
      case 2:
        providerSettings = this.renderAustraliaPost()
        break
      default:
        providerSettings = null
    }

    return (
      <div className='panel panel-default'>
        <div className='panel-heading clearfix'>
          <h3 className='panel-title pull-left'>Shipping Provider</h3>
        </div>
        <div className='panel-body'>
          <div className='form-group row'>
            <div className='col-sm-5' style={{ maxWidth: 300 }}>
              <Select
                name='shippingProvider'
                value={this.providers?.find(x => x.value === this.state.settings?.provider)}
                options={this.providers}
                onChange={(e: any) => this.changeShippingProvider(e.label, e.value)}
                isClearable={false}
                defaultValue={{ value: 0, label: 'Select provider'}}
              />
            </div>
          </div>
          <div className='form-group row'>
            {providerSettings}
          </div>
        </div>
      </div>)
  }
}