import AnyOfFilter from './AnyOfFilter'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleAp21RewardsProgram } from '../../actions/storeActions'

function mapStateToProps (state) {
  return {
    heading: '',
    labelKey: 'ap21RewardsProgramName',
    valueKey: 'ap21RewardsProgramId',
    options: state.store.ap21RewardsPrograms,
    title: 'Rewards Program',
    singleSelect: true
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    toggleOptions: toggleAp21RewardsProgram
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AnyOfFilter)