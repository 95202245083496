import authenticator from '../auth/authenticator'
import fetcher from './ApiMethods'
import ActivityNote from '../types/ActivityNote'
import { GetApiRequest } from '../utils/ApiUtils'

export default class ActivityLogApi {

  static loadActivityLogs (tableName: string, tenantId: string) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/Activity/GetAllActivityLog?tableName=${tableName}`, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static saveActivityNotes (activityNote: ActivityNote, tenantId: string) {
    const payload = {
      Id: activityNote.id,
      ActivityLogId: activityNote.activityLogId,
      Note: activityNote.note,
      ModifiedDate: activityNote.modifiedDate,
      ModifiedById: activityNote.modifiedById
    }

    const request = GetApiRequest(`/api/tenant/${tenantId}/Activity/UpdateActivityNotes`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  static deleteActivityNote (id: number, tenantId: string) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/Activity/DeleteActivityNote/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })
    return fetcher.processFetch(request)
  }
}
