export class PackType {
  packTypeId: string
  packTypeName: string
  selected: boolean
  constructor (pack: PackType) {
    if (pack) {
      this.packTypeId = pack.packTypeId
      this.packTypeName = pack.packTypeName
      this.selected = pack.selected
    }
  }
}
