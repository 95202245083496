import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export interface Props {
    onHide: (event?: any) => void
    show?: boolean
    message: string
    title: string
    onConfirm?: (event?: any) => void
    onCancel?: (event?: any) => void
}

export interface State {
}

export default class ConfirmModal extends React.Component<Props, State> {
  render () {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <Modal.Title style={{ marginRight: 'auto'}}>Delete</Modal.Title>
          {/* Custom Close Button */}
          <Button type='button' className='close' aria-label='Close' onClick={this.props.onHide} style={{ border: 'none', boxShadow: 'none' }}>
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{this.props.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{ margin: 5, textAlign: 'center' }}
            className='btn btn-primary'
            onClick={this.props.onConfirm}
          >
            <span className='text'>Yes</span>
          </button>
          <button
            style={{ margin: 5, textAlign: 'center' }}
            className='btn btn-default'
            onClick={this.props.onCancel}
          >
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}