import { CUSTOMER_FILTER, Rule, RuleChild, SubFilter, TagGroup } from './Menu'
import _ from 'lodash'
interface FieldData {
    group: string, fieldName: string, mandatory: boolean, enabled: boolean
}

export interface FieldGroup {
    group: string,
    fieldOptions: FieldOption[]
    expanded?: boolean
}

export interface FieldOption {
    name: string
    mandatory: boolean
    enabled: boolean,
    noValidation?: boolean
}

export class Customer {
  id: number
  name: string
  subFilterId: number
  userName: string | null
  modifiedById?: number
  modifiedDate?: Date | null
  tenantId: string
  companyId: string
  subFilter: SubFilter | null | undefined
  rules: Rule[]
  validationEnabled: boolean
  checkForAttachedCustomerEnabled: boolean
  googleApiKeyEnabled: boolean
  kleberApiKeyEnabled: boolean
  googleApiKey: string
  kleberApiKey: string
  ruleChilds: RuleChild[]
  tagGroups?: TagGroup[] | undefined
  groups: FieldGroup[] | undefined
  constructor (customerTemplate?: any) {
    if (customerTemplate !== undefined) {
      this.id = customerTemplate.id
      this.name = customerTemplate.name
      this.userName = customerTemplate.userName
      this.modifiedById = customerTemplate.modifiedById
      this.modifiedDate = customerTemplate.modifiedDate
      this.subFilter = customerTemplate.subFilter === null ? null : new SubFilter(customerTemplate.subFilter)
      if (this.subFilter) {
        this.subFilter.expanded = true
      }
      this.subFilterId = customerTemplate.subFilterId
      this.ruleChilds = customerTemplate.ruleChilds.map((p: any) => new RuleChild(p))
      this.rules = customerTemplate.rules.map((p: any) => new Rule(p))
      this.tenantId = customerTemplate.tenantId
      this.companyId = customerTemplate.companyId
      this.tagGroups = customerTemplate.tagGroups

      this.validationEnabled = customerTemplate.validationEnabled
      this.checkForAttachedCustomerEnabled = customerTemplate.checkForAttachedCustomerEnabled
      this.googleApiKey = customerTemplate.googleApiKey
      this.googleApiKeyEnabled = customerTemplate.googleApiKeyEnabled
      this.kleberApiKeyEnabled = customerTemplate.kleberApiKeyEnabled
      this.kleberApiKey = customerTemplate.kleberApiKey

      this.groups = _.chain(customerTemplate.fieldOptions as FieldData)
        .groupBy('group')
        .map((p: FieldData[], q: string) => {
          return {
            group: q,
            fieldOptions: p.map((r: FieldData): FieldOption => {
              return {
                mandatory: r.mandatory,
                enabled: r.enabled,
                name: r.fieldName,
                noValidation: r.fieldName === 'Permission to contact'
              }
            })
          }
        })
        .value() as any
    } else {
      this.id = 0
      this.name = ''
      this.userName = null
      this.modifiedById = 0
      this.modifiedDate = (new Date())
      this.subFilter = new SubFilter({
        id: 0, isMatchingAll: true, parentSubFilterId: 0,
        type: CUSTOMER_FILTER, title: 'Customer filter', expanded: true
      })
      this.ruleChilds = []
      this.rules = []
      this.subFilterId = 0

      this.validationEnabled = false
      this.googleApiKey = ''
      this.googleApiKeyEnabled = false
      this.kleberApiKeyEnabled = true
      this.checkForAttachedCustomerEnabled = false
      this.kleberApiKey = ''

      this.groups = [
        {
          group: 'First Name',
          fieldOptions: [{
            name: 'First Name',
            mandatory: true,
            enabled: true
          }]
        },
        {
          group: 'Last Name',
          fieldOptions: [{
            name: 'Last Name',
            mandatory: true,
            enabled: true
          }]
        },
        {
          group: 'Email Address',
          fieldOptions: [{
            name: 'Email Address',
            mandatory: true,
            enabled: true
          }]
        },
        {
          group: 'Contact Number',
          fieldOptions: [{
            name: 'Contact Number',
            mandatory: true,
            enabled: true
          }]
        },
        {
          group: 'Membership Number',
          fieldOptions: [{
            name: 'Membership Number',
            mandatory: true,
            enabled: true
          }]
        },
        {
          group: 'Birthday',
          fieldOptions: [{
            name: 'Days',
            mandatory: true,
            enabled: true
          },
          {
            name: 'Months',
            mandatory: true,
            enabled: true
          },
          {
            name: 'Year',
            mandatory: true,
            enabled: true
          }]
        },
        {
          group: 'Contact Address',
          fieldOptions: [{
            name: 'Street Address',
            mandatory: true,
            enabled: true
          },
          {
            name: 'City/Suburb',
            mandatory: true,
            enabled: true
          },
          {
            name: 'State',
            mandatory: true,
            enabled: true
          },
          {
            name: 'Postcode',
            mandatory: true,
            enabled: true
          },
          {
            name: 'Country',
            mandatory: true,
            enabled: true
          }]
        },
        {
          group: 'Permission to contact',
          fieldOptions: [{
            name: 'Permission to contact',
            mandatory: true,
            enabled: true,
            noValidation: true
          }]
        }
      ]
    }
  }
}
