import authenticator from '../auth/authenticator'
import { GetApiRequest } from '../utils/ApiUtils'
import fetcher from './ApiMethods'

export default class MenuApi {
  static saveMenu (menu: any) {
    const payload = menu

    const isNew = (menu.id === '0')
    const uri = isNew ? `/api/tenant/${menu.tenantId}/menu` : `/api/tenant/${menu.tenantId}/menu/${menu.id}`
    const request = GetApiRequest(uri, {
      method: isNew ? 'POST' : 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  static loadMenuDetail (menuId: any, tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/menu/getDetail?menuId=${menuId}` , {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static loadMenus (tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/menu`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static loadTags (tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/menu/GetTagGroups`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static deleteMenu (id: number, tenantId: string) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/menu/DeleteMenu?id=${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })
    return fetcher.processFetch(request)
  }
}
