import authenticator from '../auth/authenticator'
import { GetApiRequest } from '../utils/ApiUtils'
import fetcher from './ApiMethods'

export default class LandlordApi {
  static saveLandlord (landlord: any) {
    const payload = {
      Id: landlord.id,
      Name: landlord.name
    }

    const isNew = (landlord.id)
    const uri = landlord.id ? `/api/landlords/${landlord.id}` : '/api/landlords'
    const request = GetApiRequest(uri, {
      method: isNew ? 'PUT' : 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  static loadLandlords () {
    const request = GetApiRequest('/api/landlords', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static deleteLandlord (id: number) {
    const request = GetApiRequest(`/api/landlords/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })
    return fetcher.processFetch(request)
  }
}
