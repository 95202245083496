import React, { useState, useEffect, useRef, FC } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useParams, useNavigate } from 'react-router-dom'
import TemplateDetailHeader from './../TemplateDetailHeader'
import { ClipLoader } from 'react-spinners'
import {
  deleteCustomer,
  loadCustomerDetail,
  NameChange,
  CustomerAdd,
  customerTenantSelect,
  redirectToListing,
  saveCustomer,
  subFilterMatch,
  clear,
  updateCheckForAttachedCustomerEnabled,
  setCustomerDeletedSuccess,
  setCustomerSaved
} from '../../actions/customerActions'
import { Button } from 'react-bootstrap'
import AddressValidation from './AddressValidation'
import { SubFilter } from './../../types/Menu'
import TextInput from './../common/TextInput'
import { IDispatchProps as ISubFilterDispatchProps, TSubFilterItem, ITSubFilterOwnProps } from './../SubFilter'
import MultiField from './CustomerField'
import SingleField from './CustomerSingleField'
import BirthdayField from './CustomerBirthdayField'
import CustomerRule from './CustomerRule'
import { Customer } from '../../types/Customer'
import ToggleSwitch from '../common/ToggleSwitch'

const mapDispatchToSubItemProps = (dispatch) => {
  return {
    subFilterMatch: (id, all) => dispatch(subFilterMatch(id, all))
  }
}

const SubFilterItem = connect<{}, ISubFilterDispatchProps, ITSubFilterOwnProps>(null, mapDispatchToSubItemProps)(TSubFilterItem)

const CustomerDetail = (props) => {
  const { customerId } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const customerDeleted = useSelector((state: any) => state.customer.customerDeleted)
  const customersLoading = useSelector((state: any) => state.customer.customersLoading)
  const customer = useSelector((state: any) => state.customer.customer)
  const customerSavedId = useSelector((state: any) => state.customer.customerSavedId)
  const customerDeletedSuccess = useSelector((state: any) => state.customer.customerDeletedSuccess)
  const customerSaved = useSelector((state: any) => state.customer.customerSaved)
  const tenantId = useSelector((state: any) => state.session.tenantId)
  const userRole = useSelector((state: any) => state.session.role)
  const customerSaving = useSelector((state: any) => state.customer.customerSaving)
  const stateSubFilter = useSelector((state: any) => state.customer.customer?.subFilter)

  const [state, setState] = useState({
    id: '',
    modalIsOpen: false,
    modalError: '',
    name: '',
    deletingId: '-1'
  })

  useEffect(() => {
    if (customerId === 'new') {
      dispatch(CustomerAdd(tenantId))
      if (tenantId) {
        dispatch(customerTenantSelect(tenantId) as any)
      }
    } else {
      dispatch(loadCustomerDetail(customerId, tenantId) as any)
    }   
  }, [])

  useEffect(() => {
    if (customerId !== 'new' && !tenantId) {
      navigate('/customer')
    } else if (tenantId && customerId === 'new') {
      dispatch(customerTenantSelect(tenantId) as any)
    }

    if (!customerSaving && customerSaved) {
      dispatch(setCustomerSaved(false))
      navigate(`/customer/${customerSavedId}`)
    } else if (customerDeletedSuccess) {
      dispatch(setCustomerDeletedSuccess(false))
      navigate('/customer')
    }
  }, [customerSaving, customerSaved, customerDeletedSuccess, tenantId, customerId])

  const onSubmit = (name) => {
    dispatch(saveCustomer({ ...customer, name }) as any)
  }

  const onDeleteCustomer = () => {
    dispatch(deleteCustomer(customer) as any)
  }

  const closeModal = (event) => {
    event.preventDefault()
    setState({
      ...state,
      modalIsOpen: false,
      modalError: ''
    })
  }

  const populateExisting = (row) => {
    setState({ id: row.id, name: row.name, modalIsOpen: false, modalError: '', deletingId: '-1' })
  }

  const changeState = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const onSaveClick = () => {
    dispatch(saveCustomer(customer) as any)
  }

  const inlineSpinner = (
    <div style={{ marginLeft: '20px', height: '15px', display: 'inline-block' }}>
      <ClipLoader size={15} /></div>
  )
  const spinner = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
      <ClipLoader size={40} />
    </div>
  )
  const subFilter = (tenantId && stateSubFilter
    ? (
      <div key={stateSubFilter.id}>
        <SubFilterItem
          item={stateSubFilter}
          group=''
          key={'customerFilter'}
          seq={0}
          max={1}
          SingleRule={<CustomerRule subFilterId={stateSubFilter.id} />}
        />
      </div>)
    : '')
  let key = 0
  const body = (
    <div>
      <div>
        <TemplateDetailHeader
          title={'Customer'}
          onSave={onSubmit}
          onDelete={onDeleteCustomer}
          name={customer.name}
          hideDeleteButton={customer.id === undefined || customer.id === 0}
          templateSaving={customerSaving}
          templateDeleting={customerDeleted}
          listUrl='customer'
        />
        <hr />
        {subFilter}
        <div className='' >
          <div className={'panel panel-default'}>
            <div className='panel-heading'>
              Prompt to add customer to sale
              <div className='pull-right' style={{ width: '80px', display: 'inline-block', marginRight: '10px' }}>
                <ToggleSwitch
                  checked={customer.checkForAttachedCustomerEnabled}
                  disabled={false}
                  onChange={() => dispatch(updateCheckForAttachedCustomerEnabled())}
                  checkedIconText={'Enabled'}
                  uncheckedIconText={'Disabled'}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <h5>Customer detail screen</h5>
        </div>
        {
          customer.groups
            ? customer.groups.map(p =>
              p.group === 'Birthday' ?
                <BirthdayField group={p} key={key++} /> :
                p.fieldOptions.length > 1 ?
                  <MultiField group={p} key={key++}>
                    {
                      p.group === 'Contact Address' && userRole === 'admin' ?
                        <AddressValidation disabled={p.fieldOptions.every(q => !q.enabled)} /> :
                        null
                    }
                  </MultiField> :
                  <SingleField group={p} key={key++} />
            )
            : null
        }
      </div>
    </div>
  )

  return (
    <div className='container-fluid'>
      {customersLoading ? spinner : body}
    </div>
  )
}

export default CustomerDetail
