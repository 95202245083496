import AnyOfFilter from './AnyOfFilter'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleDiscountType } from '../../actions/storeActions'

function mapStateToProps (state) {
  return {
    heading: 'Discount Type',
    labelKey: 'discountTypeName',
    valueKey: 'discountTypeId',
    options: state.store.discountTypes,
    title: 'Discounts'
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    toggleOptions: toggleDiscountType
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AnyOfFilter)