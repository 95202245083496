import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
// import * as createLogger from 'redux-logger'
import allReducers from '../reducers/allReducers'
import { throttle } from 'lodash'
import sessionApi from '../api/SessionApi'
import { showErrorToast } from '../utils/Toast'
import { composeWithDevTools, EnhancerOptions } from '@redux-devtools/extension'
// const loggerMiddleware = createLogger()

export default function configureStore (preloadedState: any) {
  const session = sessionStorage.getItem('sessionState')
  const state = session && JSON.parse(session)

  // get the session timeout settings from api endpoint
  sessionApi.getConfiguration().then((response) => {
    if (response.success === true) {
      window.sess_inactivity = response.timeout
    }
  }).catch((error) => {
    showErrorToast(error)
  })

  const middleware = applyMiddleware(
    thunkMiddleware,
    // loggerMiddleware
  )
  const composeEnhancers = composeWithDevTools(({ port: 3000 } as EnhancerOptions))
  let store = createStore(
    allReducers,
    state || preloadedState,
    composeEnhancers(middleware)
  )

  store.subscribe(() => throttle(() => sessionStorage.setItem('sessionState', JSON.stringify(store.getState())), 1000)())

  return store
}
