import React from 'react'
import { StoreTemplate } from '../../types/StoreTemplate'
import { Carrier } from '../../types/Carrier'
import { PackType } from '../../types/PackType'

import { TransferOutSettings } from '../../types/TransferOutSettings'
import AnyOfFilter from './AnyOfFilter'
import { connect } from 'react-redux'
import { subFilterTransferOutWarehouseMatch } from '../../actions/storeActions'
import { IDispatchProps as ISubFilterDispatchProps, ITSubFilterOwnProps as IOwnProps, TSubFilterItem } from '../../components/SubFilter'
import TransferOutWarehouseRule from '../TransferOutWarehouseRule'
import { Filter } from '../../types/Menu'
import ToggleSwitch from '../common/ToggleSwitch'

const mapDispatchToSubItemProps = (dispatch) => {
  return {
    subFilterMatch: (id, all) => dispatch(subFilterTransferOutWarehouseMatch(id, all))
  }
}
const SubFilterItem = connect<{}, ISubFilterDispatchProps, IOwnProps>(null, mapDispatchToSubItemProps)(TSubFilterItem)

interface Props {
    onChange: (storeTemplate: StoreTemplate) => void
    storeTemplate: StoreTemplate
    carriers: Carrier[],
    packTypes: PackType[]
}

interface State {
    storeTemplate: StoreTemplate
    carriers: Carrier[]
    packTypes: PackType[]
}

interface StateProps {
    transferOutWarehouseFilter: Filter
}
type InternalProperties = Props & StateProps
class StockTransferOut extends React.Component<InternalProperties, State> {

  constructor (props: InternalProperties) {
    super(props)
    const template = this.props.storeTemplate === null ? new StoreTemplate(this.props.storeTemplate) : this.props.storeTemplate
    this.state = {
      storeTemplate: template,
      carriers: this.setupCarriers(this.props.carriers, template.transferOutSettings.carriers),
      packTypes: this.setupPackTypes(this.props.packTypes, template.transferOutSettings.packTypes)
    }
  }

  setupCarriers = (storeCarriers: Carrier[], carriers: Carrier[]) => {
    const loadCarriers = [] as Carrier[]
    storeCarriers.forEach(x => {
      const y = new Carrier(x)
      const found = carriers.find(p => { return p.carrierId === x.carrierId })
      if (found !== undefined && found !== null) {
        y.selected = found.selected
      } else {
        y.selected = false
      }
      loadCarriers.push(y)
    })
    return loadCarriers
  }

  setupPackTypes = (storePackTypes: PackType[], packTypes: PackType[]) => {
    const loadPackTypes = [] as PackType[]
    storePackTypes.forEach(x => {
      const y = new PackType(x)
      const found = packTypes.find(p => { return p.packTypeId === x.packTypeId })
      if (found !== undefined && found !== null) {
        y.selected = found.selected
      } else {
        y.selected = false
      }
      loadPackTypes.push(y)
    })
    return loadPackTypes
  }

  saveTransferOutSettings = (transferOutSettings: TransferOutSettings, newCarriers: Carrier[], newPackTypes: PackType[]) => {
    const template = {
      ...this.state.storeTemplate,
      transferOutSettings: { ...transferOutSettings }
    }
    this.setState({ ...this.state, storeTemplate: template, carriers: newCarriers, packTypes: newPackTypes })
    this.props.onChange(template)
  }

  changeActive = () => {
    const transferOutSettings = {
      ...this.state.storeTemplate.transferOutSettings,
      active: !this.state.storeTemplate.transferOutSettings.active
    }
    this.saveTransferOutSettings(transferOutSettings, this.state.carriers, this.state.packTypes)
  }

  changeForceScanning = () => {
    const transferOutSettings = {
      ...this.state.storeTemplate.transferOutSettings,
      forceScanning: !this.state.storeTemplate.transferOutSettings.forceScanning
    }
    this.saveTransferOutSettings(transferOutSettings, this.state.carriers, this.state.packTypes)
  }

  changeRequestPackAll = () => {
    const transferOutSettings = {
      ...this.state.storeTemplate.transferOutSettings,
      requestPackAll: !this.state.storeTemplate.transferOutSettings.requestPackAll
    }
    this.saveTransferOutSettings(transferOutSettings, this.state.carriers, this.state.packTypes)
  }

  changeRequestOverPackAllow = () => {
    const transferOutSettings = {
      ...this.state.storeTemplate.transferOutSettings,
      requestOverPackAllow: !this.state.storeTemplate.transferOutSettings.requestOverPackAllow
    }
    this.saveTransferOutSettings(transferOutSettings, this.state.carriers, this.state.packTypes)
  }

  changeRequestProcessOnceOnly = () => {
    const transferOutSettings = {
      ...this.state.storeTemplate.transferOutSettings,
      requestProcessOnceOnly: !this.state.storeTemplate.transferOutSettings.requestProcessOnceOnly
    }
    this.saveTransferOutSettings(transferOutSettings, this.state.carriers, this.state.packTypes)
  }

  toggleCarrierOptions = (selectedIds: string[]) => {
    const toggled = this.state.carriers.find(p => {
      const isSelected = selectedIds.some(q => p.carrierId === q)
      return isSelected !== p.selected
    })

    const loadCarriers = [] as Carrier[]
    this.state.carriers.forEach(x => {
      const y = new Carrier(x)
      if (x.carrierId === toggled.carrierId) {
        y.selected = !y.selected
      }
      loadCarriers.push(y)
    })

    const transferOutSettings = {
      ...this.state.storeTemplate.transferOutSettings,
      carriers: loadCarriers
    }
    this.saveTransferOutSettings(transferOutSettings, loadCarriers, this.state.packTypes)
  }

  togglePackTypeOptions = (selectedIds: string[]) => {
    const toggled = this.state.packTypes.find(p => {
      const isSelected = selectedIds.some(q => p.packTypeId === q)
      return isSelected !== p.selected
    })

    const loadPackTypes = [] as PackType[]
    this.state.packTypes.forEach(x => {
      const y = new PackType(x)
      if (x.packTypeId === toggled.packTypeId) {
        y.selected = !y.selected
      }
      loadPackTypes.push(y)
    })

    const transferOutSettings = {
      ...this.state.storeTemplate.transferOutSettings,
      packTypes: loadPackTypes
    }
    this.saveTransferOutSettings(transferOutSettings, this.state.carriers, loadPackTypes)
  }

  render () {
    const subFilter = (
      <div >
        <SubFilterItem
          item={this.props.transferOutWarehouseFilter.subFilter}
          group=''
          key={'storeFilter'}
          seq={0}
          max={1}
          SingleRule={<TransferOutWarehouseRule
            subFilterId={this.props.transferOutWarehouseFilter.subFilter.id}
          />}
        />
      </div>)
    let setting = null
    if (this.state.storeTemplate.transferOutSettings.active) {
      setting = (<div className='panel panel-default'>
        <div className='panel-heading clearfix'>
          <h3 className='panel-title pull-left'>Settings</h3>
        </div>
        <div className='panel-body' style={{ margin: 15 }}>
          <AnyOfFilter
            heading='Carrier'
            labelKey='carrierName'
            valueKey='carrierId'
            options={this.state.carriers}
            title='Carrier'
            singleSelect={false}
            toggleOptions={this.toggleCarrierOptions}
          />
          <AnyOfFilter
            heading='Pack Type'
            labelKey='packTypeName'
            valueKey='packTypeId'
            options={this.state.packTypes}
            title='Pack Type'
            singleSelect={false}
            toggleOptions={this.togglePackTypeOptions}
          />
          {subFilter}
          <div className='form-group row'>
            <div className='col-sm-7' >
              <label className='panel-title'>Force Scanning</label>
            </div>
            <div className='col-sm-2' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
                checked={this.state.storeTemplate.transferOutSettings.forceScanning}
                onChange={() => this.changeForceScanning()}
              />
            </div>
          </div>
          <div className='panel panel-default'>
            <div className='panel-heading'><h4 className='panel-title'>Transfer Request Control</h4></div>
            <div className='panel-collapse collapse in'>
              <div className='panel-body'>
                <div className='form-group row'>
                  <div className='col-sm-7' >
                    <label className='panel-title'>Pack All</label>
                  </div>
                  <div className='col-sm-2' style={{ width: 120, margin: 1 }}>
                    <ToggleSwitch
                      checkedIconText={'Enabled'}
                      uncheckedIconText={'Disabled'}
                      checked={this.state.storeTemplate.transferOutSettings.requestPackAll}
                      onChange={() => this.changeRequestPackAll()}
                    />
                  </div>
                </div><div className='form-group row'>
                  <div className='col-sm-7' >
                    <label className='panel-title'>Over Packing Allowed</label>
                  </div>
                  <div className='col-sm-2' style={{ width: 120, margin: 1 }}>
                    <ToggleSwitch
                      checkedIconText={'Enabled'}
                      uncheckedIconText={'Disabled'}
                      checked={this.state.storeTemplate.transferOutSettings.requestOverPackAllow}
                      onChange={() => this.changeRequestOverPackAllow()}
                    />
                  </div>
                </div><div className='form-group row'>
                  <div className='col-sm-7' >
                    <label className='panel-title'>Process Requests Once Only</label>
                  </div>
                  <div className='col-sm-2' style={{ width: 120, margin: 1 }}>
                    <ToggleSwitch
                      checkedIconText={'Enabled'}
                      uncheckedIconText={'Disabled'}
                      checked={this.state.storeTemplate.transferOutSettings.requestProcessOnceOnly}
                      onChange={() => this.changeRequestProcessOnceOnly()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)
    }
    return (
      <div>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <div className='form-group row' style={{ margin: 15 }}>
              <div className='col-sm-9' >
                <h5 className='text-info'>Stock Transfers Out</h5>
              </div>
              <div className='col-sm-2' style={{ width: 120, margin: 1, paddingTop: 10, marginLeft: 40 }}>
                <ToggleSwitch
                  checked={this.state.storeTemplate.transferOutSettings.active}
                  onChange={() => this.changeActive()}
                  checkedIconText={'Enabled'}
                  uncheckedIconText={'Disabled'}
                />
              </div>
            </div>
          </div>
        </div>
        {setting}
      </div>)
  }
}
function mapStateToProps (state): StateProps {
  return {
    transferOutWarehouseFilter: state.store.transferOutWarehouseFilter
  }
}
export default connect<StateProps, null, Props>
(mapStateToProps, null)(StockTransferOut as any)
