import { combineReducers } from 'redux'
import session from './sessionReducer'
import error from './errorReducer'
import user from './userReducer'
import landlord from './landlordReducer'
import company from './companyReducer'
import tenant from './tenantReducer'
import device from './deviceReducer'
import activityLog from './activitylogReducer'
import menu from './menuReducer'
import template from './templateReducer'
import product from './productReducer'
import customer from './customerReducer'
import store from './storeReducer'
import dataExplorer from './dataExplorerReducer'
import appInfo from './appInfoReducer'

const allReducers = combineReducers({
  session,
  error,
  user,
  landlord,
  company,
  tenant,
  device,
  activityLog,
  menu,
  template,
  product,
  customer,
  store,
  dataExplorer,
  appInfo
})

export default allReducers
