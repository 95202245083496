import React from 'react'
import { EmailSettings } from '../../types/EmailSettings'
import Select from 'react-select'
import TextInput from '../common/TextInput'

const providers = [
  { label: 'Select provider', value: 0 },
  { label: 'Mandrill', value: 1 },
  { label: 'Mailgun', value: 2 }
]

interface OwnProps {
    onChange: (settings: EmailSettings) => void
    settings: EmailSettings
}

interface DispatchProps {
}

interface StateProps {
    settings: EmailSettings
}

type Props = StateProps & OwnProps & DispatchProps

interface State {
    settings: EmailSettings
}

export default class EmailProvider extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      settings: !this.props.settings ? new EmailSettings(this.props.settings) : this.props.settings
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ ...this.state, settings: nextProps.settings })
  }

  change = (newSettings) => {
    this.setState({ ...this.state, settings: newSettings })
    this.props.onChange(newSettings)
  }

  changeEmailProvider = (name, id) => {
    const newSettings = { ...this.state.settings, provider: id, providerName: name, apiKey: '', domain: '' }
    this.change(newSettings)
  }

  changeApiKey = (key) => {
    const newSettings = { ...this.state.settings, apiKey: key }
    this.change(newSettings)
  }

  changeDomain = (value) => {
    const newSettings = { ...this.state.settings, domain: value }
    this.change(newSettings)
  }

  renderApiKey = () => {
    return (<div className='col-sm-10'>
      <TextInput
        maxLength={100}
        name='apiKey'
        label='API Key'
        value={this.state.settings?.apiKey}
        onChange={(e) => this.changeApiKey(e.target.value)}
      />
    </div>)
  }

  renderMandrill = () => {
    return this.renderApiKey()
  }

  renderMailgun = () => {
    return (<div>
      {this.renderApiKey()}
      <div className='col-sm-10'>
        <TextInput
          maxLength={100}
          name='domain'
          label='Domain'
          value={this.state.settings?.domain}
          onChange={(e) => this.changeDomain(e.target.value)}
        />
      </div>
    </div>)
  }

  render () {
    let providerSettings = null
    switch (this.state.settings?.provider) {
      case 1:
        providerSettings = this.renderMandrill()
        break
      case 2:
        providerSettings = this.renderMailgun()
        break
      default:
        providerSettings = null
    }

    return (
      <div className='panel panel-default'>
        <div className='panel-heading clearfix'>
          <h3 className='panel-title pull-left'>Email Provider</h3>
        </div>
        <div className='panel-body'>
          <div className='form-group row'>
            <div className='col-sm-5' style={{ maxWidth: 300 }}>
              <Select
                name='mailProvider'
                value={providers?.find(x => x.value === this.state.settings?.provider)}
                options={providers}
                onChange={(e: any) => this.changeEmailProvider(e.label, e.value)}
                isClearable={false}
                defaultValue={{ value: 0, label: 'Select provider'}}
              />
            </div>
          </div>
          <div className='form-group row'>
            {providerSettings}
          </div>
        </div>
      </div>
    )
  }
}
