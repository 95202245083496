import React from 'react'
import * as $ from 'jquery'
import '../../plugins/autoResize'
export class Input extends React.Component<
{ required?: boolean, maxLength?: number, value, focus?, min?, max?, submit?, change?, placeholder?, disabled?, style?, className?, type
    , autoResize?: boolean, autoResizeMinWidth?: number, autoResizeMaxWidth?: number}, any> {
  refs: any
  constructor (props) {
    super(props)
    this.state = {
      value: props.value,
      changed: false
    }
  }
  keyDown = (event) => {
    let ESCAPE_KEY = 27
    let ENTER_KEY = 13
    if (event.which === ESCAPE_KEY) {
      this.refs.input.value = this.props.value
    } else if (event.which === ENTER_KEY) {
      this.submit(event)

    }
    this.setState({
      value: this.refs.input.value
    })
    return false
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      value: nextProps.value

    })
  }
  componentDidUpdate () {
    if (this.props.focus)
      $(this.refs.input).focus()

  }

  componentDidMount () {
    
    if (this.props.focus)
      $(this.refs.input).focus()

    if (this.props.autoResize) {
      $(this.refs.input).autoresize({ minWidth: this.props.autoResizeMinWidth, maxWidth: this.props.autoResizeMaxWidth, padding: 38})
    }
  }

  submit = (event) => {
    if (this.state.changed) {
      if ((this.props.min && this.refs.input.value < this.props.min) || (this.props.max && this.refs.input.value > this.props.max)) {
        this.refs.input.value = this.props.value
        return
      }
      if (this.props.submit)
        this.props.submit(this.refs.input.value)
      this.refs.input.value = this.props.value
      this.setState({
        changed: false
      })
    }
  }
  change = () => {
    this.setState({
      value: this.refs.input.value,
      changed: true
    })
    if (this.props.change)
      this.props.change(this.refs.input.value)
  }

  render () {
    return <input ref='input'
      maxLength={this.props.maxLength}
      type={this.props.type}
      min={this.props.min || ''}
      max={this.props.max || ''}
      className={(this.props.className || '') + ' form-control'}
      value={this.state.value}
      onBlur={this.submit}
      onKeyDown={this.keyDown}
      onChange={this.change}
      placeholder={this.props.placeholder}
      disabled={this.props.disabled}
      required={this.props.required}
      style={this.props.style}
    />
  }
}
