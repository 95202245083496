
import React from 'react'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loadAppInfo, addNewCurrentVersion } from '../actions/appInfoActions'
import TextInput from './common/TextInput'

class AppInfoPage extends React.Component<any, any> {
  constructor (props) {
    super(props)
    this.state = {
      currentVersion: ''
    }
  }

  componentDidMount () {
    this.setState({...this.state, currentVersion: this.props.currentVersion})
    this.props.loadAppInfo()
  }

  componentWillReceiveProps (nextProps: any) {
    this.setState({...this.state, currentVersion: nextProps.currentVersion})
  }

  onApply = (event) => {
    event.preventDefault()
    if (this.state.currentVersion) {
      this.props.addNewCurrentVersion(this.state.currentVersion)
    }
  }
  onChange = (event) => {
    return this.setState({...this.state, currentVersion: event.target.value})
  }

  renderActivityLog = () => {
    if (this.props.activityLog) {
      return this.props.activityLog.map((x) => {
        return (
          <div key={x} style={{borderTop: '1px solid grey', padding: 5}}>{x}</div>
        )
      })
    } else {
      return (<div>No logs</div>)
    }
  }
  render () {
    const spinner = (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
        <ClipLoader size={40} />
      </div>
    )
    return (
      <div>
        <form>
          <h3>Current Application Version</h3>
          <div className='row' >
            <div className='col-sm-4 col-xs-6'>
              <div className='right-inner-addon '>
                <TextInput
                  maxLength={100}
                  name='currentVersion'
                  label='Current ANZ Blade Envimo Version'
                  value={this.state.currentVersion}
                  onChange={this.onChange}
                  style={{ width: 400 }}
                />
              </div>
            </div>
            <div className='col-sm-8 col-xs-6 text-right'>
              <button style={{ margin: 5 }} className='btn btn-primary' onClick={this.onApply}>Apply</button>
            </div>
          </div>
        </form>
        <div>
          <h3>Activity Log</h3>
          <div className='text-info' style={{ overflowY: 'auto' }}>
            {this.props.isLoading ? spinner : this.renderActivityLog()}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    currentVersion: state.appInfo.currentVersion,
    isLoading: state.appInfo.isLoading,
    activityLog: state.appInfo.activityLog
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    loadAppInfo,
    addNewCurrentVersion
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppInfoPage)
