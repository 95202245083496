import { LOG_IN_SUCCESS, LOG_OUT, RESPONSE_LOADING, LOG_IN_ERROR, LOG_IN_SET_CLAIMS
  , CHANGE_PASSWORD, FORGOT_PASSWORD, FORGOT_ERROR
  , FORGOT_SUCCESS, FORGOT_BACK, COMPANY_SELECTED
  , TENANT_SELECTED, SESSION_COMPANYS_LOADING, SESSION_COMPANYS_LOADED
  , SESSION_TENANTS_LOADED, SESSION_TENANTS_LOADING, SESSION_TENANTS_LOADING_FAILED
  , SESSION_UPDATE_LICENCES, SESSION_LICENCES_UPDATING, SESSION_LICENCES_UPDATE_FAILED
  , SESSION_LICENCES_UPDATED, LOG_IN_VERIFY, LOG_IN_VERIFY_RESEND
  , SESSION_INVALIDATE} from '../actions/sessionActions'
import initialState from './initialState'
import Company from '../types/Company'
import Tenant from '../types/Tenant'

const setClaims = (state: any, action: any) => {
  return {
    ...state,
    logged_in: true,
    login_error: '',
    login_verify: '',
    forgot_error: '',
    forgot_success: false,
    email: action.claims.email,
    name: action.claims.name,
    role: action.claims.role,
    landlord: action.claims.landlord,
    landlordId: action.claims.landlordId,
    userId: action.claims.nameid,
    response_loading: false
  }
}

export default function sessionReducer (state = initialState.session, action: any) {
  switch (action.type) {
    case LOG_IN_SUCCESS:
      return setClaims(state, action)
    case LOG_IN_SET_CLAIMS:
      return setClaims(state, action)
    case LOG_IN_VERIFY:
      return { ...state, response_loading: false, login_verify: action.message }
    case LOG_IN_VERIFY_RESEND:
      return { ...state, response_loading: false, login_verify: action.message, login_error: '' }
    case LOG_OUT:
      return { ...state, ...initialState.session, logged_in: false, email: '' }
    case SESSION_INVALIDATE:
      return { ...state, companys: [], companysLoading: false, tenants: [], tenantsLoading: false, logged_in: false, email: ''}
    case RESPONSE_LOADING:
      return { ...state, response_loading: true }
    case LOG_IN_ERROR:
      return { ...state, response_loading: false, login_error: action.error }
    case FORGOT_ERROR:
      return { ...state, response_loading: false, forgot_error: action.error }
    case FORGOT_SUCCESS:
      return { ...state, response_loading: false, forgot_success: true, forgot_error: action.success }
    case FORGOT_BACK:
      return { ...state, forgot_success: false, forgot_error: '' }
    case COMPANY_SELECTED:
    {
      return {
        ...state,
        selectedCompanyId: action.companyId,
        selectedTenantId: undefined,
        company: action.companyId ? action.companyId.label : '',
        companyId: action.companyId ? action.companyId.value : '',
        tenant: '',
        tenantId: ''
      }
    }
    case TENANT_SELECTED:
    {
      return {
        ...state,
        selectedTenantId: action.tenantId,
        tenant: action.tenantId ? action.tenantId.label : '',
        tenantId: action.tenantId ? action.tenantId.value : ''
      }
    }
    case SESSION_COMPANYS_LOADING:
      return { ...state, companysLoading: true, companys: undefined }

    case SESSION_COMPANYS_LOADED:
    {
      const newCompanys: any = []
      let i = 0
      if (Array.isArray(action.companys)) {
        Array.prototype.forEach.call(action.companys, (y) => {
          const newCompany = new Company(y)
          newCompany.sequence = i
          i += 1
          newCompanys.push(newCompany)
        })
      }
      return {
        ...state,
        companys: newCompanys,
        companysLoading: false
      }
    }
    case SESSION_TENANTS_LOADING_FAILED: {
      return {
        ...state,
        tenantsLoading: false
      }
    }
    case SESSION_TENANTS_LOADING:
      return {
        ...state,
        tenants: [],
        tenantsLoading: true
      }
    case SESSION_TENANTS_LOADED:
    {
      const newTenants: any = []
      if (action.tenants) {
        let i = 0
        let apiKey = ''
        action.tenants.forEach((y: any) => {
          const newTenant = new Tenant(y)
          newTenant.sequence = i
          apiKey = newTenant.apiKey
          if (newTenant.apiKey === '') {
            newTenant.displayApiKey = ''
          } else {
            const result1 = Array(apiKey.length - 4).join('*')
            const result2 = apiKey.substring(apiKey.length - 4, apiKey.length)
            newTenant.displayApiKey = result1.concat(result2)
          }
          i += 1
          newTenants.push(newTenant)
        })
      }

      return {
        ...state,
        tenants: newTenants,
        tenantsLoading: false
      }
    }
    case SESSION_LICENCES_UPDATING:
      return {
        ...state,
        licencesUpdating: true
      }

    case SESSION_LICENCES_UPDATED:
    {
      const item: any = state.tenants.find((p: any) => p.id === action.id)
      const previousIndex = state.tenants.indexOf(item)
      const updated = [
        ...state.tenants.slice(0, previousIndex),
        { ...item, licencesCountSaved: action.licences },
        ...state.tenants.slice(previousIndex + 1)
      ]
      return {
        ...state,
        tenants: updated,
        licencesUpdating: false
      }
    }
    case SESSION_LICENCES_UPDATE_FAILED:
    {
      const item = state.tenants.find((p: any) => p.id === action.id)
      const previousIndex = state.tenants.indexOf(item)
      const updated = [
        ...state.tenants.slice(0, previousIndex),
        { ...item, licencesCount: item.licencesCountSaved },
        ...state.tenants.slice(previousIndex + 1)
      ]
      return {
        ...state,
        tenants: updated,
        licencesUpdating: false
      }
    }
    case SESSION_UPDATE_LICENCES:
    {
      const item = state.tenants.find(p => p.id === action.id)
      const previousIndex = state.tenants.indexOf(item)
      const updated = [
        ...state.tenants.slice(0, previousIndex),
        { ...item, licencesCount: action.licences },
        ...state.tenants.slice(previousIndex + 1)
      ]
      return {
        ...state,
        tenants: updated
      }
    }
    default:
      return { ...state, forgot_success: false, forgot_error: '' }
  }
}
