import authenticator from '../auth/authenticator'
import User from '../types/User'
import { GetApiRequest } from '../utils/ApiUtils'
import fetcher from './ApiMethods'

export default class UserApi {
  static saveUser (claims: any, user: User) {
    const userCompanies = user.companies.filter((x: any) => (x)).map((y: any) => ({ id: y }))
    const payload = {
      Email: user.email,
      Password: user.password,
      Landlord: (claims.role === 'superuser') ? user.landlord : claims.landlord,
      LandlordId: (claims.role === 'superuser') ? user.landlordId : claims.landlordId,
      Role: user.roleIndex,
      FirstName: user.firstName,
      LastName: user.lastName,
      Id: user.id,
      CompanyIds: userCompanies.map((x: any) => {
        return x.id
      })
    }
    const callingMethod = user.id ? 'PUT' : 'POST'
    const uri = user.id ? `/api/usersForUI/${user.id}` : '/api/usersForUI'
    const request = GetApiRequest(uri, {
      method: callingMethod,
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  static loadUsers () {
    const request = GetApiRequest('/api/usersForUI', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static deleteUser (id: number) {
    const request = GetApiRequest(`/api/Users/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })
    return fetcher.processFetch(request)
  }
}
