import landlordApi from '../api/LandlordApi'
import * as Toast from '../utils/Toast'
import { showErrorToast, showSuccessToast } from '../utils/Toast'

export const LANDLORDS_LOADING = 'LANDLORDS_LOADING'
export const LANDLORDS_LOADED = 'LANDLORDS_LOADED'
export const LANDLORDS_UPDATED = 'LANDLORDS_UPDATED'
export const SAVE_LANDLORD_SUCCESS = 'SAVE_LANDLORD_SUCCESS'
export const SAVE_LANDLORD_ERROR = 'SAVE_LANDLORD_ERROR'
export const DELETE_LANDLORD_SUCCESS = 'DELETE_LANDLORD_SUCCESS'
export const DELETE_LANDLORD_ERROR = 'DELETE_LANDLORD_ERROR'
export const LANDLORD_API_STARTED = 'LANDLORD_API_STARTED'
export const LANDLORD_LOADED = 'LANDLORD_LOADED'
export const CLEAR_LANDLORDS = 'CLEAR_LANDLORDS'

export function landlordApiStarted () {
  return { type: LANDLORD_API_STARTED }
}

export function clearLandLords () {
  return { type: CLEAR_LANDLORDS }
}
export function landlordsLoading () {
  return { type: LANDLORDS_LOADING }
}

export function saveLandlordSuccess (landlordDetails: any) {
  return { type: SAVE_LANDLORD_SUCCESS, landlordDetails }
}

export function landlordLoaded () {
  return { type: LANDLORD_LOADED }
}

export function saveLandlordError (error: any) {
  return { type: SAVE_LANDLORD_ERROR, error }
}

export function landlordsLoaded (landlords: any) {
  return { type: LANDLORDS_LOADED, landlords }
}

export function landlordsUpdated (landlords: any) {
  return { type: LANDLORDS_UPDATED, landlords }
}

export function deleteLandlordSuccess (id: any) {
  return { type: DELETE_LANDLORD_SUCCESS, id }
}

export function deleteLandlordError (error: any) {
  return { type: DELETE_LANDLORD_ERROR, error }
}

export function loadLandlords () {
  return (dispatch: any) => {
    dispatch(landlordsLoading())
    return landlordApi.loadLandlords().then((response) => {
      if (response) {
        dispatch(landlordsLoaded(response))
      }
    }).catch(err => {
      // For some reason, the old UI does not show the error message
      // showErrorToast(err)
    })
  }
}

export function saveLandlord (landlord: any) {
  return (dispatch: any) => {
    if (!landlord.name) {
      return Toast.showErrorToast('Name cannot be blank')
    }
    dispatch(landlordApiStarted())
    return landlordApi.saveLandlord(landlord).then((response) => {
      if (response.id) {
        dispatch(saveLandlordSuccess(response))
        showSuccessToast('Landlord saved')
      } else if (response.error) {
        dispatch(saveLandlordError(response.error))
        showErrorToast(response.error)
      } else {
        dispatch(saveLandlordError(response))
        showErrorToast(response)
      }
    }).catch((p) => {
      showErrorToast(p)
    })
  }
}

export function deleteLandlord (landlord: any) {
  return (dispatch: any) => {
    return landlordApi.deleteLandlord(landlord.id).then(() => {
      dispatch(deleteLandlordSuccess(landlord.id))
      showSuccessToast(`Landlord ${landlord.name} has been deleted`)
    }).catch((p) => {
      showErrorToast(p)
    })
  }
}