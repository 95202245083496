import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { ClipLoader } from 'react-spinners'
import { loadCompanys } from '../actions/companyActions'
import {
  addSubFilter,
  deleteMenu,
  loadMenuDetail,
  MenuAdd,
  menuCompanySelect,
  menuTenantSelect,
  NameChange,
  redirectToListing,
  saveMenu,
  setMenuDeletedSuccess
} from '../actions/menuActions'
import withRouter from './Utils/WithRouter'
import { Button, Modal } from 'react-bootstrap'
import TextInput from './common/TextInput'
import SubFilter from './SubFilter'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

const MenuDetails = (props) => {
  const { menuId } = useParams()
  const navigate = useNavigate()
  const setContext: any = useOutletContext()
  const tenantIdRef = useRef(props.tenantId)

  const [state, setState] = useState({
    id: '',
    modalIsOpen: false,
    modalError: '',
    name: '',
    deletingId: '-1'
  })  

  useEffect(() => {
    if (props.menuDeletedSuccess) {
      props.setMenuDeletedSuccess(false)
      navigate('/navigations')
    } else if (props.menuSaved && !props.menuSaving) {
      navigate(`/navigations/${props.menuSavedId}`)
    }
  }, [props.menuDeletedSuccess, props.menuSaved, props.menuSaving])

  useEffect(() => {
    if (
      props.tenantId &&
      props.tenantId !== ''
    ) {    
      if (menuId === 'new') {
        props.MenuAdd(props.tenantId)
        if (props.tenantId) {
          props.menuTenantSelect(props.tenantId)
        }
      } else if (tenantIdRef.current !== props.tenantId) {
        navigate('/navigations') // Tenant has changed. Go to navigations, the selected menuid might be a different menu
      } else if (menuId !== '') {
        props.loadMenuDetail(menuId, props.tenantId)
      }
      tenantIdRef.current = props.tenantId
    } else {
      navigate('/navigations')
    }

  }, [props.tenantId, menuId]) 

  useEffect(() => {
    if (setContext && props.menu?.name) {
      setContext({ name: props.menu.name })
    }
  }, [props.menu?.name])

  const onChange = (event) => {
    changeState(event.target.name, event.target.value)
  }

  const onDelete = (event) => {
    event.preventDefault()
    openModal()
  }

  const onNew = () => {
    setState({ id: '', name: '', modalIsOpen: false, modalError: '', deletingId: '-1' })
  }

  const onSubmit = (event) => {
    event.preventDefault()
    props.saveMenu(state)
  }

  const deleteAndCloseModal = (event) => {
    event.preventDefault()
    props.deleteMenu(props.menu)
    setState({
      ...state,
      modalIsOpen: false,
      modalError: ''
    })
  }

  const openModal = () => {
    setState({
      ...state,
      modalIsOpen: true,
      modalError: ''
    })
  }

  const closeModal = () => {
    setState({
      ...state,
      modalIsOpen: false,
      modalError: ''
    })
  }

  const populateExisting = (row) => {
    setState({ id: row.id, name: row.name, modalIsOpen: false, modalError: '', deletingId: '-1' })
  }

  const changeState = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const renderModal = () => {
    const deleteMessage = `Please confirm you wish to delete "${props.menu.name}" `
    return (
      <Modal show={state.modalIsOpen} onHide={closeModal}>
        <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <Modal.Title style={{ marginRight: 'auto'}}>Delete</Modal.Title>
          {/* Custom Close Button */}
          <Button type='button' className='close' aria-label='Close' onClick={closeModal} style={{ border: 'none', boxShadow: 'none' }}>
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{deleteMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{ margin: 5, textAlign: 'center' }}
            className='btn btn-danger'
            onClick={deleteAndCloseModal}
          >
            Delete
          </button>
          <button
            style={{ margin: 5, textAlign: 'center' }}
            className='btn btn-default pull-left'
            onClick={closeModal}
          >
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  const spinner = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
      <ClipLoader size={40} />
    </div>
  )
  const submit = (
    <button
      style={{ margin: 5, textAlign: 'center' }}
      className='btn btn-primary'
      onClick={onSubmit}
    >
      <span className='glyphicon glyphicon-save' />
      <span
        className='text'
        style={{ marginLeft: 10 }}
      >Save</span>
    </button>
  )

  const inlineSpinner = <ClipLoader size={15} />

  let deleteBtn = null
  if (props.menu.id !== '0') {
    deleteBtn = (
      <Button
        variant='danger'
        style={{ marginLeft: 5, textAlign: 'center' }}
        onClick={onDelete}
        disabled={props.menuSaving || props.menuDeleted || props.menu.id === '0'}
        className='pull-right'
      >
        Delete {props.menuDeleted && inlineSpinner}
      </Button>
    )
  }

  return (
    <div className='container-fluid'>
      <h3 style={{ flex: '1 1 100%' }}>
        <p>{'Navigation Template Details ' + props.menu.name}</p>
      </h3>

      {props.menusLoading
        ? spinner
        : (
          <div>
            <div>
              <div className='row'>
                <div className='col-md-3 col-xs-6'>
                  <TextInput
                    maxLength={100}
                    name='name'
                    label='Name'
                    value={props.menu.name}
                    onChange={(val) => props.NameChange(val.target.value)}
                    disabled={props.tenantId === ''}
                    style={{}}
                  />
                </div>
                <div className='col-md-7 col-xs-6 pull-right'>
                  <div className='form-group'>
                    <label>&nbsp;</label>
                    <div className='field'>
                      {deleteBtn}
                      <Button
                        variant='primary'
                        onClick={() => props.saveMenu(props.menu)}
                        disabled={props.menuSaving || props.menuDeleted}
                        className='pull-right'
                      >
                        Save {props.menuSaving && inlineSpinner}
                      </Button>
                    </div>
                  </div>
                </div>
                {renderModal()}
              </div>
              <hr /> {
                props.tenantId ? (
                  <div>
                    <SubFilter group='' id={'0'} />
                  </div>
                )
                  : ''
              }
            </div>
          </div>
        )}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    menuSaved: state.menu.menuSaved,
    menuDeleted: state.menu.menuDeleted,
    id: state.menu.id,
    menus: state.menu.menuLoading
      ? undefined
      : state.menu.menus,
    error: state.menu.error,
    menusLoading: state.menu.menusLoading,
    menusLoaded: state.menu.menusLoaded,
    tenantsLoading: state.tenant.loading,
    companysLoading: state.company.companysLoading,
    companyId: state.session.companyId,
    tenantId: state.session.tenantId,
    companys: state.company.companys === undefined
      ? []
      : state
        .company
        .companys
        .map((x) => ({ value: x.id, label: x.name })),
    tenants: state
      .tenant
      .tenants
      .map((x) => ({
        ...x,
        value: x.id,
        label: x.name
      })),
    subFilters: state.menu.menu.subFilters,
    menu: state.menu.menu,
    menuSaving: state.menu.menuSaving,
    menuSavedId: state.menu.menuSavedId,
    menuDeletedSuccess: state.menu.menuDeletedSuccess
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    redirectToListing,
    saveMenu,
    deleteMenu,
    loadCompanys,
    menuCompanySelect,
    menuTenantSelect,
    addSubFilter,
    NameChange,
    loadMenuDetail,
    MenuAdd,
    setMenuDeletedSuccess
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuDetails)
