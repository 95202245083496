import React from 'react'
import { StoreTemplate } from '../../types/StoreTemplate'

import { featureIsEnabledForSelectedTenant, FeatureId } from '../../utils/FeatureFlags'
import { OrderSettings } from '../../types/OrderSettings'
import ToggleSwitch from '../common/ToggleSwitch'

interface Props {
    onChange: (storeTemplate: StoreTemplate) => void
    storeTemplate: StoreTemplate
}

interface State {
    storeTemplate: StoreTemplate
}

export default class OrderPacking extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      storeTemplate: this.props.storeTemplate === null ? new StoreTemplate(this.props.storeTemplate) : this.props.storeTemplate
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ ...this.state, storeTemplate: nextProps.storeTemplate })
  }

  saveOrderSettings = (orderSettings: OrderSettings) => {
    const template = {
      ...this.state.storeTemplate,
      orderSettings: { ...orderSettings }
    }
    this.setState({ ...this.state, storeTemplate: template })
    this.props.onChange(template)
  }

  changeOrderPackingEnabled = () => {
    const orderSettings = {
      ...this.state.storeTemplate.orderSettings,
      orderPackingEnabled: !this.state.storeTemplate.orderSettings.orderPackingEnabled
    }
    this.saveOrderSettings(orderSettings)
  }

  changeOrderForceScanning = () => {
    const orderSettings = {
      ...this.state.storeTemplate.orderSettings,
      forceScanning: !this.state.storeTemplate.orderSettings.forceScanning
    }
    this.saveOrderSettings(orderSettings)
  }

  render () {
    let setting = null
    if (this.state.storeTemplate.orderSettings.orderPackingEnabled) {
      setting = (<div className='panel panel-default'>
        <div className='panel-heading clearfix'>
          <h3 className='panel-title pull-left'>Settings</h3>
        </div>
        <div className='panel-body' style={{ margin: 15 }}>
          <div className='form-group row'>
            <div className='col-sm-7' />
          </div>
          <div className='form-group row'>
            <div className='col-sm-7' >
              <label className='panel-title'>Force Scanning</label>
            </div>
            <div className='col-sm-2' style={{ width: 120, margin: 1 }}>
              <ToggleSwitch
                checked={this.state.storeTemplate.orderSettings.forceScanning}
                onChange={() => this.changeOrderForceScanning()}
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
              />
            </div>
          </div>
        </div>
      </div>
      )
    }
    return (
      <div>
        <div className='panel panel-default'>
          <div className='panel-body'>
            <div className='form-group row' style={{ margin: 15 }}>
              <div className='col-sm-9' >
                <h5 className='text-info'>Order Packing</h5>
              </div>
              <div className='col-sm-2' style={{ width: 120, margin: 1, paddingTop: 10, marginLeft: 40 }}>
                <ToggleSwitch
                  checked={this.state.storeTemplate.orderSettings.orderPackingEnabled}
                  onChange={() => this.changeOrderPackingEnabled()}
                  checkedIconText={'Enabled'}
                  uncheckedIconText={'Disabled'}
                />
              </div>
            </div>
          </div>
        </div>
        {setting}
      </div>
    )
  }
}