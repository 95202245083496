
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withRouter from './Utils/WithRouter'
import { ClipLoader } from 'react-spinners'
import {
  loadLandlords, landlordLoaded
} from '../actions/landlordActions'
import Landlord from '../types/Landlord'
import Table from './common/Table'
import EmptyState from './common/EmptyState'

class LandlordList extends React.Component <any, any> {

  defaultProps = {
    landlords: null,
    claims: {}
  }

  columns = [
    { key: 'name', name: 'Name', sortable: true, resizable: true }
  ]
  constructor (props) {
    super(props)
    this.state = {
      search: ''
    }
  }

  onChange (event) {
    return this.setState({ ...this.state, [event.target.name]: event.target.value })
  }

  onNew = () => {
    this.props.landlordLoaded()
    this.props.navigate('/landlords/new')
  }

  onRefreshListing = () => {
    this.props.loadLandlords()
  }

  onRowClick = (id) => {
    this.props.landlordLoaded()
    this.props.navigate(`/landlords/${id}`)
  }

  getRows () {
    if (!this.state.search) {
      return !this.props.landlords ? [] : this.props.landlords
    }
    return this.props.landlords.filter(p => p.name.search(new RegExp(this.state.search, 'i')) !== -1)
  }

  render () {
    const spinner = (<ClipLoader size={40} />)
    const colHeads = this.columns.map(p => <th key={p.key}>{p.name}</th>)
    const rows = this.getRows()
    const colBody = rows.map((col) => {
      return (<tr key={col.id} onClick={(e) => this.onRowClick(col.id)}>
        {this.columns.map(p => <td key={p.key}>{col[p.key]}</td>)}
        <td className='text-right'><button className='btn btn-link' onClick={e => this.onRowClick(col.id)}>
          <i className='glyphicon glyphicon-chevron-right' />
        </button>
        </td>
      </tr>)
    })
    const dataGrid = () => {
      return rows.length ? 
        <Table data={colBody} height={this.state.height}>
          <thead>
            <tr>
              {colHeads}
              <th />
            </tr>
          </thead>
          <tbody>
            {colBody}
          </tbody>
        </Table> 
        :
        <EmptyState message={this.props.landlords.length ? 'No landlords found' : 'No landlords available'}/>
    }

    return (
      <div>
        <h3>Landlord</h3>
        <div>
          <div className='row' >
            <div className='col-sm-4 col-xs-6'>
              <div className='right-inner-addon '>
                <i className='text-muted glyphicon glyphicon-search' />
                <input
                  maxLength={100}
                  name='search'
                  value={this.state.search}
                  onChange={e => this.onChange(e)}
                  className='form-control'
                  placeholder='Search by name…'
                />
              </div>
            </div>
            <div className='col-sm-8 col-xs-6 text-right'>
              <button
                className='btn btn-primary'
                onClick={this.onNew}
              >
                Create new landlord
              </button>
            </div>
          </div>

          <div className='table-responsive'>
            {this.props.landlordsLoading ? spinner : dataGrid()}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    landlordsLoading: state.landlord.landlordsLoading,
    landlords: state.landlord.landlordsLoading ? undefined : state.landlord.landlords,
    claims: state.session
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    loadLandlords,
    landlordLoaded
  }, dispatch)
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LandlordList))
