import tenantApi from '../api/TenantApi'
import companyApi from '../api/CompanyApi'
import * as Toast from '../utils/Toast'

export const TENANTS_LOADING = 'TENANTS_LOADING'
export const TENANTS_LOADING_FAILED = 'TENANTS_LOADING_FAILED'
export const TENANTS_LOADED = 'TENANTS_LOADED'
export const ADD_TENANT = 'ADD_TENANT'
export const UPDATE_TENANT = 'UPDATE_TENANT'

export const DELETE_TENANT = 'DELETE_TENANT'
export const CLEAR_TENANTS = 'CLEAR_TENANTS'
export const SAVE_TENANTS_SUCCESS = 'SAVE_TENANTS_SUCCESS'
export const SAVE_TENANTS_STARTED = 'SAVE_TENANTS_STARTED'
export const SAVE_TENANTS_ERROR = 'SAVE_TENANTS_ERROR'
export const REGENERATE_TENANT_APIKEY = 'REGENERATE_TENANT_APIKEY'
export const REMOVE_TENANT = 'REMOVE_TENANT'
export const CHANGE_IS_TEST_ENVIRONMENT = 'CHANGE_IS_TEST_ENVIRONMENT'
export const TOGGLE_FEATURE = 'TOGGLE_FEATURE'
export const DELETE_TENANT_STARTED = 'DELETE_TENANT_STARTED'
export const DELETE_TENANT_FINISHED = 'DELETE_TENANT_FINISHED'

export function tenantsLoading () {
  return { type: TENANTS_LOADING }
}

export function tenantsLoadingFailed () {
  return { type: TENANTS_LOADING_FAILED }
}

export function tenantsLoaded (tenants: any, companyId: any) {
  return { type: TENANTS_LOADED, tenants, companyId }
}

export function tenantApiKeyGenerated (index: any, apiKey: any) {
  return { type: REGENERATE_TENANT_APIKEY, index, apiKey }
}

export function addTenant () {
  return { type: ADD_TENANT }
}

export function saveTenantsStarted () {
  return { type: SAVE_TENANTS_STARTED }
}

export function saveTenantsSuccess () {
  return { type: SAVE_TENANTS_SUCCESS }
}

export function saveTenantsError () {
  return { type: SAVE_TENANTS_ERROR }
}

export function updateTenant (name: any, index: any) {
  return { type: UPDATE_TENANT, name, index }
}

export function removeTenant (index: any, companyId = undefined) {
  return { type: REMOVE_TENANT, index, companyId }
}

export function changeIsTestEnvironment (index: any) {
  return { type: CHANGE_IS_TEST_ENVIRONMENT, index }
}

export function toggleFeature (index: any, featureId: any) {
  return { type: TOGGLE_FEATURE, index, featureId }
}

export function regenerateTenantApiKey (index: any, tenantId: any) {
  return (dispatch: any) => {
    return tenantApi.regenerateTenantApiKey(tenantId).then((response) => {
      if (response) {
        dispatch(tenantApiKeyGenerated(index, response))
      }
    }).catch((p) => {
      Toast.showErrorToast(p)
    })
  }
}

export function deleteTenant (index: any) {
  return { type: DELETE_TENANT, index }
}
export function tenantDeleteStarted (index: any) {
  return { type: DELETE_TENANT_STARTED, index }
}
export function tenantDeleteFinished (index: any) {
  return { type: DELETE_TENANT_FINISHED, index }
}
export function deleteTenantFromCompany (passValue: any, index: any) {
  return (dispatch: any) => {
    let id = passValue.id
    let cId = passValue.cId
    if (id !== '' && id !== '0' && id !== undefined) {
      dispatch(tenantDeleteStarted(index))
      companyApi.deleteTenant(id).then(() => {
        dispatch(tenantDeleteFinished(index))
        dispatch(removeTenant(index, cId))
        Toast.showSuccessToast('Environment successfully deleted')
      }).catch(response => {
        dispatch(tenantDeleteFinished(index))
        Toast.showErrorToast(response)
      })
    } else {
      dispatch(removeTenant(index))
    }
  }
}

export function clearTenants (addEmpty = false) {
  return { type: CLEAR_TENANTS, addEmpty }
}

export function loadTenants (companyId: any, fetchSecrets: boolean = false) {
  return (dispatch: any) => {
    dispatch(tenantsLoading())
    return tenantApi.loadTenants(companyId, fetchSecrets).then((response) => {
      if (response) {
        dispatch(tenantsLoaded(response, companyId))
      }
    }).catch((p) => {
      dispatch(tenantsLoadingFailed())
      Toast.showErrorToast(p)
    })
  }
}