import React from 'react'
import * as ReactBootstrap from 'react-bootstrap'
import * as LogoApi from '../../api/LogosApi'
import { ClipLoader } from 'react-spinners'
import * as Toast from '../../utils/Toast'
import Logo from './Logo'
import { cancellable, CancellablePromise } from '../../api/promiseClient'
import { LoadState } from '../../types/LoadState'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'

const loadingFailed = <div>failed to load</div>
export interface LogoChooserProps {
  tenantId: string
  onSelectLogo: (id: string) => void
  selectedLogoId?: string
}

export interface LogoChooserState {
  loadState: LoadState
  logos: LogoApi.LogoBase[]
  selectedLogoId: string
  uploadImageState: LoadState
  showDeleteModal: boolean
}

export default class LogoChooser extends React.Component<LogoChooserProps, LogoChooserState> {
  fetchLogoListPromise: CancellablePromise<LogoApi.LogoBase[]>
  deleteLogoPromises: { [id: string]: CancellablePromise<void> } = {} // Dictionary of promises
  uploadImagePromise: CancellablePromise<LogoApi.LogoBase>
  input: HTMLInputElement = undefined
  form: HTMLFormElement = undefined

  constructor (props) {
    super(props)
    this.state = {
      loadState: 'Loading',
      logos: [],
      selectedLogoId: props.selectedLogoId || undefined,
      uploadImageState: 'Success',
      showDeleteModal: false
    }
  }

  componentDidMount () {
    console.log('componentDidMount...')
    this.fetchLogoListPromise = cancellable(LogoApi.fetchLogoList(this.props.tenantId))
    this.fetchLogoListPromise.promise
      .then((result) => {
        console.log('componentDidMount logos length', this.state.logos.length)
        this.setState({ logos: result, loadState: 'Success' })
      })
      .catch((error) => {
        if (error.isCancelled)
          return undefined
        this.setState({ loadState: 'Error' })
        Toast.showErrorToast(error)
      })
  }

  componentWillUnmount () {
    if (this.fetchLogoListPromise) {
      this.fetchLogoListPromise.cancel()
    }
    if (this.deleteLogoPromises) {
      for (const key of Object.keys(this.deleteLogoPromises)) {
        const value = this.deleteLogoPromises[key]
        if (value && value.cancel) {
          value.cancel()
        }
      }
    }
    if (this.uploadImagePromise) {
      this.uploadImagePromise.cancel()
    }
  }

  uploadImage (event: Event) {
    // This is the callback for when the reader has finished reading the file
    this.input.value = '' // Reset the input back to blank
    // Then begin the actual upload

    this.uploadImagePromise = cancellable(
      LogoApi.uploadLogo(this.props.tenantId, (event.currentTarget as FileReader).result)
    )
    this.uploadImagePromise.promise
      .then((result) => {
        // We did it, add the new one to the state
        this.setState((prevState) => {
          const logos = [...this.state.logos]
          logos.unshift(result)
          return { ...prevState, logos, uploadImageState: 'Success' }
        })
      })
      .catch((error) => {
        if (error.isCancelled)
          return undefined
        // Something went wrong
        Toast.showErrorToast(`Failed to upload file: ${error}`)
        this.setState({ ...this.state, uploadImageState: 'Error' })
      })
  }

  loadFile (files: FileList) {
    if (files.length !== 1) {
      // We only allow one for now
      Toast.showErrorToast('Too many files selected')
      return
    }

    this.setState({ uploadImageState: 'Loading' }, () => {
      // Once we've set the state, start the work of reading the image
      const reader = new FileReader()
      reader.onload = (event) => this.uploadImage(event)
      reader.readAsArrayBuffer(files[0])
    })
  }

  selectLogo (id: string) {
    if (this.state.selectedLogoId === id) {
      this.setState({...this.state, selectedLogoId: ''})
      this.props.onSelectLogo('')
    } else {
      this.setState({ ...this.state, selectedLogoId: id }) // Mark the logo as selected
      this.props.onSelectLogo(id) // Call the passed in callback
    }
  }

  deleteLogo (id: string) {
    const deleteLogoPromise = this.deleteLogoPromises[id] = cancellable(LogoApi.deleteLogo(this.props.tenantId, id))
    deleteLogoPromise.promise
      .then(() => {
        this.setState((prevState: LogoChooserState) => {
          const logos = prevState.logos.filter(logo => logo.id !== id) // Remove the logo from the list
          return { ...prevState, logos }
        })
      })
      .catch((error) => {
        if (error.isCancelled)
          return undefined
        Toast.showErrorToast(error)
      })
  }

  onLoad = (event) => {
    this.loadFile(event.target.files)
  }

  render () {
    const popover = 
      <Popover id='popover-positioned-left' title='Image Upload Recommendations'>
        <span>For the best results, we recommend:</span>
        <ul>
          <li>A black-and-white image</li>
          <li>In .bmp format</li>
          <li>Does not exceed 500 pixel wide by 500 pixel high</li>
        </ul>
      </Popover>

    let body
    if (this.state.loadState === 'Loading') {
      body = (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
          <ClipLoader size={40} />
        </div>
      )
    } else if (this.state.loadState === 'Error') {
      body = loadingFailed
    } else if (this.state.logos.length === 0) {
      body = <div>No logos uploaded</div>
    } else {
      const logos = this.state.logos.map(logo => (
        <Logo
          key={logo.id}
          tenantId={this.props.tenantId}
          id={logo.id}
          name={logo.name}
          onClick={(id) => this.selectLogo(id)}
          selected={logo.id === this.state.selectedLogoId}
          onDelete={(id) => this.deleteLogo(id)}
        />)
      )
      body = <div className='logo-chooser__gallery'>
        <div className='logo-chooser__gallery__scroll'>
          {logos}
        </div>
      </div>
    }

    console.log('this.state.logos.length', this.state.logos.length)

    let buttonIcon
    switch (this.state.uploadImageState) {
      case 'Success':
        buttonIcon = <i className='bi bi-upload logo-chooser__logo__delete' />
        break
      case 'Loading':
        buttonIcon = <div style={{ display: 'inline-block', width: 14 }}><ClipLoader size={14} /></div>
        break
      case 'Error':
      default:
        buttonIcon = <i className='bi bi-exclamation-circle' />
    }

    return (
      <div className='logo-chooser'>
        <h4>Logos</h4>
        <h5>Custom logo on printed receipts</h5>
        <p>
          Please select an image file or upload a new file
          <OverlayTrigger trigger='click' placement='right' overlay={popover}>
            <i className='bi bi-info-circle logo-chooser__info' />
          </OverlayTrigger>
        </p>
        <input
          type='file'
          name='logo'
          accept='image/bmp'
          onChange={this.onLoad}
          style={{ display: 'none' }} // Hidden because we don't want to see the text
          ref={(element) => this.input = element}
        />
        <Button
          variant='primary'
          onClick={() => this.input.click()}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div style={{paddingRight: 10}}>Upload image</div>
        </Button>
        {body}
      </div>
    )
  }
}