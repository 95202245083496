import { POSSettings } from './POSSettings'
import { EmailSettings } from './EmailSettings'
import { ShippingSettings } from './ShippingSettings'
import { STORE_FILTER, Rule, RuleChild, SubFilter, Filter } from './Menu'
import { RewardSettings } from './RewardSettings'
import { TransferSettings } from './TransferSettings'
import { TransferOutSettings } from './TransferOutSettings'
import { ZipPaymentSettings } from './ZipPaymentSettings'
import { PackType } from './PackType'
import { Carrier } from './Carrier'
import { OrderSettings } from './OrderSettings'
import { FloorToDoorSettings } from './FloorToDoorSettings'

export class StoreTemplate {
  id: string
  name: string
  modifiedByName: string
  modifiedById: string
  modified: Date
  tenantId: string
  companyId: string
  printLogoId: string
  posSettings: POSSettings
  stockEnquiryFilter: Filter
  discountTypes: DiscountType[]
  packTypes: PackType[]
  paymentTypes: PaymentType[]
  Carrier: Carrier[]
  ap21RewardsPrograms: Ap21RewardsProgram[]
  watermarkLogoId: string
  emailSettings: EmailSettings
  shippingSettings: ShippingSettings
  rewardSettings: RewardSettings = new RewardSettings()
  retailTagFilter: RetailTagFilter[]
  transferSettings: TransferSettings
  transferOutSettings: TransferOutSettings
  isConcessionStore: boolean
  orderSettings: OrderSettings

  zipPaymentSettings: ZipPaymentSettings
  floorToDoorSettings: FloorToDoorSettings
  constructor (storeTemplate?: StoreTemplate) {
    if (storeTemplate !== undefined) {
      this.id = storeTemplate.id
      this.name = storeTemplate.name
      this.modifiedByName = storeTemplate.modifiedByName
      this.modifiedById = storeTemplate.modifiedById
      this.modified = storeTemplate.modified
      this.tenantId = storeTemplate.tenantId
      this.companyId = storeTemplate.companyId
      this.printLogoId = storeTemplate.printLogoId
      this.isConcessionStore = storeTemplate.isConcessionStore
      this.orderSettings = new OrderSettings(storeTemplate.orderSettings)
      this.watermarkLogoId = storeTemplate.watermarkLogoId
      this.posSettings = new POSSettings(storeTemplate.posSettings)
      this.transferSettings = new TransferSettings(storeTemplate.transferSettings)
      this.transferOutSettings = new TransferOutSettings(storeTemplate.transferOutSettings)
      this.floorToDoorSettings = new FloorToDoorSettings(storeTemplate.floorToDoorSettings)
      if (storeTemplate.emailSettings === undefined || storeTemplate.emailSettings === null) {
        this.emailSettings = new EmailSettings()
      } else {
        this.emailSettings = storeTemplate.emailSettings
      }
      if (storeTemplate.shippingSettings === undefined || storeTemplate.shippingSettings === null) {
        this.shippingSettings = new ShippingSettings()
      } else {
        this.shippingSettings = storeTemplate.shippingSettings
      }
      if (storeTemplate.rewardSettings === undefined || storeTemplate.rewardSettings === null) {
        this.rewardSettings = new RewardSettings()
      } else {
        this.rewardSettings = storeTemplate.rewardSettings
      }
      this.stockEnquiryFilter = {
        subFilter: storeTemplate.stockEnquiryFilter.subFilter === null ?
          new SubFilter({
            type: STORE_FILTER,
            title: 'Stock Enquiry - Store Filter'
          }) :
          new SubFilter(storeTemplate.stockEnquiryFilter.subFilter),
        expanded: !!storeTemplate.stockEnquiryFilter.subFilter,
        subFilterId: storeTemplate.stockEnquiryFilter.subFilterId,
        ruleChilds: storeTemplate.stockEnquiryFilter.ruleChilds.map((p) => new RuleChild(p)),
        rules: storeTemplate.stockEnquiryFilter.rules.map((p) => new Rule(p))
      }
      if (storeTemplate.stockEnquiryFilter.tagGroups?.length && storeTemplate.stockEnquiryFilter.tagGroups?.length > 0)
        this.stockEnquiryFilter.tagGroups = storeTemplate.stockEnquiryFilter.tagGroups
      if (storeTemplate.zipPaymentSettings === undefined || storeTemplate.zipPaymentSettings === null) {
        this.zipPaymentSettings = new ZipPaymentSettings()
      } else {
        this.zipPaymentSettings = storeTemplate.zipPaymentSettings
      }
    } else {
      this.posSettings = new POSSettings()
      this.emailSettings = new EmailSettings()
      this.shippingSettings = new ShippingSettings()
      this.transferSettings = new TransferSettings()
      this.zipPaymentSettings = new ZipPaymentSettings()
      this.discountTypes = []
      this.paymentTypes = []
      this.ap21RewardsPrograms = []
      this.retailTagFilter = []
      this.isConcessionStore = false
      this.orderSettings = new OrderSettings()
      this.stockEnquiryFilter = {
        subFilter: new SubFilter({
          id: 0, isMatchingAll: true, parentSubFilterId: 0,
          type: STORE_FILTER, title: 'Stock Enquiry - Store Filter', expanded: true
        }),
        ruleChilds: [],
        rules: [],
        subFilterId: '',
        tagGroups: [],
        expanded: true

      }
      this.floorToDoorSettings = {
        floorToDoorEnabled: false
      }
    }
  }
}

export class DiscountType {
  discountTypeId: string
  discountTypeName: string
  selected: boolean
}

export class PaymentType {
  paymentTypeId: string
  PaymentTypeName: string
  selected: boolean
}
export class Ap21RewardsProgram {
  ap21RewardsProgramId: string
  ap21RewardsProgramName: string
  selected: boolean
}
export class RetailTagFilter {
  retailTagFilterId: string
  retailTagFilterName: string
  selected: boolean
}
