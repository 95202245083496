export function GetApiRequest (uri: string, init: RequestInit = {}) {

  const apiUrl = process.env.REACT_APP_API_BASE_URL
  const url = `${apiUrl}${uri}`

  switch (init.method) {
    case 'POST':
      return new Request(url, init)
    case 'PUT':
      return new Request(url, init)
    case 'DELETE':
      return new Request(url, init)
    case 'GET':
    default:
      return new Request(url, init)
  }
}