import * as React from 'react'
import ToggleSwitch from '../common/ToggleSwitch'
import { FloorToDoorSettings } from '../../types/FloorToDoorSettings'
import { StoreTemplate } from '../../types/StoreTemplate'

interface OwnProps {
    storeTemplate: StoreTemplate
    onChange: (storeTemplate: StoreTemplate) => void
}

interface State {
  storeTemplate: StoreTemplate
  settings: FloorToDoorSettings
}

interface StateProps {
}

type InternalProperties = OwnProps & StateProps

export class FloorToDoor extends React.Component<InternalProperties, State> {

  constructor (props: InternalProperties) {
    super(props)
    const template = this.props.storeTemplate === null ? new StoreTemplate(this.props.storeTemplate) : this.props.storeTemplate
    this.state = {
      storeTemplate: template,
      settings: this.props.storeTemplate.floorToDoorSettings
    }
  }

  saveFloorToDoorSettings = (floorToDoorSettings: FloorToDoorSettings) => {
    const template = {
      ...this.state.storeTemplate,
      floorToDoorSettings: { ...floorToDoorSettings }
    }
    this.setState({ ...this.state, storeTemplate: template, settings: floorToDoorSettings })
    this.props.onChange(template)
  }

  changeActive = () => {
    const floorToDoorSettings = {
      ...this.state.settings,
      floorToDoorEnabled: !this.state.settings?.floorToDoorEnabled
    }
    this.saveFloorToDoorSettings(floorToDoorSettings)
  }

  render () {
    return (
      <div className='panel panel-default'>
        <div className='panel-body'>
          <div className='form-group row' style={{ margin: 15 }}>
            <div className='col-sm-9' >
              <h5 className='text-info'>Floor to door</h5>
            </div>
            <div className='col-sm-2' style={{ width: 120, margin: 1, paddingTop: 10, marginLeft: 40 }}>
              <ToggleSwitch
                checked={this.state.settings.floorToDoorEnabled}
                onChange={() => this.changeActive()}
                checkedIconText={'Enabled'}
                uncheckedIconText={'Disabled'}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
