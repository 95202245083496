import {
  COMPANY_SELECTED, TENANT_SELECTED
} from '../actions/sessionActions'
import {
  BULK_TEMPLATE_CHECKED_UNCHECKED, BULK_TEMPLATE_NAVIGATION_TYPE_SELECTED, BULK_TEMPLATE_OPEN_MODAL,
  CLEAR_TEMPLATE,
  TEMPLATE_ADD, TEMPLATE_API_STARTED, TEMPLATE_BULK_APPLIED_FINISHED, TEMPLATE_BULK_APPLY_STARTED,
  TEMPLATE_LOADED, TEMPLATE_NAVIGATION_SELECTED, TEMPLATE_SAVED, TEMPLATE_SAVING,
  TEMPLATE_SHOW_EDITOR, TEMPLATES_LOADED, TEMPLATES_LOADING,
  TEMPLATES_NAVIGATION_LOADED, TEMPLATES_UPDATED, TEMPLATES_PRODUCT_FILTER_LOADED, TEMPLATES_PRINTER_TEMPLATES_LOADED,
  TEMPLATES_GENERAL_TEMPLATES_LOADED, CLEAR_CURRENT_TEMPLATE_NAME, SET_CURRENT_TEMPLATE_NAME, TEMPLATES_CUSTOMER_TEMPLATES_LOADED
} from '../actions/templateActions'
import { Template, ShopUpdateType } from '../types/Template'
import initialState from './initialState'

let sortField = (x: any, y: any, fieldToSort: any) => {
  let nameA = x[fieldToSort].trim().toUpperCase()
  let nameB = y[fieldToSort].trim().toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}
export default function templateReducer (state = initialState.template, action: any) {
  switch (action.type) {

    case TEMPLATE_SAVED: {
      return {
        ...state,
        templateSaving: false,
        templates: state.templates ? state.templates.map((p) => p.showEditor === true ? { ...p, showEditor: false } : p) : []
      }
    }

    case TEMPLATE_SHOW_EDITOR: {
      return {
        ...state,
        templates: [
          ...state.templates.map((p) => p.id === action.id ?
            { ...p, showEditor: true } : { ...p, showEditor: false })
        ]
      }
    }

    case TEMPLATE_NAVIGATION_SELECTED: {
      if (action.shopUpdateType === ShopUpdateType.CustomerTemplateId) {
        return {
          ...state,
          templates: [
            ...state.templates.map((p) => p.id === action.id ?
              { ...p, customerTemplateId: action.val.value, customers: action.val.label } : p)
          ]
        }
      } else if (action.shopUpdateType === ShopUpdateType.ProductFilterId) {
        return {
          ...state,
          templates: [
            ...state.templates.map((p) => p.id === action.id ?
              { ...p, productFilterId: action.val.value, products: action.val.label } : p)
          ]
        }
      } else if (action.shopUpdateType === ShopUpdateType.PrinterTemplateId) {
        return {
          ...state,
          templates: [
            ...state.templates.map((p) => p.id === action.id ?
              { ...p, printerTemplateId: action.val.value, printerTemplate: action.val.label } : p)
          ]
        }
      } else if (action.shopUpdateType === ShopUpdateType.StoreTemplateId) {
        return {
          ...state,
          templates: [
            ...state.templates.map((p) => p.id === action.id ?
              { ...p, generalTemplateId: action.val.value, generalTemplate: action.val.label } : p)
          ]
        }
      }
      return {
        ...state,
        templates: [
          ...state.templates.map((p) => p.id === action.id ?
            { ...p, menuId: action.val.value, navigation: action.val.label } : p)
        ]
      }
    }

    case TEMPLATE_SAVING: {
      return {
        ...state,
        templateSaving: true
      }
    }

    case CLEAR_TEMPLATE: {
      const newTemplates: any = []
      return {
        ...state, templates: newTemplates, bulkTemplates: newTemplates,
        templatesLoading: false, templatesLoaded: false, navigations: [], products: [], printerTemplates: [],
        generalTemplates: []
      }
    }

    case TEMPLATE_LOADED: {
      return {
        ...state,
        template: action.template,
        templatesLoading: false,
        templatesLoaded: true
      }

    }

    case TEMPLATES_LOADED: {
      const bulkNavigationType = [
        { value: ShopUpdateType.StoreTemplateId, label: 'Store' },
        { value: ShopUpdateType.MenuId, label: 'Navigation' },
        { value: ShopUpdateType.ProductFilterId, label: 'Products' },
        { value: ShopUpdateType.CustomerTemplateId, label: 'Customers' },
        { value: ShopUpdateType.PrinterTemplateId, label: 'Receipts' }
      ]
      const newTemplates: any = []
      const newBulkTemplates: any = []
      action.templates.forEach((y: any) => {
        const newTemplate = new Template(y)
        newTemplates.push(newTemplate)
        newBulkTemplates.push({ ...newTemplate })
      })
      newBulkTemplates.sort((x: any, y: any) => sortField(x, y, 'name'))
      return {
        ...state,
        templates: newTemplates,
        bulkTemplates: newBulkTemplates,
        bulkNavigationType,
        templateSaved: false,
        templateSaving: false,
        templateDeleted: false,
        templatesLoading: false,
        templatesLoaded: true
      }
    }

    case TEMPLATES_NAVIGATION_LOADED: {
      const newNavigations: any = []
      action.navigations.forEach((y: any) => {
        newNavigations.push({ value: Number(y.id), label: y.name })
      })
      newNavigations.sort((x: any, y: any) => sortField(x, y, 'label'))
      return {
        ...state,
        navigations: newNavigations
      }
    }

    case TEMPLATES_PRODUCT_FILTER_LOADED: {
      const newProducts: any = []
      action.products.forEach((y: any) => {
        newProducts.push({ value: Number(y.id), label: y.name })
      })
      newProducts.sort((x: any, y: any) => sortField(x, y, 'label'))
      return {
        ...state,
        products: newProducts
      }
    }

    case TEMPLATES_PRINTER_TEMPLATES_LOADED: {
      const newPrinterTemplates: any = []
      action.printerTemplates.forEach((template: any) => {
        newPrinterTemplates.push({ value: Number(template.id), label: template.name })
      })
      newPrinterTemplates.sort((x: any, y: any) => sortField(x, y, 'label'))
      return {
        ...state,
        printerTemplates: newPrinterTemplates
      }
    }

    case TEMPLATES_GENERAL_TEMPLATES_LOADED: {
      const newGeneralTemplates: any = []
      action.generalTemplates.forEach((template: any) => {
        newGeneralTemplates.push({ value: Number(template.id), label: template.name })
      })
      newGeneralTemplates.sort((x: any, y: any) => sortField(x, y, 'label'))
      return {
        ...state,
        generalTemplates: newGeneralTemplates
      }
    }
    case TEMPLATES_CUSTOMER_TEMPLATES_LOADED: {
      const newCustomerTemplates: any = []
      action.customerTemplates.forEach((template: any) => {
        newCustomerTemplates.push({ value: Number(template.id), label: template.name })
      })
      newCustomerTemplates.sort((x: any, y: any) => sortField(x, y, 'label'))
      return {
        ...state,
        customerTemplates: newCustomerTemplates
      }
    }
    case TEMPLATE_LOADED:
      return { ...state, error: '', menuSaved: false, menuDeleted: false }

    case TEMPLATES_LOADING:
      return { ...state, templatesLoading: true, templates: undefined, bulkTemplates: undefined }

    case COMPANY_SELECTED:
      return { ...state, companyId: action.companyId ? action.companyId.value : '' }

    case TENANT_SELECTED:
      return { ...state, tenantId: action.tenantId ? action.tenantId.value : '' }

    case TEMPLATE_API_STARTED:
      return { ...state, error: '', templateSaved: false, templateDeleted: false }

    case BULK_TEMPLATE_NAVIGATION_TYPE_SELECTED: {
      const selectedType = action.value.value
      if (selectedType === ShopUpdateType.MenuId) {
        return { ...state, bulkNavigationOptions: [...state.navigations] }
      }
      if (selectedType === ShopUpdateType.ProductFilterId) {
        return { ...state, bulkNavigationOptions: [...state.products] }
      }
      if (selectedType === ShopUpdateType.PrinterTemplateId) {
        return { ...state, bulkNavigationOptions: [...state.printerTemplates] }
      }
      if (selectedType === ShopUpdateType.StoreTemplateId) {
        return { ...state, bulkNavigationOptions: [...state.generalTemplates] }
      }
      if (selectedType === ShopUpdateType.CustomerTemplateId) {
        return { ...state, bulkNavigationOptions: [...state.customerTemplates] }
      }
      return { ...state, bulkNavigationOptions: [] }
    }

    case TEMPLATE_BULK_APPLY_STARTED:
      return { ...state, templatesLoading: true }

    case TEMPLATE_BULK_APPLIED_FINISHED:
      return { ...state, templatesLoading: false }

    case BULK_TEMPLATE_CHECKED_UNCHECKED: {
      return {
        ...state,
        bulkTemplates: (action.id === '-1') ? [
          ...state.bulkTemplates.map((p: any) => ({ ...p, checked: action.checked }))
        ]
          : [
            ...state.bulkTemplates.map((p: any) => p.id === action.id ?
              { ...p, checked: action.checked } : p)
          ]
      }
    }

    case BULK_TEMPLATE_OPEN_MODAL: {
      return {
        ...state,
        bulkTemplates: [
          ...state.bulkTemplates.map((p) => ({ ...p, checked: false }))
        ]
      }
    }

    case SET_CURRENT_TEMPLATE_NAME: {
      return {
        ...state,
        currentTemplateName: action.name
      }
    }

    case CLEAR_CURRENT_TEMPLATE_NAME: {
      return {
        ...state,
        currentTemplateName: ''
      }
    }

    default:
      return state
  }
}
