export default class RegistrationPin {

  id: string
  pin: string
  createdByUserId: string
  createdByUser: string
  createdByUserEmail: string
  validUntil: Date | null
  tenantId: number | undefined

  constructor (regPin?: RegistrationPin) {
    if (regPin) {
      this.id = regPin.id
      this.pin = regPin.pin
      this.createdByUserId = regPin.createdByUserId
      this.createdByUserEmail = regPin.createdByUserEmail
      this.createdByUser = regPin.createdByUser
      this.validUntil = regPin.validUntil
      this.tenantId = regPin.tenantId
    } else {
      this.id = ''
      this.pin = ''
      this.createdByUserId = ''
      this.createdByUserEmail = ''
      this.createdByUser = ''
      this.validUntil = null
      this.tenantId = undefined
    }
  }
}
