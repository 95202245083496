import React from 'react'
import sessionApi from '../../api/SessionApi'
import { showErrorToast } from '../../utils/Toast'

interface CaptchaProps {
  isLoaded?: boolean
  setRef?: (ref: any) => void
  onCallback?: () => void
  onExpiredCallback?: () => void
  shake?: boolean
}

declare const grecaptcha: any
declare global {
  interface Window {
    onRecaptchaLoad: () => void
  }
}

class Captcha extends React.Component<CaptchaProps> {
  state = {
    siteKey: ''
  }

  loadCaptcha () {
    if (this.state.siteKey) {
      const script = document.createElement('script')
      script.src =
        'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit'
      document.body.appendChild(script)
    }
    window.onRecaptchaLoad = this.onRecaptchaLoad
  }

  componentDidMount () {
    sessionApi.getConfiguration().then((response) => {
      if (response.success === true) {
        this.setState({siteKey: response.key}, this.loadCaptcha)
      }
    }).catch((error) => {
      // console.log('error', error)
      showErrorToast(error)
    })
  }

  onRecaptchaLoad = () => {
    const recaptchaContainer = document.getElementById('recaptcha-container')
    if (recaptchaContainer && !recaptchaContainer.hasChildNodes()) {
      const captchaRef = grecaptcha?.render(recaptchaContainer, {
        sitekey: this.state.siteKey,
        callback: this.props.onCallback,
        'expired-callback': this.props.onExpiredCallback
      })
      this.props.setRef(captchaRef)
    }
  }

  render () {
    const { shake } = this.props
    let wrapperClass = 'form-group'
    if (shake === true) {
      wrapperClass += ' shake'
    }

    return (
      <div className={wrapperClass}>
        <div id='recaptcha-container' />
      </div>
    )
  }
}

export default Captcha
