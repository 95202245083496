import AnyOfFilter from './AnyOfFilter'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleCarrier } from '../../actions/storeActions'

function mapStateToProps (state) {
  return {
    heading: 'Carrier',
    labelKey: 'carrierName',
    valueKey: 'carrierId',
    options: state.store.carriers,
    title: 'Carrier'
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    toggleOptions: toggleCarrier
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AnyOfFilter)