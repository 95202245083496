export class OrderSettings {
  orderPackingEnabled: boolean
  forceScanning: boolean

  constructor (orderSettings?: OrderSettings) {
    if (orderSettings !== undefined && orderSettings !== null) {
      this.orderPackingEnabled = orderSettings.orderPackingEnabled
      this.forceScanning = orderSettings.forceScanning
    } else {
      this.orderPackingEnabled = false
      this.forceScanning = false
    }
  }
}