export class PrinterTemplate {
  id: string
  name: string
  modifiedByName: string
  modifiedById: string
  modified: Date
  tenantId: string
  companyId: string
  receiptFormat: string
  orderReceiptFormat: string
  digitalReceiptFormat: string
  digitalEmailFormat: string
  transferOutReceiptFormat: string
  emailFrom: string
  emailSubject: string
  returnsReceiptFormat: string
  returnsDigitalReceiptFormat: string
  returnsDigitalEmailFormat: string
  returnsEmailFrom: string
  returnsEmailSubject: string
  constructor (printerTemplate?: PrinterTemplate) {
    if (printerTemplate !== undefined) {
      this.id = printerTemplate.id
      this.name = printerTemplate.name
      this.modifiedByName = printerTemplate.modifiedByName
      this.modifiedById = printerTemplate.modifiedById
      this.modified = printerTemplate.modified
      this.tenantId = printerTemplate.tenantId
      this.companyId = printerTemplate.companyId
      this.receiptFormat = printerTemplate.receiptFormat
      this.orderReceiptFormat = printerTemplate.orderReceiptFormat
      this.digitalReceiptFormat = printerTemplate.digitalReceiptFormat
      this.digitalEmailFormat = printerTemplate.digitalEmailFormat
      this.emailFrom = printerTemplate.emailFrom
      this.emailSubject = printerTemplate.emailSubject
      this.transferOutReceiptFormat = printerTemplate.transferOutReceiptFormat
      this.returnsReceiptFormat = printerTemplate.returnsReceiptFormat
      this.returnsDigitalReceiptFormat = printerTemplate.returnsDigitalReceiptFormat
      this.returnsDigitalEmailFormat = printerTemplate.returnsDigitalEmailFormat
      this.returnsEmailFrom = printerTemplate.returnsEmailFrom
      this.returnsEmailSubject = printerTemplate.returnsEmailSubject
    }
  }
}