import authenticator from '../auth/authenticator'
import { GetApiRequest } from '../utils/ApiUtils'
import fetcher from './ApiMethods'

export default class TemplateApi {
  static saveTemplate (template: any, tenantId: string) {
    const payload = template

    const uri = `/api/tenant/${tenantId}/template/${template.id}`
    const request = GetApiRequest(uri, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  static applyBulkTemplate (shopIds: any, update: any, updateId: string, tenantId: string) {
    const payload = { shopIds: shopIds, update: update, updateId: updateId }

    const uri = `/api/tenant/${tenantId}/template/BulkUpdate`
    const request = GetApiRequest(uri, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  static loadTemplates (tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/template`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }
}
