import companyApi from '../api/CompanyApi'
import * as Toast from '../utils/Toast'
import { saveTenantsStarted, saveTenantsSuccess, saveTenantsError } from './tenantActions'
import { loadTenants } from './sessionActions'

export const COMPANYS_LOADING = 'COMPANYS_LOADING'
export const COMPANYS_LOADED = 'COMPANYS_LOADED'
export const COMPANYS_UPDATED = 'COMPANYS_UPDATED'
export const SAVE_COMPANY_SUCCESS = 'SAVE_COMPANY_SUCCESS'
export const SAVE_COMPANY_ERROR = 'SAVE_COMPANY_ERROR'
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS'
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR'
export const COMPANY_API_STARTED = 'COMPANY_API_STARTED'
export const COMPANY_LOADED = 'COMPANY_LOADED'
export const CLEAR_COMPANYS = 'CLEAR_COMPANYS'

export function companyApiStarted () {
  return { type: COMPANY_API_STARTED }
}

export function companysLoading () {
  return { type: COMPANYS_LOADING }
}

export function saveCompanySuccess (companyDetails: any) {
  return { type: SAVE_COMPANY_SUCCESS, companyDetails }
}

export function saveCompanyError (error: any) {
  return { type: SAVE_COMPANY_ERROR, error }
}

export function companysLoaded (companys: any) {
  return { type: COMPANYS_LOADED, companys }
}

export function companyLoaded () {
  return { type: COMPANY_LOADED }
}

export function clearCompanys () {
  return { type: CLEAR_COMPANYS }
}

export function companysUpdated (companys: any) {
  return { type: COMPANYS_UPDATED, companys }
}

export function deleteCompanySuccess (id: any) {
  return { type: DELETE_COMPANY_SUCCESS, id }
}

export function deleteCompanyError (error: any) {
  return { type: DELETE_COMPANY_ERROR, error }
}

export function loadCompanys () {
  return (dispatch: any) => {
    dispatch(companysLoading())
    return companyApi.loadCompanys().then((response) => {
      if (response) {
        dispatch(companysLoaded(response))
      }
    }).catch((p) => {
      dispatch(companysLoaded([]))
      Toast.showErrorToast(p)
    })
  }
}

export function saveCompany (claims: any, company: any, tenants: any) {
  return (dispatch: any, getState: any) => {
    if (!company.name) {
      return dispatch(saveCompanyError('Error - Name cannot be blank'))
    }
    if (!company.sourceId) {
      // return dispatch(saveCompanyError('Error - Source Id cannot be blank'))
      // OVQ --
      company.sourceId = company.name
    }
    if (claims.role === 'superuser') {
      if (!company.landlord) {
        return dispatch(saveCompanyError('Error - Landlord cannot be blank'))
      }
    }
    if (claims.role !== 'superuser') {
      if (!company.landlord) {
        return dispatch(saveCompanyError('Error - User is not assigned to a Landlord'))
      }
    }
    let error = ''
    tenants.forEach((x: any) => {
      if (x.name === '') {
        error = 'Error - Environment name cannot be blank'
      }
    })

    if (error === '') {
      const flags: any = []
      tenants.forEach((x: any) => {
        const c = x.name.toLowerCase()
        if (flags[c]) {
          error = 'Error - Environment names cannot be duplicated'
        }
        flags[c] = true
      })
    }

    if (error !== '') {
      return dispatch(saveCompanyError(error))
    }

    if (claims.role === 'superuser' || claims.role === 'admin') {
      dispatch(companyApiStarted())
      companyApi.saveCompany(company).then((response) => {
        if (response.id) {
          company.id = response.id
          return companyApi.updateTenants(company, tenants).then((tenantResponse) => {
            if (tenantResponse.ok) {
              const state = getState()
              if (state.session.companyId === company.id)
                dispatch(loadTenants(company.id, state.session.tenantId))
              dispatch(saveCompanySuccess(response))
              Toast.showSuccessToast('Company saved')
            } else if (tenantResponse.error) {
              dispatch(saveCompanyError(tenantResponse.error))
            } else {
              dispatch(saveCompanyError(tenantResponse))
            }
          }).catch(err => {
            dispatch(saveCompanyError(''))
            Toast.showErrorToast(err)
          })
        } else if (response.error) {
          return dispatch(saveCompanyError(response.error))
        }
        return dispatch(saveCompanyError(response))
      }).catch((p) => {
        dispatch(saveCompanyError(''))
        Toast.showErrorToast(p)
      })
    } else {
      dispatch(saveTenantsStarted())
      return companyApi.updateTenantFeatures(company, tenants).then((tenantResponse) => {
        if (tenantResponse.ok) {
          const state = getState()
          if (state.session.companyId === company.id)
            dispatch(loadTenants(company.id, state.session.tenantId))
          dispatch(saveTenantsSuccess())
          Toast.showSuccessToast('Environments saved')
        } else if (tenantResponse.error) {
          dispatch(saveTenantsError())
        } else {
          dispatch(saveTenantsError())
        }
      }).catch((p) => {
        dispatch(saveTenantsError())
        Toast.showErrorToast(p)
      })
    }
  }
}

export function deleteCompany (company: any) {
  return (dispatch: any) => {
    return companyApi.deleteCompany(company.id).then((response) => {
      if (response.ok) {
        dispatch(deleteCompanySuccess(company.id))
        Toast.showSuccessToast(`Company ${company.name} has been deleted`)
      } else if (response.error) {
        dispatch(deleteCompanyError(response.error))
      } else {
        dispatch(deleteCompanyError(response))
      }
    })
  }
}
