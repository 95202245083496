import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useParams, useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import {
  deleteProduct,
  loadProductDetail,
  NameChange,
  ProductAdd,
  productTenantSelect,
  redirectToListing,
  saveProduct,
  setProductDeletedSuccess,
  subFilterMatch
} from '../actions/productActions'
import { Button, Modal } from 'react-bootstrap'
import TextInput from './common/TextInput'
import ProductRules from './ProductRule'
import { IDispatchProps as ISubFilterDispatchProps, ITSubFilterOwnProps as IOwnProps, TSubFilterItem } from './SubFilter'

const mapDispatchToSubItemProps = (dispatch) => {
  return {
    subFilterMatch: (id, all) => dispatch(subFilterMatch(id, all))
  }
}
const SubFilterItem = connect<{}, ISubFilterDispatchProps, IOwnProps>(null, mapDispatchToSubItemProps)(TSubFilterItem)

interface IDispatchProps {
  redirectToListing,
  saveProduct,
  deleteProduct,
  productTenantSelect,
  NameChange,
  loadProductDetail,
  ProductAdd,
  setProductDeletedSuccess
}

interface IStateProps {
  product,
  subFilter,
  productSaving,
  tenantId,
  productDeleted,
  productsLoading,
  productSaved,
  productSavedId,
  productDeletedSuccess
}

const ProductDetail = (props: IDispatchProps & IStateProps) => {
  const { productId } = useParams()
  const navigate = useNavigate()
  const tenantIdRef = useRef(props.tenantId)

  const [state, setState] = useState({
    id: '',
    modalIsOpen: false,
    modalError: '',
    name: '',
    deletingId: '-1'
  })  

  useEffect(() => {
    if (
      props.tenantId &&
      props.tenantId !== ''
    ) {
      if (productId === 'new') {
        props.ProductAdd(props.tenantId)
        if (props.tenantId) {
          props.productTenantSelect(props.tenantId)
        }
      } else if (tenantIdRef.current !== props.tenantId) {
        navigate('/products') // Tenant has changed. Go to products, the selected product id might be a different product
      } else if (productId !== '') {
        props.loadProductDetail(productId, props.tenantId)
      }
      tenantIdRef.current = props.tenantId
    } else {
      navigate('/products')
    }
  }, [props.tenantId, productId])

  useEffect(() => {
    if (props.productSaved && !props.productSaving) {
      navigate(`/products/${props.productSavedId}`)
    }
  }, [props.productSaved, props.productSaving])

  useEffect(() => {
    if (props.productDeletedSuccess) {
      props.setProductDeletedSuccess(false)
      navigate('/products')
    }
  }, [props.productDeletedSuccess])

  const onChange = (event) => {
    changeState(event.target.name, event.target.value)
  }

  const onDelete = (event) => {
    event.preventDefault()
    openModal()
  }

  const onNew = () => {
    setState({ id: '', name: '', modalIsOpen: false, modalError: '', deletingId: '-1' })
  }

  const onSubmit = (event) => {
    event.preventDefault()
    props.saveProduct(state)
  }

  const deleteAndCloseModal = (event) => {
    event.preventDefault()
    props.deleteProduct(props.product, props.tenantId)
    setState({
      ...state,
      modalIsOpen: false,
      modalError: ''
    })
  }

  const openModal = () => {
    setState({
      ...state,
      modalIsOpen: true,
      modalError: ''
    })
  }

  const closeModal = () => {
    setState({
      ...state,
      modalIsOpen: false,
      modalError: ''
    })
  }

  const populateExisting = (row) => {
    setState({ id: row.id, name: row.name, modalIsOpen: false, modalError: '', deletingId: '-1' })
  }

  const changeState = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const renderModal = () => {
    const deleteMessage = `Please confirm you wish to delete "${props.product.name}" `
    return (
      <Modal show={state.modalIsOpen} onHide={closeModal}>
        <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <Modal.Title style={{ marginRight: 'auto'}}>Delete</Modal.Title>
          {/* Custom Close Button */}
          <Button type='button' className='close' aria-label='Close' onClick={closeModal} style={{ border: 'none', boxShadow: 'none' }}>
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{deleteMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{ margin: 5, textAlign: 'center' }}
            className='btn btn-danger'
            onClick={deleteAndCloseModal}
          >
            Delete
          </button>
          <button
            style={{ margin: 5, textAlign: 'center' }}
            className='btn btn-default pull-left'
            onClick={closeModal}
          >
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  const onValueChange = (event) => {
    props.NameChange(event.target.value)
  }

  const onSaveClick = () => {
    props.saveProduct(props.product, props.tenantId)
  }

  const inlineSpinner = (
    <div style={{ marginLeft: '20px', height: '15px', display: 'inline-block' }}>
      <ClipLoader size={15} />
    </div>
  )
  const spinner = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
      <ClipLoader size={40} />
    </div>
  )
  let deleteBtn = null
  if (props.product.id) {
    deleteBtn = (
      <Button
        variant='danger'
        style={{ marginLeft: 5, textAlign: 'center' }}
        onClick={onDelete}
        disabled={props.productSaving || props.productDeleted}
        className='pull-right'
      >
        Delete {!props.productDeleted || inlineSpinner}
      </Button>
    )
  }

  const subFilter = (props.tenantId && props.subFilter
    ? (
      <div key={props.subFilter.id}>
        <SubFilterItem
          item={props.subFilter}
          group=''
          key={'productFilter'}
          seq={0}
          max={1}
          SingleRule={<ProductRules subFilterId={props.subFilter.id} />}
        />
      </div>)
    : '')
  const body = (
    <div>
      <div>
        <div className='row'>
          <div className='col-md-3 col-xs-6'>
            <TextInput
              maxLength={100}
              name='name'
              label='Name'
              value={props.product.name}
              onChange={onValueChange}
              disabled={props.tenantId === ''}
              style={{}}
            /></div>
          <div className='col-md-7 col-xs-6 pull-right'>
            <div className='form-group'>
              <label>&nbsp;
              </label>
              <div className='field'>
                {deleteBtn}
                <Button
                  variant='primary'
                  onClick={onSaveClick}
                  disabled={props.productSaving || props.productDeleted}
                  className='pull-right'
                >
                  Save {!props.productSaving || inlineSpinner}
                </Button>
              </div>
            </div>
          </div>
          {renderModal()}
        </div>
        <hr /> {subFilter}
      </div>
    </div>
  )

  return (
    <div className='container-fluid'>
      <h3 style={{ flex: '1 1 100%' }}>
        <p>{'Product Template Details ' + props.product.name}</p>
      </h3>
      {props.productsLoading ? spinner : body}
    </div>
  )
}

function mapStateToProps (state): IStateProps {
  return {
    productDeleted: state.product.productDeleted,
    productsLoading: state.product.productsLoading,
    tenantId: state.session.tenantId,
    subFilter: state.product.product.subFilter,
    product: state.product.product,
    productSaving: state.product.productSaving,
    productSaved: state.product.productSaved,
    productSavedId: state.product.productSavedId,
    productDeletedSuccess: state.product.productDeletedSuccess
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    redirectToListing,
    saveProduct,
    deleteProduct,
    productTenantSelect,
    NameChange,
    loadProductDetail,
    ProductAdd,
    setProductDeletedSuccess
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)