import activityLogApi from '../api/ActivityLogApi'
import * as Toast from '../utils/Toast'
import { showErrorToast } from '../utils/Toast'

export const ACTIVITY_LOGS_LOADING = 'ACTIVITY_LOGS_LOADING'
export const ACTIVITY_LOGS_LOADED = 'ACTIVITY_LOGS_LOADED'
export const ACTIVITY_NOTE_ADD = 'ACTIVITY_NOTE_ADD'
export const ACTIVITY_NOTE_UPDATE = 'ACTIVITY_NOTE_UPDATE'
export const ACTIVITY_NOTE_SAVE_SUCCESS = 'ACTIVITY_NOTE_SAVE_SUCCESS'
export const ACTIVITY_NOTE_SAVING = 'ACTIVITY_NOTE_SAVING'
export const ACTIVITY_NOTE_CANCEL = 'ACTIVITY_NOTE_CANCEL'
export const CLEAR_ACTIVITY_LOGS = 'CLEAR_ACTIVITY_LOGS'
export const ACTIVITY_NOTE_DELETE_SUCCESS = 'ACTIVITY_NOTE_DELETE_SUCCESS'
export const ACTIVITY_NOTE_DELETING = 'ACTIVITY_NOTE_DELETING'

export function activityLogsLoading (activityNote: any) {
  return { type: ACTIVITY_LOGS_LOADING, activityNote }
}
export function activityLogsLoaded (activityLogs: any) {
  return { type: ACTIVITY_LOGS_LOADED, activityLogs }
}
export function loadActivityLogs (tableName: any, parentRecordId: any) {
  return (dispatch: any) => {
    dispatch(activityLogsLoading(undefined))
    return activityLogApi.loadActivityLogs(tableName, parentRecordId).then((response) => {
      if (response) {
        dispatch(activityLogsLoaded(response))
      }
    }).catch(err => {
      showErrorToast(err)
    })
  }
}

export function clearActivityLogs () {
  return { type: CLEAR_ACTIVITY_LOGS }
}

export function activityNoteSaving (activityNote: any) {
  return { type: ACTIVITY_NOTE_SAVING, activityNote }
}

export function activityNoteCacel (activityNote: any) {
  return { type: ACTIVITY_NOTE_CANCEL, activityNote }
}

export function activityNoteAdd (id: any) {
  return { type: ACTIVITY_NOTE_ADD, id }
}

export function activityNoteSaveSuccessful (activityNote: any) {
  return { type: ACTIVITY_NOTE_SAVE_SUCCESS, activityNote }
}
export function updateNote (activityNote: any) {
  return { type: ACTIVITY_NOTE_UPDATE, activityNote }
}
export function saveActivityNote (activityNote: any, tenantId: any) {
  return (dispatch: any) => {
    if (!activityNote.note) {
      Toast.showErrorToast('Note can not be empty')
    }
    dispatch(activityNoteSaving(activityNote))
    return activityLogApi.saveActivityNotes(activityNote, tenantId).then((response) => {
      activityNote.id = response.id
      dispatch(activityNoteSaveSuccessful(response))
    }).catch(err => {
      showErrorToast(err)
    })
  }
}

export function deleteActivityNoteSuccess (activityNote: any) {
  return { type: ACTIVITY_NOTE_DELETE_SUCCESS, activityNote }
}

export function deletingActivityNote (activityNote: any) {
  return { type: ACTIVITY_NOTE_DELETING, activityNote }
}

export function deleteActivityNote (activityNote: any, tenantId: any) {
  return (dispatch: any) => {
    dispatch(deletingActivityNote(activityNote))
    return activityLogApi.deleteActivityNote(activityNote.id, tenantId).then(() => {
      dispatch(deleteActivityNoteSuccess(activityNote))
      Toast.showSuccessToast('Note has been deleted')
    })
  }
}
