import {
  STORE_RULE_ADD, STORE_SUBMENU_MATCH, STORE_RULE_LOAD, STORE_TAGS_LOADED, STORE_TENANT_SELECT, STORE_CLEAR,
  STORE_RULE_CHANGE_OPERATOR, STORE_RULE_CHANGE_TAGGROUP, STORE_RULE_CHANGE_TAGS, STORE_RULE_DELETE,
  STORE_DISCOUNTTYPES_LOADED, STORE_DISCOUNTTYPE_TOGGLE, STORE_CARRIER_TOGGLE, STORE_PACKTYPE_TOGGLE, STORE_PAYMENTTYPE_TOGGLE,
  STORE_AP21REWARDSPROGRAM_TOGGLE, STORE_PAYMENTTYPES_LOADED, STORE_RETAILTAGTYPE_TOGGLE, STORE_TRANSFEROUT_WAREHOUSE_RULE_ADD,
  STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_TAGGROUP, STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_OPERATOR,
  STORE_TRANSFEROUT_WAREHOUSE_RULE_DELETE, STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_TAGS, STORE_TRANSFEROUT_WAREHOUSE_SUBMENU_MATCH
} from '../actions/storeActions'
import { Rule, RuleChild } from '../types/Menu'
import initialState from './initialState'

export default function storeReducer (state = initialState.store, action: any) {
  switch (action.type) {
    case STORE_RULE_LOAD: {
      const { stockEnquiryFilter, discountTypes, packTypes, carriers, ap21RewardsPrograms,
        paymentTypes, retailTagTypes, warehouseSubFilter } = action
      return {
        ...state,
        stockEnquiryFilter: {
          ...state.stockEnquiryFilter,
          ...stockEnquiryFilter
        },
        discountTypes,
        carriers,
        packTypes,
        ap21RewardsPrograms,
        paymentTypes,
        retailTagTypes,
        transferOutWarehouseFilter: {
          ...state.transferOutWarehouseFilter,
          ...warehouseSubFilter
        }
      }
    }
    case STORE_SUBMENU_MATCH: {
      const { id, all } = action
      return {
        ...state,
        stockEnquiryFilter: {
          ...state.stockEnquiryFilter,
          subFilter: {
            ...state.stockEnquiryFilter.subFilter,
            isMatchingAll: all
          }
        }
      }
    }

    case STORE_RULE_ADD:
    {

      return {
        ...state,
        stockEnquiryFilter: {
          ...state.stockEnquiryFilter,
          rules: [
            ...state.stockEnquiryFilter.rules,
            new Rule({ subFilterId: action.subFilterId })
          ]
        }
      }
    }

    case STORE_RULE_CHANGE_TAGGROUP:
    {
      return {
        ...state,
        stockEnquiryFilter: {
          ...state.stockEnquiryFilter,
          rules: [
            ...state.stockEnquiryFilter.rules.map((p: any) => {
              if (p.id === action.ruleId) {
                return {
                  ...p,
                  tagGroupId: action.tagGroup
                }
              } else { return p }
            })
          ],

          ruleChilds: [
            ...state.stockEnquiryFilter.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId)
          ]
        }
      }
    }

    case STORE_RULE_CHANGE_OPERATOR:
    {
      return {
        ...state,
        stockEnquiryFilter: {
          ...state.stockEnquiryFilter,
          rules: [
            ...state.stockEnquiryFilter.rules.map((p: any) => {
              if (p.id === action.ruleId) {
                return {
                  ...p,
                  operatorType: action.operator
                }
              } else {
                return p
              }
            })
          ],
          ruleChilds: [
            ...state.stockEnquiryFilter.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId)
          ]
        }
      }
    }

    case STORE_RULE_DELETE:
    {
      return {
        ...state,
        stockEnquiryFilter: {
          ...state.stockEnquiryFilter,
          rules: [
            ...state.stockEnquiryFilter.rules.filter((p: any) => p.id !== action.id)
          ],
          ruleChilds: [
            ...state.stockEnquiryFilter.ruleChilds.filter((p: any) => p.ruleId !== action.id)
          ]
        }
      }
    }
    case STORE_RULE_CHANGE_TAGS:
      return {
        ...state,
        stockEnquiryFilter: {
          ...state.stockEnquiryFilter,
          ruleChilds: [
            ...state.stockEnquiryFilter.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId),
            ...action.tags.map((p: any) => new RuleChild({ ruleId: action.ruleId, tagId: p }))
          ]
        }
      }

    case STORE_TAGS_LOADED:
      return {
        ...state,
        stockEnquiryFilter: {
          ...state.stockEnquiryFilter,
          tagGroups: action.tags
        }
      }

    case STORE_DISCOUNTTYPE_TOGGLE:
      return {
        ...state,
        discountTypes: state.discountTypes.map((p: any) => p.discountTypeId === action.discountTypeId ?
          {
            ...p,
            selected: !p.selected
          } : p)
      }
    case STORE_PAYMENTTYPE_TOGGLE:
      // 115176 - isindevelopment - comment out for now
      // const newTypes = []
      // let toggledType = null
      // for (const type of state.paymentTypes) {
      //   if (type.paymentTypeId === action.paymentTypeId) {
      //     type.selected = !type.selected

      //     if (type.selected) {
      //       toggledType = type
      //     } else {
      //       newTypes.push(type)
      //     }
      //   } else {
      //     newTypes.push(type)
      //   }
      // }

      // if (toggledType) {        
      //   newTypes.push(toggledType)
      // }

      // return {
      //   ...state,
      //   paymentTypes: [...newTypes]
      // }
      return {
        ...state,
        paymentTypes: state.paymentTypes.map((p: any) => p.paymentTypeId === action.paymentTypeId ?
          {
            ...p,
            selected: !p.selected
          } : p)
      }
    case STORE_PACKTYPE_TOGGLE:
      return {
        ...state,
        packTypes: state.packTypes.map((p: any) => p.packTypeId === action.packTypeId ?
          {
            ...p,
            selected: !p.selected
          } : p)
      }
    case STORE_CARRIER_TOGGLE:
      return {
        ...state,
        carriers: state.carriers.map((p: any) => p.carrierId === action.carrierId ?
          {
            ...p,
            selected: !p.selected
          } : p)
      }
    case STORE_AP21REWARDSPROGRAM_TOGGLE:
      return {
        ...state,
        ap21RewardsPrograms: state.ap21RewardsPrograms.map((p: any) => p.ap21RewardsProgramId === action.ap21RewardsProgramId[0] ?
          {
            ...p,
            selected: true
          } :
          {
            ...p,
            selected: false
          })
      }
    case STORE_RETAILTAGTYPE_TOGGLE:
      return {
        ...state,
        retailTagTypes: state.retailTagTypes.map((p: any) => p.retailTagFilterId === action.selectedRetailTagTypeId ?
          {
            ...p,
            selected: true
          } :
          {
            ...p,
            selected: false
          })
      }
    case STORE_DISCOUNTTYPES_LOADED:
      return {
        ...state,
        discountTypes: action.discountTypes.map((p: any) => ({
          discountTypeId: p.id,
          discountTypeName: p.name,
          selected: false
        }))
      }
    case STORE_PAYMENTTYPES_LOADED:
      return {
        ...state,
        paymentTypes: action.paymentTypes.map((p: any) => ({
          paymentTypeId: p.id,
          paymentTypeName: p.name,
          selected: false
        }))
      }
    case STORE_TENANT_SELECT:
      return {
        ...state,
        stockEnquiryFilter: {
          ...state.stockEnquiryFilter,
          tenantId: action.id
        },
        transferOutWarehouseFilter: {
          ...state.transferOutWarehouseFilter
        }
      }

    case STORE_CLEAR:
      return initialState.store

    case STORE_TRANSFEROUT_WAREHOUSE_SUBMENU_MATCH: {
      const { id, all } = action
      return {
        ...state,
        transferOutWarehouseFilter: {
          ...state.transferOutWarehouseFilter,
          subFilter: {
            ...state.transferOutWarehouseFilter.subFilter,
            isMatchingAll: all
          }
        }
      }
    }
    case STORE_TRANSFEROUT_WAREHOUSE_RULE_ADD:
    {
      return {
        ...state,
        transferOutWarehouseFilter: {
          ...state.transferOutWarehouseFilter,
          rules: [
            ...state.transferOutWarehouseFilter.rules,
            new Rule({ subFilterId: action.subFilterId })
          ]
        }
      }
    }
    case STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_TAGGROUP:
    {
      return {
        ...state,
        transferOutWarehouseFilter: {
          ...state.transferOutWarehouseFilter,
          rules: [
            ...state.transferOutWarehouseFilter.rules.map((p: any) => {
              if (p.id === action.ruleId) {
                return {
                  ...p,
                  tagGroupId: action.tagGroup
                }
              } else { return p }
            })
          ],
          ruleChilds: [
            ...state.transferOutWarehouseFilter.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId)
          ]
        }
      }
    }
    case STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_OPERATOR:
    {
      return {
        ...state,
        transferOutWarehouseFilter: {
          ...state.transferOutWarehouseFilter,
          rules: [
            ...state.transferOutWarehouseFilter.rules.map((p: any) => {
              if (p.id === action.ruleId) {
                return {
                  ...p,
                  operatorType: action.operator
                }
              } else {
                return p
              }
            })
          ],
          ruleChilds: [
            ...state.transferOutWarehouseFilter.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId)
          ]
        }
      }
    }
    case STORE_TRANSFEROUT_WAREHOUSE_RULE_DELETE:
    {
      return {
        ...state,
        transferOutWarehouseFilter: {
          ...state.transferOutWarehouseFilter,
          rules: [
            ...state.transferOutWarehouseFilter.rules.filter((p: any) => p.id !== action.id)
          ],
          ruleChilds: [
            ...state.transferOutWarehouseFilter.ruleChilds.filter((p: any) => p.ruleId !== action.id)
          ]
        }
      }
    }
    case STORE_TRANSFEROUT_WAREHOUSE_RULE_CHANGE_TAGS:
      return {
        ...state,
        transferOutWarehouseFilter: {
          ...state.transferOutWarehouseFilter,
          ruleChilds: [
            ...state.transferOutWarehouseFilter.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId),
            ...action.tags.map((p: any) => new RuleChild({ ruleId: action.ruleId, tagId: p }))
          ]
        }
      }
    default:
      return state
  }
}
