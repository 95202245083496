import auth from '../auth/authenticator'
import sessionApi from '../api/SessionApi'
import { clearCompanys } from './companyActions'
import { clearLandLords } from './landlordActions'
import { clearUsers } from './userActions'
import { clearDevicesPage } from './deviceActions'
import { clearTemplates } from './templateActions'
import { clearActivityLogs, loadActivityLogs } from './activityLogActions'
import { clearTenants } from './tenantActions'
import { clearMenus } from './menuActions'
import { clearCustomers } from './customerActions'
import { clearProducts } from './productActions'
import companyApi from '../api/CompanyApi'
import tenantApi from '../api/TenantApi'
import * as Toast from '../utils/Toast'
import { passwordRegex } from '../utils/PasswordPolicy'
import { showErrorToast, showSuccessToast } from '../utils/Toast'

export const RESPONSE_LOADING = 'RESPONSE_LOADING'
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_IN_VERIFY = 'LOG_IN_VERIFY'
export const LOG_IN_VERIFY_RESEND = 'LOG_IN_VERIFY_RESEND'
export const LOG_OUT = 'LOG_OUT'
export const LOG_IN_ERROR = 'LOG_IN_ERROR'
export const FORGOT_ERROR = 'FORGOT_ERROR'
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
export const LOG_IN_SET_CLAIMS = 'LOG_IN_SET_CLAIMS'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_MY_PASSWORD = 'CHANGE_MY_PASSWORD'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_BACK = 'FORGOT_BACK'
export const COMPANY_SELECTED = 'COMPANY_SELECTED'
export const TENANT_SELECTED = 'TENANT_SELECTED'
export const SESSION_COMPANYS_LOADING = 'SESSION_COMPANYS_LOADING'
export const SESSION_COMPANYS_LOADED = 'SESSION_COMPANYS_LOADED'
export const SESSION_TENANTS_LOADING = 'SESSION_TENANTS_LOADING'
export const SESSION_TENANTS_LOADED = 'SESSION_TENANTS_LOADED'
export const SESSION_TENANTS_LOADING_FAILED = 'SESSION_TENANTS_LOADING_FAILED'
export const SESSION_LICENCES_UPDATING = 'SESSION_LICENCES_UPDATING'
export const SESSION_LICENCES_UPDATED = 'SESSION_LICENCES_UPDATED'
export const SESSION_LICENCES_UPDATE_FAILED = 'SESSION_LICENCES_UPDATE_FAILED'
export const SESSION_UPDATE_LICENCES = 'SESSION_UPDATE_LICENCES'
export const SESSION_INVALIDATE = 'SESSION_INVALIDATE'

export function loginSuccess (claims: any) {
  return { type: LOG_IN_SUCCESS, claims }
}

export function loginVerify (message: any) {
  return { type: LOG_IN_VERIFY, message }
}

export function loginVerifyResend (message?: any) {
  return { type: LOG_IN_VERIFY_RESEND, message }
}

export function loginError (error: any) {
  return { type: LOG_IN_ERROR, error }
}

export function forgotError (error: any) {
  return { type: FORGOT_ERROR, error }
}

export function forgotSuccess (success?: any) {
  return { type: FORGOT_SUCCESS, success }
}
export function loginSetClaims (claims: any) {
  return { type: LOG_IN_SET_CLAIMS, claims }
}

function logout () {
  return { type: LOG_OUT }
}

function sessionInvalidate () {
  auth.serverLogOut()
  auth.logOut()
  return { type: SESSION_INVALIDATE }
}

function loading () {
  return { type: RESPONSE_LOADING }
}

export function changePassword () {
  return { type: CHANGE_PASSWORD }
}

export function forgotPassword () {
  return { type: FORGOT_PASSWORD }
}
export function forgotBack () {
  return { type: FORGOT_BACK }
}

export function sessionCompanySelected (companyId: any) {
  return { type: COMPANY_SELECTED, companyId }
}

export function sessionTenantSelected (tenantId: any) {
  return { type: TENANT_SELECTED, tenantId }
}

export function sessionTenantSelection (tenantId: any) {
  return (dispatch: any, getState: any) => {
    dispatch(sessionTenantSelected(tenantId))
  }
}

export function sessionCompanySelection (companyId: any) {
  return (dispatch: any) => {
    dispatch(sessionCompanySelected(companyId))
  }
}

export function companysLoading () {
  return { type: SESSION_COMPANYS_LOADING }
}

export function companysLoaded (companys: any) {
  return { type: SESSION_COMPANYS_LOADED, companys }
}

export function tenantsLoading () {
  return { type: SESSION_TENANTS_LOADING }
}

export function tenantsLoaded (tenants: any, companyId?: any) {
  return { type: SESSION_TENANTS_LOADED, tenants, companyId }
}

export function tenantsLoadingFailed () {
  return { type: SESSION_TENANTS_LOADING_FAILED }
}

export function loadTenants (companyId: any, tenantId?: any) {
  return (dispatch: any) => {
    dispatch(tenantsLoading())
    return tenantApi.loadTenants(companyId).then((response) => {
      if (response) {
        dispatch(tenantsLoaded(response, companyId))
        let selectedTenant = tenantId ? response.find((x: any) => { return x.id === tenantId }) : null
        if (selectedTenant) {
          dispatch(sessionTenantSelection({
            ...selectedTenant,
            value: selectedTenant.id,
            label: selectedTenant.name
          }))
        } else if (response.length === 1) {
          selectedTenant = response[0]
          dispatch(sessionTenantSelection({
            ...selectedTenant,
            value: selectedTenant.id,
            label: selectedTenant.name
          }))
        }
      }
    }).catch((p) => {
      dispatch(tenantsLoadingFailed())
      Toast.showErrorToast(p)
    })
  }
}
export function loadCompanys () {
  return (dispatch: any, getState: any) => {
    if (getState().session.companys.length > 0)
      return
    dispatch(companysLoading())
    return companyApi.loadCompanys().then((response) => {
      if (response) {
        dispatch(companysLoaded(response))
        if (response.length === 1) {
          const selectedCompany = response[0]
          dispatch(sessionCompanySelection({
            value: selectedCompany.id,
            label: selectedCompany.name
          }))
          dispatch(loadTenants(selectedCompany.id))
        } else {
          dispatch(sessionCompanySelection(undefined))
        }
      }
    }).catch((p) => {
      dispatch(companysLoaded([]))
      Toast.showErrorToast(p)
    })
  }
}

export function licencesUpdating () {
  return { type: SESSION_LICENCES_UPDATING }
}

export function licencesUpdated (id: any, licences: any) {
  return { type: SESSION_LICENCES_UPDATED, id, licences }
}
export function licencesUpdateFailed (id: any) {
  return { type: SESSION_LICENCES_UPDATE_FAILED, id }
}

export function updateLicences (id: any, licences: any) {
  return { type: SESSION_UPDATE_LICENCES, id, licences }
}

export function saveLicences (id: any, licences: any) {
  return (dispatch: any) => {
    dispatch(licencesUpdating())
    return tenantApi.saveLicences(id, licences).then(() => {
      dispatch(licencesUpdated(id, licences))
      dispatch(loadActivityLogs('Devices', id))
    }).catch((p) => {
      dispatch(licencesUpdateFailed(id))
      Toast.showErrorToast(p)
    })
  }
}

export function loginUser (credentials: any) {
  return function (dispatch: any) { // eslint-disable-line func-names
    dispatch(loading())
    return sessionApi.login(credentials).then((response) => {
      if (response.success === true) {
        if (response.apitoken) {
          auth.saveUserSession(response.apitoken)
          const claims = auth.getUserSession()
          if (claims) {
            dispatch(loginSuccess(claims))
          } else {
            dispatch(loginError('Login failed, session expired'))
          }
        } else {
          if (credentials.resend === false) {
            dispatch(loginError('')) // clear previous login errors
            dispatch(loginVerify(response.message))
          } else {
            dispatch(loginVerifyResend(response.message))
          }
        }
      } else {
        dispatch(loginError(response.message))
      }
    }).catch((response) => {
      dispatch(loginError(response.message))
    })
  }
}

export function forgotPasswordEmail (credentials: any) {
  return function (dispatch: any) { // eslint-disable-line func-names
    dispatch(loading())
    if (credentials.email === '') {
      dispatch(forgotError('Email address cannot be empty'))
      return
    }
    return sessionApi.forgotPassword(credentials).then((response) => {
      if (response.success !== true) {
        dispatch(forgotError(response.message))
      } else {
        dispatch(forgotSuccess(response.message))
      }
    }).catch((error) => {
      showErrorToast(error.message)
    })
  }
}

export function ResetPassword (token: any, email: any, pwd: any, confirmPwd: any) {
  return function (dispatch: any) { // eslint-disable-line func-names
    dispatch(loading())
    if (pwd === '') {
      dispatch(forgotError('Password cannot be empty.'))
      return
    }
    if (confirmPwd === '') {
      dispatch(forgotError('Confirm Password cannot be empty.'))
      return
    }

    if (pwd !== confirmPwd) {
      dispatch(forgotError('Password and Confirm Password do not match.'))
      return
    }
    if (pwd === email) {
      dispatch(forgotError('Email address cannot be used as password.'))
      return
    }
    if (!passwordRegex.test(pwd)) {
      dispatch(forgotError('Password must be between 6–20 characters long and contain a mix of letters, numbers and special characters.'))
      return
    }

    return sessionApi.resetPassword(token, email, pwd).then((response) => {
      if (response.success === true) {
        dispatch(forgotSuccess())
        showSuccessToast('Password has been changed successfully.')
        response = true
      } else {
        dispatch(forgotError(response.message))
        response = false
      }
    }).catch((response) => {
      dispatch(forgotError(response.message))
      response = false
    })
  }
}

export function ChangeMyPassword (currentPwd: any, pwd: any, confirmPwd: any) {
  return function (dispatch: any) { // eslint-disable-line func-names
    if (currentPwd === '') {
      dispatch(forgotError('Current Password cannot be empty.'))
      return
    }
    if (pwd === '') {
      dispatch(forgotError('Password cannot be empty.'))
      return
    }
    if (confirmPwd === '') {
      dispatch(forgotError('Confirm Password cannot be empty.'))
      return
    }
   
    if (pwd !== confirmPwd) {
      dispatch(forgotError('Password and Confirm Password do not match.'))
      return
    }
    if (currentPwd === pwd) {
      dispatch(forgotError('Current Password and New Password cannot be the same.'))
      return
    }
    const email = auth.getUserSession().email
    if (pwd === email) {
      dispatch(forgotError('Email address cannot be used as password.'))
      return
    }
    if (!passwordRegex.test(pwd)) {
      dispatch(forgotError('Password must be between 6–20 characters long and contain a mix of letters, numbers and special characters.'))
      return
    }

    return sessionApi.changePassword(currentPwd, pwd).then((response) => {
      if (response === true) {
        dispatch(forgotSuccess())
        dispatch(sessionInvalidate())
      } else {
        dispatch(forgotError(response))
      }
    }).catch((response) => {
      dispatch(forgotError(response))
    })
  }
}

export function checkUserLoggedIn () {
  return async function (dispatch: any) { // eslint-disable-line func-names
    const claims = auth.getUserSession()
    if (claims) {
      await auth.isValidSession().then((response) => {
        if (response) {
          dispatch(loginSetClaims(claims))
        } else {
          dispatch(sessionTimeout())
        }
      })
    }
  }
}

export function logoutUser () { // eslint-disable-line func-names
  return async function (dispatch: any) { // eslint-disable-line func-names
    await auth.logOut()
    dispatch(companysLoaded([]))
    dispatch(tenantsLoaded([]))
    dispatch(clearCompanys())
    dispatch(clearUsers())
    dispatch(clearLandLords())
    dispatch(clearTenants())
    dispatch(clearDevicesPage())
    dispatch(clearActivityLogs())
    dispatch(clearTemplates())
    dispatch(clearMenus())
    dispatch(clearCustomers())
    dispatch(clearProducts())
    dispatch(logout())
  }
}

export function sessionTimeout () {
  return async function (dispatch: any) { // eslint-disable-line func-names
    dispatch(sessionInvalidate())
  }
}