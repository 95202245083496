import { Link, useLocation, useParams } from 'react-router-dom'

const breadcrumbNameMap = {
  '/products': 'Product Templates',
  '/devices': 'Devices',
  '/applytemplate': 'Apply Template',
  '/dataexplorer': 'Data Explorer',
  '/receipts': 'Receipts',
  '/store': 'Store Templates',
  '/store/new': 'Store Details new',
  '/navigations': 'Navigation Templates',
  '/navigations/new': 'Store Details new',
  '/customer': 'Customers',
  '/companies': 'Companies',
  '/landlords': 'Landlords',
  '/admin-users': 'Users'
}

// Function to dynamically create breadcrumb links
const Breadcrumbs = ({
  name
}: {name?: string}) => {
  const location = useLocation()

  // Split the pathname to create breadcrumb segments
  const pathnames = location.pathname.split('/').filter(Boolean)
  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb' style={{ backgroundColor: 'white', margin: 0, left: 0 }}>
        <li className='breadcrumb-item'>
          <Link to='/'>Dashboard</Link>
        </li>
        {pathnames.map((_, index) => {
          // Dynamically build the route path for each breadcrumb segment
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
          const routes = routeTo.split('/')
          const hasChildRoute = routes?.length > 2

          let endRoute = routes[routes.length - 1]
          if (endRoute === 'new') {
            endRoute = 'Create New'
          } else {
            endRoute = name
          }

          return (
            <li className='breadcrumb-item' key={routeTo}>
              {!hasChildRoute && 
                <Link to={routeTo}>
                  {breadcrumbNameMap[routeTo]}
                </Link>
              }
              {hasChildRoute &&
                <span>{endRoute}</span>
              }
            </li>          
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
