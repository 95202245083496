import {
  CLEAR_PRODUCTS, DELETE_PRODUCT_ERROR, DELETE_PRODUCT_FINISHED,
  DELETE_PRODUCT_STARTED,
  DELETE_PRODUCT_SUCCESS, PRODUCT_ADD,
  PRODUCT_API_STARTED, PRODUCT_COMPANY_SELECT, PRODUCT_LOADED, PRODUCT_NAME_CHANGED,
  PRODUCT_REDIRECT_LISTING, PRODUCT_RULE_ADD,
  PRODUCT_RULE_CHANGE_OPERATOR, PRODUCT_RULE_CHANGE_TAGGROUP, PRODUCT_RULE_CHANGE_TAGS, PRODUCT_RULE_DELETE,
  PRODUCT_SUBMENU_MATCH, PRODUCT_TAGS_LOADED, PRODUCT_TENANT_SELECT,
  PRODUCTS_LOADED, PRODUCTS_LOADING, PRODUCTS_SAVED,
  PRODUCTS_SAVING, PRODUCTS_UPDATED,
  SET_DELETE_PRODUCT_SUCCESS
} from '../actions/productActions'
import {
  COMPANY_SELECTED, TENANT_SELECTED
} from '../actions/sessionActions'
import { Rule, RuleChild, SubFilter } from '../types/Menu'
import { ProductFilter } from '../types/Product'
import initialState from './initialState'

export default function productReducer (state = initialState.product, action: any) {
  switch (action.type) {

    case PRODUCT_REDIRECT_LISTING:
      return state

    case PRODUCT_ADD:
      const product = new ProductFilter()
      product.tenantId = action.Id
      return {
        ...state,
        product
      }
    case PRODUCTS_SAVED: {
      return {
        ...state,
        productSaving: false,
        productSaved: action.success,
        productSavedId: action.result.id
      }
    }

    case PRODUCTS_SAVING: {
      return {
        ...state,
        productSaving: true
      }
    }
    case PRODUCT_LOADED:
    {
      return {
        ...state,
        product: action.product,
        productsLoading: false,
        productsLoaded: true
      }

    }
    case PRODUCTS_LOADED:
    {
      const newProducts: any = []
      action.products.forEach((y: any) => {
        const newProduct = new ProductFilter(y)
        newProducts.push(newProduct)
      })

      return {
        ...state,
        products: newProducts,
        productSaved: false,
        productDeleted: false,
        productsLoading: false,
        productsLoaded: true
      }
    }
    case PRODUCT_LOADED:
      return { ...state, error: '', productSaved: false, productDeleted: false, productSaving: false }
    case PRODUCT_TAGS_LOADED:
      return { ...state, error: '', productLoaded: false, product: { ...state.product, tagGroups: action.tags } }

    case PRODUCTS_LOADING:
      return { ...state, productsLoading: true, products: undefined }
    case CLEAR_PRODUCTS:
    {
      const newProducts: any = []
      return {
        ...state,
        products: newProducts,
        productsLoading: false,
        productsLoaded: false,
        productSaving: false
      }
    }
    case PRODUCT_COMPANY_SELECT:
      return { ...state, product: { ...state.product, companyId: action.id } }

    case PRODUCT_TENANT_SELECT:
      return { ...state, product: { ...state.product, tenantId: action.id } }

    case COMPANY_SELECTED:
    {
      return { ...state, product: { ...state.product, companyId: action.companyId
        ? action.companyId.value : '' } }

    }
    case TENANT_SELECTED:
    {
      return { ...state, product: { ...state.product, tenantId: action.tenantId
        ? action.tenantId.value : '' } }
    }

    case PRODUCTS_UPDATED:
      return { ...state, products: action.products }
    case DELETE_PRODUCT_SUCCESS: {
      return { ...state, productDeletedSuccess: true }
    }
    case SET_DELETE_PRODUCT_SUCCESS: {
      return { ...state, productDeletedSuccess: action.value }
    }
    case DELETE_PRODUCT_ERROR:
      return { ...state, error: action.error, productSaved: false, productDeleted: false }
    case DELETE_PRODUCT_STARTED:
      return { ...state, productDeleted: true, productDeletedSuccess: false }
    case DELETE_PRODUCT_FINISHED:
      return { ...state, productDeleted: false }
    case PRODUCT_API_STARTED:
      return { ...state, error: '', productSaved: false, productDeleted: false }

    case PRODUCT_NAME_CHANGED: {
      const {name} = action
      return {
        ...state,
        product: {
          ...state.product,
          name
        }
      }
    }

    case PRODUCT_SUBMENU_MATCH: {
      const {id, all} = action
      return {
        ...state,
        product: {
          ...state.product,
          subFilter: {
            ...state.product.subFilter,
            isMatchingAll: all
          }
        }
      }
    }

    case PRODUCT_RULE_ADD:
    {

      return {
        ...state,
        product: {
          ...state.product,
          rules: [
            ...state.product.rules,
            new Rule({ subFilterId: action.subFilterId })
          ]
        }
      }
    }

    case PRODUCT_RULE_CHANGE_TAGGROUP:
    {
      return {
        ...state,
        product: {
          ...state.product,
          rules: [
            ...state.product.rules.map((p: any) => {
              if (p.id === action.ruleId) {
                return {
                  ...p,
                  tagGroupId: action.tagGroup
                }
              } else { return p }
            })
          ],

          ruleChilds: [
            ...state.product.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId)
          ]
        }
      }
    }

    case PRODUCT_RULE_CHANGE_OPERATOR:
    {
      return {
        ...state,
        product: {
          ...state.product,
          rules: [
            ...state.product.rules.map((p: any) => {
              if (p.id === action.ruleId) {
                return {
                  ...p,
                  operatorType: action.operator
                }
              } else {
                return p
              }
            })
          ],
          ruleChilds: [
            ...state.product.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId)
          ]
        }
      }
    }

    case PRODUCT_RULE_DELETE:
    {
      return {
        ...state,
        product: {
          ...state.product,
          rules: [
            ...state.product.rules.filter((p: any) => p.id !== action.id)
          ],
          ruleChilds: [
            ...state.product.ruleChilds.filter((p: any) => p.ruleId !== action.id)
          ]
        }
      }
    }
    case PRODUCT_RULE_CHANGE_TAGS:
      return {
        ...state,
        product: {
          ...state.product,
          ruleChilds: [
            ...state.product.ruleChilds.filter((p: any) => p.ruleId !== action.ruleId),
            ...action.tags.map((p: any) => new RuleChild({ ruleId: action.ruleId, tagId: p }))
          ]
        }
      }
    default:
      return state
  }
}
