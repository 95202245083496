export class ShippingSettings {
  provider: number = 0
  providerName: string = 'None'
  apiUrl: string = ''
  automaticOrderManifesting: boolean
  constructor (shippingSettings?: ShippingSettings) {
    if (shippingSettings !== undefined && shippingSettings !== null) {
      this.provider = shippingSettings.provider
      this.apiUrl = shippingSettings.apiUrl
      this.automaticOrderManifesting = shippingSettings.automaticOrderManifesting
      this.providerName = shippingSettings.providerName
    }
  }
}