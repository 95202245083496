import authenticator from '../auth/authenticator'
import fetcher from './ApiMethods'
import { GetApiRequest } from '../utils/ApiUtils'

export default class DataExplorerApi {

  static loadData (tenantId: string, type: string, id: string, page: number, pageRows: number) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/DataExplorer/${type}/${id}?page=${page}&pageSize=${pageRows}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

}
