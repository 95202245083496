import authenticator from '../auth/authenticator'
import { GetApiRequest } from '../utils/ApiUtils'
import fetcher from './ApiMethods'

export default class ProductApi {
  static saveProduct (productFilter: any, tenantId: any) {
    const payload = productFilter

    const isNew = (productFilter.id)
    const uri = productFilter.id ? `/api/tenant/${tenantId}/productfilter/${productFilter.id}` :
      `/api/tenant/${tenantId}/productfilter`
    const request = GetApiRequest(uri, {
      method: isNew ? 'PUT' : 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }

  static loadProductDetail (productfilter: any, tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/productfilter/getDetail?productFilterId=${productfilter}` , {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static loadProducts (tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/productfilter`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static loadTags (tenantId: any) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/productfilter/GetTagGroups`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static deleteProduct (id: number, tenantId: string) {
    const request = GetApiRequest(`/api/tenant/${tenantId}/productfilter/DeleteProduct?id=${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })
    return fetcher.processFetch(request)
  }
}
