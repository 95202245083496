import moment from 'moment'

export default class Device {
  id: string
  name: string
  systemVersion: string
  os: string
  model: string
  appVersion: string
  country: string
  locale: string
  timezone: string
  tenantId: number | undefined
  shops: string[]
  lastLogin: Date | undefined
  lastLoginStr: string
  constructor (device: Device) {
    if (device) {
      this.id = device.id
      this.name = device.name
      this.systemVersion = device.systemVersion
      this.os = device.os
      this.model = device.model
      this.appVersion = device.appVersion
      this.country = device.country
      this.locale = device.locale
      this.timezone = device.timezone
      this.tenantId = device.tenantId
      this.shops = device.shops.map((x: any) => x.name)
      this.lastLogin = device.lastLogin
      this.lastLoginStr = 'Never'
      if (this.lastLogin) {
        const utcDate = new Date(this.lastLogin)
        utcDate.setMinutes(utcDate.getMinutes() - utcDate.getTimezoneOffset())
        this.lastLoginStr = moment(utcDate).format('DD/MM/YY, h:mm A')
      }
    } else {
      this.id = ''
      this.name = ''
      this.systemVersion = ''
      this.os = ''
      this.model = ''
      this.appVersion = ''
      this.country = ''
      this.locale = ''
      this.timezone = ''
      this.tenantId = undefined
      this.shops = []
      this.lastLogin = undefined
    }
  }
}
