import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import AppRoutes from './routes'
import './polyfills'

export const store = configureStore({})
declare global {
  interface Array<T> {
    find(callbackfn: (value: T, index: number, array: T[]) => any, thisArg?: any): T
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  </React.StrictMode>
)