import React from 'react'
import { ClipLoader } from 'react-spinners'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Outlet } from 'react-router-dom'

interface IDispatchProps {
}

interface IStateProps {
    logged_in: boolean
}

class ProductPage extends React.Component<IDispatchProps & IStateProps & any, {}> {

  render () {
    const display = (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ClipLoader size={40} />
      </div>
    )
    return (
      <div>
        {this.props.logged_in ?
          this.props.children
          : display }
      </div>
    )
  }
}

function mapStateToProps (state): IStateProps {
  return {
    logged_in: state.session.logged_in
  }
}

function mapDispatchToProps (dispatch): IDispatchProps {
  return bindActionCreators({
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPage)
