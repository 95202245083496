export default class Landlord {
  id: string
  name: string
  createdByEmail: string | null
  createdById: string | null
  createdDate: Date | null
  sequence: Number | null

  constructor (landlord?: Landlord) {
    if (landlord) {
      this.id = landlord.id
      this.name = landlord.name
      this.createdByEmail = landlord.createdByEmail
      this.createdById = landlord.createdById
      this.createdDate = landlord.createdDate
      this.sequence = landlord.sequence
    } else {
      this.id = ''
      this.name = ''
      this.createdByEmail = null
      this.createdById = null
      this.createdDate = null
      this.sequence = null
    }
  }
}
