
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import {
  loadCompanys, companysUpdated, saveCompany, deleteCompany, companyLoaded
} from '../actions/companyActions'
import Company from '../types/Company'
import withRouter from './Utils/WithRouter'
import Table from './common/Table'
import EmptyState from './common/EmptyState'

class CompanyList extends React.Component<any, any> {

  columnsSuperuser = [
    { key: 'name', name: 'Company', sortable: true, resizable: true },
    { key: 'landlord', name: 'Landlord', sortable: true, resizable: true },
    { key: 'environmentsCount', name: 'No. of Environments', sortable: true, resizable: true },
    { key: 'created', name: 'Created', sortable: true, resizable: true },
    { key: 'modified', name: 'Last Modified', sortable: true, resizable: true }
  ]

  columnsAdmin = [
    { key: 'name', name: 'Company', sortable: true, resizable: true },
    { key: 'environmentsCount', name: 'No. of Environments', sortable: true, resizable: true },
    { key: 'created', name: 'Created', sortable: true, resizable: true },
    { key: 'modified', name: 'Last Modified', sortable: true, resizable: true }
  ]

  columnsCompanyAdmin = [
    { key: 'name', name: 'Company', sortable: true, resizable: true },
    { key: 'environmentsCount', name: 'No. of Environments', sortable: true, resizable: true },
    { key: 'created', name: 'Created', sortable: true, resizable: true },
    { key: 'modified', name: 'Last Modified', sortable: true, resizable: true }
  ]
  columns = []

  constructor (props) {
    super(props)
    this.state = { search: '' }
  }

  onChange (event) {
    return this.setState({ ...this.state, [event.target.name]: event.target.value })
  }
  onNew = () => {
    this.props.companyLoaded()
    this.props.navigate('/companies/new')
  }

  onRefreshListing = () => {
    this.props.loadCompanys()
  }

  onRowClick = (id) => {
    this.props.companyLoaded()
    this.props.navigate(`/companies/${id}`)
  }

  getRowAt = (index) => {
    if (index < 0 || index > this.getSize()) {
      return undefined
    }
    return this.getRows()[index]
  }

  getSize = () => {
    return this.getRows().length
  }

  getRows () {
    if (!this.state.search) { return !this.props.companys ? [] : this.props.companys }

    if (this.props.claims.role === 'superuser') {
      return this.props.companys.filter(p => p.name.search(new RegExp(this.state.search, 'i')) !== -1
        || p.created.search(new RegExp(this.state.search, 'i')) !== -1
        || p.modified.search(new RegExp(this.state.search, 'i')) !== -1
        || p.landlord.search(new RegExp(this.state.search, 'i')) !== -1
        || p.environmentsCount.toString().search(new RegExp(this.state.search, 'i')) !== -1)
    } return this.props.companys.filter(p => p.name.search(new RegExp(this.state.search, 'i')) !== -1
        || p.created.search(new RegExp(this.state.search, 'i')) !== -1
        || p.modified.search(new RegExp(this.state.search, 'i')) !== -1
        || p.environmentsCount.toString().search(new RegExp(this.state.search, 'i')) !== -1)
  }

  handleGridSort = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return (a[sortColumn] > b[sortColumn]) ? 1 : -1
      } else if (sortDirection === 'DESC') {
        return (a[sortColumn] < b[sortColumn]) ? 1 : -1
      }
      return (a.sequence > b.sequence) ? 1 : -1
    }
    this.props.companysUpdated(this.getRows().sort(comparer).slice())
  }

  render () {
    if (this.props.claims.role === 'superuser') {
      this.columns = this.columnsSuperuser
    } else if (this.props.claims.role === 'admin') {
      this.columns = this.columnsAdmin
    } else if (this.props.claims.role === 'companyAdmin') {
      this.columns = this.columnsCompanyAdmin
    }
    let spinner
    let dataGrid
    if (this.props.companysLoading) {
      spinner = (<ClipLoader size={40} />)
    } else {
      const rows = this.getRows()
      const colHeads = this.columns.map(p => <th key={p.key}>{p.name}</th>)
      const colBody = rows.map((col) => {
        return (<tr key={col.id} onClick={(e) => this.onRowClick(col.id)}>
          {this.columns.map(p => <td key={p.key}>{col[p.key]}</td>)}
          <td><button className='btn btn-link' onClick={e => this.onRowClick(col.id)}>
            <i className='glyphicon glyphicon-chevron-right' />
          </button>
          </td>
        </tr>)
      })
      dataGrid = () => {
        return rows.length && rows.length > 0 ? 
          <Table data={colBody} height={this.state.height}>
            <thead>
              <tr>
                {colHeads}
                <th />
              </tr>
            </thead>
            <tbody>
              {colBody}
            </tbody>
          </Table> 
          :
          <EmptyState message={this.props.companys.length ? 'No companies found' : 'No companies available'}/>
      }
    }

    let newButton = null
    if (this.props.claims.role === 'superuser' || this.props.claims.role === 'admin') {
      newButton = (
        <button
          style={{ margin: 5 }}
          className='btn btn-primary'
          onClick={this.onNew}
        >
          Create new company
        </button>
      )
    }

    return (
      <div>
        <h3>Manage Companies</h3>
        <div>
          <div className='row' >
            <div className='col-sm-4 col-xs-6'>
              <div className='right-inner-addon '>
                <i className='text-muted glyphicon glyphicon-search' />
                <input
                  maxLength={100}
                  name='search'
                  value={this.state.search}
                  onChange={e => this.onChange(e)}
                  className='form-control'
                  placeholder='Search…'
                />
              </div>
            </div>
            <div className='col-sm-8 col-xs-6 text-right'>
              {newButton}
            </div>
          </div>
          <hr />
          <div className='table-responsive' 
            style={
              this.props.companysLoading ? {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                justifyItems: 'center',
                overflow: 'hidden'
              } : {} 
            }
          >
            {this.props.companysLoading ? spinner : dataGrid()}
          </div>

        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    claims: state.session,
    companys: state.company.companysLoading ? undefined : state.company.companys,
    companysLoading: state.company.companysLoading
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    companysUpdated,
    loadCompanys,
    saveCompany,
    deleteCompany,
    companyLoaded
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyList))
