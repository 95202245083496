import React from 'react'
import { EmailSettings } from '../../types/EmailSettings'
import Select from 'react-select'
import TextInput from '../common/TextInput'

interface OwnProps {
    valueKey: string,
    labelKey: string,
    heading: string,
    title: string,
    singleSelect: boolean
}

interface DispatchProps {
    toggleOptions
}

interface StateProps {
    options: any[]
}

type Props = StateProps & OwnProps & DispatchProps

interface State {

}

export default class EmailProvider extends React.Component<Props & any, State> {

  constructor (props) {
    super(props)

  }
  onChange = (e) => {
    if (e !== null) {
      this.props.toggleOptions(this.props.singleSelect ? [e.value?.toString()] : e.map(q => q.value?.toString()))
    } else {
      this.props.toggleOptions(null)
    }
  }

  render () {
    const options = this.props.options || []

    return (<div className='panel panel-default'>
      {this.props.heading !== '' && <div className='panel-heading'><h3 className='panel-title'>{this.props.heading}</h3></div>}
      <div className='panel-collapse collapse in'>
        <div className='panel-body'>
          <div className='sortable--container mg-bottom-lg' >
            <div className='sortable--label' style={{
              paddingRight: '20px', overflow: 'visible', minWidth: '350px'
            }}
            >
              <input
                type={'text'}
                name={this.props.title.toLocaleLowerCase()}
                value={this.props.title}
                disabled={true}
                maxLength={100}
                className={' form-control'}
              />
            </div>

            <div className='' style={{ width: '200px', paddingRight: '20px' }}>
              <input
                type={'text'}
                name={'operatorType'}
                value={this.props.singleSelect ? 'Is' : 'Is any of'}
                disabled={true}
                maxLength={100}
                className={' form-control'}
              />
            </div>

            <div className='sortable--label' style={{ paddingRight: '20px', overflow: 'visible', maxHeight: 'none' }} >
              <Select
                isMulti={!this.props.singleSelect}
                options={options?.map(q => { return { value: q[this.props.valueKey], label: q[this.props.labelKey] } })}
                isClearable={false}
                onChange={this.onChange}
                value={options?.filter(q => q.selected)?.map(q => { return { value: q[this.props.valueKey], label: q[this.props.labelKey] } })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    )

  }
}
