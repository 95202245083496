import { dataPoint } from '../../types/DataPoint'

export const logoDataPoint: dataPoint[] = [
  { code: 'Logo', text: 'Store logo assigned in general template' }
]

export const physicalReceiptDataPoints: dataPoint[] = [
  { code: 'IsReprint', text: 'True if the receipt is being reprinted' }
]
export const receiptDataPoints: dataPoint[] = [
  { code: 'DocketNumber' },
  { code: 'SelfFit' },
  { code: 'TotalPrice', text: 'The total value of the order' },
  { code: 'TotalDiscount', text: 'The total value of discounts on the order' },
  { code: 'TotalLines', text: 'The total number of lines on the order' },
  { code: 'TotalUnits', text: 'The total units on the order' },
  {
    code: 'SalesRep', text: 'The sales rep assigned to the sale', subPoints: [
      { code: 'Code' },
      { code: 'FirstName' },
      { code: 'LastName' }
    ]
  },
  {
    code: 'User', text: 'The user that put the sale through Envimo', subPoints: [
      { code: 'Code' },
      { code: 'FirstName' },
      { code: 'LastName' }
    ]
  },
  { code: 'TimeOfSale' },
  {
    code: 'Device', text: 'The device that created the sale', subPoints: [
      { code: 'Id' }
    ]
  },
  {
    code: 'Shop', text: 'The shop that created the sale', subPoints: [
      { code: 'Code' },
      { code: 'Name' },
      { code: 'StoreNumber' },
      { code: 'Phone' },
      { code: 'Email' },
      {
        code: 'Address', subPoints: [
          { code: 'Address1' },
          { code: 'Address2' },
          { code: 'City' },
          { code: 'State' },
          { code: 'Country' },
          { code: 'PostCode' }
        ]
      }
    ]
  },
  {
    code: 'OwnerShop', text: 'The shop that owns the sale', subPoints: [
      { code: 'Code' },
      { code: 'Name' },
      { code: 'StoreNumber' },
      { code: 'Phone' },
      { code: 'Email' },
      {
        code: 'Address', subPoints: [
          { code: 'Address1' },
          { code: 'Address2' },
          { code: 'City' },
          { code: 'State' },
          { code: 'Country' },
          { code: 'PostCode' }
        ]
      }
    ]
  },
  {
    code: 'Customer', text: 'The customer that the goods were sold to', subPoints: [
      { code: 'BirthDay' },
      { code: 'BirthMonth' },
      { code: 'BirthYear' },
      { code: 'Email' },
      { code: 'FirstName' },
      { code: 'LastName' },
      { code: 'Phone' },
      {
        code: 'ContactAddress', subPoints: [
          { code: 'Address1' },
          { code: 'Address2' },
          { code: 'City' },
          { code: 'State' },
          { code: 'Country' },
          { code: 'PostCode' }
        ]
      }
    ]
  },
  {
    code: 'Details', text: 'A list of the detail lines on the sale', subPoints: [
      { code: 'Price' },
      { code: 'Quantity' },
      { code: 'Amount' },
      { code: 'TaxPercent' },
      { code: 'LineTotal', text: 'Quantity multiplied by price, minus the total discounts' },
      { code: 'TotalDiscounts', text: 'The sum of the discounts applied to this line' },
      {
        code: 'Product', text: 'The product that was sold on this detail line', subPoints: [
          { code: 'StyleCode' },
          { code: 'StyleName' },
          { code: 'Colour' },
          { code: 'Size' }
        ]
      },
      {
        code: 'Discounts', text: 'A list of the discounts that were applied to this line', subPoints: [
          { code: 'Amount' },
          { code: 'Percent' },
          { code: 'Type' }
        ]
      },
      {
        code: 'Promotions', text: 'A list of the promotions that were applied to this line', subPoints: [
          { code: 'Amount' },
          { code: 'Percent' },
          { code: 'Type' }
        ]
      },
      {
        code: 'Loyalties', text: 'A list of the loyalty discounts that were applied to this line', subPoints: [
          { code: 'Amount' },
          { code: 'Percent' },
          { code: 'Type' }
        ]
      }
    ]
  },
  { code: 'ReceiptPrintLines', text: 'A list of the eftpos receipts on the sale' }
]

export const returnsReceiptDataPoints: dataPoint[] = [
  { code: 'DocketNumber' },
  { code: 'SelfFit' },
  { code: 'OriginalDocketNumber', text: 'The original Docket Number for returns'},
  { code: 'OriginalOrderNumber', text: 'The order number of the customer order being returned'},
  { code: 'OriginalStoreCode', text: 'The original store for returns'},
  { code: 'TotalPrice', text: 'The total value of the order' },
  { code: 'TotalDiscount', text: 'The total value of discounts on the order' },
  { code: 'TotalLines', text: 'The total number of lines on the order' },
  { code: 'TotalUnits', text: 'The total units on the order' },
  { code: 'TotalReturns', text: 'The total units being returned' },
  {
    code: 'SalesRep', text: 'The sales rep assigned to the sale', subPoints: [
      { code: 'Code' },
      { code: 'FirstName' },
      { code: 'LastName' }
    ]
  },
  {
    code: 'User', text: 'The user that put the sale through Envimo', subPoints: [
      { code: 'Code' },
      { code: 'FirstName' },
      { code: 'LastName' }
    ]
  },
  { code: 'TimeOfSale' },
  {
    code: 'Device', text: 'The device that created the sale', subPoints: [
      { code: 'Id' }
    ]
  },
  {
    code: 'Shop', text: 'The shop that created the sale', subPoints: [
      { code: 'Code' },
      { code: 'Name' },
      { code: 'StoreNumber' },
      { code: 'Phone' },
      { code: 'Email' },
      {
        code: 'Address', subPoints: [
          { code: 'Address1' },
          { code: 'Address2' },
          { code: 'City' },
          { code: 'State' },
          { code: 'Country' },
          { code: 'PostCode' }
        ]
      }
    ]
  },
  {
    code: 'OwnerShop', text: 'The shop that owns the sale', subPoints: [
      { code: 'Code' },
      { code: 'Name' },
      { code: 'StoreNumber' },
      { code: 'Phone' },
      { code: 'Email' },
      {
        code: 'Address', subPoints: [
          { code: 'Address1' },
          { code: 'Address2' },
          { code: 'City' },
          { code: 'State' },
          { code: 'Country' },
          { code: 'PostCode' }
        ]
      }
    ]
  },
  {
    code: 'Customer', text: 'The customer that the goods were sold to', subPoints: [
      { code: 'BirthDay' },
      { code: 'BirthMonth' },
      { code: 'BirthYear' },
      { code: 'Email' },
      { code: 'FirstName' },
      { code: 'LastName' },
      { code: 'Phone' },
      {
        code: 'ContactAddress', subPoints: [
          { code: 'Address1' },
          { code: 'Address2' },
          { code: 'City' },
          { code: 'State' },
          { code: 'Country' },
          { code: 'PostCode' }
        ]
      }
    ]
  },
  {
    code: 'Details', text: 'A list of the detail lines on the sale', subPoints: [
      { code: 'Price' },
      { code: 'Quantity' },
      { code: 'Amount' },
      { code: 'TaxPercent' },
      { code: 'LineTotal', text: 'Quantity multiplied by price, minus the total discounts' },
      { code: 'TotalDiscounts', text: 'The sum of the discounts applied to this line' },
      { code: 'HasReason', text: 'Item is returned and contains a reason for return'},
      { code: 'ReturnReason', text: 'Reason for the return'},
      {
        code: 'Product', text: 'The product that was sold on this detail line', subPoints: [
          { code: 'StyleCode' },
          { code: 'StyleName' },
          { code: 'Colour' },
          { code: 'Size' }
        ]
      },
      {
        code: 'Discounts', text: 'A list of the discounts that were applied to this line', subPoints: [
          { code: 'Amount' },
          { code: 'Percent' },
          { code: 'Type' }
        ]
      },
      {
        code: 'Promotions', text: 'A list of the promotions that were applied to this line', subPoints: [
          { code: 'Amount' },
          { code: 'Percent' },
          { code: 'Type' }
        ]
      },
      {
        code: 'Loyalties', text: 'A list of the loyalty discounts that were applied to this line', subPoints: [
          { code: 'Amount' },
          { code: 'Percent' },
          { code: 'Type' }
        ]
      }
    ]
  },
  { code: 'ReceiptPrintLines', text: 'A list of the eftpos receipts on the sale' },
  { code: 'HasReturn', text: 'Order has a return item'},
  { code: 'IsReturnFromOrder', text: 'Return of customer order'}
]

export const transferOutDataPoints: dataPoint[] = [
  { code: 'TransferNumber' },
  { code: 'DocketNumber' },
  { code: 'TotalLines', text: 'The total number of lines on the order' },
  { code: 'TotalUnits', text: 'The total units on the order' },
  { code: 'ConnoteNumber' },
  {
    code: 'SalesRep', text: 'The sales rep processed the transfer', subPoints: [
      { code: 'Code' },
      { code: 'FirstName' },
      { code: 'LastName' }
    ]
  },
  { code: 'TimeOfReceipt' },
  {
    code: 'Device', text: 'The device that created the sale', subPoints: [
      { code: 'Id' }
    ]
  },
  {
    code: 'Device', text: 'The device that created the sale', subPoints: [
      { code: 'Id' }
    ]
  },
  {
    code: 'Shop', text: 'The shop that created the sale', subPoints: [
      { code: 'Code' },
      { code: 'Name' },
      { code: 'StoreNumber' },
      { code: 'Phone' },
      { code: 'Email' },
      {
        code: 'Address', subPoints: [
          { code: 'Address1' },
          { code: 'Address2' },
          { code: 'City' },
          { code: 'State' },
          { code: 'Country' },
          { code: 'PostCode' }
        ]
      }
    ]
  },
  {
    code: 'To', text: 'The shop that is reciving the Transfer', subPoints: [
      { code: 'Code' },
      { code: 'Name' },
      { code: 'StoreNumber' },
      { code: 'Phone' },
      { code: 'Email' },
      {
        code: 'Address', subPoints: [
          { code: 'Address1' },
          { code: 'Address2' },
          { code: 'City' },
          { code: 'State' },
          { code: 'Country' },
          { code: 'PostCode' }
        ]
      }
    ]
  },
  {
    code: 'Details', text: 'A list of the detail lines on the sale', subPoints: [
      { code: 'Quantity' },
      { code: 'LineTotal', text: 'Quantity multiplied by price, minus the total discounts' },
      {
        code: 'Product', text: 'The product that was sold on this detail line', subPoints: [
          { code: 'StyleCode' },
          { code: 'StyleName' },
          { code: 'Colour' },
          { code: 'Size' }
        ]
      }
    ]
  }
]

export const extraOrderReceiptDataPoints: dataPoint[] = [
  { code: 'TransactionNumber', text: 'The transaction number of the order which the sale was generated from' },
  { code: 'OrderNumber', text: 'The order number of the order which the sale was generated from' }
]
