import React, { useState, useEffect, useRef } from 'react'
import { Buffer as buff } from 'buffer'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import SecuredTextInput from './common/SecuredTextInput'
import PasswordStrength from './common/PasswordStrength'
import { ResetPassword } from '../actions/sessionActions'
import Captcha from './common/Captcha'

declare const grecaptcha: any

const ResetPasswordComponent = (props) => {
  const [searchParams] = useSearchParams()
  const [credentials, setCredentials] = useState({ email: '', pwd: '', confirmPwd: '' })
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [shake, setShake] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [strength, setStrength] = useState('')
  const recaptchaRef = useRef(null)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    const email = searchParams.get('email')
    if (email) {
      setCredentials(prevCredentials => ({
        ...prevCredentials,
        email: getEmail(email)
      }))
    }
  }, [searchParams])

  const onChange = (event) => {
    const { name, value } = event.target
    setCredentials(prevCredentials => ({
      ...prevCredentials,
      [name]: value
    }))
  }

  const handleChangeStrength = (pwStrength) => {
    setStrength(pwStrength)
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    if (captchaVerified === true || process.env.REACT_APP_ENVIRONMENT === 'local') {
      await props.resetPassword(
        searchParams.get('token'),
        credentials.email,
        credentials.pwd,
        credentials.confirmPwd
      )
      if (props.forget_error) {
        setCaptchaVerified(false)
        if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
          grecaptcha?.reset()
        }
      }
    } else {
      setShake(true)
      setTimeout(() => {
        setShake(false)
      }, 500)
    }
  }

  const onCaptchaVerified = () => {
    setCaptchaVerified(true)
  }

  const onCaptchaExpired = () => {
    setCaptchaVerified(false)
  }

  const setRecaptchaRef = (ref) => {
    recaptchaRef.current = ref
  }

  const getEmail = (email) => {
    if (email) {
      // Decode Base64 string to binary
      const binaryString = atob(email)

      // Use Array.from for compatibility
      const emailBytes = new Uint8Array(Array.from(binaryString).map(char => char.charCodeAt(0)))

      // Decode UTF-8 string
      return new TextDecoder().decode(emailBytes)
    }
    return null
  }

  const inputStyle = {}
  const inlineSpinner = (
    <div style={{ marginLeft: '20px', height: '15px', display: 'inline-block' }}>
      <ClipLoader size={15} />
    </div>
  )

  return (
    <div className='text-center login'>
      <img src='/images/EnvimoLogoNormal.png' style={{ paddingBottom: 30 }} />
      <div className='panel panel-default login-control' style={{ width: 350 }}>
        <h3 className='text-info'>Reset Password<br /></h3>
        <br />
        {props.forget_success !== true ? (
          <form className='text-left'>
            <div className='alert alert-info'>
              The password must be between 6–20 characters and contain a mix of:
              <ul>
                <li> Letters (Aa–Zz)</li>
                <li> Digits (0–9)</li>
                <li> Non-alphanumeric characters (i.e. !, $, ?, % and etc.)</li>
              </ul>
            </div>
            {!credentials.email ? (
              <div className='alert alert-danger'>Unable to validate token.</div>
            ) : ''}
            {props.forget_error ? (
              <div className='alert alert-danger'>{props.forget_error}</div>
            ) : ''}
            <SecuredTextInput
              maxLength={20}
              name='email'
              label='Email'
              disabled={true}
              value={credentials.email}
              onChange={onChange}
              style={inputStyle}
            />
            <SecuredTextInput
              maxLength={20}
              name='pwd'
              label='Password'
              type='Password'
              value={credentials.pwd}
              onChange={onChange}
              style={inputStyle}
            />
            <SecuredTextInput
              maxLength={20}
              name='confirmPwd'
              label='Confirm Password'
              type='Password'
              value={credentials.confirmPwd}
              onChange={onChange}
              style={inputStyle}
            />
            <PasswordStrength
              password={credentials.pwd}
              onChangeStrength={handleChangeStrength}
            />
            {process.env.REACT_APP_ENVIRONMENT !== 'local' && (
              <Captcha
                isLoaded={isLoaded}
                setRef={setRecaptchaRef}
                onCallback={onCaptchaVerified}
                onExpiredCallback={onCaptchaExpired}
                shake={shake}
              />
            )}
            {props.response_loading ? (
              <button
                type='submit'
                value='Enter'
                className='btn btn-primary btn-block'
                disabled={true}
              >
                Enter {inlineSpinner}
              </button>
            ) : (
              credentials.email === null || strength === 'weak' || strength === '' ? (
                <input
                  type='submit'
                  value='Enter'
                  className='btn btn-primary btn-block'
                  disabled={true}
                />
              ) : (
                <input
                  type='submit'
                  value='Enter'
                  className='btn btn-primary btn-block'
                  onClick={onSubmit}
                />
              )
            )}
          </form>
        ) : (
          <form>
            <div className='alert alert-info'>Password has been changed successfully. Please proceed to login page.</div>
            <Link to='/login' className='btn btn-primary'>Login</Link>
          </form>
        )}
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  return {
    response_loading: state.session.response_loading,
    forget_success: state.session.forgot_success,
    forget_error: state.session.forgot_error
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ resetPassword: ResetPassword }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent)