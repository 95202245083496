
import React from 'react'
import { ClipLoader } from 'react-spinners'
import { Button, ListGroup, Modal } from 'react-bootstrap'
import { AutoAffix } from 'react-overlays'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import Clipboard from 'react-clipboard.js'
import { updateLicences, saveLicences } from '../actions/sessionActions'
import {
  loadDevices, clearDevices, generateNewRegPin,
  clearRegPins, loadRegPins, deactivateDevice
} from '../actions/deviceActions'
import Device from '../types/Device'
import * as Toast from '../utils/Toast'
import ActivityLogList from './ActivityLogList'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

class DevicesPage extends React.Component<any, any> {
  tabContainer: any
  defaultProps = {
    companys: null,
    tenants: null
  }

  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      deletingId: '',
      activeTab: 'Devices',
      search: ''
    }
    this.onLicenseChange = this.onLicenseChange.bind(this)
  }

  componentDidMount () {    
    if (this.props.tenantId !== '' && this.props.tenantId !== undefined && this.props.tenantId !== null) {
      this.props.loadRegPins(this.props.tenantId)
      this.props.loadDevices(this.props.tenantId)
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.tenantId === undefined
      && (nextProps.companyId !== undefined
        && nextProps.companyId !== ''
        && this.props.companyId !== nextProps.companyId)) {
      this.props.clearRegPins(nextProps.companyId)
      this.props.clearDevices(nextProps.companyId)
    } else if (nextProps.tenantId !== undefined && nextProps.tenantId !== '' && this.props.tenantId !== nextProps.tenantId) {
      this.props.loadRegPins(nextProps.tenantId)
      this.props.loadDevices(nextProps.tenantId)
    }
  }
  onSaveLicence () {
    this.props.saveLicences(this.currentTenant().id, this.currentTenant().licencesCount)
  }
  onLicenseChange (event) {
    const value = event.target.value
    this.props.updateLicences(this.currentTenant().id, value)
  }

  onActivateNew = () => {
    if (this.props.tenantId) {
      this.props.generateNewRegPin(this.props.tenantId)
      this.setState({ ...this.state, activeTab: 'Pins' })
    }
  }
  onChange (event) {
    const field = event.target.name
    const state = { ...this.state }
    state[field] = event.target.value
    return this.setState(state)
  }

  onCopySuccess = () => {
    Toast.showSuccessToast('Copied to clipboard!')
  }

  onDelete = (event, index) => {
    event?.preventDefault()
    this.setState({ ...this.state, deletingId: index, modalIsOpen: true })
  }
  getDevices (): Device[] {
    return this.props.devices.filter((p: Device) => p.name.search(new RegExp(this.state.search, 'i')) !== -1
      || p.systemVersion.search(new RegExp(this.state.search, 'i')) !== -1
      || p.os.search(new RegExp(this.state.search, 'i')) !== -1
      || p.model.search(new RegExp(this.state.search, 'i')) !== -1
      || p.shops.join(', ').search(new RegExp(this.state.search, 'i')) !== -1
      || p.appVersion.search(new RegExp(this.state.search, 'i')) !== -1
      || p.country.search(new RegExp(this.state.search, 'i')) !== -1
      || p.locale.search(new RegExp(this.state.search, 'i')) !== -1
      || p.timezone.search(new RegExp(this.state.search, 'i')) !== -1
      || p.locale.search(new RegExp(this.state.search, 'i')) !== -1
      || p.lastLoginStr.search(new RegExp(this.state.search, 'i')) !== -1)
  }

  deleteAndCloseModal = (event) => {
    event.preventDefault()
    this.props.deactivateDevice(this.state.deletingId, this.props.tenantId)
    this.setState({ ...this.state, modalIsOpen: false })
  }

  openModal = () => {
    this.setState({ ...this.state, modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ ...this.state, modalIsOpen: false })
  }

  currentTenant () {
    try {
      const ret = this.props.tenants.find(p => p.id === this.props.tenantId)
      return ret || {}
    } catch (e) {
      return {}
    }
  }

  handleTabSelect = (key) => {
    this.setState({ ...this.state, activeTab: key })
  }

  renderDeviceList = () => {
    if (this.props.devices) {
      const listItems = this.getDevices().sort((p, q) => (new Date(p.lastLogin)).getTime() - (new Date(q.lastLogin)).getTime())
        .reverse().map((d) => {
          let lastLogin = d.lastLoginStr
          return (
            <div className='list-item col-lg-3 col-md-4 col-sm-6' key={d.id}>
              <div className='panel panel-default list-content'>
                <div className='row content'>
                  <div className='panel-body'>
                    <p />
                    <div className='col-sm-3'>
                      <i style={{ padding: '0 10px' }} className='material-icons'>{d.os === 'Windows' ? 'desktop_windows' : 'tablet_mac'}</i>
                    </div>
                    <div className='col-sm-9'>
                      <p>{d.shops.join(', ')}</p>
                      <div>{`${d.name}, ${d.model}`}</div>
                      <div>{d.systemVersion}</div>
                      <div>Version {d.appVersion}</div>
                      <div>Last Login: {lastLogin}</div>
                    </div>
                  </div>
                </div>
                <div className='text-right'>
                  <button className='btn-link btn btn-xs' onClick={event => this.onDelete(event, d.id)}>
                    <i className='material-icons'>delete</i>
                  </button>
                </div>
              </div>
            </div>
          )
        }
        )

      return (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-6 col-md-4 pull-right ' style={{ paddingTop: '15px' }}>
              <div className='right-inner-addon '>
                <i className='text-muted glyphicon glyphicon-search' />
                <input
                  maxLength={100}
                  name='search'
                  value={this.state.search}
                  onChange={e => this.onChange(e)}
                  className='form-control'
                  placeholder='Search…'
                />
              </div>
            </div></div>

          <div className='list' style={{ marginTop: '15px' }} >
            {listItems}
          </div>
        </div>
      )
    }
    return null
  }
  renderModal = () => {
    return (
      <Modal show={this.state.modalIsOpen} onHide={this.closeModal}>
        <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <Modal.Title style={{ marginRight: 'auto'}}>Deactivate</Modal.Title>
          {/* Custom Close Button */}
          <Button type='button' className='close' aria-label='Close' onClick={this.closeModal} style={{ border: 'none', boxShadow: 'none' }}>
            <span aria-hidden='true'>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>Please confirm you wish to deactivate this device</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{ margin: 5, textAlign: 'center' }}
            className='btn btn-danger'
            onClick={this.deleteAndCloseModal}
          >
            Deactivate
          </button>
          <button
            style={{ margin: 5, textAlign: 'center' }}
            className='btn btn-default pull-left'
            onClick={this.closeModal}
          >
            <span className='text'>Cancel</span>
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
  renderPinList = () => {
    if (this.props.pins) {
      const listItems = this.props.pins.map((p) => {
        const utcUntil = new Date(p.validUntil)
        const until = Date.UTC(utcUntil.getFullYear(),
          utcUntil.getMonth(),
          utcUntil.getDate(),
          utcUntil.getHours(),
          utcUntil.getMinutes(),
          utcUntil.getSeconds())

        return (
          <ListGroup.Item className='row' key={p.id} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: '1 1 auto' }}>Created by {p.createdByUser}</div>
            <small style={{ padding: '0 10px' }} > Expires {moment(until).calendar().toLowerCase()}</small>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <pre style={{ display: 'inline', margin: 0, fontSize: 16 }}>{p.pin} </pre>
              <Clipboard data-clipboard-text={p.pin} className='btn-link btn pull-right btn-xs' onSuccess={this.onCopySuccess}>
                <i style={{ padding: '0 10px' }} className='material-icons'>content_copy</i>
              </Clipboard >
            </div>
          </ListGroup.Item>
        )
      }
      )
      return (
        <div style={{ padding: '15px 0' }}>
          <ListGroup style={{ padding: '0 15px' }}>
            {listItems}
          </ListGroup>
        </div>
      )
    }
    return null
  }
  render () {
    let licences = null
    let progress = null
    if (this.props.tenantId) {
      if (this.props.role === 'superuser' || this.props.role === 'admin') {
        if (this.props.licencesUpdating) {
          licences = (<div style={{ height: '40px' }}>
            <ClipLoader size={40} /></div>)
          progress = null
        } else {
          licences = (<form onSubmit={() => this.onSaveLicence()}>
            <div className='form-group'>
              <label htmlFor='#licences' className='text-info'>Maximum Devices</label>
              <div className='input-group'>
                <input
                  id='licences'
                  maxLength={3}
                  min={this.props.devices.length}
                  type='number'
                  className='form-control'
                  name='licences'
                  value={this.currentTenant().licencesCount}
                  onChange={this.onLicenseChange}
                />
                <span className='input-group-btn'>
                  <button className='btn btn-primary' type='submit'>Update</button>
                </span>
              </div>
            </div>

          </form>)
        }
      }
    }

    progress = (
      <div>
        <div className='text-center'>
          <b className='text-info'>{this.props.devices.length} of {this.currentTenant().licencesCountSaved} licenses in use</b></div>
        <div className='progress' style={{ marginTop: '15px', height: 10 }}>
          <div className='progress-bar progress-bar-success'
            style={{ width: `${(this.props.devices.length / this.currentTenant().licencesCountSaved) * 100}%` }}
          >
            <span className='sr-only'>{(this.props.devices.length / this.currentTenant().licencesCountSaved) * 100}%</span>
          </div>
          <div className='progress-bar progress-bar-default'
            style={{ width: `${(1 - (this.props.devices.length / this.currentTenant().licencesCountSaved)) * 100}%` }}
          >
            <span className='sr-only'>{(1 - (this.props.devices.length / this.currentTenant().licencesCountSaved)) * 100}%</span>
          </div>
        </div></div>)

    let activateNew = null
    if (this.props.tenantId) {
      activateNew = (
        <div className='panel panel-default' style={{ margin: '20px 0', padding: 20, display: 'flex' }}>
          <section style={{ flex: 'auto' }}>
            <p className='text-info' style={{ margin: '0' }}><strong>Activate New Devices</strong></p>
            <p className='text-info' style={{ margin: '0' }}>Generate activation key to start using a new device in store</p>
          </section>
          <button
            style={{ margin: 5, textAlign: 'center', alignSelf: 'center' }}
            className='btn btn-primary'
            onClick={this.onActivateNew}
          >
            Create key
          </button>
        </div>
      )
    }

    const inlineSpinner = (<div style={{ marginLeft: '20px', height: '15px', display: 'inline-block' }}>
      <ClipLoader size={15} /></div>)
    let activity = null
    if (this.props.tenantId) {
      activity = (<ActivityLogList
        parentRecordId={this.props.selectedTenantId === undefined ? '0' : this.props.selectedTenantId.value}
        tableName='Devices'
      />)
    }
    const display = (<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ClipLoader size={40} /></div>)

    return (
      <div>
        {this.props.logged_in ?
          (this.props.companyId && this.props.tenantId) ?
            <div className='container-fluid' >
              <div className='row' style={{ display: 'flex' }}>
                {this.props.selectedTenantId && <div style={{ flex: '30%' }}>{licences}</div>}
                {this.props.selectedTenantId && <div style={{ flex: '30%', marginLeft: '20px', alignSelf: 'flex-end' }}>{progress}</div>}
              </div>
              <hr />
              {this.props.selectedTenantId &&
            <div className='row'>
              <div className='col-md-8' ref={p => (this.tabContainer = p)}>
                <h3>Manage Devices</h3>
                {activateNew}
                <AutoAffix viewportOffsetTop={0} container={this.tabContainer}>
                  <Tabs id='tabs' className='hide-tab-content'
                    defaultActiveKey={'Devices'}
                    activeKey={this.state.activeTab}
                    onSelect={this.handleTabSelect} style={{ paddingTop: '15px', backgroundColor: '#FFF', zIndex: 5 }}
                  >
                    <Tab
                      style={{ border: 'none', height: 180 }}
                      eventKey='Devices'
                      title={
                        (<span>Active Devices &nbsp;
                          {
                            this.props.devicesLoading // eslint-disable-line no-nested-ternary
                              ? inlineSpinner
                              : (this.getDevices().length !== this.props.devices.length
                                ? <span>
                                  <span className='label label-warning'>
                                    {this.getDevices().length}
                                  </span> of <span className='label label-primary'>{this.props.devices.length}</span> </span>
                                : <span className='label label-primary'>{this.getDevices().length}</span>)}</span>) as any
                      }
                    />
                    <Tab
                      style={{ border: 'none', height: 180 }}
                      eventKey='Pins' 
                      title={
                        (
                          <span>
                            Keys awaiting activation &nbsp;
                            {this.props.regPinsLoading
                              ? inlineSpinner
                              : <span className='label label-primary'>{this.props.pins.length}</span>}
                          </span>
                        ) as any
                      }
                    />
                  </Tabs>
                </AutoAffix>
                {this.state.activeTab === 'Devices' ? this.renderDeviceList() : this.renderPinList()}
              </div>
              <div className='col-md-4'>
                {activity}
              </div>
            </div>}
              {this.renderModal()}
            </div> :
            <div style={{ maxWidth: 500, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
              <p className='alert alert-danger'>Select Company and Enviroment</p>
            </div>
          : display }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    companys: state.session.companys === undefined ? [] :
      state.session.companys.map((x) => { return { value: x.id, label: x.name } }),
    tenants: state.session.tenants.map((x) => { return { ...x, value: x.id, label: x.name } }),
    selectedCompanyId: state.session.selectedCompanyId,
    selectedTenantId: state.session?.selectedTenantId,
    tenantId: state.session.tenantId,
    companyId: state.session.companyId,
    pins: state.device.regPins,
    devices: state.device.devices,
    role: state.session.role,
    licencesUpdating: state.session.licencesUpdating,
    tenantsLoading: state.session.loading,
    companysLoading: state.session.companysLoading,
    devicesLoading: state.device.devicesLoading,
    regPinsLoading: state.device.regPinsLoading,
    logged_in: state.session.logged_in
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    loadRegPins,
    clearRegPins,
    loadDevices,
    clearDevices,
    generateNewRegPin,
    deactivateDevice,
    updateLicences,
    saveLicences
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesPage)
