import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Modal } from 'react-bootstrap'
import { ClipLoader } from 'react-spinners'
import TextInput from './common/TextInput'
import * as Toast from '../utils/Toast'
import { setCurrentTemplateName, clearCurrentTemplateName } from '../actions/templateActions'
import DeleteModal from './common/DeleteModal'

interface OwnProps {
  onSave: (name: string) => void
  onDelete: () => void
  deleteMessage?: string
  name: string
  hideDeleteButton: boolean
  title: string
  templateSaving: boolean
  templateDeleting: boolean,
  listUrl: string
}

interface DispatchProps {
  setCurrentTemplateName: (name: string) => void
  clearCurrentTemplateName: () => void
}

interface StateProps {
}

type Props = StateProps & OwnProps & DispatchProps

interface State {
  name: string
  modalIsOpen: boolean
  modalError: ''
}

class TemplateDetailHeader extends React.Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      name: this.props.name,
      modalIsOpen: false,
      modalError: ''
    }
  }

  deleteAndCloseModal = (event) => {
    event.preventDefault()
    this.props.onDelete()
    this.closeModal()
  }

  closeModal = (event?) => {
    if (event)
      event.preventDefault()
    this.setState({
      modalIsOpen: false,
      modalError: ''
    })
  }

  openModal = (event) => {
    event.preventDefault()
    this.setState({
      modalIsOpen: true,
      modalError: ''
    })
  }

  save = () => {
    if (!this.state.name || this.state.name.trim().length === 0) {
      Toast.showErrorToast('Name cannot be blank')
      return
    }
    this.props.onSave(this.state.name)
  }

  componentWillMount () {
    this.props.setCurrentTemplateName(this.props.name)
  }

  componentWillUnmount () {
    this.props.clearCurrentTemplateName()
  }

  renderModal = () => {
    return (
      <DeleteModal
        onHide={this.closeModal}
        message={this.props.deleteMessage ? this.props.deleteMessage : 'Please confirm you wish to delete this template'}
        onCancel={this.closeModal}
        onConfirm={this.deleteAndCloseModal}
        show={this.state.modalIsOpen}
      />
    )
  }

  onNameChange = (event) => {
    this.setState({ name: event.target.value })
  }

  render () {
    const spinner = <ClipLoader size={40} />

    const inlineSpinner = <ClipLoader size={15} />

    const deleteBtn = this.props.hideDeleteButton ? null :
      <Button
        variant='danger'
        style={{ marginLeft: 5, textAlign: 'center' }}
        onClick={this.openModal}
        disabled={this.props.templateSaving || this.props.templateDeleting}
        className='pull-right'
      >
        Delete {this.props.templateDeleting && inlineSpinner}
      </Button>

    const style = {
      minWidth: '100%',
      maxWidth: '100%',
      height: 600
    }

    return (
      <div>
        <h3 style={{ flex: '1 1 100%' }}>
          <p>{this.props.title} Template Details {this.props.name}</p>
        </h3>
        <div className='row'>
          <div className='col-md-5 col-xs-6'>
            <TextInput
              maxLength={100}
              name='name'
              label='Name'
              value={this.state.name}
              onChange={this.onNameChange}
              style={{}}
            />
          </div>
          <div className='col-md-7 col-xs-6 pull-right'>
            <div className='form-group'>
              <label>&nbsp;</label>
              <div className='field'>
                {deleteBtn}
                <Button
                  variant='primary'
                  onClick={this.save}
                  disabled={this.props.templateSaving || this.props.templateDeleting}
                  className='pull-right'
                >
                  Save {this.props.templateSaving && inlineSpinner}
                </Button>
              </div>
            </div>
          </div>
          {this.renderModal()}
        </div>
      </div>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    setCurrentTemplateName,
    clearCurrentTemplateName
  }, dispatch)
}

export default connect<StateProps, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(TemplateDetailHeader)