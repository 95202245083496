import React from 'react'
import * as ReactDOM from 'react-dom'
import Sortable from 'sortablejs'

const store = {
  nextSibling: null,
  activeComponent: null
}

export class SortableJS extends React.Component<{
    options?: any,
    onChange: (items: any[], sortable: any, evt: any) => void,
    tag?: keyof JSX.IntrinsicElements,
    style?: React.CSSProperties,
    className?: string,
    children?: React.ReactNode // Specify children prop
}, any> {
  static defaultProps = {
    options: {},
    tag: 'div',
    style: {}
  }

  sortable: any = null

  tagRef = React.createRef<HTMLDivElement>()

  componentDidMount () {
    const options = { ...this.props.options }

    const data = [
      'onStart',
      'onEnd',
      'onAdd',
      'onSort',
      'onUpdate',
      'onRemove',
      'onFilter',
      'onMove'
    ]
    data.forEach((name) => {
      const eventHandler = options[name]

      options[name] = (evt) => {
        if (name === 'onStart') {
          store.nextSibling = evt.item.nextElementSibling
          store.activeComponent = this
        } else if ((name === 'onAdd' || name === 'onUpdate') && this.props.onChange) {
          const items = this.sortable.toArray()
          const remote = store.activeComponent
          const remoteItems = remote.sortable.toArray()

          evt.from.insertBefore(evt.item, store.nextSibling)

          if (remote !== this) {
            const remoteOptions = remote.props.options || {}

            if ((typeof remoteOptions.group === 'object') && (remoteOptions.group.pull === 'clone')) {
              // Remove the node with the same data-reactid
              evt.item.parentNode.removeChild(evt.item)
            }

            if (remote.props.onChange)
              remote.props.onChange(remoteItems, remote.sortable, evt)
          }

          if (this.props.onChange)
            this.props.onChange(items, this.sortable, evt)
        }

        if (evt.type === 'move') {
          const canMove = eventHandler ? eventHandler(evt) : true
          return canMove
        }

        setTimeout(() => {
          if (eventHandler)
            eventHandler(evt)
        }, 0)
      }
    })

    this.sortable = Sortable.create(this.tagRef.current, options)
  }

  componentWillUnmount () {
    if (this.sortable) {
      this.sortable.destroy()
      this.sortable = null
    }
  }

  render () {
    const { children, className, tag: Tag, style } = this.props
    return (
      <div ref={this.tagRef}>
        <Tag className={className} style={style}>
          {children}
        </Tag>
      </div>
    ) // Dynamically render the tag
  }
}
