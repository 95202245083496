import authenticator from '../auth/authenticator'
import { GetApiRequest } from '../utils/ApiUtils'
import fetcher from './ApiMethods'

export default class AppInfoApi {

  static loadAppInfo () {
    const request = GetApiRequest('/api/AppInfo/', {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static addNewCurrentVersion (version: string) {
    const payload = {
      CurrentVersion: version,
      ActivityLog: [] as any[]
    }

    const callingMethod = 'POST'
    const uri = '/api/AppInfo'
    const request = GetApiRequest(uri, {
      method: callingMethod,
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(payload)
    })

    return fetcher.processFetch(request)
  }
}
