import { connect } from 'react-redux'

import {
  DispatchProps, OwnProps,
  StateProps, Rules as TransferOutWarehouseRule
} from './Rules'

import {
  addTransferOutWarehouseRule, changeTransferOutWarehouseRuleOperator, changeTransferOutWarehouseRuleTagGroup,
  changeTransferOutWarehouseRuleTags, deleteTransferOutWarehouseRule
} from '../actions/storeActions'

const mapDispatchToProductRuleProps = (dispatch): DispatchProps => {
  return {
    addRule: (subMenuId) => dispatch(addTransferOutWarehouseRule(subMenuId)),
    changeRuleOperator: (ruleId, operator) => dispatch(changeTransferOutWarehouseRuleOperator(ruleId, operator)),
    changeRuleTagGroup: (ruleId, tagGroup) => dispatch(changeTransferOutWarehouseRuleTagGroup(ruleId, tagGroup)),
    changeRuleTags: (ruleId, tags) => dispatch(changeTransferOutWarehouseRuleTags(ruleId, tags)),
    deleteRule: (id) => dispatch(deleteTransferOutWarehouseRule(id))
  }
}

function mapStateToProductRuleProps (state): StateProps {
  return {
    rules: state.store.transferOutWarehouseFilter.rules,
    ruleChilds: state.store.transferOutWarehouseFilter.ruleChilds,
    tagGroups: state.store.transferOutWarehouseFilter.tagGroups
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProductRuleProps,
  mapDispatchToProductRuleProps
)(TransferOutWarehouseRule)