import initialState from './initialState'
import { DATA_LOADED,
  DATA_LOADING, DATA_EXPLORER_ID_CHANGE, DATA_EXPLORER_TYPE_CHANGE } from '../actions/dataExplorerActions'

export default function dataExplorerReducer (state = initialState.store, action: any) {

  switch (action.type) {
    case DATA_EXPLORER_ID_CHANGE:
    {
      return {
        ...state,
        id: action.id
      }
    }
    case DATA_EXPLORER_TYPE_CHANGE: {
      let dataExplorerType = action.dataExplorerType === undefined ? '' : action.dataExplorerType
      return {
        ...state,
        id: '',
        dataExplorerType
      }
    }
    case DATA_LOADING: {
      return {
        ...state,
        loading: true
      }
    }

    case DATA_LOADED: {
      return {
        ...state,
        loading: false,
        data: action.data,
        page: action.page
      }
    }

    default: return state
  }
}