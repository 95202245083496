export class Carrier {
  carrierId: string
  carrierName: string
  selected: boolean
  constructor (carrier: Carrier) {
    if (carrier) {
      this.carrierId = carrier.carrierId
      this.carrierName = carrier.carrierName
      this.selected = carrier.selected
    }
  }
}
