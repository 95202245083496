import authenticator from '../auth/authenticator'
import { GetApiRequest } from '../utils/ApiUtils'
import fetcher from './ApiMethods'

export default class TenantApi {

  static loadTenants (companyId: number, fetchSecrets: boolean = false) {
    const request = GetApiRequest(`/api/adminportaltenants?companyid=${companyId}&fetchSecrets=${fetchSecrets}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static regenerateTenantApiKey (tenantId: number) {
    const request = GetApiRequest(`/api/adminportaltenants/GenerateTenantApiKey?tenantId=${tenantId}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static saveLicences (id: string, licences: number) {
    const licenseDetails = { tenantId: id, licencesCount: licences}

    const request = GetApiRequest('/api/adminportaltenants/UpdateLicences', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(licenseDetails)
    })

    return fetcher.processFetch(request)
  }
}
