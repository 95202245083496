
import {
  CLEAR_CUSTOMERS, DELETE_CUSTOMER_ERROR, DELETE_CUSTOMER_FINISHED,
  DELETE_CUSTOMER_STARTED,
  DELETE_CUSTOMER_SUCCESS, CUSTOMER_ADD,
  CUSTOMER_API_STARTED, CUSTOMER_COMPANY_SELECT, CUSTOMER_LOADED, CUSTOMER_NAME_CHANGED,
  CUSTOMER_REDIRECT_LISTING, CUSTOMER_RULE_ADD,
  CUSTOMER_RULE_CHANGE_OPERATOR, CUSTOMER_RULE_CHANGE_TAGGROUP, CUSTOMER_RULE_CHANGE_TAGS, CUSTOMER_RULE_DELETE,
  CUSTOMER_SUBMENU_MATCH, CUSTOMER_TAGS_LOADED, CUSTOMER_TENANT_SELECT,
  CUSTOMERS_LOADED, CUSTOMERS_LOADING, CUSTOMERS_SAVED,
  CUSTOMERS_SAVING, CUSTOMERS_UPDATED, CUSTOMER_FIELD_SET_MANDATORY, CUSTOMER_GROUP_SET_ENABLED, CUSTOMER_CLEAR,
  CUSTOMER_ADDRESS_VALIDATION_APIKEY, CUSTOMER_ADDRESS_VALIDATION_ENABLE, CUSTOMER_ADDRESS_VALIDATION_SELECT,
  TOGGLE_CHECK_FOR_ATTACHED_CUSTOMER_ENABLED,
  SET_DELETE_CUSTOMER_SUCCESS,
  SET_CUSTOMERS_SAVED
} from '../actions/customerActions'
import {
  COMPANY_SELECTED, TENANT_SELECTED
} from '../actions/sessionActions'
import { Rule, RuleChild, SubFilter } from '../types/Menu'
import { Customer } from '../types/Customer'
import initialState from './initialState'

export default function customerReducer (state = initialState.customer, action: any) {
  switch (action.type) {
    case TOGGLE_CHECK_FOR_ATTACHED_CUSTOMER_ENABLED: {
      return {
        ...state,
        customer: {
          ...state.customer,
          checkForAttachedCustomerEnabled: !state.customer.checkForAttachedCustomerEnabled
        }
      }
    }
    case CUSTOMER_ADDRESS_VALIDATION_SELECT: {
      let google = action.provider === 'GOOGLE'
      return {
        ...state,
        customer: {
          ...state.customer,
          googleApiKeyEnabled: google,
          kleberApiKeyEnabled: !google
        }
      }
    }

    case CUSTOMER_ADDRESS_VALIDATION_ENABLE: {
      return {
        ...state,
        customer: {
          ...state.customer,
          validationEnabled: action.enable
        }
      }
    }

    case CUSTOMER_ADDRESS_VALIDATION_APIKEY: {
      return {
        ...state,
        customer: {
          ...state.customer,
          googleApiKey: state.customer.googleApiKeyEnabled ? action.key : state.customer.googleApiKey,
          kleberApiKey: state.customer.kleberApiKeyEnabled ? action.key : state.customer.kleberApiKey
        }
      }
    }

    case CUSTOMER_REDIRECT_LISTING:
      return state

    case CUSTOMER_ADD:
    case CUSTOMER_CLEAR:
      const customer = new Customer()
      customer.tenantId = action.Id
      return {
        ...state,
        customer,
        customerDeleted: false,
        customerSaved: false,
        customerDeletedSuccess: false
      }
    case CUSTOMERS_SAVED: {
      return {
        ...state,
        customerSaving: false,
        customerSaved: action.success,
        customerSavedId: action.result.id
      }
    }
    case SET_CUSTOMERS_SAVED: {
      return {
        ...state,
        customerSaved: action.value
      }
    }


    case CUSTOMERS_SAVING: {
      return {
        ...state,
        customerSaving: true
      }
    }
    case CUSTOMER_LOADED:
    {
      return {
        ...state,
        customer: action.customer,
        customersLoading: false,
        customersLoaded: true,
        customerSaving: false,
        customerDeleted: false
      }

    }
    case CUSTOMERS_LOADED:
    {
      const newProducts: any = []
      action.customers.forEach((y: any) => {
        const newProduct = new Customer(y)
        newProducts.push(newProduct)
      })

      return {
        ...state,
        customers: newProducts,
        customerSaved: false,
        customerDeleted: false,
        customersLoading: false,
        customersLoaded: true
      }
    }
    case CUSTOMER_LOADED:
      return { ...state, error: '', customerSaved: false, customerDeleted: false }
    case CUSTOMER_TAGS_LOADED:
      return { ...state, error: '', customerLoaded: false, customer: { ...state.customer, tagGroups: action.tags } }

    case CUSTOMERS_LOADING:
      return { ...state, customersLoading: true, customers: undefined }
    case CLEAR_CUSTOMERS:
    {
      const newProducts: any = []
      return {
        ...state,
        customers: newProducts,
        customersLoading: false,
        customersLoaded: false,
        customerSaving: false
      }
    }
    case CUSTOMER_COMPANY_SELECT:
      return { ...state, customer: { ...state.customer, companyId: action.id } }

    case CUSTOMER_TENANT_SELECT:
      return { ...state, customer: { ...state.customer, tenantId: action.id } }

    case COMPANY_SELECTED:
    {
      return {
        ...state, customer: {
          ...state.customer, companyId: action.companyId
            ? action.companyId.value : ''
        }
      }

    }
    case TENANT_SELECTED:
    {
      return {
        ...state, customer: {
          ...state.customer, tenantId: action.tenantId
            ? action.tenantId.value : ''
        }
      }
    }

    case CUSTOMERS_UPDATED:
      return { ...state, customers: action.customers }
    case DELETE_CUSTOMER_SUCCESS: {
      return { ...state, customerDeletedSuccess: true }
    }
    case SET_DELETE_CUSTOMER_SUCCESS: {
      return { ...state, customerDeletedSuccess: action.value }
    }
    case DELETE_CUSTOMER_ERROR:
      return { ...state, error: action.error, customerSaved: false, customerDeleted: false }
    case DELETE_CUSTOMER_STARTED:
      return { ...state, customerDeleted: true, customerDeletedSuccess: false }
    case DELETE_CUSTOMER_FINISHED:
      return { ...state, customerDeleted: false }
    case CUSTOMER_API_STARTED:
      return { ...state, error: '', customerSaved: false, customerDeleted: false }

    case CUSTOMER_NAME_CHANGED: {
      const { name } = action
      return {
        ...state,
        customer: {
          ...state.customer,
          name
        }
      }
    }

    case CUSTOMER_SUBMENU_MATCH: {
      const { id, all } = action
      return {
        ...state,
        customer: {
          ...state.customer,
          subFilter: {
            ...state.customer.subFilter,
            isMatchingAll: all
          }
        }
      }
    }

    case CUSTOMER_RULE_ADD:
    {

      return {
        ...state,
        customer: {
          ...state.customer,
          rules: [
            ...state.customer.rules,
            new Rule({ subFilterId: action.subFilterId })
          ]
        }
      }
    }

    case CUSTOMER_RULE_CHANGE_TAGGROUP:
    {
      return {
        ...state,
        customer: {
          ...state.customer,
          rules: [
            ...state.customer.rules.map((p) => {
              if (p.id === action.ruleId) {
                return {
                  ...p,
                  tagGroupId: action.tagGroup
                }
              } else { return p }
            })
          ],

          ruleChilds: [
            ...state.customer.ruleChilds.filter((p) => p.ruleId !== action.ruleId)
          ]
        }
      }
    }

    case CUSTOMER_RULE_CHANGE_OPERATOR:
    {
      return {
        ...state,
        customer: {
          ...state.customer,
          rules: [
            ...state.customer.rules.map((p) => {
              if (p.id === action.ruleId) {
                return {
                  ...p,
                  operatorType: action.operator
                }
              } else {
                return p
              }
            })
          ],
          ruleChilds: [
            ...state.customer.ruleChilds.filter((p) => p.ruleId !== action.ruleId)
          ]
        }
      }
    }

    case CUSTOMER_RULE_DELETE:
    {
      return {
        ...state,
        customer: {
          ...state.customer,
          rules: [
            ...state.customer.rules.filter((p) => p.id !== action.id)
          ],
          ruleChilds: [
            ...state.customer.ruleChilds.filter((p) => p.ruleId !== action.id)
          ]
        }
      }
    }
    case CUSTOMER_RULE_CHANGE_TAGS:
      return {
        ...state,
        customer: {
          ...state.customer,
          ruleChilds: [
            ...state.customer.ruleChilds.filter((p) => p.ruleId !== action.ruleId),
            ...action.tags.map((p: any) => new RuleChild({ ruleId: action.ruleId, tagId: p }))
          ]
        }
      }

    case CUSTOMER_FIELD_SET_MANDATORY:
      return {
        ...state,
        customer: {
          ...state.customer,
          groups: state.customer.groups ? state.customer.groups
            .map(p => p.group === action.group
              ? {
                ...p,
                fieldOptions: p.fieldOptions.map(q =>
                  q.name === action.fieldName || !action.fieldName ?
                    { ...q, mandatory: action.mandatory } : q)

              }
              : p
            ) : []
        }
      }
    case CUSTOMER_GROUP_SET_ENABLED:
      return {
        ...state,
        customer: {
          ...state.customer,
          groups: state.customer.groups ? state.customer.groups
            .map(p => p.group === action.group
              ? {
                ...p,
                fieldOptions: p.fieldOptions.map(q =>
                  q.name === action.fieldName || !action.fieldName ?
                    { ...q, enabled: action.enabled } : q)
              }
              : p
            ) : []
        }
      }
    default:
      return state
  }
}
