import fetcher from './ApiMethods'
import authenticator from '../auth/authenticator'
import { GetApiRequest } from '../utils/ApiUtils'
export default class SessionApi {

  static login (credentials: any) {
    const loginCredentials = { email: credentials.email, password: credentials.password, twoFactorPin: credentials.twoFactorPin }

    const request = GetApiRequest('/api/login', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(loginCredentials)
    })

    return fetcher.processFetch(request)
  }

  static forgotPassword (credentials: any) {
    const userEmail = { email: credentials.email }

    const request = GetApiRequest('/api/login/forgetpassword', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(userEmail)
    })

    return fetcher.processFetch(request)
  }

  static resetPassword (token: string, eml: string, pwd: string) {
    const resetPasswordDetails = { token: token, email: eml, password: pwd}

    const request = GetApiRequest('/api/login/resetpassword', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(resetPasswordDetails)
    })

    return fetcher.processFetch(request)
  }

  static changePassword (currentPwd: string, pwd: string) {
    const changePasswordDetails = { oldPassword: currentPwd, password: pwd}

    const request = GetApiRequest('/api/login/changepassword', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      }),
      body: JSON.stringify(changePasswordDetails)
    })

    return fetcher.processFetch(request)
  }

  static getConfiguration () {
    const request = GetApiRequest('/api/login/GetConfiguration', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    })

    return fetcher.processFetch(request)
  }

  static validateSession () {
    const request = GetApiRequest('/api/login/ValidateSession', {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }

  static invalidateSession () {
    const request = GetApiRequest('/api/login/InvalidateSession', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticator.getLoggedInToken()}`
      })
    })

    return fetcher.processFetch(request)
  }
}
