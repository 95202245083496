import {
  USER_API_STARTED, USERS_LOADED, USERS_UPDATED, SAVE_USER_SUCCESS,
  SAVE_USER_ERROR, DELETE_USER_SUCCESS, DELETE_USER_ERROR, USER_LOADED, USERS_LOADING, CLEAR_USERS,
  SET_USER_SAVED,
  SET_USER_DELETED,
  SET_USERS_LOADED,
  SET_ERROR,
  SET_USER_SAVING,
  SET_USER_DELETING
} from '../actions/userActions'
import initialState from './initialState'
import User from '../types/User'

export default function userReducer (state = initialState.user, action: any) {
  switch (action.type) {
    case USERS_LOADED:
    {
      const newUsers: any = []
      let i = 0
      action.users.forEach((y: any) => {
        const newUser = new User(y)
        newUser.sequence = i
        i += 1
        newUsers.push(newUser)
      })

      return { ...state, users: newUsers, error: '', userSaved: false, userDeleted: false, usersLoading: false, usersLoaded: true }
    }
    case USER_LOADED:
      return { ...state, error: '', userSaved: false, userDeleted: false }
    case CLEAR_USERS:
    {
      const newUsers: any = []
      return { ...state, companys: newUsers, usersLoading: false, usersLoaded: false }
    }
    case USERS_LOADING:
      return { ...state, usersLoading: true }
    case USERS_UPDATED:
      return { ...state, users: action.users }
    case SAVE_USER_SUCCESS:
    {
      const newUsers = []
      let thisOnedone = false
      const newUser = new User(action.userDetails)

      state.users.forEach((y: any) => {
        if (y.id === action.userDetails.id) {
          thisOnedone = true
          newUsers.push(newUser)
        } else {
          newUsers.push(new User(y))
        }
      })

      if (!thisOnedone) {
        newUser.sequence = newUsers.length + 1
        newUsers.push(newUser)
      }

      return {
        ...state, users: newUsers, error: '', userSaved: true, id: action.userDetails.id,
        companies: action.userDetails.companies
      }
    }
    case SAVE_USER_ERROR:
      return { ...state, error: action.error, userSaved: false, userDeleted: false, userSaving: false }
    case DELETE_USER_SUCCESS:
    {
      const newUsers: any = []
      state.users.filter((x: any) => x.id !== action.id).forEach((y) => {
        newUsers.push(new User(y))
      })
      return { ...state, error: '', users: newUsers, userDeleted: true, userDeleting: false }
    }
    case DELETE_USER_ERROR:
      return { ...state, error: action.error, userSaved: false, userDeleted: false }
    case USER_API_STARTED:
      return { ...state, error: '', userSaved: false, userDeleted: false, userSaving: true }
    case SET_USER_SAVED:
      return { ...state, userSaved: action.value }
    case SET_USERS_LOADED:
      return { ...state, usersLoaded: action.value }
    case SET_USER_DELETED:
      return { ...state, userDeleted: action.value }
    case SET_USER_SAVING:
      return { ...state, userSaving: action.value }
    case SET_USER_DELETING:
      return { ...state, userDeleting: action.value }
    case SET_ERROR:
      return { ...state, error: action.value }
    default:
      return state
  }
}
