import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

// Custom HOC to pass router props (navigate, location, params)
const withRouter = (Component) => {
  function ComponentWithRouterProp (props) {
    let navigate = useNavigate()
    let location = useLocation()
    let params = useParams()

    return (
      <Component
        {...props}
        navigate={navigate}
        location={location}
        params={params}
      />
    )
  }

  return ComponentWithRouterProp
}

export default withRouter