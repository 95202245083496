export class EmailSettings {
  provider: number = 0
  providerName: string = 'None'
  apiKey: string = ''
  domain: string = ''
  constructor (mailSettings?: EmailSettings) {
    if (mailSettings !== undefined && mailSettings !== null) {
      this.provider = mailSettings.provider
      this.apiKey = mailSettings.apiKey
      this.domain = mailSettings.domain
      this.providerName = mailSettings.providerName
    }
  }
}