import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import withRouter from './Utils/WithRouter'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import Table from './common/Table'
import * as Toast from '../utils/Toast'
import {getAsync} from '../api/httpClient'
import {TemplateHeader, ShopUpdateType} from '../types/Template'
import EmptyState from './common/EmptyState'

interface DispatchProps {
}

interface State {
  search: string
  templateList: TemplateHeader[]
  templateListLoading: boolean
}

interface OwnProps {
  url: string
  title: string,
  description: string,
  templateType: ShopUpdateType
  children?: any
}

interface StateProps {
  tenantId: string
  companyId: string
  logged_in: boolean
}

type Props = DispatchProps & OwnProps & StateProps

class TemplateList extends React.Component<Props & any, State> {

  columns = [
    { key: 'name', name: 'Name', sortable: true, resizable: true }
  ]

  constructor (props) {
    super(props)
    this.state = {
      search: '',
      templateList: [],
      templateListLoading: false
    }
  }

  componentDidMount () {
    
    if (this.props.tenantId !== '' && this.props.tenantId !== undefined)
      this.loadTemplateList(this.props.tenantId)
  }

  componentWillReceiveProps (nextProps) {
    // If company has changed clear the list
    // If the tenant has changed get their template list
    if (nextProps.tenantId === undefined && (nextProps.companyId !== undefined &&
        nextProps.companyId !== '' && this.props.companyId !== nextProps.companyId)) {
      this.setState({templateList: []})
    } else if (nextProps.tenantId !== undefined && nextProps.tenantId !== '' && nextProps.tenantId !== this.props.tenantId) {
      this.loadTemplateList(nextProps.tenantId)
    }
  }

  onChange (event) {
    const field = event.target.name
    const state = this.state
    state[field] = event.target.value
    return this.setState({ ...state })
  }

  loadTemplateList (id) {
    this.setState({templateListLoading: true})
    let requestParams = {
      url: `/api/tenant/${id}/template/headers/${this.props.templateType}`,
      succeededCallback: (templateList) => this.setState({templateList: templateList, templateListLoading: false}),
      failedCallback: (error) => {
        this.setState({templateListLoading: false})
        Toast.showErrorToast(error)
      }
    }
    getAsync(requestParams)
  }

  onNew = () => {
    this.props.navigate(`${this.props.url}/new`)
  }

  onRowClick = (id) => {
    this.props.navigate(`${this.props.url}/${id}`)
  }

  getRows () {
    if (this.state.search === '')
      return this.state.templateList === undefined ? [] : this.state.templateList
    return this.state.templateList.filter((template) => template.name.search(new RegExp(this.state.search, 'i')) !== -1)
  }

  renderTemplateList = () => {
    const spinner = 
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
        <ClipLoader size={40} />
      </div>
    const colHeads = this.columns.map((p) => <th key={p.key}>{p.name}</th>)
    const rows = this.getRows()
    const colBody = rows.map((col) => {
      return (
        <tr key={col.id} onClick={() => this.onRowClick(col.id)}>
          <td key={`name${col.id}`}>{col.name}</td>
          <td key={`style${col.id}`}>
            {col.modified ? <span> Last modified {moment(col.modified).format('DD/MM/YY, h:mm A')}</span> : null}
            {col.modifiedByName ?
              <span>
                &nbsp;by <strong>{col.modifiedByName}</strong>
              </span> :
              null}
          </td>
          <td className='text-right'>
            <button className='btn btn-link' onClick={() => this.onRowClick(col.id)}>
              <i className='glyphicon glyphicon-chevron-right' />
            </button>
          </td>
        </tr>
      )
    })
    const dataGrid = () => {
      return rows.length ?
        <Table data={colBody}>          
          <thead>
            <tr>
              {colHeads}
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {colBody}
          </tbody>
        </Table> :
        <EmptyState message={this.state.templateList.length ? 'No templates found' : 'No templates have been created'}/>
    }

    return (
      <div>

        <h3>{this.props.title}</h3>
        <div className='panel panel-default'
          style={{ margin: '20px 0px' }}
        >
          <div className='panel-heading clearfix'>
            <div className='col-xs-12 col-md-6'
              style={{ display: 'flex', flexDirection: 'row', minHeight: '70px', justifyContent: 'flex-start' }}
            >
              <div style={{ alignSelf: 'center', margin: '0' }}>
                <p className='text-info' style={{ alignSelf: 'center', margin: '0px' }}>
                  <strong>{this.props.description}</strong>
                </p>
                <p style={{ margin: '0' }}>{this.props.children}</p>
              </div>
            </div>

            <div className='col-xs-12 col-md-6 text-right'
              style={{ display: 'flex', flexDirection: 'row', minHeight: '70px', justifyContent: 'flex-end' }}
            >
              <button
                style={{ alignSelf: 'center' }}
                className='btn btn-primary'
                onClick={this.onNew}
              >
              Create new template
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div className='row'>
          <div className='col-sm-4 col-xs-6'>
            <div className='right-inner-addon'>
              <i className='text-muted glyphicon glyphicon-search' />
              <input
                maxLength={100}
                name='search'
                value={this.state.search}
                onChange={(e) => this.onChange(e)}
                className='form-control'
                placeholder='Search by template name…'
              />
            </div>
          </div>
        </div>
        <div ref='table' className='table-responsive'>
          {this.state.templateListLoading ? spinner : dataGrid()}
        </div>
      </div>
    )
  }

  render () {
    const display = (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ClipLoader size={40} />
      </div>
    )
    return (
      <div>
        { this.props.logged_in ?
          this.props.companyId && this.props.tenantId ?
            <div className='container-fluid'>
              {this.renderTemplateList()}
            </div> :
            <div style={{ maxWidth: 500, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
              <p className='alert alert-danger'>Select Company and Enviroment</p>
            </div>
          : display
        }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    tenantId: state.session.tenantId,
    companyId: state.session.companyId,
    logged_in: state.session.logged_in
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  }, dispatch)
}
export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TemplateList as any))
