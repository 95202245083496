import * as $ from 'jquery'
import 'datatables.net'
import FixedHeader from 'datatables.net-fixedheader'
import React, { createRef } from 'react'

let opts = {
  paginate: false,
  searching: false,
  ordering: true,
  info: false,
  columns: undefined
}

export default class Table extends React.Component<any, any> {
  tableRef = createRef<HTMLTableElement>()

  componentDidMount () {
    $('.fixedHeader-floating').remove()
    const table = this.GetTable().DataTable({ ...opts })

    // tslint:disable-next-line:no-unused-expression
    const dt = new FixedHeader(table)
    $.data(this.GetTable()[0], 'dt', dt)
  }

  componentWillUnmount () {
    const table = this.GetTable().DataTable()
    let dt = $.data(this.GetTable()[0], 'dt')

    dt._scroll = () => true
    $(window).off(dt.s?.namespace)
    table.destroy(false)
  }

  shouldComponentUpdate (nextProps: Object, nextState) {
    for (let prop in nextProps) {
      if (nextProps[prop] !== this.props[prop] && prop !== 'children') return true
    }
    return false
  }

  GetTable () {
    let $table = $(this.tableRef.current)
    let columns = []
    $table.find('tr th').each((q, p) =>
      columns.push(p.innerText.trim() === '' ? { orderable: false } : null)
    )
    opts = {
      ...opts,
      columns
    }
    return $table
  }

  componentWillUpdate (nextProps, nextState, nextContext) {
    this.GetTable().DataTable().destroy(false)
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    this.GetTable().DataTable({ ...opts })
  }

  render () {
    return (
      <table ref={this.tableRef} className='table table-hover'>
        {this.props.children}
      </table>
    )
  }
}