import moment from 'moment'

import React from 'react'
import { connect } from 'react-redux'
import withRouter from './Utils/WithRouter'
import Select from 'react-select'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import {
  loadProducts
} from '../actions/productActions'
import { ProductFilter } from '../types/Product'
import Table from './common/Table'
import EmptyState from './common/EmptyState'

interface IDispatchProps {
    loadProducts
}

interface IStateProps {
    products: ProductFilter[],
    productsLoading: boolean, claims: any, tenantId: string, companyId: string
}

interface IState {
    search: string,
    height: number
}

class MenuList extends React.Component<IDispatchProps & IStateProps & any, IState> {

  defaultProps = {
    products: null,
    claims: {}
  }
  columns = [
    { key: 'name', name: 'Name', sortable: true, resizable: true }

  ]
  constructor (props) {
    super(props)
    this.state = {
      search: '',
      height: -1
    }
  }

  componentDidMount () {
    
    if (this.props.tenantId !== '' && this.props.tenantId !== undefined) {
      this.props.loadProducts(this.props.tenantId)
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.tenantId === undefined &&
            (nextProps.companyId !== undefined && nextProps.companyId !== ''
                && this.props.companyId !== nextProps.companyId)) {
      this.props.loadProducts('-1')
    } else if (nextProps.tenantId !== undefined && nextProps.tenantId
            !== '' && this.props.tenantId !== nextProps.tenantId) {
      this.props.loadProducts(nextProps.tenantId)
    }
  }

  onChange (event) {
    const field = event.target.name
    const state = this.state
    state[field] = event.target.value
    return this.setState({ ...state })
  }

  onNew = () => {
    this.props.navigate('/products/new')
  }

  onRowClick = (id) => {
    this.props.navigate(`/products/${id}`)
  }

  getRows () {
    if (this.state.search === '') {
      return this.props.products === undefined ? [] : this.props.products
    }
    return this.props.products.filter((p) => p.name.search(new RegExp(this.state.search, 'i')) !== -1)
  }

  changeState = (name, value) => {
    const state = this.state
    state[name] = value
    return this.setState(state)
  }

  renderProduct = () => {
    const spinner = (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
        <ClipLoader size={40} />
      </div>
    )
    const colHeads = this.columns.map((p) => <th key={p.key}>{p.name}</th>)
    const rows = this.getRows()
    const colBody = rows.map((col) => {
      const modifiedDate = new Date(col.modifiedDate)
      const untilModifiedDate = moment(modifiedDate).format('DD/MM/YY, h:mm A')
      return (
        <tr key={col.id}
          onClick={() => this.onRowClick(col.id)}
        >
          <td key={`name${col.id}`}>{col.name}</td>
          <td key={`style${col.id}`}> Last modified {untilModifiedDate}
                        &nbsp;by <strong>{col.userName}</strong></td>
          <td className='text-right'>
            <button className='btn btn-link'
              onClick={() => this.onRowClick(col.id)}
            >
              <i className='glyphicon glyphicon-chevron-right' />
            </button>
          </td>
        </tr>
      )
    })
    const dataGrid = () => {
      return rows.length ?
        (
          <Table data={colBody} height={this.state.height}>            
            <thead>
              <tr>
                {colHeads}
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {colBody}
            </tbody>
          </Table>
        ) :
        <EmptyState message={this.props.products && this.props.products.length ? 'No templates found' : 'No product template available'} />
    }

    return (
      <div >
        <h3>Product Templates</h3>
        <div className='panel panel-default' style={{ margin: '20px 0px' }}>
          <div className='panel-heading clearfix'>
            <div className='col-xs-12 col-md-6'
              style={{ display: 'flex', flexDirection: 'row', minHeight: '70px', justifyContent: 'flex-start' }}
            >
              <div style={{ alignSelf: 'center', margin: '0' }}>
                <p className='text-info' style={{ alignSelf: 'center', margin: '0px' }}>
                  <strong>Create products filter</strong>
                </p>
                <p style={{ margin: '0' }}>Apply customised rules to filter products showing in your store.</p>
              </div>
            </div>
            <div className='col-xs-12 col-md-6 text-right'
              style={{ display: 'flex', flexDirection: 'row', minHeight: '70px', justifyContent: 'flex-end' }}
            >
              <button
                style={{ alignSelf: 'center' }}
                className='btn btn-primary'
                onClick={this.onNew}
              >
                                Create new template
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div className='row'>
          <div className='col-sm-4 col-xs-6'>
            <div className='right-inner-addon'>
              <i className='text-muted glyphicon glyphicon-search' />
              <input
                maxLength={100}
                name='search'
                value={this.state.search}
                onChange={(e) => this.onChange(e)}
                className='form-control'
                placeholder='Search by template name…'
              />
            </div>
          </div>
        </div>
        <div className='table-responsive'>
          {this.props.productsLoading ? spinner : dataGrid()}
        </div>
      </div>
    )
  }
  render () {
    return (
      (this.props.companyId && this.props.tenantId) ?
        (
          <div className='container-fluid'>
            {this.renderProduct()}
          </div>) :
        (
          <div style={{ maxWidth: 500, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <p className='alert alert-danger'>Select Company and Enviroment</p>
          </div>
        )
    )
  }
}

function mapStateToProps (state): IStateProps {
  return {
    productsLoading: state.product.productsLoading,
    products: state.product.productsLoading ? undefined : state.product.products,
    claims: state.session,
    tenantId: state.session.tenantId,
    companyId: state.session.companyId
  }
}
function mapDispatchToProps (dispatch): IDispatchProps {
  return bindActionCreators({
    loadProducts
  }, dispatch)
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuList))
