import moment from 'moment'

import React from 'react'
import { connect } from 'react-redux'
import withRouter from './Utils/WithRouter'
import Select from 'react-select'
import { ClipLoader } from 'react-spinners'
import { bindActionCreators } from 'redux'
import { loadCompanys } from '../actions/companyActions'
import {
  loadMenus
} from '../actions/menuActions'
import { Menu } from '../types/Menu'
import Table from './common/Table'
import EmptyState from './common/EmptyState'

class MenuList extends React.Component<{
    menuLoaded, loadMenus, menus: Menu[], error: string,
    menusLoading: boolean, claims: any, tenantId: string, companyId: string
} & any,
    { search: string, height: number }> {

  defaultProps = {
    menus: null,
    claims: {}
  }
  columns = [
    { key: 'name', name: 'Name', sortable: true, resizable: true }

  ]
  constructor (props) {
    super(props)
    this.state = {
      search: '',
      height: -1
    }
  }

  componentDidMount () {
    
    if (this.props.tenantId !== '' && this.props.tenantId !== undefined) {
      this.props.loadMenus(this.props.tenantId)
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.tenantId === undefined
            && (nextProps.companyId !== undefined
                && nextProps.companyId !== ''
                && this.props.companyId !== nextProps.companyId)) {
      this.props.loadMenus('-1')
    } else if (nextProps.tenantId !== undefined
            && nextProps.tenantId !== ''
            && this.props.tenantId !== nextProps.tenantId) {
      this.props.loadMenus(nextProps.tenantId)
    }
  }

  onChange (event) {
    const field = event.target.name
    const state = this.state
    state[field] = event.target.value
    return this.setState({ ...state })
  }

  onNew = () => {
    this.props.navigate('/navigations/new')
  }

  onRowClick = (id, e) => {
    this.props.navigate(`/navigations/${id}`)
  }

  getRows () {
    if (this.state.search === '')
      return this.props.menus === undefined ? [] : this.props.menus
    return this.props.menus.filter((p) => p.name.search(new RegExp(this.state.search, 'i')) !== -1)
  }

  changeState = (name, value) => {
    const state = this.state
    state[name] = value
    return this.setState(state)
  }

  renderMenu = () => {
    const spinner = (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
        <ClipLoader size={40} />
      </div>
    )
    const colHeads = this.columns.map((p) => <th key={p.key}>{p.name}</th>)
    const rows = this.getRows()
    const colBody = rows.map((col) => {
      const modifiedDate = new Date(col.modifiedOn)
      return (
        <tr key={col.id} onClick={(e) => this.onRowClick(col.id, e)}>
          <td key={`name${col.id}`}>{col.name}</td>
          <td key={`style${col.id}`}> Last modified {moment(modifiedDate).format('DD/MM/YY, h:mm A')}
                        &nbsp;by <strong>{col.userName}</strong></td>
          <td className='text-right'>
            <button className='btn btn-link' onClick={(e) => this.onRowClick(col.id, e)}>
              <i className='glyphicon glyphicon-chevron-right' />
            </button>
          </td>
        </tr>
      )
    })
    const dataGrid = () => {
      return rows.length ?
        <Table data={colBody} height={this.state.height}>
          <thead>
            <tr>
              {colHeads}
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {colBody}
          </tbody>
        </Table> :
        <EmptyState message={this.props.menus && this.props.menus.length ?
          'No templates found' :
          'No navigation template available'}
        />
    }

    return (
      <div ref='parent'>
        <h3>Navigation Templates</h3>
        <div className='panel panel-default' style={{ margin: '20px 0px' }}>
          <div className='panel-heading clearfix'>
            <div className='col-xs-12 col-md-6'
              style={{ display: 'flex', flexDirection: 'row', minHeight: 70, justifyContent: 'flex-start' }}
            >
              <div style={{ alignSelf: 'center', margin: '0' }}>
                <p className='text-info' style={{ margin: '0' }}>
                  <strong>Create product category</strong>
                </p>
                <p style={{ margin: '0' }}>Organise product browsing categories with sub menus.</p>
              </div>
            </div>
            <div className='col-xs-12 col-md-6 text-right'
              style={{ display: 'flex', flexDirection: 'row', minHeight: 70, justifyContent: 'flex-end' }}
            >
              <button
                style={{ alignSelf: 'center' }}
                className='btn btn-primary'
                onClick={this.onNew}
              >
                                Create new template
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div className='row'>
          <div className='col-sm-4 col-xs-6'>
            <div className='right-inner-addon'>
              <i className='text-muted glyphicon glyphicon-search' />
              <input
                maxLength={100}
                name='search'
                value={this.state.search}
                onChange={(e) => this.onChange(e)}
                className='form-control'
                placeholder='Search by template name…'
              />
            </div>
          </div>
        </div>
        <div ref='table' className='table-responsive'>
          {this.props.menusLoading ? spinner : dataGrid()}
        </div>
      </div>
    )
  }
  render () {

    return (
      (this.props.companyId && this.props.tenantId) ?
        <div className='container-fluid'>
          {this.renderMenu()}
        </div> :
        <div style={{ maxWidth: 500, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
          <p className='alert alert-danger'>Select Company and Enviroment</p>
        </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    menusLoading: state.menu.menusLoading,
    menus: state.menu.menusLoading ? undefined : state.menu.menus,
    claims: state.session,
    tenantId: state.session.tenantId,
    companyId: state.session.companyId
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    loadMenus
  }, dispatch)
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuList as any))
